import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ProcessMapTable from 'ecto-common/lib/ProcessMaps/ProcessMapTable';
import T from 'ecto-common/lib/lang/Language';
import {
  buttonListColumn,
  calculateDataTableMinHeight
} from 'ecto-common/lib/utils/dataTableUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import _ from 'lodash';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import styles from './SelectProcessMapDialog.module.css';
import { ProcessMapDataType } from './ProcessMapDataHandling';
import usePictureLibrary, { useNodeRelation } from './usePictureLibrary';

const topColumns = [
  {
    label: T.admin.processmaps.name,
    dataKey: 'name',
    idKey: 'id',
    linkColumn: true,
    dataFormatter: (value: string) => (
      <>
        <Icons.File /> {value}{' '}
      </>
    )
  }
];

interface SelectProcessMapDialogProps {
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  equipmentTypeId?: string;
  onConfirm?: (processMap: ProcessMapDataType[]) => void;
  actionText?: React.ReactNode;
}

const canChangeProcessMap = ({
  nodeId,
  processMap
}: {
  nodeId: string;
  processMap: ProcessMapDataType;
}) => {
  return (
    nodeId == null || // We are in equipment type context
    processMap.nodeId === nodeId || // Process map is assign to this node already
    processMap.nodeId == null // Process map has not yet been assigned to this node
  );
};

const isTypeSpecificNode = (nodeId: string) => (data: ProcessMapDataType) => {
  return nodeId != null && data.nodeId != null && data.nodeId !== nodeId;
};

const mapPriority = ({
  nodeId,
  data
}: {
  nodeId: string;
  data: ProcessMapDataType[];
}) => {
  if (nodeId === null) {
    // When we are in equipment type id selection.
    // each priority should step by 100 that way we can easily insert node specific items between them.
    return _.map(data, (selection, index) => ({
      ...selection,
      priority: 100 * (data.length - index)
    }));
  }

  const maxNodeSpecificPriority =
    data.find(isTypeSpecificNode(nodeId))?.priority ?? data.length - 1;
  let lastPriority = maxNodeSpecificPriority + 2;
  const result = _.map(data, (processMap) => {
    if (isTypeSpecificNode(nodeId)(processMap)) {
      lastPriority = processMap.priority;
      return processMap;
    }
    return {
      ...processMap,
      priority: lastPriority - 1
    };
  });

  return result;
};

const SelectProcessMapDialog = ({
  isOpen,
  onModalClose,
  nodeId,
  equipmentTypeId,
  onConfirm = null,
  actionText = T.common.save
}: SelectProcessMapDialogProps) => {
  const [searchString, setSearchString] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  const processMapsData = usePictureLibrary();
  const equipmentOrNodeId = nodeId ?? equipmentTypeId;

  const { query: relationQuery, nodeTypes } = useNodeRelation({
    nodeId,
    equipmentTypeId,
    options: { enabled: isOpen }
  });

  const [_selectedData, setSelectedData] = useState<ProcessMapDataType[]>(null);
  const selectedData = _selectedData ?? relationQuery.data?.items;

  const saveMutation = processMapsData.useUpdateNodeRelationMutation(
    equipmentOrNodeId,
    {
      onSuccess: () => {
        onModalClose();
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.processmaps.error.update);
      }
    }
  );

  const processMapsQuery = processMapsData.useListQuery(nodeTypes, {
    enabled: isOpen
  });

  const items = useMemo(
    () => _.keyBy(processMapsQuery.data, 'id'),
    [processMapsQuery.data]
  );

  const onClickRow = useCallback(
    (processMap: ProcessMapDataType) => {
      const selectedProcessMap = selectedData?.find(
        (data) => data.id === processMap.id
      );

      if (
        selectedProcessMap == null ||
        canChangeProcessMap({ nodeId, processMap: selectedProcessMap })
      ) {
        setHasChanges(true);
        setSelectedData(() => {
          return _.find(selectedData, (data) => data.id === processMap.id)
            ? _.reject(selectedData, (data) => data.id === processMap.id)
            : mapPriority({
                nodeId,
                data: [...(selectedData ?? []), processMap]
              });
        });
      }
    },
    [nodeId, selectedData]
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedData(null);
      setHasChanges(false);
    }
  }, [isOpen]);

  const onDelete = useCallback(
    ({ id }: ProcessMapDataType) => {
      const deleteItem = selectedData?.find((item) => item.id === id);
      if (
        deleteItem != null &&
        canChangeProcessMap({ nodeId, processMap: deleteItem })
      ) {
        setSelectedData(_.reject(selectedData, { id }));
        setHasChanges(true);
      }
    },
    [nodeId, selectedData]
  );

  const moveProcessMapUp = useCallback(
    (_processMap: ProcessMapDataType, indexToMove: number) => {
      if (indexToMove > 0) {
        const newSelection = [...selectedData];
        newSelection.splice(
          indexToMove - 1,
          2,
          newSelection[indexToMove],
          newSelection[indexToMove - 1]
        );
        setSelectedData(mapPriority({ nodeId, data: newSelection }));
        setHasChanges(true);
      }
    },
    [nodeId, selectedData]
  );

  const moveProcessMapDown = useCallback(
    (_processMap: ProcessMapDataType, indexToMove: number) => {
      if (indexToMove < selectedData.length - 1) {
        const newSelection = [...selectedData];
        newSelection.splice(
          indexToMove,
          2,
          newSelection[indexToMove + 1],
          newSelection[indexToMove]
        );
        setSelectedData(mapPriority({ nodeId, data: newSelection }));
        setHasChanges(true);
      }
    },
    [nodeId, selectedData]
  );

  const allTopColumns = useMemo(() => {
    return [
      ...topColumns,
      buttonListColumn<ProcessMapDataType>([
        {
          icon: <Icons.NavigationArrowDown />,
          action: moveProcessMapDown,
          enabled: (processMap) => canChangeProcessMap({ nodeId, processMap })
        },
        {
          icon: <Icons.NavigationArrowUp />,
          action: moveProcessMapUp,
          enabled: (processMap) => canChangeProcessMap({ nodeId, processMap })
        },
        {
          icon: <Icons.Delete />,
          action: onDelete,
          enabled: (processMap) => canChangeProcessMap({ nodeId, processMap })
        }
      ])
    ];
  }, [moveProcessMapDown, moveProcessMapUp, nodeId, onDelete]);

  const onConfirmClick = useCallback(() => {
    if (onConfirm) {
      onConfirm(selectedData);
    } else {
      saveMutation.mutate({
        originalData: relationQuery.data,
        assignedPictures: selectedData
      });
    }
  }, [onConfirm, selectedData, saveMutation, relationQuery.data]);

  const onChangeSearchString = useCallback(
    (changeEvent: React.ChangeEvent<HTMLInputElement>) =>
      setSearchString(changeEvent.target.value),
    []
  );

  const selectedIds = useMemo(() => _.map(selectedData, 'id'), [selectedData]);
  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      title={T.admin.editlocation.editprocessmap}
      onConfirmClick={onConfirmClick}
      actionText={actionText}
      disableActionButton={!hasChanges}
      headerIcon={Icons.Settings}
      isLoading={saveMutation.isPending}
      className={styles.dialog}
    >
      <KeyValueLine>
        <KeyValueGeneric keyText={T.admin.processmaps.currentmap}>
          <DataTable
            isLoading={relationQuery.isLoading || processMapsQuery.isLoading}
            columns={allTopColumns}
            data={selectedData}
            noDataText={T.admin.processmaps.nocurrentmap}
            minHeight={calculateDataTableMinHeight({
              pageSize: 1,
              withButtons: true,
              showNoticeHeaders: false
            })}
            showNoticeHeaders={false}
            hasError={relationQuery.isError}
          />
        </KeyValueGeneric>
      </KeyValueLine>

      <TextInput
        placeholder={T.common.search.placeholder}
        value={searchString}
        icon={<Icons.Search />}
        onChange={onChangeSearchString}
      />

      <ProcessMapTable
        searchString={searchString}
        onClickRow={onClickRow}
        showCheckboxes
        selectedIds={selectedIds}
        hasError={processMapsQuery.isError}
        isLoading={relationQuery.isLoading || processMapsQuery.isLoading}
        items={items}
      />
    </ActionModal>
  );
};

export default SelectProcessMapDialog;
