import React, { useCallback, useMemo, useContext } from 'react';
import SelectDashboardCollectionRelationModal, {
  SelectDashboardCollectionRelationModalProps,
  onUpdateRelationError,
  onUpdateRelationSuccess
} from './SelectDashboardCollectionRelationModal';
import APIGen, {
  DashboardCollectionResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

type SelectDashboardCollectionForBuildingTemplateModalProps =
  SelectDashboardCollectionRelationModalProps & {
    buildingTemplateId?: string;
  };

const SelectDashboardCollectionForBuildingTemplateModal = ({
  buildingTemplateId,
  onModalClose,
  ...otherProps
}: SelectDashboardCollectionForBuildingTemplateModalProps) => {
  const getRelationsArgs = useMemo(
    () => ({ buildingTemplateIds: [buildingTemplateId] }),
    [buildingTemplateId]
  );
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const updateRelationMutation =
    APIGen.AdminDashboard.addOrUpdateDashboardCollectionBuildingTemplateRelations.useMutation(
      {
        onSuccess: () => {
          onUpdateRelationSuccess(contextSettings, queryClient, onModalClose);
        },
        onError: () => {
          onUpdateRelationError();
        }
      }
    );

  const onSelectDone = useCallback(
    (selectedCollection: DashboardCollectionResponseModel) => {
      updateRelationMutation.mutate([
        {
          dashboardCollectionId: selectedCollection?.dashboardCollectionId,
          buildingTemplateId
        }
      ]);
    },
    [buildingTemplateId, updateRelationMutation]
  );

  return (
    <SelectDashboardCollectionRelationModal
      getRelationsArgs={getRelationsArgs}
      onSelectDone={onSelectDone}
      onModalClose={onModalClose}
      isLoading={updateRelationMutation.isPending}
      {...otherProps}
    />
  );
};

export default SelectDashboardCollectionForBuildingTemplateModal;
