//
// THIS FILE IS GENERATED, PLEASE DON'T EDIT.
//
/* eslint-disable */

import React from 'react';
import {
  getSelectedLanguage,
  setSelectedLanguage
} from '../utils/localStorageUtil';
import moment from 'moment';

let selectedLanguage = getSelectedLanguage();

import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';

const formatLangString = (template: string, ...params: any[]) => {
  if (template == null) {
    return [UnlocalizedString];
  }

  const formatIndex = (template: string): any => {
    const match = template.match(/{(\d+)}/);

    if (match) {
      const index = match[1] as any;

      if (index != null && index < params.length) {
        return params[index];
      }
    }

    return template;
  };

  const items = template.split(/({\d+})/).filter((s) => s != '');

  for (var i = 0; i < items.length; i++) {
    items[i] = formatIndex(items[i]);
  }

  return items;
};

const langEn = {
  admin: {
    alarmrules: {
      activationdelay: 'Activation delay',
      addrule: 'Add rule',
      editrule: 'Edit rule',
      resetoperator: 'Reset operator',
      resetvalue: 'Reset value',
      thresholdoperator: 'Threshold operator',
      thresholdvalue: 'Threshold value'
    },
    alarmselector: {
      placeholder: 'Select alarm signal template'
    },
    alarmstatuspanel: {
      tooltip: {
        alarmseverites: 'Class {0} alarms',
        noactivealarms: 'No active alarms'
      }
    },
    alarmtemplates: {
      addnew: 'Add new alarm template',
      addsignal: 'Add signal',
      delete: 'Delete alarm template',
      displayname: 'Display name',
      error: {
        couldnotremove: 'Could not delete template.',
        couldnotremoveconflict:
          'Could not delete template. It is currently used by other resources.',
        duplicate: 'Duplicate signal name.',
        duplicates: 'Duplicate signal names. Enter unique signal names.',
        notallowed: 'This is a system template and cannot be removed.'
      },
      header: 'Alarm templates',
      none: 'None',
      severity: 'Severity',
      systemname: 'Name',
      templatename: 'Template name',
      type: 'Type',
      unit: 'Unit',
      writable: 'Writable'
    },
    batterychargingthreshold: {
      maxchargerate: 'Maximum charge rate kW',
      maxdischargerate: 'Maximum discharge rate kW',
      powerelectricitygridsignal: 'Power Electricity Signal',
      stateofchargemax: 'Maximum state of charge %',
      stateofchargemin: 'Minimum state of charge %',
      timeconstant: 'Time constant'
    },
    buildings: {
      column: {
        districtheatingfacilityid: 'Facility id',
        name: 'Name',
        status: 'Status',
        statusdate: 'Status date',
        tags: 'Tags'
      },
      export: 'Export',
      nodatatext: 'No buildings found',
      numberofbuildings: 'Number of buildings',
      statusplaceholder: 'Status',
      tagsplaceholder: 'Tags',
      title: 'Buildings'
    },
    comfort: {
      askdeploymessage:
        'Tools have been updated. Do you wish to deploy configuration to the device?',
      askdeploytitle: 'Deploy tools?',
      deploydialogtitle: 'Deploy comfort tools',
      dialog: {
        create: {
          title: 'Add comfort tool'
        },
        edit: {
          title: 'Edit comfort tool'
        }
      },
      edittool: 'Edit tool...',
      enum: {
        sign: {
          negative: 'Negative',
          positive: 'Positive'
        }
      },
      error: {
        loading: 'Failed to load comfort tool data.',
        signalfetchfailed:
          'Failed to get signal information. Signal names will not be visible.'
      },
      name: 'Name',
      parameters: {
        alarmconfiguration: {
          inputconfiguration: {
            maxdiffvalue: 'Max diff value',
            maxvalue: 'Max value',
            mindiffvalue: 'Min diff value',
            minvalue: 'Min value',
            setsignal: 'Compare signal',
            signal: 'Signal'
          },
          sectiontitle: 'Alarms',
          timedelay: 'Time delay'
        },
        highpass: {
          maxoutputvalue: 'Max output',
          minoutputvalue: 'Min output',
          sectiontitle: 'Highpass',
          sign: 'Sign',
          timeconstant: 'Time constant'
        },
        integralgain: {
          integralgain: 'Integral gain',
          integraltime: 'Integral time',
          maxoutputvalue: 'Max output',
          maxouttempvalue: 'Max out temp',
          maxroomtempsetpointvalue: 'Max room temp setpoint',
          maxroomtempvalue: 'Max room temp',
          maxwindspeedvalue: 'Max windspeed',
          minoutputvalue: 'Min output',
          minroomtempsetpointvalue: 'Min room temp setpoint',
          minroomtempvalue: 'Min room temp',
          minwindspeedvalue: 'Min windspeed',
          outtempmeanhours: 'Outdoor temp. mean hours',
          outtempmeanmaxintegration: 'Outdoor temp. mean max integration',
          outtempsectiontitle: 'Integral Gain Out Temp',
          timeconstant: 'Time constant',
          weekdaysectiontitle: 'Integral Gain Weekday',
          weekendsectiontitle: 'Integral Gain Weekend',
          windspeedsectiontitle: 'Integral Gain Wind Speed'
        },
        pid: {
          derivativetime: 'Derivative time',
          gain: 'Gain',
          inputdeadbanddifflower: 'Input deadband diff lower',
          inputdeadbanddiffupper: 'Input deadband diff upper',
          integraltime: 'Integral time',
          maxoutputvalue: 'Max output',
          minoutputvalue: 'Min output',
          sectiontitle: 'PID',
          sign: 'Sign'
        },
        sectionstitle: 'Algorithm settings'
      },
      signals: {
        error: {
          failedtoload: 'Failed to load tool data',
          failedtosetsignal: 'Failed to set signal'
        },
        outdoortemperature: 'Outdoor temperature',
        roomtemperature: 'Room temperature',
        signalgroup: 'Comfort Signals',
        signalgroupformat: 'Comfort Signals - {0} radiators',
        signalnamecolumn: 'Name',
        tapwater: 'Tap water',
        windspeed: 'Wind speed'
      }
    },
    createbuilding: {
      error: {
        creating: 'Failed to add building, unknown error.',
        creatingsiblingnameconflict:
          'Failed to add building, another building with the same name shares a parent with this building.'
      },
      state: {
        creating: 'Creating building...'
      },
      success: {
        noerrorsformat: 'Successfully added new building {0}.',
        witherrorsformat:
          'Added new building {0}, but an error occured during initialization. {1}'
      }
    },
    createlocation: {
      addanotherformat: 'Add another {0}',
      addressfieldplaceholder: 'Select a location',
      addresslabel: 'Address:',
      addressplaceholder: 'Street',
      choosetemplate: 'Select template',
      configurelocation: {
        title: 'Choose location'
      },
      configurequipment: {
        title: 'Configure equipment'
      },
      configureweather: {
        searchplaceholder: 'Search for location',
        sectionheader: 'Weather point',
        title: 'Choose weather point'
      },
      connectionformat: 'Connection {0}',
      connections: {
        manage: 'Manage',
        name: 'Name',
        newconnection: '(new connection)',
        noconnectionsinfotext:
          'No connections found in template. You will have to add a new connection to receive and send data.',
        noexistingconnection:
          'The existing device has no connections. In order to use this device, set up a connection for the existing building.',
        slaveid: 'Slave ID',
        title: 'Connections',
        type: 'Type'
      },
      coordinate: 'Location',
      createnewdevice: 'Add new device',
      dashboardcollection: 'Dashboard collection',
      devicelabel: 'Energy manager:',
      equipment: {
        enable: 'Enable',
        name: 'Name',
        noequipment:
          'This template does not have any equipment configuration. Edit this template to add buildings before you can use it.',
        type: 'Type'
      },
      equipmentgrouptemplate: 'Building template',
      error: {
        addingequipment: 'Failed to initialize equipment.',
        creating: 'Failed to add location, unknown error.',
        creatingsiblingnameconflict:
          'Failed to add location, another location with the same name shares a parent with this location.',
        fetchdeviceconfig:
          'Failed to fetch device config for equipment, try again.',
        initconnection: 'Failed to initialize new connection.',
        missingconnection: 'Connection not instantiated properly.'
      },
      existingconnectioninfo:
        'Since you are connecting to an existing device a new connection with a new slave ID will automatically be added to handle the equipment in this location.',
      loaddashboarderror:
        'Failed to load dashboard collection from building template',
      namelabel: 'Name:',
      nameplaceholder: 'Name',
      none: 'None',
      parentlabel: 'Parent:',
      selectdashboardcollection: 'Select dashboard collection',
      selectexistingdevice: 'Select existing EM',
      state: {
        addingdashboardcollectionrelation: 'Adding dashboard collection...',
        addingequipment: 'Adding equipment...',
        addingweather: 'Adding weather point...',
        creating: 'Creating location...',
        initconnection: 'Initializing new connection...',
        updatingweather: 'Updating weather point...'
      },
      success: {
        noerrorsformat: 'Successfully added new location {0}.',
        witherrorsformat:
          'Added new location {0}, but an error occured during initialization. {1}'
      },
      titleformat: 'Add new {0}'
    },
    createsite: {
      error: {
        creating: 'Failed to add site, unknown error.',
        creatingsiblingnameconflict:
          'Failed to add site, another site with the same name shares a parent with this site.'
      },
      state: {
        creating: 'Creating site...'
      },
      success: {
        noerrorsformat: 'Successfully added new site {0}.',
        witherrorsformat:
          'Added new site {0}, but an error occured during initialization. {1}'
      }
    },
    dashboardcollection: {
      add: 'Add dashboard collection',
      addfailure: 'Failed to add dashboard collection',
      addsuccess: 'Added dashboard collection',
      current: 'Selected collection',
      defaultdashboard: 'Default',
      edit: 'Edit dashboard collection',
      editfailure: 'Failed to update dashboard collection',
      editsuccess: 'Updated dashboard collection',
      get: {
        failure: 'Failed to fetch dasboard collections'
      },
      nodata: 'There are no available dashboard collections',
      noselectedcollection: 'No collection selected',
      noselecteddashboards: 'No selected dashboards',
      remove: {
        failure: 'Failed to delete dashboard collection',
        success: 'Removed dashboard collection'
      },
      select: 'Select dashboard collection',
      selectdashboards: 'Select dashboards',
      selectincontext: 'Select collection',
      setdefault: 'Set default collection',
      setdefaultdashboard: 'Set to default',
      title: 'Dashboard collections'
    },
    dashboards: {
      add: 'Add dashboard',
      added: {
        success: 'Added dashboard'
      },
      change: {
        failure: 'Failed to update dashboard',
        success: 'Updated dashboard'
      },
      column: {
        dashboard: {
          data: 'Dashboard data'
        },
        description: 'Description',
        name: 'Name'
      },
      comparebarchart: {
        series: 'Value'
      },
      configuredashboardcollection: 'Select dashboard collection',
      datasources: {
        lastvalue: {
          settings: 'Last value settings',
          uselastbeforerange: 'Skip partial value at end of range'
        },
        node: 'Location',
        proptexts: {
          defaulttotimerange: 'Time range based',
          forceexactvalues: 'Force exact values',
          hoursbackplaceholder: 'Use time range from dashboard',
          hoursbackward: 'Hours backward',
          hoursforward: 'Hours forward',
          node: 'The node used to fetch data. Leave empty to use current node.',
          signalcurves: 'Signal curves',
          signals: 'Signals'
        }
      },
      deletebutton: 'Delete dashboard',
      deletepanel: 'Delete panel',
      duplicate: 'Duplicate panel',
      edit: 'Edit dashboard',
      empty: 'This dashboard is empty. Add panels to show information.',
      forms: {
        signaldetails: {
          editdialog: {
            addtitle: 'Add signal',
            title: 'Edit signal settings'
          }
        },
        signalnames: {
          addconstant: 'Add constant',
          addfromexisting: 'Add based on signal...',
          addsignal: 'Add signal name',
          category: 'Category',
          curvedata: 'Curve signals',
          empty:
            'No signals added. Press one of the add buttons to get started.',
          modaltitle: 'Add signal type from signal',
          name: 'Name',
          timerange: 'For time range'
        }
      },
      getdashboards: {
        failure: 'Failed to fetch dashboards',
        nodata: 'There are no available dashboards'
      },
      initialdescription: 'Description',
      initialname: 'New dashboard',
      loadfile: {
        failure: 'Failed to load dashboard document'
      },
      loadobject: {
        failure: 'Failed to load dashboard'
      },
      panels: {
        add: 'Add panel',
        alarmcount: 'Alarm counter',
        alarmlist: 'Alarm list',
        alarmmap: 'Alarm map',
        alarmstatuslist: 'Alarm status list',
        barchart: 'Bar chart',
        barchartcompare: 'Compare values chart',
        buildingstatuses: 'Building statuses',
        customprocessmap: 'Custom process map',
        edit: 'Edit panel',
        gauge: 'Gauge',
        id: 'ID',
        initialtitle: 'Title',
        linechart: 'Line chart',
        locationmap: 'Map',
        minmaxsettings: 'Min/max for chart values',
        nodename: 'Location name',
        placeholders: {
          text: 'The text to show in the panel.'
        },
        processmap: 'Process map',
        scatterchart: 'Scatter chart',
        scatterchartxaxis: 'Scatter chart X-axis',
        signalcurveeditor: 'Signal curve editor',
        signalslist: 'Signals list',
        text: 'Text',
        title: 'Title',
        type: 'Type',
        types: {
          alarmcount: {
            seealarms: 'See alarms'
          },
          alarmlist: {
            pagesize: 'Max number of alarms'
          },
          barchart: {
            stacking: 'Stacking',
            stackingoption: {
              disabled: 'Disabled',
              normal: 'Normal',
              percent: 'Percent'
            }
          },
          gauge: {
            fontsize: 'Font size',
            hideunit: 'Hide unit',
            maxvalue: 'Max value',
            minvalue: 'Min value',
            type: 'Gauge type',
            types: {
              bar: 'Bar',
              meter: 'Meter',
              solidgauge: 'Solid Gauge',
              value: 'Value'
            }
          },
          linechart: {
            chartlayout: 'Chart layout',
            hidelegend: 'Hide legend',
            paddingleft: 'Padding left',
            paddingright: 'Padding right',
            refreshinterval: 'Refresh interval (minutes)',
            showcurrenttime: 'Show current time'
          },
          map: {
            autoselect: 'Automatically select first signal',
            hidecontrols: 'Hide controls'
          },
          nodename: {
            node: 'Location',
            nodeplaceholder:
              'The node used to fetch data. Leave empty to use current node.'
          },
          scatterchart: {
            xaxisprovidername: 'X-axis provider name',
            xaxissignalname: 'X-axis signal name'
          }
        }
      },
      remove: {
        failure: 'Failed to delete dashboard',
        success: 'Removed dashboard'
      },
      save: {
        failure: 'Failed to save dashboard',
        success: 'Saved dashboard'
      },
      sections: {
        aggregation: 'Aggregation',
        alarm: 'Alarm settings',
        barchart: 'Bar chart',
        curverange: 'Curve range',
        curvexaxis: 'Vertical X-axis',
        datarange: 'Time range',
        gauge: 'Gauge',
        linechart: 'Line chart',
        map: 'Map',
        signals: 'Signals'
      },
      title: 'Dashboards'
    },
    deploytemplates: {
      deploy: 'Deploy',
      deploysuccessfulformat: 'Deployment successfully completed to: {0}',
      failedtoloadtenants: 'Failed to load tenants',
      tenants: 'Deploy templates to tenants'
    },
    deprovisionreason: {
      faulty: 'Faulty',
      other: 'Other'
    },
    ectotable: {
      addroom: 'Add room...',
      addroomtitle: 'Add ectotable room',
      addscreencontent: 'Add screen content...',
      addscreencontentdialog: 'Add screen content',
      background: 'Background image',
      company: 'Company',
      contactperson: 'Contact person',
      editroomtitle: 'Edit ectotable room',
      editscreencontent: 'Edit screen content',
      existingfile: 'File',
      fullscreentype: 'Full screen',
      location: 'Location',
      name: 'Name',
      rooms: 'Ectotable rooms',
      screencontent: 'Screen content',
      screencontents: 'Screen contents',
      screenname: 'Screen name',
      text: 'Text',
      title: 'Ectotable',
      typeformat: 'Screen {0} type',
      video: 'Video'
    },
    editbuilding: {
      conflict: {
        facilityid:
          'Conflict: There is already a building with this Facility id.'
      },
      delete: {
        title: 'Do you really want to delete the building?'
      },
      deletelocation: {
        text: 'Deleting this building removes all equipment and related signals.',
        title: 'Delete building'
      },
      savelocation: 'Save building',
      update: {
        failed: 'Failed to update building'
      },
      updated: {
        building: 'Updated building'
      }
    },
    editlocation: {
      addlogdialog: {
        description: 'Add log entry for {0}.',
        error: 'Could not add log entry.',
        placeholder: 'Entry',
        success: 'Added log entry to {0}.'
      },
      addlogentry: 'Add log entry',
      addnewbuilding: 'Add new building',
      addnewrootsite: 'Add new root site',
      addnewsite: 'Add new site',
      deletelocation: {
        button: 'Delete location',
        text: 'Deleting this location removes all equipment and related signals.',
        title: 'Do you really want to delete the location?'
      },
      details: 'Details',
      editmeteorology: 'Edit meteorology point',
      editnotifications: {
        button: 'Edit notifications'
      },
      editparents: {
        button: 'Edit parents',
        title: 'Edit parent locations'
      },
      editprocessmap: 'Select process map',
      edittools: 'Manage tools',
      editusers: 'Edit users',
      error: {
        failedtoloadmappings: 'Failed to load user data.',
        noparentlocation: 'Must have at least one parent location.'
      },
      fields: {
        activealarms: 'Active alarms:',
        buildingstatus: 'Building status:',
        childlocations: 'Child locations:',
        districtheatingfacilityid: 'Facility id:',
        equipment: 'Equipment:',
        grids: 'Grids:',
        linearoptimisations: 'Linear optimisations:',
        name: 'Name:',
        parents: 'Parents:',
        powercontrols: 'Power controls:',
        street: 'Street:',
        type: 'Type:'
      },
      placeholder: {
        districtheatingfacilityid: 'Facility id',
        name: 'Name',
        street: 'Street'
      },
      savelocation: 'Save location',
      tags: 'Tags',
      tools: {
        comfort: {
          batchcommonsection: {
            title: 'Shared settings'
          },
          batchdeploy: 'Deploy tools',
          batchedittools: 'Batch edit/add',
          batchtitle: 'Edit Comfort tools',
          columns: {
            radiatorname: 'Radiator name'
          },
          error: {
            batchmultipledevices:
              'Incorrect device configuration. Radiators must use be managed by the same device in order to for batch edit to work.'
          },
          noradiators: 'No radiators found in this location.',
          title: 'Comfort'
        },
        configured: 'Configured.',
        manage: 'Manage',
        readytoadd: 'Ready to add.',
        status: 'Status',
        temperatureimpact: 'Temperature Impact',
        tooltype: 'Tool type',
        unsupported:
          'Unsupported. The location does not have all required parameters.'
      },
      users: {
        addtosublocations: 'Add to sublocations',
        failure: 'Failed to update users',
        name: 'Name',
        nousersfound: 'No users found.',
        searchplaceholder: 'Search for user',
        title: 'Edit users in location',
        updated: 'Updated users',
        username: 'Username'
      }
    },
    editsite: {
      delete: {
        title: 'Do you really want to delete the site?'
      },
      deletelocation: {
        text: 'Deleting this site removes all equipment and related signals.',
        title: 'Delete site'
      },
      savelocation: 'Save site',
      update: {
        failed: 'Failed to update site'
      },
      updated: {
        site: 'Updated site'
      }
    },
    elvaco: {
      dialog: {
        available: {
          signals: 'Available signals'
        },
        choose: {
          account: 'Choose Elvaco account'
        },
        create: {
          title: 'Add Elvaco tool'
        },
        edit: {
          title: 'Edit Elvaco tool'
        },
        no: {
          available: {
            signals: 'There are no available signals'
          },
          selected: {
            signals: 'There are no selected signals'
          }
        },
        selected: {
          signals: 'Selected signals'
        }
      },
      error: {
        addresswithoutcity:
          'You need to enter a city if searching for an address.',
        addresswithoutnumber:
          'You need to enter a street number when searching for an address.',
        invalidsyntax: 'Invalid search syntax.'
      },
      filter: {
        address: 'Address',
        alarm: 'Alarm',
        clearall: 'Clear all',
        meterName: 'Meter name',
        title: 'Filter'
      },
      get: {
        accounts: {
          failed: 'Failed to fetch Elvaco accounts'
        },
        signals: {
          failed: 'Failed to fetch signals. Check search fields.'
        },
        tool: {
          failed: 'Failed to fetch Elvaco tool'
        }
      }
    },
    energymanager: {
      application: 'Application',
      changeiotdevice: 'Change to new device',
      confirmunlink: 'Disconnect from device',
      confirmunlinktextformat:
        'Do you want to disconnect from the device with MAC address {0}?',
      connections: 'Connections',
      createconfig: 'Add device config',
      currentmd5: 'Current MD5',
      deploy: 'Deploy',
      deploymd5: 'Deploy MD5',
      deployment: 'Deployment',
      deploymentdetails: 'Deployment details',
      deploymenterrorformat: 'Refresh failed (code {0})',
      deploymentstatus: 'Deployment status',
      deploynopairinginfoformat:
        'This equipment is not connected to a real-world Energy Manager at the moment. Please select a real device in the {0} tab to enable config file deployment.',
      devicefile: 'Device file',
      devicemac: 'Device MAC',
      devicesettings: 'Device settings',
      error: {
        nodeviceconfig:
          'We could not find a valid device config associated with this Energy Manager. You can try to add a new device config to fix this.'
      },
      file: 'File',
      information: 'Information',
      loading: 'Loading...',
      missingmac: 'You must enter a MAC address.',
      missingmodbusaddressess:
        'There are signals without any modbus address. They are needed on given signals to be able to deploy.',
      noiothubdevice:
        'No IoT hub device found. Verify that the MAC address corresponds to a configured Energy Manager.',
      nopairinginfo:
        'There is no connection to a real-world Energy Manager at the moment. Please select a device to enable data flow.',
      pairingerror: 'Failed to connect to new device.',
      pairingsuccess: 'Successfully connected to new device.',
      pairwithdeviceandcopybutton: 'Connect and copy settings',
      pairwithdevicebutton: 'Connect',
      pairwithdeviceformat:
        'Do you want to connect to device with the MAC address {0}?',
      pairwithdevicetitle: 'Connect to device',
      pairwithotherdeviceformat:
        'Do you want to disconnect from device with MAC address {0} and connect to device with the MAC address {1}?',
      pairwithotherdevicerelinkformat:
        'By confirming you will disconnect from device with MAC address {0} and connect to device with the MAC address {1}. If you want you can copy over settings (network configuration and tags) from the old device to the new one.',
      pending: 'Pending',
      readytodeploy: 'Ready to deploy',
      reportedmd5: 'Reported MD5',
      saveiotdevice: 'Save device',
      selectiotdevice: 'Connect to device',
      showdevicefiles: 'Show device files',
      signalsfile: 'Signals file',
      title: 'Energy Manager',
      toolsfile: 'Tools file',
      unlinkiotdevice: 'Unlink current device',
      unpairingerror: 'Failed to disconnect from device.',
      unpairingsuccess: 'Successfully disconnected from device.',
      uptodate: 'Configuration files are up-to-date.',
      uptodatewaitingforprocessreload:
        'Configuration files are now up-to-date, but we are waiting for the Energy Manager to reload its database.',
      uptodatewithchanges: 'There are new changes that need to be deployed.',
      validation: {
        error: {
          address_conflict: 'Address conflict',
          duplicate_outtemp: 'Duplicate outdoor temperature signal',
          missing_outtemp: 'Missing outdoor temperature signal',
          system_name_conflict: 'Signal name conflict'
        },
        fetchfailed:
          'Failed to fetch list of possible configuration errors, please try again.',
        title: 'Configuration errors'
      },
      version: 'Version',
      versions: 'Application versions'
    },
    energymanagers: {
      devicename: 'Device name',
      failedtofetchsignalsconfig:
        'Failed to fetch Energy Manager signals config',
      heartbeat: 'Heartbeat',
      locations: 'Locations',
      nodevice: 'No device',
      search: 'Search for device name',
      searchplaceholder: 'Device name',
      status: 'Status'
    },
    equipment: {
      add: 'Add new equipment',
      addbutton: 'Add',
      addfromtemplate: {
        addingconnection: 'Adding connections for equipment...',
        error: 'Failed to add equipments from template',
        errorafterinit:
          'Successfully added new equipments but failed to fetch new data. Are you having connectivity problems?',
        errorconnection: 'Failed to add instantiate new connection',
        instantiating: 'Adding equipments from template...',
        success: 'Successfully added new equipments.',
        title: 'Add from template'
      },
      addnewlinearoptimisation: 'Add Linear Optimisation',
      addnewpowercontrol: 'Add Power Control',
      addsignal: {
        error: 'Failed to add signal.',
        success: 'Successfully added signal.'
      },
      alarmtemplate: 'Alarm template',
      algorithmtype: {
        basic: 'Basic',
        title: 'Algorithm',
        tracking: 'Tracking',
        trackingventilation: 'Tracking Ventilation',
        transmit: 'Transmit'
      },
      chooseexistingem: 'Choose existing Energy Manager',
      configerror: 'An error occured while fetching configuration data.',
      confirmdelete: {
        message: 'Are you sure you want to delete this equipment?',
        title: 'Confirm delete'
      },
      confirmdeletesignal: {
        message: 'Are you sure you want to delete {0}?'
      },
      createnewem: 'Add new Energy Manager',
      delete: {
        error: 'Failed to delete equipment.',
        success: 'Successfully deleted equipment.'
      },
      deletedatapoint: {
        buttontitle: 'Delete signal values...',
        deleteformat: 'Are you sure you want to remove values from {0}?',
        enddate: 'End date',
        nodata: 'No data found in selected time range.',
        startdate: 'Start date',
        success: 'Sucessfully deleted signal values',
        title: 'Delete signal values'
      },
      deleteequipment: 'Delete equipment',
      deletesignal: {
        error: 'Failed to delete signal.',
        success: 'Successfully deleted signal.'
      },
      deploytool: 'Deploy tool',
      description: 'Description',
      descriptionlabel: 'Description',
      deviceparent: 'Device',
      editlinearoptimisation: 'Edit linear optimisation',
      editpowercontrol: 'Edit power control',
      energymanagerfromformat: 'Energy Manager from {0}',
      error: {
        mustspecifyname: 'You must specify a name.',
        nodevice:
          'No devices. Please add devices from a template or add an Energy Manager to continue.',
        nogridequipment:
          'Root level grid contains no equipment. Visit a child location to manage equipment there.',
        nositeequipment:
          'There is no support for adding or managing equipment for sites.'
      },
      files: {
        button: 'Device files...',
        title: 'Device files'
      },
      information: 'Information',
      linearoptimisation: 'Linear Optimisation',
      name: 'Equipment name',
      namelabel: 'Name',
      nameplaceholder: 'Name',
      none: 'None',
      notools: 'No tools available',
      numberofpoints: 'Number of points',
      powercontrolformat: 'Power Control {0}',
      powercontroltype: {
        cooling: 'Cooling',
        heating: 'Heating',
        title: 'Type'
      },
      processmap: {
        error: 'Failed to update process map on equipment',
        updatesuccess: 'Process map updated on equipment'
      },
      remove: {
        error: {
          relationconflict:
            "Could not delete this tool because it's used by something else."
        }
      },
      request: {
        fetchtoolsfailed: 'Failed to fetch tools',
        updatedem: 'Updated Energy Manager successfully',
        updatedtool: 'Updated tool successfully',
        updateemfailed: 'Failed to update Energy Manager',
        updatetoolfailed: 'Failed to update tool'
      },
      section: {
        connections: 'Connection',
        deployment: 'Deploy settings',
        details: 'Details',
        files: 'Files',
        iotdevice: 'Hardware',
        signals: 'Signals',
        tools: 'Tools'
      },
      selectequipment: 'Please select an equipment to continue.',
      title: 'Equipment',
      tool: {
        batterychargingthreshold: 'Battery Charging Threshold',
        comfort: 'Comfort',
        dialog: {
          create: {
            title: 'Add {0} tool'
          },
          edit: {
            title: 'Edit {0} tool'
          }
        },
        ecoguard: 'Ecoguard',
        elvaco: 'Elvaco',
        linearoptimisation: 'Linear Optimisation',
        powercontrol: 'Power Control',
        powerdeltacontrol: 'Power Delta Control'
      },
      tools: 'Tools',
      type: 'Equipment type'
    },
    equipmentgroup: {
      addequipment: {
        add: 'Add equipment',
        alarms: 'Alarm template',
        placeholder: 'Select equipment type',
        title: 'Add equipment',
        tools: 'Tools'
      },
      connections: 'Connections',
      energymanager: {
        addconnection: 'Add connection',
        connection: 'Connection',
        signoff: {
          button: 'Sign off',
          error: 'Failed to update connection',
          name: 'Name',
          notes: 'Notes',
          signed: 'Signed off by {0}',
          title: 'Sign off'
        }
      },
      equipment: {
        alarms: 'Alarm template',
        description: 'Description',
        name: 'Name',
        signaltype: 'Signal type',
        tools: 'Tools'
      }
    },
    equipmentgrouptemplates: {
      addnew: 'Add new building template',
      confirmdelete: {
        button: 'Delete',
        text: 'Are you sure you want to delete this building template? Buildings added from this template will not be affected.',
        title: 'Confirm delete'
      },
      delete: 'Delete building template',
      deletefailed: 'Failed to delete template.',
      deletesuccess: 'Deleted template.',
      error: {
        invalidname: 'Please enter a valid name'
      },
      name: 'Name',
      save: 'Save building template',
      templatename: 'Template name',
      title: 'Building templates'
    },
    equipmentsignal: {
      address: 'Address',
      connection: 'Connection',
      description: 'Description',
      displayname: 'Display name',
      editformat: 'Edit {0} value',
      failedtoloadvalues: 'Failed to load signal values.',
      failedtosetsignal: 'Failed to update signal value.',
      noconnection: 'None',
      signaltype: 'Signal type',
      systemname: 'Name',
      title: 'Signals',
      type: 'Type',
      unit: 'Unit',
      value: 'Value'
    },
    equipmentsignaltemplates: {
      addnew: 'Add new equipment type',
      delete: 'Delete equipment type',
      equipmentname: 'Name',
      header: 'Equipment types',
      name: 'Name',
      parenttype: 'Parent type (optional)',
      processmap: 'Process map'
    },
    equipmenttemplates: {
      addsignal: 'Add signal',
      dataformat: 'Data format',
      defaultvalue: 'Default value',
      description: 'Description',
      displayname: 'Display name',
      error: {
        cannotremoveparentsignal: 'Cannot delete parent template signal.',
        displayname: 'Enter display name for signal',
        duplicate: 'Duplicate signal name. Enter unique signal name.',
        duplicates: 'Duplicate signal names. Enter unique signal names.',
        failedtofetch: 'Failed to fetch template',
        missingfields: 'Enter all required fields before saving.',
        missingname: 'Enter name for signal',
        missingnames: 'Enter name for all signals',
        missingtype: 'Enter type for signal',
        missingunit: 'Signal must have a unit',
        processmaps: 'Failed to fetch process maps',
        processmapsrevisions: 'Failed to fetch process maps revisions',
        updatetemplate: 'Failed to update template'
      },
      graphicalrepresentation: 'Graphical representation',
      none: 'None',
      signaldirection: 'Signal direction',
      signalfunction: 'Signal function',
      signaltype: 'Signal type',
      source: 'Source',
      systemname: 'Name',
      type: 'Type',
      unit: 'Unit',
      usemodbus: 'Modbus',
      writable: 'Writable'
    },
    equipmenttools: {
      error: {
        signalfetchfailed: 'Failed to fetch {0} tool.'
      },
      manage: 'Manage',
      title: 'Equipment tools',
      tooltype: 'Tool type'
    },
    equipmenttypes: {
      add: 'Add new equipment type',
      failed: {
        to: {
          fetch: {
            templates: 'Failed to fetch equipment types templates'
          }
        }
      },
      processmaps: 'Process maps',
      selectprocessmap: 'Select process map',
      signaltemplates: 'Signal templates',
      title: 'Equipment types',
      updated: 'Equipment type updated'
    },
    form: {
      unsavedstate: 'You have unsaved data, are you sure you want to leave?'
    },
    integration: {
      ecoguard: {
        account: {
          editgroups: 'Edit groups...',
          editgroupstitle: 'Edit groups'
        },
        add: {
          account: 'Add Ecoguard account'
        },
        column: {
          description: 'Description',
          groupname: 'Group name',
          keyvaultsecret: 'Key vault secret',
          name: 'Account name'
        },
        confirmcreate: {
          text: 'To edit groups you have to add the new account before proceeding.',
          title: 'Confirm add account'
        },
        edit: {
          account: 'Edit Ecoguard account'
        },
        genericaccountcollectionname: 'Ecoguard accounts',
        genericaccountname: 'Ecoguard account',
        genericgroupcollectionname: 'Ecoguard groups',
        genericgroupname: 'Ecoguard group',
        generictoolcollectionname: 'Ecoguard tools',
        generictoolname: 'Ecoguard tool',
        group: {
          add: {
            button: 'Add group...',
            title: 'Add group'
          },
          edit: {
            title: 'Edit group'
          },
          nodata: 'No groups found. Press "Add group..." to get started.',
          nodataforpicker: 'No groups found.'
        },
        integrationtype: {
          meanroomtemperature: 'Mean room temperature',
          roomtemperature: 'Room temperature',
          title: 'Integration type'
        },
        nametaken: 'The group name is already taken on this Ecoguard account',
        noaccountselected: 'No account selected',
        selectaccount: 'Account',
        selectaccountmodaltitle: 'Select account',
        selectaccountplaceholder: 'Select account...',
        selectgroup: 'Group',
        selectgroupmodaltitle: 'Select group',
        selectgroupplaceholder: 'Select group...',
        title: 'Ecoguard',
        tool: {
          add: 'Add Ecoguard tool',
          edit: 'Edit Ecoguard tool'
        }
      },
      elvaco: {
        account: {
          add: {
            failed: 'Failed to add Elvaco account',
            success: 'Successfully added Elvaco account'
          },
          save: {
            failed: 'Failed to save Elvaco account',
            success: 'Successfully saved Elvaco account'
          }
        },
        add: {
          account: 'Add Elvaco account'
        },
        column: {
          description: 'Description',
          name: 'Account name',
          status: 'Status'
        },
        countries: {
          sverige: 'Sweden'
        },
        edit: {
          account: 'Edit Elvaco account'
        },
        form: {
          baseurl: 'Base URL',
          country: 'Country',
          description: 'Description',
          jwt: 'JWT-token',
          name: 'Name',
          userid: 'User ID'
        },
        get: {
          accounts: {
            error: 'Failed to fetch Elvaco accounts'
          }
        },
        nodata: 'There are no Elvaco accounts available',
        title: 'Elvaco'
      },
      generic: {
        get: {
          accounts: {
            empty: 'No accounts available',
            error: 'Failed to fetch accounts'
          }
        }
      },
      netmore: {
        account: 'Netmore account',
        add: {
          account: 'Add Netmore account',
          tool: 'Add Netmore tool'
        },
        availablesignals: 'Available signals',
        edit: {
          account: 'Edit Netmore account',
          tool: 'Edit Netmore tool'
        },
        error: {
          failedtoloadsignals: 'Failed to load signals for account'
        },
        form: {
          account: 'Account',
          description: 'Description',
          name: 'Name',
          password: 'Password',
          username: 'User name'
        },
        headers: {
          description: 'Description',
          euiserialnumber: 'DevEUI'
        },
        nodatatext:
          'No signals found. Please select an account and enter a valid DevEUI in the search field to continue.',
        nosignalsadded: 'No signals added.',
        selectedsignals: 'Selected signals',
        title: 'Netmore'
      }
    },
    integrations: {
      addnodetopointformat: 'Add {0} to {1}?',
      addpoint: 'Add integration point...',
      addpointmodalformat: 'Add {0} integration point',
      addtopoint: 'Add to integration point...',
      addtopointtitle: 'Add location to integration point',
      editpointmodalformat: 'Edit {0} integration point',
      integrationpointname: 'Integration point',
      integrationproxyname: 'Integration proxy',
      managepoints: 'Manage integration points',
      nodeaddedtopoint: 'Location added to integration point',
      proxy: {
        form: {
          error: {
            invalidformat: 'Invalid format, expected: {0}',
            missing: 'Required value'
          },
          name: 'Name',
          nodeids: 'Locations'
        }
      }
    },
    iotdevicedetails: {
      activeinternetroute: 'Active as internet route',
      address: 'Address',
      cancelled: 'Cancelled',
      configurehardware: 'Configure hardware',
      currentgateway: 'Current gateway',
      currentipaddress: 'Current IP address',
      currentiptype: 'Current network mode',
      currentnetmask: 'Current netmask',
      defaultnetworkinterface: 'Default network interface',
      deprovisiondevice: 'Delete device',
      deprovisionreason: 'Reason',
      desiredinternetroute: 'Desired internet route',
      devicedefaultnetworkinterface: 'Device network interface',
      deviceinfo: 'Device information',
      devicename: 'MAC-address',
      dhcp: 'DHCP',
      editnetworksettings: 'Edit network settings...',
      error: {
        failedtofetchdeviceinfo: 'Failed to fetch device info',
        failedtofetchtags: 'Failed to fetch tags',
        failedtoupdate: 'Could not fetch device settings'
      },
      gateway: 'Gateway',
      hardwareactions: 'Hardware actions',
      heartbeat: 'Last active',
      heartbeataction: 'Request heartbeat',
      heartbeaterror: 'Heartbeat failed',
      heartbeatoutput: 'Heartbeat result',
      heartbeatresponseerror: 'Heartbeat failed. Status code: {0}',
      heartbeatsuccess: 'Heartbeat success',
      heartbeattitle: 'Heartbeat',
      ipaddress: 'IP address',
      iptype: 'Network mode',
      lastcomms: 'Last communication',
      locations: 'Locations',
      logs: 'Requests',
      manufacturer: 'Manufacturer',
      model: 'Model',
      modules: 'Modules',
      modulesdetail: {
        exitCode: 'Exit code',
        lastExitTime: 'Last exit',
        lastRestartTime: 'Last restart',
        lastStartTime: 'Last start',
        runtimeStatus: 'Status',
        status: {
          dead: 'Dead',
          failed: 'Failed',
          running: 'Running',
          stopped: 'Stopped',
          unknown: 'Unknown'
        },
        version: 'Version'
      },
      modulesname: 'Name',
      modulesversion: 'Current Version',
      modulesversionlatest: 'Latest version',
      netmask: 'Netmask',
      networksettings: 'Network settings',
      noheartbeat: 'No data reported',
      notactiveinternetroute: 'Not active as internet route',
      notes: 'Notes',
      pairactions: 'Configure device pairing',
      performrequestheartbeat: 'Requesting heartbeat',
      performrequestping: 'Requesting {0}',
      performrequestreboot: 'Rebooting',
      performrequestrestartservice: 'Restarting service {0}',
      ping: 'Ping',
      pingaction: 'Ping address',
      pingoutput: 'Ping ouput',
      pingresponse: 'Response time: {0}ms',
      pingresponseerror: 'Failed to ping. Status code: {0}',
      provisiondevice: 'Add device',
      reboot: 'Reboot',
      rebootconfirm: 'Are you sure you want to reboot the device?',
      rebootdevice: 'Reboot Energy Manager',
      rebooterror: 'Failed to reboot device',
      rebootresponseerror: 'Failed to reboot device. Status code: {0}',
      rebootsuccess: 'Device rebooted',
      reportedaddress: 'Device IP address',
      reportedgateway: 'Device Gateway',
      reportediptype: 'Device IP mode',
      reportednetmask: 'Device Netmask',
      restartservice: 'Restart service',
      restartserviceoutput: 'Restart service status',
      restartserviceresponse: 'Restarted service.',
      restartservicesresponseerror: 'Failed to restart service.',
      restartservicesresponseerrorentry:
        'Failed to restart service. Status code: {0}',
      savefailed: 'Failed to save device data.',
      savesuccess: 'Successfully saved device data.',
      signalstrength: 'Signal strength',
      sim: 'SIM card number',
      static: 'Static',
      tab: {
        details: 'Details',
        hardware: 'Hardware',
        hardwareactions: 'Actions',
        networksettings: 'Network'
      },
      tags: 'Tags',
      title: 'Network settings',
      unknown: 'Unknown',
      updatenetworksettings: 'Update network settings',
      uptime: 'Uptime',
      wiredconnection: 'Wired connection',
      wirelessconnection: 'Mobile connection'
    },
    lastdeployed: 'Last deploy was:',
    meteorology: {
      action: {
        create: 'Add new point'
      },
      createnew: {
        header: 'Add new point'
      },
      error: {
        entername: 'Please enter name'
      },
      fetch: {
        failed: 'Failed to fetch process meteorology data'
      },
      header: {
        coordinates: 'Coordinates',
        name: 'Name',
        numberoflocations: 'Number of locations'
      },
      input: {
        name: 'Name'
      },
      locations: 'Locations',
      overview: {
        header: 'Weather points'
      },
      value: {
        numberoflocationsformat: '{0} locations'
      }
    },
    modbusconnection: {
      byte_timeout_microseconds: 'Byte timeout (µs)',
      byte_timeout_seconds: 'Byte timeout (s)',
      connection_timeout: 'Timeout',
      debug: 'Debug level',
      delay_read_db: 'Delay read DB',
      delay_read_modbus: 'Delay read modbus',
      delay_write_db: 'Delay write DB',
      delay_write_modbus: 'Delay write modbus',
      mode: {
        master: 'Master',
        slave: 'Slave',
        title: 'Mode'
      },
      properties: 'Connection properties',
      queue_size_factor: 'Queue size factor',
      response_timeout_microseconds: 'Response timeout (µs)',
      response_timeout_seconds: 'Response timeout (s)',
      rtubaudrate: 'Baud rate',
      rtudatabits: 'Data bits',
      rtuparity: 'Parity',
      rtuparityeven: 'Even',
      rtuparitynone: 'None',
      rtuparityodd: 'Odd',
      rtuserialname: 'Serial name',
      rtusettings: 'RTU settings',
      rtustopbits: 'Stop bits',
      slave_id: 'Slave ID',
      tcpipaddress: 'IP address',
      tcpport: 'Port',
      tcpsettings: 'TCP settings',
      titleformat: 'Edit connection',
      type: {
        rtu: 'RTU',
        tcp: 'TCP',
        title: 'Type'
      },
      watchdog_bms_address: 'Watchdog BMS Address',
      watchdog_modbus_alarm: 'Watchdog alarm',
      watchdog_modbus_timeout: 'Watchdog timeout'
    },
    modbuslayout: {
      config: 'Config',
      documentation: 'Documentation',
      downloadfile: 'Download file',
      layout: 'Layout',
      signals: 'Signals',
      title: 'Device files',
      tools: 'Tools'
    },
    modbussignal: {
      bit: 'Bit',
      byteorder: {
        abcd: 'abcd',
        badc: 'badc',
        cdab: 'cdab',
        dcba: 'dcba',
        title: 'Byteorder'
      },
      coil: 'Coil',
      config: 'Config',
      connection: 'Connection',
      connectiondefault: 'Connection default',
      datatype: {
        bitmask: 'bitmask',
        int16: 'int16',
        int32: 'int32',
        int64: 'int64',
        real: 'real',
        title: 'Data type',
        uint16: 'uint16',
        uint32: 'uint32',
        uint64: 'uint64'
      },
      delay: 'Delay',
      delaydb: 'Delay DB',
      delaymodbus: 'Delay Modbus',
      disableconfig: 'Disable modbus config',
      discreteinput: 'Discrete input',
      editformat: 'Edit {0}',
      enableconfig: 'Enable modbus config',
      factor: 'Factor',
      flag: {
        delay: 'Flag delay',
        name: 'Flag name',
        timeout: 'Flag timeout',
        title: 'Flag'
      },
      holdingregister: 'Holding register',
      inputregister: 'Input register',
      modbusmaster: 'Modbus Master',
      modbusslave: 'Modbus Slave',
      mode: 'Mode',
      read: 'Read',
      readdelay: 'Read delay',
      readname: 'Read name',
      readsetting: {
        title: 'Read'
      },
      readwrite: 'Read/Write',
      signalproperties: 'Signal properties',
      signaltype: 'Signal type',
      step: 'Step',
      watchdog: {
        alarm: 'Alarm',
        enablesignal: 'Enable',
        high: 'High',
        period: 'Period',
        title: 'Watchdog'
      },
      write: 'Write'
    },
    modeltypes: {
      signals: {
        numberselected: '{0} signals',
        zeroselected: 'No signals'
      },
      users: {
        numberselected: '{0} users',
        zeroselected: 'No users'
      }
    },
    notifications: {
      addnotification: 'Add notification',
      deletenotification: 'Delete notification',
      editnotification: 'Edit notification',
      error: {
        failedtoadd: 'Failed to add notification.',
        failedtodelete: 'Failed to delete notification.',
        missingname: 'Enter name for notification',
        nonodeselected: 'No location selected.',
        nousers: 'No users selected.'
      },
      header: {
        location: 'Location',
        name: 'Name',
        severities: 'Severities',
        signals: 'Signals',
        users: 'Users'
      },
      selectsignals: 'Select Signals',
      selectusers: 'Select Users',
      title: 'Notifications'
    },
    powerdeltacontrol: {
      cooling: 'Cooling',
      error: {
        signalfetchfailed: 'Failed to fetch Power Delta Control tool.'
      },
      heating: 'Heating',
      returntemperaturesignal: 'Return temperature signal',
      timeconstant: 'Time constant',
      type: 'Type'
    },
    previewtenant: {
      previewnode: 'Preview node',
      previewnodetooltip:
        'Preview node. Set this to view the process map using signals from a node in the preview tenant.',
      previewtenant: 'Preview tenant',
      previewtenanttooltip:
        'Preview tenant. Set this to select nodes in another tenant to preview the process map from.'
    },
    processmaps: {
      add: 'Add',
      addconnection: 'Add connection',
      addmap: 'Add process map',
      addsvgimage: 'SVG image...',
      addsvgsymbol: 'Add symbol from SVG...',
      clickaction: {
        node: 'Location'
      },
      comment: 'Map comment',
      commentrequired: 'A comment is required',
      contextmenu: {
        addline: 'Line',
        addrect: 'Rectangle',
        addsignal: 'Signal',
        addsymbol: 'Symbol',
        addtext: 'Text',
        deleteconnections: 'Delete connections',
        deleteobject: 'Delete object',
        deletepoint: 'Delete point',
        sendback: 'Send back',
        sendforward: 'Send forward',
        sendtoback: 'Send to back',
        sendtofront: 'Send to front'
      },
      copysource: 'Copy SVG source',
      created: 'Process map added',
      currentmap: 'Selected process map',
      customzoom: 'Custom zoom',
      default: 'Default',
      deleteconnection: 'Delete connection',
      deleteitem: 'Delete symbol',
      deletemap: 'Delete process map',
      description: 'Description',
      download: 'Download',
      downloadsvg: 'Download SVG',
      edit: 'Edit process map',
      editnodetyperelation: 'Edit node type relations',
      editsymbollibrary: 'Edit symbol library',
      error: {
        create: "Couldn't add process map",
        delete: "Couldn't delete process map",
        invalidfile: 'Invalid file',
        missingcomment: 'You must add a comment',
        missingmap: 'You must add a process map',
        missingname: 'You must enter a valid name',
        update: "Couldn't update process map"
      },
      failedtoload: 'Failed to load process map',
      failedtoparse: 'Failed to parse process map',
      history: 'History',
      map: 'Map',
      mapheight: 'Height',
      maps: {
        error: 'Failed to fetch map process maps'
      },
      mapwidth: 'Width',
      name: 'Name',
      newcomment: 'New map comment',
      newmap: 'New map',
      newsymbol: 'New symbol',
      nocurrentmap: 'No process map is selected',
      nocurrentmapdefault:
        'No process map is selected. Using default process map "{0}"',
      nofound: 'No Process maps found',
      nosignaltypeselected: 'No signal type selected',
      notsvgerror:
        'File is not an SVG image. Only SVG images supported at the moment.',
      objecteditor: {
        actiontype: 'Action type',
        addsymbolrule: 'Add symbol rule',
        bold: 'Bold',
        changestatetitle: 'Change state based on signal value',
        clickaction: 'Click action',
        cornerradius: 'Corner radius',
        dashed: 'Dashed',
        dimensions: 'Dimensions',
        endendcapstyle: 'End endcap style',
        equipmentactionhelp:
          'The user will be navigated to the equipment type on the current location matching the type you have selected',
        fillcolor: 'Fill color',
        fillcolorrule: 'Fill color rule',
        fliphorizontal: 'Flip horizontal',
        flipmidpoint: 'Flip midpoint',
        flipvertical: 'Flip vertical',
        fontsize: 'Font size',
        height: 'Height',
        hideitem: 'Hide object',
        hidelabel: 'Hide label',
        hiderequiredsignal: 'Required signal',
        hiderequiredsignalhelp:
          'Hide object if no signals of this type can be found. Still visible when editing.',
        hiderequiredsignalid: 'Required signal (hard coded)',
        hiderequiredsignalidhelp:
          'Only use this if the signal type is not specific enough.',
        hiderulesignals: 'Validate rules',
        hiderulesignalshelp:
          'Hide object if none of the signals defined in the rules can be found in the current node.',
        hidevalue: 'Hide value',
        italic: 'Italic',
        labeltextstyle: 'Label text style',
        linemode: 'Line mode',
        linemodes: {
          path: 'Path',
          straight: 'Straight'
        },
        lineobject: 'Line object',
        linewidth: 'Line width',
        nosymbolrules: 'No symbol rules defined',
        pointformat: 'Point {0}',
        rectobject: 'Rectangle object',
        rotation: 'Rotation',
        rounded: 'Rounded',
        scale: 'Scale',
        signalidindexformat: 'Signal {0} id (hardcoded)',
        signalobject: 'Signal object',
        signaltypeindexformat: 'Signal {0} type',
        startendcapstyle: 'Start endcap style',
        state: 'State',
        strokecolor: 'Stroke color',
        strokewidth: 'Stroke width',
        symbol: 'Symbol',
        symbolobject: 'Symbol object',
        symbolrules: 'Symbol rules',
        text: 'Text',
        textalignment: 'Text alignment',
        textalignments: {
          center: 'Center',
          left: 'Left',
          right: 'Right'
        },
        textobject: 'Text object',
        textstyle: 'Text style',
        valuetextstyle: 'Value text style',
        width: 'Width',
        x: 'X',
        y: 'Y'
      },
      previewsymbolstates: 'Preview symbol states',
      projectsettings: {
        title: 'Project settings',
        trimmap: 'Trim process map size',
        updatesymbolsversions: 'Update symbols versions',
        updatesymbolsversionstoast: 'Updated symbols versions.'
      },
      removed: 'Process map removed',
      revision: {
        error: 'Failed to fetch map history'
      },
      svgmenu: {
        copyobjects: 'Import all objects from other process map',
        showpreview: 'Show preview of old process map'
      },
      symbolactions: 'Symbol actions',
      templatename: 'Template name',
      title: 'Process maps',
      updated: 'Process map updated',
      updatesvgsymbol: 'Update SVG image...',
      zoom: 'Zoom'
    },
    provisioning: {
      connectionstatus: 'Connection status',
      filters: {
        tags: 'Tags',
        types: 'Types'
      },
      header: {
        heartbeat: 'Last active',
        locations: 'Locations',
        name: 'MAC',
        notes: 'Notes',
        online: 'Online',
        sim: 'SIM',
        tags: 'Tags',
        type: 'Type'
      },
      lastheartbeatformat: 'Last online {0}',
      nodatatext: 'No devices found.',
      nofreedevicesdatatext: 'No available devices found.',
      query: {
        all: 'All',
        offline: 'Offline',
        online: 'Online',
        paired: 'Paired',
        unpaired: 'Available'
      },
      summary: {
        online: 'Online:',
        total: 'Devices:',
        unprovisioned: 'Available:'
      },
      tagsplaceholder: 'Tags...'
    },
    requests: {
      addequipment: {
        failure: 'Failed to add equipment.',
        success: 'Added equipment.'
      },
      addequipmenttype: {
        failure: 'Failed to add equipment type.',
        success: 'Added equipment type.'
      },
      addweatherpoint: {
        failure: 'Failed to add weather point.',
        success: 'Added weather point.'
      },
      createdeviceconfig: {
        failure: 'Failed to add device config.',
        success: 'Added device config.'
      },
      createtemplate: {
        failure: 'Failed to add template.',
        success: 'Added template.'
      },
      deletelocation: {
        failure:
          'Failed to delete location. Does it have child locations? If so, delete them first.',
        success: 'Successfully deleted location.'
      },
      updatedeviceconfig: {
        failure: 'Failed to update device config.',
        success: 'Updated device config.'
      },
      updatelocation: {
        failure: 'Failed to update location.',
        success: 'Successfully updated location.'
      },
      updatesignals: {
        failure: 'Failed to update signals.',
        success: 'Updated signals.'
      },
      updatetemplate: {
        failure: 'Failed to update template.',
        success: 'Updated template.'
      },
      updateweatherpoint: {
        failure: 'Failed to update weather point.',
        success: 'Updated weather point.'
      }
    },
    selectdevice: {
      title: 'Select device'
    },
    selectequipment: {
      title: 'Select equipment'
    },
    selectlocationdialog: {
      title: 'Select location'
    },
    selectsignals: {
      noresult: 'No signals',
      searchplaceholder: 'Search for signals'
    },
    selectsignalsdialog: {
      title: 'Select signals'
    },
    selectusers: {
      error: {
        couldnotfindusers: 'Could not find users for location.'
      }
    },
    signalcategories: {
      loaderror: 'Failed to load signal categories',
      title: 'Signal categories',
      validationerror: {
        alarm: 'This signal type requires the Alarm category to be set',
        energymanager:
          'This signal type requires the Energy Manager category to be set'
      }
    },
    signalmapping: {
      action: {
        noaction: 'No action',
        sendbasedoncycletime: 'Send based on cycle time',
        sendonpush: 'Send on push'
      },
      common: {
        name: 'Name'
      },
      createmapping: 'Add mapping',
      destination: {
        option: {
          timetolive: 'Time to live (seconds)'
        },
        selectsignals: 'Select destination signals',
        title: 'Destination'
      },
      destinationsignals: {
        title: 'Destination signals'
      },
      editdialog: {
        add: {
          title: 'Add signal mapping'
        },
        edit: {
          title: 'Edit signal mapping'
        }
      },
      error: {
        destsourceoverlap:
          'You cannot have the same signal in the destination and source configurations.',
        missingaggregation:
          'You need to enable aggregation if using more than one source signal.',
        nosignals: 'Please add signals to continue.',
        ttl: 'This value needs to be set to at least 60 seconds.'
      },
      help: {
        action:
          'No action: The mapper will not do any action. This is primarily used for Energy Manager mappings. \nSend on push: The mapper will forward to the destinations when new source writes arrive.\nSend based on cycle time: The mapper will update values every x minutes (as defined in cycle time).',
        cycletime:
          'How often to look for and send values (when sending based on cycle time).',
        hoursback:
          'Send older values missed due to the cycle time (or older values that are useful to send).',
        hoursforward:
          'If the value is set only a certain amount of hours will be included in the signal time series.',
        ttl: 'Needs to be set when sending messages to an Energy Manager in order to not increase its queue unnecessarily.',
        uselastvaluebeforestartdate:
          'Overrides the start date and tries to get the latest value before given start date, if exists.'
      },
      nodata:
        "Can't find any signal maps for this site. Please select a different site.",
      request: {
        add: {
          failed: 'Failed to add signal mapping.',
          success: 'Signal mappning added.'
        },
        delete: {
          failed: 'Failed to delete signal mapping.',
          success: 'Signal mappning deleted.'
        },
        update: {
          failed: 'Failed to update signal mapping.',
          success: 'Signal mappning updated.'
        }
      },
      signalselection: {
        defaultvalue: 'Default value',
        timeoffset: 'Time offset (hours)',
        title: 'Signals'
      },
      source: {
        option: {
          action: 'Action',
          aggregation: 'Destination aggregation',
          cycletime: 'Cycle time (minutes)',
          hoursback: 'Hours backward',
          hoursforward: 'Hours forward',
          onlyUpdateDestinationOnChangedValue:
            'Only update destination on changed value',
          samplinginterval: 'Sampling interval',
          uselastvalue: 'Use last value before start date'
        },
        selectsignals: 'Select source signals',
        title: 'Source'
      },
      sourcesignals: {
        title: 'Source signals'
      },
      title: 'Signal Mapping'
    },
    signaltypes: {
      addbuttontext: 'Add type...',
      addfolderbuttontext: 'Add folder...',
      addfoldertitle: 'Add folder',
      addfromexisting: 'Add from signal...',
      addtypetitle: 'Add signal type',
      editbuttontext: 'Edit...',
      editfoldertitle: 'Edit folder',
      edittypetitle: 'Edit signal type',
      folderform: {
        folder: 'Folder',
        nonemptyerror:
          'Failed to delete signal folder. It appears the folder is not empty.'
      },
      form: {
        name: 'Name'
      },
      newfolder: 'New folder',
      newtype: 'New signal type',
      selectfolder: 'Select folder',
      selecttypedialogtitle: 'Select signal type',
      typedescription: 'Type description',
      typeform: {
        conflicterror:
          'Failed to save signal type. There appears to be another signal type with the same name.',
        description: 'Description',
        folder: 'Folder',
        nonemptyerror: 'Failed to delete signal type. It appears to be in use.',
        unit: 'Unit'
      },
      typename: 'Type name'
    },
    tabs: {
      alarmrules: 'Alarm rules',
      alarmtemplates: 'Alarms',
      dashboard: 'Dashboard',
      deploytemplates: 'Deploy templates',
      devices: 'Energy Managers',
      ectotable: 'Ectotable',
      equipment: 'Equipment',
      equipmentgrouptemplates: 'Buildings',
      equipmenttemplates: 'Equipments',
      installation: 'Installation',
      integrationaccounts: 'Integration accounts',
      integrations: 'Integrations',
      locations: 'Locations',
      managetenants: 'All organisations',
      meteorology: 'Weather points',
      notifications: 'Notifications',
      processmaps: 'Process maps',
      signalmapping: 'Signal mappings',
      signaltypes: 'Signal types',
      templates: 'Templates',
      users: 'Users'
    },
    templates: {
      error: {
        couldnotremove: 'Could not delete equipment type.',
        couldnotremoveconflict:
          'Could not delete equipment type. It is currently used by other resources.'
      },
      removed: 'Template was removed'
    },
    tenants: {
      edit: {
        addtitle: 'Add organisation',
        descriptions: {
          database:
            'The name of the database that your organisation will use. Once you have created the organisation this field cannot be changed.',
          environment:
            'The environment of the organisation. Once you have created the organisation this field cannot be changed.',
          id: 'This name is used as a unique ID for your organisation. Must be at least 7 characters long and can only contain the following characters: a-z, 0-9 and -. Cannot start or end with -. Once you have created the tenant this field cannot be changed.',
          isdisabled:
            'When disabled the organisation will not be visible for tenant users. The data will still be kept and the organisation can be activated again at any time.',
          timezone: 'The time zone of the organisation. Not currently used.'
        },
        fields: {
          databasename: 'Database name',
          description: 'Description',
          environment: 'Environment',
          id: 'ID',
          iothubhostname: 'Azure IoT Hub host name',
          isdisabled: 'Disabled',
          name: 'Name',
          resources: 'Resources',
          timezone: 'Time zone'
        },
        sections: {
          resources: 'Resources',
          tenant: 'Organisation'
        },
        title: 'Edit organisation'
      },
      editusers: {
        add: 'Add user',
        addexternaluser: 'Add external user',
        addkiduser: 'Add E.ON KID user',
        title: 'Edit organisation users',
        titleformat: 'Edit {0} users'
      },
      manage: 'Manage all organisations',
      roles: {
        admin: 'Administrator',
        read: 'Reader',
        write: 'Operator'
      },
      user: {
        confirmexternal:
          'Are you sure you want to add an external e-mail account? If you add a KID account instead you will have more secure login functionality. We strongly advise you to cancel and do so.',
        errors: {
          invalidemail: 'Invalid e-mail format.',
          invalidkid: 'Invalid E.ON KID user name.',
          invalidkidemail:
            "Don't use external accounts with E.ON KID e-mail addresses."
        },
        fields: {
          accounttype: 'Account type',
          accounttypeformat: 'Account type: {0}',
          description: 'Description',
          eontype: 'E.ON',
          kid: 'E.ON KID user name',
          name: 'Name',
          role: 'Role',
          standardtype: 'Standard',
          username: 'E-mail'
        },
        helptext: {
          external:
            'A regular e-mail address. External accounts should only be added in exceptional cases, KID accounts are more secure.',
          kid: 'Example of E.ON KID user name: A12345@eon.com. Enter only the first part (before @).'
        },
        nodisplayname:
          'This is the first time this user is added to an organisation. Please provide a name for the user.',
        nonkidemail:
          'You have entered an E.ON email address, but it is not a valid KID email address. Are you sure you want to use this e-mail address? If you use your KID email address instead you will have more secure login functionality. We strongly advise you to cancel and do so.',
        usertypes: {
          external: 'External user',
          kid: 'E.ON KID user'
        }
      }
    },
    toolselector: {
      placeholder: 'Select tools'
    },
    updatedbuilding: {
      success: {
        noerrorsformat: 'Successfully updated building {0}.'
      }
    },
    updatedlocation: {
      success: {
        noerrorsformat: 'Successfully updated location {0}.'
      }
    },
    updatedsite: {
      success: {
        noerrorsformat: 'Successfully updated site {0}.'
      }
    },
    users: {
      access: {
        activitylogs: 'Activity Logs',
        adminlocation: 'Admin Location',
        adminuser: 'Admin User',
        alarm: 'Alarm',
        device: 'Device',
        devicefiles: 'Device Files',
        diagnostic: 'Diagnostic',
        equipment: 'Equipment',
        location: 'Location',
        meteorology: 'Meteorology',
        powercontrol: 'Power Control',
        remoteoptimisation: 'Remote Optimisation',
        schedulesrunner: 'Schedules Runner',
        telemetry: 'Telemetry',
        tools: 'Tools'
      },
      accessrights: {
        admin: 'Admin',
        custom: 'Custom',
        hasaccess: 'Has access',
        none: 'None',
        user: 'User'
      },
      adduser: 'Add new user',
      deleteuser: 'Delete user',
      edituser: 'Edit User',
      error: {
        loadfailed: 'Failed to load users.',
        usernameconflict: 'That e-mail is already associated with another user.'
      },
      firstname: 'First Name',
      lastname: 'Last Name',
      name: 'Name',
      password: 'Password',
      passwordhelp:
        'Password needs to contain at least 1 digit, 1 uppercase character, 1 lowercase character and 1 symbol. It also needs to be between 8 and 16 characters long.',
      request: {
        createduser: 'Added user',
        createuserfailed: 'Failed to add user',
        deleteduser: 'Deleted user',
        deleteuserfailed: 'Failed to delete user',
        getresourcesfailed: 'Failed to fetch user resources',
        getrolesfailed: 'Failed to fetch user roles',
        updateduser: 'Updated user',
        updateuserfailed: 'Failed to update user'
      },
      role: 'Role',
      selectaccess: {
        access: 'Access',
        name: 'Name'
      },
      selectaccessrights: 'Select access rights',
      selectcustom: 'Custom...',
      title: 'Manage Users',
      username: 'E-mail'
    }
  },
  alarm: {
    details: {
      acknowledged: 'Acknowledged',
      active: 'Active',
      created: 'Added',
      hidefullhistory: 'Hide full history',
      history: 'History',
      name: 'Name',
      no: 'No',
      notacknowledged: 'Not acknowledged',
      showfullhistory: 'Show full history',
      user: 'User',
      yes: 'Yes'
    }
  },
  alarms: {
    acknowledge: {
      action: 'Acknowledge',
      all: {
        error: 'Unable to acknowledge all alarms, please try again later',
        prompt:
          'Are you sure you want to acknowledge ALL alarms? You need to enter a comment.',
        promptwithbuildingstatuses:
          'Are you sure you want to acknowledge ALL alarms with building status {0}? You need to enter a comment.',
        success: 'Successfully acknowledged all alarms'
      },
      button: 'Acknowledge alarm',
      comment: 'Comment'
    },
    acknowledgeallstatus: {
      failure: 'Failed to acknowledge all alarms',
      success: 'Acknowledged all alarms'
    },
    acknowledgebutton: 'Acknowledge',
    acknowledged: 'Acknowledged',
    acknowledgedate: 'Acknowledge date',
    acknowledgedby: 'Acknowledged by',
    acknowledgedtext: 'Acknowledged',
    acknowledgedwithoutcomment: 'Acknowledged without comment',
    acknowledgeformat:
      'Are you sure you want to acknowledge the alarm {0} for {1}?',
    acknowledgestatus: {
      failure: 'Failed to acknowledge alarm',
      success: 'Acknowledged alarm'
    },
    acknowledgetitle: 'Acknowledge alarm',
    activationdate: 'Activation date',
    active: 'Active',
    alarmtriggeredformat: 'Alarm triggered: {0}',
    category: {
      activeacknowledged: 'Active acknowledged',
      activenotacknowledged: 'Active not acknowledged',
      inactivenotacknowledged: 'Inactive not acknowledged'
    },
    clearall: 'Clear all',
    columns: {
      acknowledge: 'Acknowledgment',
      ackstatus: 'Acknowledgment status',
      comment: 'Comment',
      description: 'Description',
      equipment: 'Equipment',
      firstactivedate: 'Start date',
      location: 'Location',
      needsacknowledgement: 'Needs acknowledgment',
      severity: 'Severity',
      signal: 'Signal',
      status: 'Status'
    },
    comment: 'Comment',
    commentplaceholder: 'Optional comment',
    datesubtitleformat: 'at {0}',
    description: 'Description',
    equipmentname: 'Equipment name',
    eventid: 'Event',
    events: 'Events',
    filter: 'Filter',
    filterall: 'All',
    filterparams: {
      buildingstatus: 'Building status'
    },
    help: {
      title: 'Alarms'
    },
    historymodal: {
      titleformat: 'History - {0}'
    },
    inactive: 'Inactive',
    noalarmswerefoundinthecurrentlocation:
      'No alarms were found in the current location.',
    nodata: 'No alarms were found in the current location.',
    nodatainsection: 'This page has no alarms in this category.',
    notacknowledged: 'Not acknowledged',
    notacknowledgedstatus: '-',
    numactivealarms: 'Number of active alarms',
    numberofactiveformat: '+ {0} more',
    page: {
      heading: {
        acknowledgeallalarms: 'Acknowledge all',
        activealarms: 'Active',
        allalarms: 'History'
      }
    },
    section: {
      eventlist: 'Event list',
      signalclusters: 'Current alarms'
    },
    severityMax: 'Maximum severity',
    severityMin: 'Minimum severity',
    title: 'Alarms',
    unacknowledgedtext: 'Not acknowledged'
  },
  authenticate: {
    authentification: {
      failure: 'Authentication failed'
    },
    login: {
      again: 'Try to login again'
    }
  },
  buildingstatus: {
    created: 'Added',
    production: 'Production',
    testing: 'Testing',
    timetype: {
      after: 'After',
      before: 'Before',
      exact: 'Exact'
    }
  },
  common: {
    actionmodal: {
      cancel: 'Cancel'
    },
    add: 'Add',
    affected: 'Affected',
    all: 'All',
    allowednodetypes: 'Allowed node types',
    and: 'and',
    back: 'Back',
    baserequesterror:
      'Error communicating with server. Some features might not work as intended.',
    cancel: 'Cancel',
    clear: 'Clear',
    clearvalues: 'Clear values',
    clicktoedit: 'Click to edit',
    close: 'Close',
    color: 'Color',
    column: {
      error: {
        render:
          "Your render function must return a RowItem. Received '{0}' instead."
      }
    },
    columns: 'Columns',
    compare: {
      equal: 'Equal',
      greaterthan: 'Greater than',
      greaterthanorequal: 'Greater than or equal',
      lessthan: 'Less than',
      lessthanorequal: 'Less than or equal',
      notequal: 'Not equal'
    },
    confirmdelete: {
      message: 'Are you sure you want to delete {0}?',
      title: 'Confirm delete'
    },
    copytoclipboard: {
      copy: 'Copy',
      defaultitem: 'value',
      failure: 'Could not copy the value to your clipboard.',
      failureformat: 'Could not copy {0} to your clipboard.',
      success: 'Copied value to your clipboard.',
      successformat: 'Copied {0} to your clipboard.',
      tooltip: 'Copy to clipboard'
    },
    dBm: 'dBm',
    datatable: {
      error:
        'An error occurred while displaying the data, please try again later',
      loadmore: 'Load more',
      nodata: 'No data available'
    },
    date: 'Date',
    dateinput: {
      from: 'From:',
      to: 'To:'
    },
    delete: 'Delete',
    description: 'Description',
    disable: 'Disable',
    disabled: 'Disabled',
    discardchanges: 'Discard changes',
    displayname: 'Display name',
    done: 'Done',
    duplicate: 'Add a copy',
    edit: 'Edit',
    enable: 'Enable',
    enabled: 'Enabled',
    environmenterror: 'Failed to read environment configuration.',
    error: 'Error',
    exactmatch: 'Exact match',
    hours: 'hours',
    iconbutton: {
      add: {
        title: 'Add'
      },
      help: {
        title: 'Help'
      },
      search: {
        title: 'Search'
      }
    },
    information: 'Information',
    loading: 'Loading...',
    loadingtextlong: 'Loading, please wait...',
    name: 'Name',
    networkfailure: {
      error: 'Failed to execute request due to network issues.'
    },
    next: 'Next',
    no: 'No',
    nodename: 'Name',
    none: 'None',
    notavailable: 'n/a',
    ok: 'OK',
    optional: 'Optional',
    previous: 'Previous',
    priority: 'Priority',
    range: {
      maxx: 'Maximum X',
      maxy: 'Maximum Y',
      minx: 'Minimum X',
      miny: 'Minimum Y'
    },
    reason: 'Reason',
    redo: 'Redo',
    save: 'Save',
    search: {
      placeholder: 'Search...'
    },
    selectcolor: 'Select color',
    selectsignal: 'Select signal...',
    share: 'Share...',
    shorthours: 'h',
    status: 'Status',
    tags: 'Tags',
    undo: 'Undo',
    unit: 'Unit',
    unknown: 'Unknown',
    unknownerror: 'Unknown error.',
    unsavedchanges: 'Unsaved changes',
    unsavedchangesmessage:
      'You have unsaved changes. Do you want to save them?',
    users: 'Users',
    yes: 'Yes'
  },
  creatableselect: {
    create: 'Add "{0}"',
    createnew: 'Add new...',
    placeholder: 'Select...'
  },
  curves: {
    add: 'Add curve'
  },
  dashboard: {
    addpanel: {
      error: 'Failed to copy panel.',
      successformat: 'Copied panel to {0}.'
    },
    addpaneltopersonalbutton: 'Copy to personal dashboard',
    addpaneltopersonaltitleformat: 'Copy {0} to personal dashboard',
    alarms: {
      showall: 'Show All',
      title: 'Alarms'
    },
    confirmsave: {
      message:
        'There are multiple unsaved dashboards. Do you wish to save all of them all or just this one?',
      saveall: 'Save all',
      savecurrent: 'Save current',
      title: 'Save all dashboards?'
    },
    enterfullscreen: 'Fullscreen mode',
    error: {
      nodashboardsfound: 'No dashboards were found for this location.',
      personaldashboards: 'Error loading your dashboards.',
      personaldashboardsempty: 'No dashboards found. Press + to add one.',
      unsupportedpaneltype: 'Incorrect panel type.'
    },
    exitfullscreen: 'Exit fullscreen mode',
    map: {
      namelabel: 'Name:',
      streetlabel: 'Street:'
    },
    missingdashboards: 'This dashboard collection is empty',
    newpaneltitle: 'Panel title',
    newuserdashboard: {
      description: 'Description',
      name: 'Name',
      title: 'Add new personal dashboard'
    },
    selectdashboard: 'Select dashboard...',
    selectuserdashboardplaceholder: 'Select dashboard...',
    selectuserdashboardtitle: 'Dashboard',
    showingraphs: 'Show in graphs',
    timerange: {
      custom: 'Custom',
      durationheader: 'Time range',
      positionheader: 'Time range position'
    },
    titlewithchangesformat: '{0} (unsaved)',
    usefixedlocation: 'Use locked location',
    usefixedlocationhelpformat: 'Enable to always load data from {0}.'
  },
  durationtime: {
    daysformat: '{0} days',
    hoursformat: '{0} hours',
    lessthanoneminute: 'less than 1 minute',
    minutesformat: '{0} minutes',
    onehour: '1 hour',
    oneminute: '1 minute'
  },
  ectoplanner: {
    addnewversion: 'Add new version',
    balancinghelp: {
      disclaimer:
        'Note that this is just an approximation, and the actual capacity may vary.',
      statusinsufficientcooling:
        'There seems to be insufficient cooling capacity in the grid. Cooling capacity is at approximately {0}%.',
      statusinsufficientheating:
        'There seems to be insufficient heating capacity in the grid. Heating capacity is at approximately {0}%.',
      statusinsufficientheatingandcooling:
        'There seems to be insufficient heating and cooling capacity in the grid. Heating capacity is at approximately {0}% and cooling capacity is at approximately {1}%.',
      statusok: 'There seems to be sufficient thermal capacity in the grid.'
    },
    balancingunit: {
      maximumcoolingpowerbu: 'Maximum cooling power of BU: {0} kWth',
      maximumheatingpowerbu: 'Maximum heating power of BU: {0} kWth',
      recommendedpipediameter: 'Recommended pipe diameter at BU: DN{0}',
      resultingflowvelocity: '(resulting flow velocity: {0} m/s)'
    },
    buildings: {
      add: 'Add building',
      edit: 'Edit building',
      energyoverview: {
        helptext:
          'When selecting demand you will get all energy sources for that category (space heating and domestic hot water for heating, space cooling and process cooling for cooling) - balancing is not included. When you select import, you will get the heating or cooling taken from the ectogrid (with balancing included).',
        label: 'Energy overview'
      }
    },
    buildingsummary: {
      balancedloadsbetweenbuildings: 'Balanced loads between buildings',
      balancingunitloads: 'Energy center loads',
      buildingdemands: 'Building demands',
      buildingimports: 'Building imports',
      button: 'Aggregated building results...',
      coolequipment: {
        chiller: 'Chiller',
        directcooling: 'Direct cooling',
        peakchiller: 'Peak chiller'
      },
      coolingcapacity: 'Cooling capacity',
      coolingdemand: 'Cooling demand',
      coolingimportcapacity: 'Cooling import capacity',
      coolingloaddemand: 'Cooling load demand',
      electricityimportcapacity: 'Electricity import capacity',
      energyimports: {
        annualimport: 'Annual import',
        coolingfrom: 'Cooling from ectogrid network',
        demandfooter:
          '{0} waste heat from the cooling system is directly used at the evaporator of the heat pump. As a result, the heat and cold import from the network is reduced by {1}.',
        grid: 'Electricity grid',
        heatfrom: 'Heat from ectogrid network',
        name: 'Name',
        peakimport: 'Peak import'
      },
      graphnames: {
        cooling: 'Cooling',
        heating: 'Heating'
      },
      heatcapacity: 'Heat capacity',
      heatdemand: 'Heat demand',
      heatequipment: {
        electricheatingrod: 'Electric heating rod',
        hotwaterhp: 'Hot water HP',
        spaceheatinghp: 'Space heating HP'
      },
      heatimportcapacity: 'Heat import capacity',
      heatloaddemand: 'Heat load demand',
      importedcooling: 'Imported cooling',
      importedelectricity: 'Imported electricity',
      importedheat: 'Imported heat',
      importfromgrid: 'Import from grid',
      moreinfo: 'More information...',
      rows: {
        balcoolloadsbldgs: 'Balanced cooling demands in buildings',
        balcoolloadsnetw: 'Balanced cooling demands in network',
        balheatloadsbldgs: 'Balanced heat demands in buildings',
        balheatloadsnetw: 'Balanced heat demands in network',
        colddemallbldgs: 'Cold demand of all buildings',
        coldgainsnet: 'Cooling gains (net)',
        coldimportallbldgs: 'Cold import of all buildings',
        coolgenpeakchillers: 'Cooling generation of air-cooled chillers',
        coolloadatenergyhub: 'Cold feed-in at energy center',
        coollossescoolingnetw: 'Thermal losses of cooling network',
        elecdemallbldgs: 'Electricity demand of all buildings',
        elecdemandchillers: 'Electricity demand of chillers',
        elecdemandelheatingrods: 'Heat generation of heating rods',
        elecdemandheatpumps: 'Electricity demand of heat pumps',
        elecdembes: 'Elec. demand of decentral units',
        elecimportallbldgs: 'Electricity import of all buildings',
        elecloadatenergyhub: 'Electricity load at energy center',
        energyimportallbuildings: 'Energy import of all buildings',
        heatdemallbldgs: 'Heat demand of all buildings',
        heatgainscoldnet: 'Heat gains (net)',
        heatgainsheatnet: 'Heat gains (net)',
        heatimportallbldgs: 'Heat import of all buildings',
        heatloadatenergyhub: 'Heat feed-in at energy center',
        heatlossesheatingnetw: 'Heat losses of heating network',
        heatlossnet: 'Heat losses (net)',
        loadatenergyhub: 'Load at energy center',
        pumpwork: 'Pump work',
        sharedhw: 'Share of domestic hot water',
        shareemobility: 'Share e-mobility',
        shareplugloads: 'Share plug loads',
        shareprocesscooling: 'Share process cooling',
        sharespacecooling: 'Share space cooling',
        sharespaceheating: 'Share space heating'
      },
      sections: {
        balancing: 'Balancing of thermal demands',
        cooling: 'Cooling',
        coolingpower: 'Cooling power in buildings',
        electricity: 'Electricity',
        energydemands: 'Energy demands',
        energyimports: 'Energy imports after internal balancing',
        feedin: 'Feed-in of energy center',
        heating: 'Heating',
        heatingpower: 'Heating power in buildings',
        loadprofiles: 'Load profiles in Ectogrid'
      },
      sectiontooltips: {
        balancing:
          'The visualization shows the demand balancing of heating and cooling demands in buildings and between buildings through the network. For more information, tap the info button at the bottom.',
        energyimports:
          'Heat, cold and electricity imports of the building from the thermal and electric network. The energy import depends on the energy system of the building.',
        feedin:
          'The visualization shows the monthly feed-in of heat and cold from the energy center into the network.'
      },
      series: {
        balanced: 'Balanced',
        coldfed: 'Cold fed into the network by the energy center',
        electricity: 'Electricity',
        electricitydemand:
          'Electricity demand of decentral heat pumps,<br/> heating rods and chillers',
        electricityimport: 'Electricity imported',
        heatdemand: 'Heat demand at the evaporator of heat pumps',
        heatfed: 'Heat fed into the network by the energy center',
        heatimported: 'Heat imported from the Ectogrid network',
        totalspacecooling: 'Total space cooling and process cooling demand',
        totalspaceheating: 'Total space heating and domestic hot water demand',
        wasteheatdemand:
          'Waste heat at condenser of chillers<br/> or heat exchangers',
        wasteheatfed: 'Waste heat fed from buildings into the network'
      },
      tablecolumns: {
        annualdemand: 'Annual demand',
        annualenergy: 'Annual energy',
        cold: 'Cold',
        cop: 'COP',
        electricity: 'Electricity',
        electricitydemand: 'Electricity demand',
        floorarea: 'Floor area',
        generatedenergy: 'Generation',
        heat: 'Heat',
        label: 'Name',
        machinecapacity: 'Machine capacity',
        maxpower: 'Max power',
        name: 'Name',
        peakdemand: 'Peak demand',
        peakpower: 'Peak power',
        processcooling: 'Process cooling',
        seasonalcop: 'SCOP',
        spacecooling: 'Space cooling',
        spaceheating: 'Space heating',
        technology: 'Technology',
        thermalcapacity: 'Thermal capacity'
      },
      tabletooltips: {
        cold: 'Cooling energy imported from the network to cover net cooling demands of the building (after demand balancing in building).',
        electricity:
          'Electricity imported from the electricity grid to cover electricity demands for plug loads, e-mobility and decentral heat pumps, heating rods and chillers.',
        energyimportallbuildings:
          'Total heat, cold and electricity import of buildings. The heat/cold import describes the heat/cold transferred from the network to the building. The balancing of simultaneous heating and cooling demands in the building reduces the imported heating and cooling energy. The electricity import results from the electricity demands of the building (plug loads and e-mobility) as well as the electricity demand of the building energy system (for example for heat pumps). The power values (in kW) describe the annual peak load.',
        heat: 'Heat imported from the network to cover net heating demands of the building (after demand balancing in building).',
        loadatenergyhub:
          'Heat and cold feed-in at the energy center into the network (diversity factor and balancing of heating and cooling loads included). The electricity demand is the sum of the electricity imports of all buildings (plug loads, e-mobility and electricity for decentral heat pumps, heating rods and chillers) plus the pump work. The power values (in kW) describe the annual peak load.'
      },
      title: 'Aggregated building results',
      tooltips: {
        balcoolloadsbldgs:
          'If one building imports heat from the network and at the same time another building imports cold (this means feeds heat into the network), these demands partially balance each other out and thus, reduce the feed-in at the energy center.',
        balcoolloadsnetw:
          'If one building imports heat from the network and at the same time another building imports cold (this means feeds heat into the network), these demands partially balance each other out and thus, reduce the feed-in at the energy center.',
        balheatloadsbldgs:
          'Simultaneous heating and cooling demands in buildings can be partially balanced out by heat recovery: Waste heat from the cooling supply is used at the evaporator of the heat pump, thus reducing the buildings heat and cold demand from the network.',
        balheatloadsnetw:
          'If one building imports heat from the network and at the same time another building imports cold (this means feeds heat into the network), these demands partially balance each other out and thus, reduce the feed-in at the energy center.',
        coldgainsnet:
          'Annual energy by which the cooling load at the energy center decreases (due to heat losses to the ground and diversity effects).',
        coldimportallbldgs:
          'Total heat import of all buildings. The heat import describes the heat transferred from the network to the building.',
        coolgenpeakchillers:
          'Air-cooled chillers cover peak loads of the cooling demand in the building. This reduces the amount of cooling that the buildings need to import from the heat network.',
        coolloadatenergyhub:
          'Cooling load at the energy center for the supply of the network. Here, the diversity factor of the network as well as the balancing of simultaneous heating and cooling loads in the network is included. The load balancing in the network reduces the residual load at the energy center.',
        coollossescoolingnetw: 'Thermal losses of cooling network',
        elecdemandelheatingrods:
          'Electric heating rods cover peak loads of the space heating demand in buildings. This reduces the amount of heat that needs to be imported from the heating network by the buildings.',
        elecdemandheatpumps:
          'By using water-to-water heat pumps in the buildings, part of the heat demand is covered by the heat pump electricity. This reduces the amount of heat that needs to be imported from the heat network by the buildings.',
        elecdembes:
          'Annual electricity demand of all building energy systems, including heat pumps, chillers or electric heating rods.',
        elecimportallbldgs:
          'Total electricity import of all buildings. The electricity import results from the electricity demands of the building (plug loads and e-mobility) as well as the electricity demand of the building energy system (for example for heat pumps).',
        elecloadatenergyhub:
          'The electricity load at the energy center is the sum of the electricity imports of all buildings (plug loads, e-mobility and electricity  for the building energy systems including decentral heat pumps) plus the pump work of the network.',
        heatgainscoldnet:
          'Annual energy by which the cooling load at the energy center increases (due to a heat flow from the ground and diversity effects).',
        heatgainsheatnet:
          'Annual energy by which the heat load at the energy center decreases (due to heat gains from the ground and diversity effects).',
        heatimportallbldgs:
          'Total heat import of all buildings. The heat import describes the heat transferred from the network to the building. The balancing of simultaneous heating and cooling demands in the building reduces the imported heating and cooling energy.',
        heatloadatenergyhub:
          'Heating load at the energy center for the supply of the network. Here, the diversity factor of the network as well as the balancing of simultaneous heating and cooling loads in the network is included. The load balancing in the network reduces the residual load at the energy center.',
        heatlossnet:
          'Annual energy by which the heat load at the energy center increases (due to heat losses to the ground and diversity effects).'
      },
      xaxis: {
        demandofallbuildings: 'Demand of all buildings',
        demandofdecentralunits: 'Demand of decentral units',
        energycentersupply: 'energy center supply',
        importofallbuildings: 'Import of all buildings'
      }
    },
    calculate: 'Calculate',
    calculateerror: 'Failed to start calculation',
    calculatetitle: 'Start calculation',
    calculations: {
      builds: {
        date: 'Date',
        description: 'Description',
        status: 'Status'
      },
      calculating: 'Calculating...',
      calculationfailed: 'Calculation failed',
      copylinktoclipboard: 'Copy link to clipboard',
      description: 'Description',
      editdescription: 'Edit description',
      failedtoloadbuilds: 'Failed to load calculations',
      formerrorsetting: 'Setting',
      formerrorstitle: 'Correct errors before calculating',
      genericbuildname: 'this calculation',
      newchangesinfo:
        'You have made changes to the project. Press calculate to see updated results.',
      nosolutionfound:
        'No feasible solution found. Usually, the energy center could not cover the thermal demand of the ectogrid. Please, allow more heating and cooling generation capacity, e.g. gas boiler.',
      notcalculated: 'No calculation',
      notcalculatedinfo:
        'The project has not been calculated yet. Press "Calculate" to start the calculation.',
      status: {
        error: 'Error',
        no_solution_found: 'No solution found',
        results_done: 'Done',
        timedout: 'Timed out'
      },
      title: 'Ectoplanner calculation'
    },
    confirmdeleteproject:
      'Are you sure you want to delete this project? All versions will also be deleted.',
    confirmdeleteversion: 'Are you sure you want to delete this version?',
    confirmdisabletech: {
      removepassivebalancing:
        'If the passive balancing tank is deselected, an additional 10,000 euros will be automatically added to the project investment cost, covering the hydraulic connection between hot and cold pipes, including regulators and sensors.',
      titleformat: 'Are you sure you want to deselect {0}?'
    },
    currentversion: 'Current version',
    datacopyformat: 'Data will be copied from "{0}".',
    download: 'Download',
    energygraphs: {
      breakpointchiller: 'Breakpoint chiller backup',
      breakpointheatpump: 'Breakpoint heat pump backup',
      energydemand: 'Energy demand',
      energyoptions: {
        cop: 'COP',
        demand: 'Building demand',
        import: 'Import from grid'
      },
      energytype: 'Graph data',
      plotoptions: {
        annual: 'Annual',
        durationcurve: 'Duration curve',
        monthly: 'Monthly'
      },
      plottype: 'Plot type'
    },
    export: 'Export',
    exportairtemp: 'Export air temperature',
    exportcitydata: 'Export city data',
    exportcsv: 'Export CSV',
    exportformdata: 'Export data with calculations',
    exportproject: 'Export data',
    failedtoloadproject: 'Failed to load project',
    fileeditor: {
      change: 'Change...',
      failedtoloadfile: 'Failed to load file',
      uploaderror: 'Failed to upload new file'
    },
    form: {
      accutankbu: {
        enabled: {
          helptext:
            'Passive balancing tank balances residual load from ectogrid.'
        },
        maxvol: {
          label: 'Maximum volume'
        },
        minvol: {
          label: 'Minimum volume'
        }
      },
      aquiferstoragebu: {
        enabled: {
          helptext:
            'Aquifer storage to inject and extract heat to/from ground (seasonal storage).'
        },
        maxchenergy: {
          helptext: 'Maximum amount of heat stored in ground.',
          label: 'Maximum thermal energy'
        },
        maxchpower: {
          helptext: 'Maximum thermal power injected to ground.',
          label: 'Maximum thermal power'
        },
        pumpwork: {
          helptext:
            'Additional electricity demand for every kWh of heat extracted from or injected to ground.',
          label: 'Pump work'
        },
        roundeff: {
          helptext: 'Ratio of extracted to injected heat from/to ground.',
          label: 'Round trip efficiency'
        },
        roundeffenabled: {
          label: 'Round trip calculation'
        },
        roundeffmax: {
          label: 'Round trip efficiency max.'
        },
        roundeffmin: {
          label: 'Round trip efficiency min.'
        }
      },
      batterybu: {
        enabled: {
          helptext:
            'Battery can be charged with surplus power from CHP unit or PV installation.'
        }
      },
      building: {
        bldgspeccops: {
          label: 'Use building specific COPs'
        },
        buildingformat: 'Building {0}',
        calcdemands: {
          label: 'Estimate demands'
        },
        coolingtimeseries: {
          label: 'Cooling demand (kWh/h)'
        },
        floorarea: {
          label: 'Heated / cooled floor area'
        },
        heatingtimeseries: {
          label: 'Space heating (kWh/h)'
        },
        machinecapacitytooltip:
          'Machine Capacity is the dimensioned capacity of the machine. In most cases, it will be the same with the max heating or cooling production. However, the machine capacity can be different from max heat or cooling production in transversal heat pumps.',
        params: {
          anndemspec: {
            helptext:
              'Annual demand of energy kind per square meter of floor area.',
            label: 'Specific annual demand'
          },
          anndemtot: {
            helptext: 'Total annual demand of energy kind.',
            label: 'Total annual demand'
          },
          beslowexelheaterenabled: {
            helptext:
              'If enabled, an electric heating rod covers the peak heat loads. For the heating rod, an efficiency of 100 % is assumed.',
            label: 'Electric heating rod'
          },
          beslowexelheatermaxload: {
            helptext:
              'Share of the maximum heating load that is covered by the heating rod (bivalent-parallel operation)',
            label: 'Coverage of maximum heat load'
          },
          beslowexhpcop: {
            label: 'Constant COP value'
          },
          beslowexhpdhwcop: {
            label: 'Domestic hot water (heat pump) COP'
          },
          beslowexpeakchillercop: {
            helptext:
              'Coefficient of performance of the cooling supply (air-cooled chiller and cooling tower)',
            label: 'COP of the cooling generation'
          },
          beslowexpeakchillerenabled: {
            helptext:
              'If enabled, an air-cooled chiller is installed to cover peak cooling loads. The waste heat of the chiller is released into the environment using a cooling tower. The chiller reduces the peak loads of the thermal network in summer.',
            label: 'Air-cooled chiller for peak-loads'
          },
          beslowexpeakchillermaxload: {
            helptext:
              'Share of the maximum cooling load that is covered by the air-cooled chiller (bivalent-parallel operation)',
            label: 'Coverage of maximum cooling load'
          },
          buildingsubtype: {
            label: 'Building subtype'
          },
          buildingtype: {
            helptext:
              'Overall category of building. Use subtype to specify further. Changing the building types will insert default values and affect heating and cooling profile calculations.',
            label: 'Building type'
          },
          carnoteta: {
            label: 'Exergy efficiency'
          },
          carnotetachiller: {
            helptext:
              'The exergy efficiency is used as pre-factor of the Carnot efficiency. Chillers usually have exergy efficiencies between 40 (small-scale, low efficiency) and 60 (large-scale, high efficiency).'
          },
          carnotetahp: {
            helptext:
              'The exergy efficiency is used as pre-factor of the Carnot efficiency. Heat pumps usually have exergy efficiencies between 40 (small-scale, low efficiency) and 60 (large-scale, high efficiency).'
          },
          considercurve: {
            helptext:
              'If enabled, the supply temperature of the heating system in the building decreases linearly as the outdoor air temperature increases (otherwise it is constant all year round). When the heating curve is considered, a supply temperature of 25 °C is assumed at an outdoor temperature of 15 °C (or higher). The maximum supply temperature is reached when the outdoor air temperature is at its minimum (coldest hour of the year).',
            label: 'Consider supply temperature curve'
          },
          consumptionemob: {
            label: 'Route consumption'
          },
          cop: {
            label: 'COP calculation'
          },
          copoptions: {
            carnot: 'Calculate COP (Carnot)',
            const: 'Constant COP',
            productdata: 'Product data',
            upload: 'Upload COP time series'
          },
          customprofile: {
            helptext:
              'Specify a custom demand profile for this energy kind. It will replace the algorithm that calculates a profile based on the input values.',
            label: 'Custom demand profile'
          },
          distanceemob: {
            label: 'Charged driving distance per day'
          },
          enableformat: 'Enable {0}',
          enablehelptext:
            'If enabled, the demand for this energy kind is considered in the calculations.',
          energysystem: {
            copoptions: {
              helptext:
                'Specify the way COP (Coefficent Of Performance) is calculated for the heat pump or chiller. You can choose to calculate using the Carnot formula, a constant value or by specifying a product. If you specify a product the COP is calculated with manufacturer data based on the standard EN 14825.'
            },
            sections: {
              coolingsupply: 'Cooling supply',
              dhw: 'Domestic hot water (heat pump)',
              spaceheating: 'Space heating (heat pump)'
            }
          },
          floorarea: {
            helptext:
              'Heated or cooled floor area. The floor area is only used to convert area-specific demand values (kWh/m²) into annual demands (MWh) and vice versa.',
            label: 'Floor area'
          },
          fullloadhours: {
            helptext: 'Full load hours of energy generation.',
            label: 'Full load hours'
          },
          individualcosts: {
            helptext:
              'Enable individual costs for the installed energy technologies in this building. If not set, it will use the default values from the Model section.',
            label: 'Individual costs'
          },
          lowexcc: {
            label: 'Chiller'
          },
          lowexcoolingoption: {
            helptext:
              'Specifies the supply type of the chiller. With passive cooling, the cooling demand is covered by a heat exchanger (no electricity needed). The waste heat is fed into the network. With chiller (water-cooled) chillers cover the cooling demands and feed the waste heat into the network. A chiller may be necessary if the network temperatures are too high to provide the cooling directly with a heat exchanger. Learn',
            label: 'Supply type'
          },
          lowexdrc: {
            label: 'Passive cooling (heat exchanger)'
          },
          peakdemspec: {
            helptext: 'Specific maximum load for energy kind.',
            label: 'Specific peak demand'
          },
          peakdemtot: {
            helptext: 'Maximum load for energy kind.',
            label: 'Total peak demand'
          },
          productdata: {
            formatstring: 'COP: {0} (for temperature lift from {1} to {2} °C)',
            label: 'Product'
          },
          seasonbegin: {
            helptextcooling:
              'Begin of the cooling season (typically in spring).',
            helptextheating:
              'Begin of the heating season (typically in autumn).',
            label: 'Beginning of season'
          },
          seasonend: {
            helptextcooling: 'End of the cooling season (typically in autumn)',
            helptextheating: 'End of the heating season (typically in spring).',
            label: 'End of season'
          },
          sections: {
            building: 'Building',
            cooling: 'Cooling',
            dhw: 'Domestic hot water',
            electricity: 'Electricity',
            emob: 'E-mobility',
            energysystem: 'Substation equipment',
            heating: 'Heating',
            heatingcooling: 'Heating & cooling',
            investment: 'Investment',
            plugloads: 'Plug loads',
            processcool: 'Process cooling',
            spacecooling: 'Space cooling',
            spaceheating: 'Space heating',
            summary: 'Summary',
            transversal: 'Transversal energy system'
          },
          setpeakload: {
            helptext:
              'Specify parameters for maximum load and amount of full load hours for energy kind.',
            label: 'Set peak load'
          },
          setseason: {
            helptext:
              'A season can be set. In the off-season, the demand for this energy kind is zero.',
            label: 'Set season'
          },
          sharedhw: {
            helptext:
              'Share of domestic hot water heating demand in relation to total heating demand (space heating and domestic hot water).',
            label: 'Share of domestic hot water'
          },
          supplytempchiller: {
            helptext:
              'Supply temperature of the cooling system in the building (for example 16 °C for underfloor cooling or 6 °C for conventional cooling systems).',
            label: 'Supply temperature'
          },
          supplytempdhw: {
            helptext:
              'Domestic hot water temperature (usually between 55 and 60 °C)',
            label: 'Supply temperature'
          },
          supplytempheatpump: {
            helptext:
              'Maximum supply temperature of the heating system in the building (for example 35 °C for underfloor heating or 50-90 °C for radiators).',
            label: '(Max) supply temperature'
          },
          textareaplaceholder:
            'Enter a time series or paste text from Excel or a text editor. You can also drag and drop a text file into this area.\n\nValid number of values:\n- 8760 (hours),\n- 365 (days),\n- 12 (months),\n- 4 (seasons), or\n- 1 (constant value over year).\nExample input:  5.2, 4.8, 3.6, 4.9',
          transversal: {
            coolsupplytemp: 'Cooling supply temperature',
            supplytempdwh: 'Domestic hot water supply temperature',
            usetransversal: {
              helptext:
                'Choose between the transversal energy system and traditional energy system. The transversal energy system uses a single heat pump to provide the heating and cooling. The traditional energy system has a dedicated heat pump for heating and a dedicated cooler for cooling.',
              label: 'Use transversal energy system'
            }
          }
        },
        showsummary: 'Summary...',
        specCOPheat: {
          label: 'COP for space heating'
        },
        specCOPtapwater: {
          label: 'COP for domestic hot water heating'
        },
        speccooldem: {
          label: 'Specific space cooling demand'
        },
        specheatdem: {
          label: 'Specific space heating demand'
        },
        tapwaterdem: {
          label:
            'Domestic hot water demand as percentage of total heating demand'
        },
        tapwatertimeseries: {
          helptext:
            'Upload of domestic hot water is not mandatory. If no file is uploaded, no domestic hot water demand is assumed.',
          label: 'Domestic Hot Water (kWh/h)'
        }
      },
      chiller: {
        copconst: {
          helptext: 'Constant chiller COP throughout the year.'
        },
        copwithcarnot: {
          helptext:
            'Calculate COP based on Carnot efficiency with cooling supply temperature in building as heat source and cold ectogrid pipe as heat sink.'
        },
        enabled: {
          label: 'Install chillers'
        },
        tempcooling: {
          label: 'Supply temperature space cooling'
        },
        title: 'Chillers'
      },
      chpbu: {
        etael: {
          helptext:
            'For small CHP units with small capacities (&lt;100 kW), the electric efficiency is up to 35 %. Large CHP units with a capacity of larger than 1 MW reach electric efficiencies of 40 % and higher.',
          label: 'Electric efficiency'
        }
      },
      coldstoragebu: {
        enabled: {
          helptext:
            'Cold storage can be charged with compression and absorption chiller.'
        }
      },
      common: {
        solararealist: {
          label: 'Areas'
        }
      },
      compressionchiller: {
        passive_cooling_enabled: 'Enable passive cooling'
      },
      compressionchillerbu: {
        copcarnoteff: {
          helptext:
            'Calculate COP with ectogrid temperature and outdoor air temperature.'
        }
      },
      districtcoolingbu: {
        cap: {
          label: 'Capacity of district cooling connection'
        }
      },
      districtheatingbu: {
        cap: {
          label: 'Capacity of district heating connection'
        }
      },
      ecologicalimpact: {
        co2el: {
          helptext:
            'Specific CO₂ equivalents of the electricity mix of the power grid.',
          label: 'CO₂ emissions power grid'
        },
        co2gas: {
          helptext:
            'Specific CO₂ equivalents that arise from burning natural gas.',
          label: 'CO₂ emissions natural gas'
        },
        district_cooling_co2: 'District cooling CO₂',
        district_cooling_primary_energy: 'District cooling primary energy',
        district_heating_co2: 'District heating CO₂',
        district_heating_primary_energy: 'District heating primary energy',
        gas_primary_energy: 'Gas primary energy',
        grid_primary_energy: 'Grid primary energy',
        title: 'Environmental parameters',
        waste_cold_co2: 'Waste cold CO₂',
        waste_cold_primary_energy: 'Waste cold primary energy',
        waste_heat_co2: 'Waste heat CO₂',
        waste_heat_primary_energy: 'Waste heat primary energy'
      },
      economicparameters: {
        enablescenario: {
          helptext: 'Load economic parameters from a predefined scenario',
          label: 'Enable scenario'
        },
        envsurcharge: {
          helptext: 'For the German case, this corresponds to the EEG levy.',
          label: 'Environmental surcharge'
        },
        interestrate: {
          helptext:
            'The interest rate affects the annualization of the investments according to VDI 2067.',
          label: 'Interest rate'
        },
        othsurcharge: {
          label: 'Other surcharges'
        },
        priceexel: {
          helptext: 'Wholesale electricity price at present time.'
        },
        priceexgas: {
          helptext: 'Wholesale gas price at present time.'
        },
        projectlifetime: {
          helptext:
            'The project lifetime affects annualization of investments according to VDI 2067.',
          label: 'Project lifetime'
        },
        scenario: {
          helptext:
            'Select one of the predefined scenarios. You can further adjust the scenario parameters if required.',
          label: 'Scenario chosen'
        },
        section: {
          electricity: 'Electricity price',
          gas: 'Gas price',
          general: 'Economic parameters'
        },
        taxrate: {
          label: 'Tax rate'
        }
      },
      electricalheater: {
        enabled: {
          label: 'Install auxiliary electric heating rod'
        },
        peakcover: {
          helptext:
            'Proportion of heating demand that is covered by the electric heating rod when the maximum heating demand occurs (e.g. coldest winter day).'
        },
        title: 'Auxiliary electric heating rod'
      },
      emptyform: {
        label:
          'To run calculations you will need to select a location and add some buildings.'
      },
      energycosts: {
        enablefeedinel: {
          helptext:
            'If checked, surplus electricity, e.g. by CHP or PV, can be fed into the power grid. If feed-in is not allowed, generated power is used on-site only. PV curtailment is possible.',
          label: 'Enable feed-in'
        },
        price_el_profile: {
          label: 'Electricity price profile'
        },
        price_el_uploaded: {
          label: 'Upload price profile'
        },
        priceel: {
          label: 'Annualized electricity price'
        },
        pricegas: {
          label: 'Annualized natural gas price'
        },
        revenuefeedinel: {
          helptext: 'Revenue for electricity feed-in to power grid.',
          label: 'Feed-in tariff'
        },
        title: 'Energy costs'
      },
      freecooling: {
        enabled: {
          helptext:
            'Enable direct cooling with the ectogrid. Heat exchangers are installed in buildings instead of chillers.',
          label: 'Install direct cooling'
        },
        invvar: {
          helptext: 'Investment for heat exchanger.'
        }
      },
      gasboiler: {
        enabled: {
          label: 'Install auxiliary gas boiler'
        },
        peakcover: {
          helptext:
            'Proportion of heating demand that is covered by the gas boiler when the maximum heating demand occurs (e.g. coldest winter day).'
        },
        title: 'Auxiliary gas boiler'
      },
      gsheatpumpbu: {
        copconstcool: {
          label: 'Cooling COP (constant)'
        }
      },
      heatpump: {
        copconstspaceheating: {
          label: 'COP space heating'
        },
        copconsttapwater: {
          label: 'COP domestic hot water'
        },
        tempspaceheating: {
          label: 'Supply temperature space heating'
        },
        temptapwater: {
          label: 'Supply temperature domestic hot water'
        },
        title: 'Heat pumps in buildings'
      },
      heatstoragebu: {
        enabled: {
          helptext:
            'Heat storage can be charged with gas boiler, CHP or electric boiler.'
        }
      },
      investment: {
        cc: {
          label: 'Chiller'
        },
        drc: {
          label: 'Direct cooling'
        },
        eh: {
          label: 'Electrical heater'
        },
        helptext:
          'These are the general investment settings for the energy system. You can also specify individual costs for each building in the building section.',
        hp: {
          label: 'Heat pump'
        },
        peak_chiller: {
          label: 'Peak chiller'
        },
        title: 'Investment building equipment'
      },
      locationhelp:
        'Please note that changing locations will update the specific annual demands for each energy kind in all buildings to reflect the demands of the new location.',
      network: {
        buttontitle: 'Network parameters...',
        costsearthwork: {
          label: 'Costs earthworks and installation'
        },
        costspipes: {
          label: 'Costs pipe material'
        },
        diversityfactor: {
          helptext:
            'The diversity factor describes how much the peak load at the energy center is reduced. The total demand of the annual load profile is not changed by the smoothing. For a conservative calculation, a diversity factor of 1 can be assumed (no smoothing and therefore no reduction of the peak load).',
          label: 'Diversity factor'
        },
        enablenetwcosts: {
          helptext:
            'Network costs are a constant offset to system costs only. It does not affect the energy flow calculation.',
          label: 'Consider network costs'
        },
        enabletempupload: {
          label: 'Upload mean netw. temp. profile (°C)'
        },
        estimated_volume: {
          helptext:
            'Estimated volume of the network and passive balancing tank',
          label: 'Estimated volume'
        },
        gridmeantemperature: 'Grid mean temperature',
        gridmeantempgraph: {
          helptext:
            'The grid mean temperature is the setpoint for the ectogrid. The warm pipe temperature is the mean temperature plus the ½ of delta T, while the cool pipe temperature is the mean temperature minus the ½ of delta T.',
          label: 'Grid mean temperature'
        },
        gridtemptablehelp:
          'Active balancing working hours: the amount of the lowest operating hours to supply the grid with the required energy. The optimization algorithm will give the optimal solution.',
        gridtmethod: {
          constant: 'Constant value',
          label: 'Grid temperature method',
          suggestion: 'Grid mean temperature suggestion',
          userupload: 'User upload profile'
        },
        gridweightedavgtempgraph: {
          helptext:
            'The weighted average temperature considers the volumes of warm and cool water in the pipes and tank, providing a more accurate indication of the stored energy level and cycles',
          label: 'Grid weighted average temperature'
        },
        groundtempoption: {
          const: 'Constant',
          label: 'Ground temperature method',
          variable: 'Calculation'
        },
        heatlosspipediameter: {
          helptext:
            'Mean inner diameter of all pipes of the thermal network. Since the mean pipe diameter only moderately affects heat losses and gains, it can be estimated. It is typically around 250 mm.',
          label: 'Mean pipe diameter'
        },
        initial_kpis_weighted_t_after_balancing: {
          label:
            'Grid weighted average temperature with user input mean temperature'
        },
        installationdepth: {
          helptext:
            'Depth at which the network is installed. The installation depth influences the ground temperature. Ground temperature profiles can only be calculated up to a depth of 2 m.',
          label: 'Installation depth'
        },
        max_grid_t: {
          helptext: 'Maximum allowed grid temperature',
          label: 'Max grid temperature'
        },
        mean_t_profile: {
          label: 'User input grid mean temperature'
        },
        min_grid_t: {
          helptext: 'Minimum allowed grid temperature',
          label: 'Min grid temperature'
        },
        netwinsulation: {
          helptext:
            "If significant heat gains and losses are expected, the calculation should be based on the pipe type 'Uninsulated plastic pipe'. Please note that the calculation of heat gains and losses could not be validated with real-world data yet and is therefore only an estimation.",
          insulated: 'Insulated pipes (no heat losses/gains)',
          label: 'Pipe insulation',
          uninsulated: 'Uninsulated plastic pipe'
        },
        networklength: {
          helptext:
            'Length of ditch assuming that both pipes are installed in a single ditch.',
          label: 'Network length'
        },
        netwtempcoldpipe: {
          helptext:
            'Network temperatures affect COP calculation if COPs are based on Carnot efficiencies as well as storage capacity of the passive balancing tank.',
          label: 'Temperature of cold pipe'
        },
        netwtempdiff: {
          helptext: 'Constant design delta T between the warm and cold pipe.',
          label: 'Temp. difference (warm/cold pipe)'
        },
        netwtempwarmpipe: {
          label: 'Temperature of warm pipe'
        },
        suggested_kpis_weighted_t_after_balancing: {
          label:
            'Grid weighted average temperature with suggested grid mean temperature'
        },
        suggested_mean_t_profile: {
          label: 'Suggested grid mean temperature'
        },
        suggestedcoldpipetemp: {
          label: 'Suggested cold pipe temperature'
        },
        suggestedgridmeantemp: {
          label: 'Suggested grid mean temperature​'
        },
        suggestedwarmpipetemp: {
          label: 'Suggested warm pipe temperature'
        },
        table: {
          activebalancingtankworkinghours: 'Active balancing working hours',
          gridandtankvolume: 'Grid and tank volume'
        },
        tempgroundconst: {
          helptext:
            'Ground temperature at installation depth of thermal network. The ground temperature influences heat losses or gains.',
          label: 'Ground temperature'
        },
        temptimeseries: {
          label: 'Temperature time series'
        },
        thconductivityground: {
          helptext:
            'The thermal conductivity of the soil depends on the local conditions. The German standard VDI 4640 indicates 1.2 W/mK for sand, 1.5 W/mK for clay and silt, and 1.8 W/mK for sandy clay.',
          label: 'Thermal conductivity ground'
        },
        title: 'Network parameters',
        use_grid_t_suggestion: {
          label: 'Use grid mean temperature suggestion'
        },
        userinputgridmeantemp: {
          label: 'User input grid mean temperature​'
        }
      },
      nobuildings: {
        label: 'No buildings added.'
      },
      notechs: {
        label: 'Please add energy center technologies to calculate.'
      },
      photovoltaic: {
        efficiency: {
          label: 'Efficiency'
        },
        enabled: {
          label: 'Photovoltaic'
        },
        etael: {
          label: 'Module efficiency'
        },
        headers: {
          area: 'Area (m²)',
          azimuth: 'Azimuth (°)',
          elevation: 'Elevation (°)'
        },
        maxarea: {
          label: 'Maximum area'
        },
        minarea: {
          label: 'Minimum area'
        },
        pvprofile: {
          label: 'Profile series'
        },
        pvprofileuploaded: {
          label: 'Upload profile series'
        }
      },
      referencesystem: {
        ashp_cop_dhw: 'COP of air source heat pump domestic hot tap water',
        ashp_cop_space_heat: 'COP of air source heat pump',
        boiler_eff: 'Thermal efficiency of boiler in buildings',
        central_gas_boiler_eta_th:
          'Thermal efficiency of central gas boiler in heat network scenario',
        central_gas_boiler_inv:
          'Investment for central gas boiler in heat network scenario',
        central_gas_boiler_om:
          'Maintenance for central gas boiler in heat network scenario',
        chiller_cop: 'COP of chillers in buildings',
        gshp_cop_dhw: 'COP of ground source heat pump domestic hot tap water',
        gshp_cop_space_heat: 'COP of ground source heat pump',
        inv_ashp: 'Investment for air source heat pump in buildings',
        inv_chiller: 'Investment for chiller in buildings',
        inv_gas_boiler: 'Investment for gas boilers in buildings',
        inv_gshp: 'Investment for ground source heat pump in buildings',
        netw_losses: 'Network losses of conventional 4GDH heat network',
        om_costs:
          'Share of maintenance costs (for all buildings devices the same)',
        title: 'Reference system',
        tooltips: {
          ashp_cop_dhw:
            'COP of air-source heat pumps for covering domestic hot water demands',
          ashp_cop_space_heat:
            'COP of air-source heat pumps for covering space heating demands',
          boiler_eff:
            'Thermal efficiency of decentral gas boilers for covering space heating and domestic hot water demands',
          central_gas_boiler_eta_th:
            'Thermal efficiency of central gas boiler that supplies the network',
          central_gas_boiler_inv:
            'Investment cost for central gas boiler that supplies the network',
          central_gas_boiler_om:
            'Maintenance costs as share of the investments for the central gas boiler that supplies the heat network',
          chiller_cop:
            'OP for covering cooling demands in buildings (with air-source heat pump, ground-source heat pump or chillers - depending on the scenario).',
          gshp_cop_dhw:
            'COP of ground-source heat pumps for covering domestic hot water demands',
          gshp_cop_space_heat:
            'COP of ground-source heat pumps for covering space heating demands',
          inv_chiller: 'Investment cost for decentral chillers.',
          netw_losses:
            'Thermal losses as share of the supplied heat by the network',
          om_costs:
            'Maintenance costs as share of the investments for decentral heat pumps, chillers and boilers in the buildings.'
        }
      },
      revheatpumpbu: {
        consider_defrosting: {
          helptext:
            'If enabled, the Carnot efficiency is reduced by 30 % during times in which the heat pump switches between regular operation and defrost mode. It is assumed that the defrost mode occurs when the ambient air temperature is below 5 °C and the relative humidity is larger than 50 %.',
          label: 'Consider defrosting'
        },
        cop: {
          label: 'COP calculation'
        },
        cop_const_cool: {
          label: 'Constant COP value cool'
        },
        cop_const_heat: {
          label: 'Constant COP value heat'
        },
        cop_cool_timeseries: {
          label: 'Custom cool COP time series'
        },
        cop_heat_timeseries: {
          label: 'Custom heat COP time series'
        },
        maxCOPcool: {
          label: 'Maximum COP (cooling)'
        },
        maxCOPheat: {
          label: 'Maximum COP (heating)'
        }
      },
      saveandcalculate: 'Save and calculate',
      sensitivity: {
        title: 'CO₂ sensitivity analysis',
        value: 'Value (€/t)'
      },
      shared: {
        ASHPcarnoteff: {
          label: 'Carnot efficiency'
        },
        absorptionchiller: 'Absorption chiller',
        accumulatortank: 'Passive balancing tank',
        addproject: 'Add project',
        airsourceheatpump: 'Air-source heat pump',
        ambientcoldsource: 'Ambient cold source',
        ambientheatsource: 'Ambient heat source',
        aquiferstorage: 'Aquifer storage',
        battery: 'Battery',
        building: 'Building',
        buildingname: 'Building name',
        buildings: 'Buildings',
        capacity: 'Capacity',
        category: 'Category',
        changeratehelp:
          'Change rate with that the wholesale price decreases or increases each year into the future.',
        chiller: 'Chiller',
        chpunit: 'CHP unit',
        co2emissions: 'CO2 emissions',
        coldstorage: 'Cold storage',
        compressionchiller: 'Compression chiller',
        copconst: {
          label: 'Constant COP'
        },
        coptimeseries: {
          label: 'COP time series'
        },
        costom: {
          label: 'Cost operation & maintenance'
        },
        coverofpeakdemand: 'Cover of peak demand',
        dhw: 'Domestic hot tap water',
        directcooling: 'Direct cooling',
        districtcooling: 'District cooling',
        districtheating: 'District heating',
        electricboiler: 'Electric boiler',
        electricheatingrod: 'Electric heating rod',
        electricity: 'Electricity',
        electricityfeedin: 'Electricity feed-in',
        electricityfromgrid: 'Electricity from grid',
        electricityimport: 'Electricity import',
        enablecopupload: {
          label: 'Upload COP profile'
        },
        enablesupplylimit: {
          label: 'Consider annual supply limit'
        },
        endenergy: 'Primary energy source',
        equipmenttype: 'Equipment type',
        error: 'Error',
        etath: {
          helptext:
            'Usually the sum of electric efficiency and thermal efficiency is around 90 %.',
          label: 'Thermal efficiency'
        },
        feedin: 'Feed-in',
        feedintogrid: 'Feed-in to grid',
        fullloadhours: 'Full load hours',
        gasboiler: 'Gas boiler',
        generation: 'Generation',
        groundsourceheatpump: 'Ground-source heat pump',
        heatingcopconstant: 'Heating COP (constant)',
        heatpump: 'Heat pump',
        heatpumpheatingonly: 'Heat pump (heating only)',
        heatstorage: 'Heat storage',
        initialgridsurcharge: 'Initial grid surcharge',
        initialwholesalemarketprice: 'Initial wholesale market price',
        invvar: {
          helptext: 'Specific investment costs for the technology.',
          label: 'Investment'
        },
        lifetime: {
          helptext: 'Expected lifetime of the technology.',
          label: 'Lifetime'
        },
        maxcap: {
          label: 'Maximum capacity'
        },
        maximumcop: 'Maximum COP',
        mincap: {
          label: 'Minimum capacity'
        },
        naturalgas: 'Natural gas',
        naturalgasbalancingunit: 'Natural gas (energy center)',
        naturalgasbuildings: 'Natural gas (buildings)',
        nodatafound: 'No data found',
        peakloadchiller: 'Peak-load chiller',
        pipeinstallation: 'Pipe installation',
        potentialtimeseries: {
          label: 'Potential time series'
        },
        price: 'Price',
        spacecooling: 'Space cooling',
        spaceheating: 'Space heating',
        storageloss: 'Storage loss',
        supplylimit: {
          label: 'Annual supply limit'
        },
        technology: 'Technology',
        thermalpoweravailableovertheyear:
          'Thermal power available over the year.',
        transversalhp: 'Transversal Heat Pump',
        usecarnotefficiency: 'Use Carnot efficiency',
        useconstantcop: 'Use constant COP',
        wasteheatsource: 'Waste heat',
        yearlygridsurchargegrowthrate: 'Yearly grid surcharge growth rate',
        yearlywholesalepricechangerate: 'Yearly wholesale price change rate'
      },
      simpleheatpumpbu: {
        copisconst: {
          label: 'Ground source heat pump'
        },
        isASHP: {
          label: 'Air source heat pump'
        }
      },
      solarthermal: {
        coll_temp: {
          label: 'Collector temperature'
        },
        enabled: {
          label: 'Solar thermal'
        }
      },
      wastecooling: {
        availablepower: {
          label: 'Available cooling power'
        },
        copchiller: {
          label: 'COP chiller'
        },
        enablechiller: {
          label: 'Install additional chiller'
        },
        enablepotentialupload: {
          label: 'Upload cooling potential (kW)'
        }
      },
      wasteheat: {
        availablepower: {
          label: 'Available heating power'
        },
        cophp: {
          label: 'COP heat pump'
        },
        enable_temp_upload: {
          label: 'Upload temp. time series'
        },
        enablehp: {
          label: 'Install booster heat pump'
        },
        enablepotentialupload: {
          label: 'Upload heat potential (kW)'
        },
        temp_time_series: {
          label: 'Temp. time series'
        }
      }
    },
    graphs: {
      aggregation: 'Aggregation',
      aggregations: {
        average: 'Average',
        sum: 'Sum'
      },
      allsignals: 'All signals',
      clear: 'Clear',
      collection: 'Collection:',
      editgraph: 'Edit graph',
      editsignalsettings: 'Edit signal settings',
      graphtype: 'Graph type',
      graphtypes: {
        column: 'Bar graph',
        durationcurve: 'Duration curve',
        line: 'Line graph',
        scatter: 'Scatter chart'
      },
      import: 'Import data...',
      newgraph: 'New graph',
      nosignals: 'No signals selected',
      nosignalsselected:
        'No signals selected. Edit the graph to select signals',
      notimeseries: 'No time series available for this version.',
      referenceyear: 'Reference year',
      scatterxaxisseriesid: 'Scatter chart X axis signal',
      selectedsignals: 'Selected signals',
      selectgraph: 'Select time series to import',
      title: 'Graphs',
      unsavedsuffix: '(unsaved)',
      zoomlevels: {
        day: 'Day',
        month: 'Month',
        week: 'Week',
        year: 'Year'
      },
      zoomout: 'Zoom out'
    },
    invalidform: {
      label:
        'There are errors in your form. Before you correct them, no visualizations are updated and calculations cannot run. To see a summary of the errors, you can click on Calculate.'
    },
    location: {
      city: 'City',
      country: 'Country',
      title: 'Location'
    },
    newbuildname: 'New version',
    newprojectname: 'New project',
    newtype: 'Type',
    newtypes: {
      blankproject: 'Blank project',
      copyproject: 'Copy data to new project',
      version: 'Add new version'
    },
    noprojectshelp:
      'There are no projects available. Press "Add..." to create a new project, or ask a colleague to share a project.',
    pipes: {
      button: 'Show pipe dimensioning...',
      form: {
        buildings: 'Buildings',
        pipediameters: 'Pipe diameters',
        piperoughness: 'Pipe roughness',
        tempdiff: 'Temperature difference'
      },
      pipeformat: 'DN{0}',
      recommendeddiameterformat: 'Recommended diameter: DN{0}',
      results: 'Pipe dimensioning results',
      suggestpipediameters: 'Suggest pipe diameters',
      summarymaxmassflowrate: 'Max. mass flow rate',
      summarymaxvolumeflowrate: 'Max. volume flow rate',
      title: 'Pipe dimensioning'
    },
    profileeditor: {
      action: 'Set',
      annualsum: 'Annual sum',
      invalidformaterror:
        'Comma and dot cannot be used at the same time in the same series. Use space or nothing as thousand separator.',
      invalidnumlineserror: 'Invalid number of lines: {0}',
      invalidtimeseriesformat: 'Time series format invalid{0}',
      manualcurve: 'Manual curve',
      notimeseriesnotice: 'Please configure a time series to continue',
      range: 'Range',
      select: {
        file: {
          button: 'Select file...',
          title: 'Upload from file'
        },
        timeseriesdata: 'Time series data',
        unit: 'Unit'
      },
      timeseries: 'Time series',
      types: {
        consumptionm3: 'Consumption (m³)',
        cop: 'COP',
        emissions: 'Emissions (gCO2/kWh)',
        price: 'Price (€/kWh)',
        pricemwh: 'Price (€/MWh)',
        temperature: 'Temperature (°C)'
      },
      typetitles: {
        consumption: 'Consumption profile',
        cop: 'COP profile',
        emissions: 'Emission profile',
        energy: 'Energy profile',
        price: 'Price profile',
        temperature: 'Temperature profile'
      },
      validatetimeseries: 'Validate time series'
    },
    project: {
      edittitle: 'Edit project',
      name: 'Name',
      newprojectname: 'New project',
      type: 'Project type',
      types: {
        ectoplanner: 'Ectoplanner',
        secosim: 'Secosim (beta)'
      }
    },
    projectstitle: 'Ectoplanner projects',
    resultgraphs: {
      balancingunitload4mwh: 'Energy center load ④ (MWh)',
      coolingdemand: 'Cooling demand',
      coolingdemandmwh: 'Cooling demand (MWh)',
      electricityimportmwh: 'Electricity import (MWh)',
      energydemandmwh: 'Energy demand (MWh)',
      energyimportmwh: 'Energy import (MWh)',
      equipmentinbalancingunit: 'Equipment in energy center',
      equipmentinbuildings: 'Equipment in buildings',
      heatingdemand: 'Heating demand',
      heatingdemandmwh: 'Heating demand (MWh)',
      operationcostseura: 'Operation costs (EUR/a)',
      operationmaintenancecosts: 'Operation maintenance costs',
      pvpower: 'PV power',
      tapwaterdemand: 'Domestic hot water demand'
    },
    results: {
      balancingunit: {
        coolinggeneration: 'Cooling generation',
        heatingandcooling: 'Heating and cooling',
        heatinggeneration: 'Heating generation',
        renewablesandstorage: 'Renewables and storage'
      },
      balancingunitload: 'Energy center load',
      balancingunitsecondary: {
        area: 'Area'
      },
      buildingdemandsinput: {
        annualdemandmwha: 'Annual demand (MWh/a)'
      },
      buildingenergysystems: 'Building energy systems',
      buildings: {
        titles: {
          buildingequipment: 'Building equipment',
          capexbuildings: 'CAPEX buildings',
          cumulated: 'Cumulated installations',
          energydemands: 'Energy demands'
        }
      },
      buildingsinputdata: 'Buildings (input data)',
      burningnaturalgas: 'Burning natural gas',
      capitalrecoveryfactor: 'Capital recovery factor',
      cooling: 'Cooling',
      costs: {
        annualcostseura: 'Annual costs (EUR/a)',
        investmentseur: 'Investments (EUR)'
      },
      cumulatedinstallations: {
        meancop: 'Mean COP',
        powergasdemand: 'Power / Gas demand'
      },
      demandbalancing: {
        balanceddemands: 'Balanced demands',
        balanceddemandsmwha: 'Balanced demands (MWh/a)',
        colddemandmwha: 'Cold demand (MWh/a)',
        heatdemandmwha: 'Heat demand (MWh/a)',
        overview: 'Overview'
      },
      district_cooling: 'District cooling',
      district_heating: 'District heating',
      download: 'Excel file',
      ecologickpis: {
        primary_energy_demand: 'Primary energy demand',
        title: 'Environmental KPIs'
      },
      endenergydemand: {
        annualenergymwha: 'Annual energy (MWh/a)',
        energysources: 'Energy sources',
        peakpowerkw: 'Peak power (kW)'
      },
      energydemands: {
        balanceddemandsinbuilding: 'Balanced demands in building',
        coolingdemandmwha: 'Cooling demand (MWh/a)',
        electricitydemandmwha: 'Electricity demand (MWh/a)',
        gasdemandmwha: 'Gas demand (MWh/a)',
        meanheatpumpcop: 'Mean heat pump COP',
        spaceheatingdemandmwha: 'Space heating demand (MWh/a)',
        tapwaterdemandmwha: 'Domestic hot water demand (MWh/a)'
      },
      failedtoload: 'Failed to load results',
      genericresulterror:
        'Results no longer valid. Please check for errors and recalculate.',
      investmentbalancingunit: 'Investment energy center',
      investmentbuildings: 'Investment buildings',
      locationerror: 'Unknown error occured when fetching location.',
      locationnotfound:
        'Location used no longer valid. Please select a new location and re-run the calculation.',
      netbuildingdemands: 'Net building demands',
      operationandmaintenancecosts: 'Operation & maintenance costs',
      pagetitle: '{0} results',
      photovoltaics: 'Photovoltaics',
      print: 'Print',
      pvgeneration: 'PV generation',
      sections: {
        balancingunit: 'Energy center',
        buildingdemands: 'Building demands & emissions',
        costs: 'Costs',
        demandbalancing: 'Demand balancing'
      },
      solarthermal: 'Solar thermal collectors',
      storage: {
        chargingcycles: 'Charging cycles',
        volume: 'Volume'
      },
      ta: 't/a',
      tapwater: 'Domestic Hot Water',
      technologies: {
        feedinkwha: 'Feed-in (kWh/a)',
        selfconsumptionkwha: 'Self-consumption (kWh/a)'
      },
      title: 'Results',
      totalemissions: 'Total emissions',
      waste_cooling: 'Waste cooling',
      waste_heat: 'Waste heat'
    },
    saveerror: 'Failed to save form',
    secosim: {
      columns: {
        description: 'Description',
        kpi: 'KPI',
        total: 'Total'
      },
      kpis: {
        cumulative_co2_emission: 'Cumulative CO2 emission',
        cumulative_energy_savings:
          'Cumulative energy savings due to self-consumption',
        cumulative_eur_savings:
          'Cumulative Eur savings due to self-consumption',
        cumulative_opex: 'Cumulative OPEX',
        descriptions: {
          cumulative_co2_emission: 'The tonns of CO2 emissions',
          cumulative_energy_savings:
            'The energy that we are saving by self consuming PV generated energy, this amount would have been otherwise purchased from the grid',
          cumulative_eur_savings:
            'The money we are saving by self consuming PV generated energy, this money would have been otherwise used to purchase energy from the grid',
          cumulative_opex: 'The energy expenditure',
          feed_in_energy: 'The energy that we have sold to the grid',
          feed_in_revenues: 'The money that we made by selling to the grid',
          peak_power_absorption_grid:
            'How close did we get during the peak demand to the contracted power capacity, E.g. 80% = the peak power was 80% of the contracted limit',
          pv_generated_going_to_grid_export:
            'The energy generated from PV that was used to grid export demand',
          pv_self_consumption:
            'The % of energy from PV that we are self consuming (the remaining goes to grid export)',
          pv_to_heating_and_cooling_demand:
            'The % of energy from PV that powers the heating and cooling demand',
          savings_building_demand:
            'The % of energy powering the building that is coming from PV self consumption',
          savings_co2_vs_historical:
            'compared to benchmark, E.g. &gt;0 = savings = lower CO2 emissions',
          savings_cooling_demand:
            'The % of energy powering the cooling that is coming from PV self consumption',
          savings_dhw_demand:
            'The % of energy powering the DHW that is coming from PV self consumption',
          savings_heating_demand:
            'The % of energy powering the heating that is coming from PV self consumption',
          savings_opex:
            'compared to benchmark historical data, E.g. &gt;0 = savings = lower OPEX'
        },
        energyflow: 'Energy flow',
        feed_in_energy: 'Feed in energy',
        feed_in_revenues: 'Feed in revenues',
        graphs: {
          sankey: {
            energyflowformat: '{0} to {1}'
          },
          series: {
            airsourceheatpumps: 'Air source heat pumps',
            batterycharge: 'Battery Charge',
            batterydischarge: 'Battery Discharge',
            buffertanktemperature: 'Buffer tank temperature',
            co2historical: 'CO2 historical',
            co2savings: 'CO2 savings',
            co2withsectorcoupling: 'CO2 with sector coupling',
            cooling: 'Cooling',
            coolingpumps: 'Cooling pumps',
            dailypeakpower: 'Daily peak power',
            dhw: 'DHW',
            dhwairheatpump: 'DHW air heat pump',
            dhweboiler: 'DHW e-boiler',
            dhwwellsystem: 'DHW well system',
            eboilerheating: 'E-boiler heating',
            electricityimportcost: 'Electricity import cost',
            energycosthistorical: 'Energy cost historical',
            energycostwithsectorcoupling: 'Energy cost with sector coupling',
            feedin: 'Feed in',
            feedinenergy: 'Feed in energy',
            feedinrevenues: 'Feed in revenue',
            generalbuilding: 'General building',
            heating: 'Heating',
            heatingdemand: 'Heating demand',
            heatingwellnominalpower: 'Heating well nominal power',
            heatingwellsystemgeneration: 'Heating well system generation',
            maxweeklypowerabsorptionashp:
              'Max weekly power absorption air source heat pumps',
            opexsavings: 'OPEX savings',
            outdoortemperature: 'Outdoor temperature',
            powerlimit: 'Power limit',
            pvcontribution: 'PV contribution',
            pvgeneration: 'PV generation',
            pvnominalpeakpower: 'PV nominal peak power',
            totalheatpumpgeneration: 'Total heat pump generation',
            watersourceheatpumps: 'Water source heat pumps',
            weeklyoutdooravgtemp: 'Weekly outdoor average temperature'
          },
          titles: {
            atesconservation: 'ATES conservation',
            batterychargeanddischarge: 'Battery charge and discharge',
            energyusage: 'Energy usage',
            energyusagebyasset: 'Energy usage by asset',
            feedinenergyandrevenue: 'Feed in energy and revenue',
            heatingoff: 'Heating off - T down',
            heatpumpoperation: 'Heat pump operation',
            impactofsectorcouplingonco2: 'Impact of sector coupling on CO2',
            impactofsectorcouplingoncost: 'Impact of sector coupling on cost',
            opexandco2savings: 'OPEX and CO2 savings',
            outdoortemperaturevsenergyprices:
              'Outdoor temperature vs energy prices',
            powerpeakfromthegrid: 'Power peak from the grid',
            pvcontributiontotheenergyneeds:
              'PV contribution to the energy needs',
            pvgeneration: 'PV generation',
            savingspersector: 'Savings per sector',
            thermaldemandsplit: 'Thermal demand split'
          }
        },
        peak_power_absorption_grid: 'Peak power absorption from grid',
        pv_generated_going_to_grid_export: 'PV generated going to Grid export',
        pv_self_consumption: 'PV self consumption',
        pv_to_heating_and_cooling_demand: 'PV to heating and cooling demand',
        savings_building_demand:
          'Savings in building demand due to self-consumption',
        savings_co2_vs_historical: 'Savings CO2 vs Historical Data- 2022',
        savings_cooling_demand:
          'Savings in cooling demand due to self-consumption',
        savings_dhw_demand: 'Savings in DHW demand due to self-consumption',
        savings_heating_demand:
          'Savings in heating demand due to self-consumption',
        savings_opex: 'Savings OPEX vs Historical Data- 2022'
      },
      models: {
        air_hp: 'Heating from ambient air',
        ates: {
          title: 'ATES balancing'
        },
        battery: 'Battery',
        battery_capacity: {
          title: 'Battery capacity'
        },
        battery_efficiency: {
          title: 'Round trip efficiency'
        },
        battery_power: {
          title: 'Rated power'
        },
        co2_data: {
          title: 'CO2 data'
        },
        cooling_capacity: {
          title: 'Cooling capacity'
        },
        cooling_direct: 'Cooling',
        demand_cooling: {
          title: 'Building cooling demand'
        },
        demand_dhw: {
          title: 'Building DHW demand'
        },
        demand_heating: {
          title: 'Building heating demand'
        },
        demand_temperature: {
          title: 'Building min. heating temperature'
        },
        dhw_air_hp: 'DHW from ambient air',
        dhw_buffer: 'DHW buffer',
        dhw_buffering: 'Enable DHW buffering',
        dhw_capacity: {
          title: 'DHW capacity'
        },
        dhw_hp: 'DHW from ATES',
        e_boiler: 'Electrical boilers',
        el_demand: {
          title: 'Building eLoad historical'
        },
        el_export_price: {
          title: 'Electricity export cost'
        },
        el_import_price: {
          title: 'Electricity import cost'
        },
        feed_in_capacity: {
          title: 'Feed in capacity'
        },
        gas_boiler: 'Gas boilers',
        gas_prices: {
          title: 'Gas prices'
        },
        goal: {
          co2: 'CO2',
          cost: 'Cost',
          reference: 'Reference',
          title: 'Goal'
        },
        grid_capacity: {
          title: 'Grid connection capacity'
        },
        grid_connection: 'Grid connection',
        heat_buffer: 'Heat buffer',
        heat_buffering: 'Enable heat buffering',
        heat_hp: 'Heating from ATES',
        heat_to_ates_capacity: {
          title: 'Heat to ATES capacity'
        },
        heating_buffer: {
          title: 'Enable heating buffer'
        },
        heating_capacity: {
          title: 'Heating capacity'
        },
        hist_gas_h_consumption_m3: {
          title: 'Historical gas consumption'
        },
        hp_capacity: {
          title: 'Capacity'
        },
        hp_eta: {
          title: 'Nominal heating eta'
        },
        hp_max_temp: {
          title: 'Max temp'
        },
        hp_pump_cop: {
          title: 'COP'
        },
        indoors_cooling_buffer: {
          title: 'Cooling buffer'
        },
        indoors_dhw_buffer: {
          title: 'DHW Buffer'
        },
        loss_buffer: {
          title: 'Loss'
        },
        max_temp_buffer: {
          title: 'Max temp'
        },
        min_temp_buffer: {
          title: 'Min temp'
        },
        pv_capacity: {
          title: 'PV capacity'
        },
        pv_gen: {
          title: 'PV power output'
        },
        scenario_definition: 'Scenario definition',
        timeseries: 'Time series',
        volume_buffer: {
          title: 'Volume'
        }
      },
      releasenote:
        'This is a preview release with limited functionality. Expect large changes to the user interface and functionality.',
      sections: {
        co2: 'CO2',
        feedin: 'Feed in',
        opex: 'OPEX',
        peakpower: 'Peak power',
        pv: 'PV',
        savings: 'Savings'
      },
      tabs: {
        graphs: 'Graphs',
        input: 'Input',
        kpigraphs: 'KPI Graphs',
        result: 'Result',
        table: 'Table'
      },
      title: 'Secosim'
    },
    sections: {
      balancingunit: 'Active Balance',
      bms: 'Energy',
      calculations: 'Calculations',
      energystorages: 'Storages',
      grid: 'Grid',
      modelparams: 'Business model',
      othersources: 'Other'
    },
    share: {
      adduser: 'Add user',
      displayname: 'User',
      failedtoaddusers: 'Failed to add user. Is the email valid?',
      failedtodeleteproject:
        'Failed to delete project. Are there more than one user of this project?',
      failedtoremoveuser:
        'Failed to delete user. Is this the owner of the project?',
      mail: 'E-mail',
      placeholder: 'User e-mail',
      title: 'Share {0}'
    },
    technologies: 'Technologies',
    title: 'Ectoplanner',
    unauthorized: 'Unauthorized. You do not have access to this page.',
    unitdescriptions: {
      maxflowvel: 'Max. flow velocity (m/s)',
      maxpam: 'Max. pressure gradient (Pa/m)',
      minflowvel: 'Min. flow velocity (m/s)',
      pam: 'Pressure gradient (Pa/m)',
      specannpumpwork: 'Spec. annual pump work (kWh/m/a)'
    },
    units: {
      btuh: 'Btuh',
      degc: '°C',
      eur: 'EUR',
      eura: 'EUR/a',
      eurkw: '€/kW',
      eurkwel: (
        <span>
          €/kW<sub>el</sub>
        </span>
      ),
      eurkwh: '€/kWh',
      eurkwp: '€/kWp',
      eurkwth: (
        <span>
          €/kW<sub>th</sub>
        </span>
      ),
      eurm: '€/m',
      eurm3: '€/m³',
      eurmwh: '€/MWh',
      gco2kwh: 'gCO2/kWh',
      gkwh: 'g/kWh',
      ha: 'h/a',
      hyear: 'h/year',
      k: 'K',
      kgCO2eq: 'kg CO₂eq',
      kgkwh: 'kg/kWh',
      kgs: 'kg/s',
      km: 'km',
      kmday: 'km/day',
      kw: 'kW',
      kwel: (
        <span>
          kW<sub>el</sub>
        </span>
      ),
      kwh: 'kWh',
      kwh100km: 'kWh/100 km',
      kwha: 'kWh/a',
      kwhel: 'kWh_el',
      kwhkwh: 'kWh/kWh',
      kwhm2: 'kWh/m²',
      kwhm2year: 'kWh/m²/year',
      kwp: 'kWp',
      kwth: (
        <span>
          kW<sub>th</sub>
        </span>
      ),
      ls: 'l/s',
      m: 'm',
      m2: 'm²',
      m3: 'm³',
      m3h: 'm³/h',
      mm: 'mm',
      mw: 'MW',
      mwel: (
        <span>
          MW<sub>el</sub>
        </span>
      ),
      mwh: 'MWh',
      mwha: 'MWh/a',
      mwhel: (
        <span>
          MWh<sub>el</sub>
        </span>
      ),
      mwhth: (
        <span>
          MWh<sub>th</sub>
        </span>
      ),
      mwhyear: 'MWh/year',
      mwth: (
        <span>
          MW<sub>th</sub>
        </span>
      ),
      pam: 'Pa/m',
      percent: '%',
      percenta: '%/a',
      percentdoc: '% (DOC)',
      percentofheatdemand: '% of heat demand',
      percentofinvest: '% of Invest',
      percentperhour: '% per hour',
      th: 't/h',
      tonnco2: 'tonnCO2',
      w: 'W',
      wm2: 'W/m²',
      wmk: 'W/(m K)',
      years: 'years'
    },
    validationerror: {
      maxformat: 'Value must be less than or equal to {0}',
      minformat: 'Value must be greater than or equal to {0}',
      notset: 'Value must be set'
    }
  },
  editsignalvalue: {
    dialogtitle: 'State reason for value change'
  },
  equipment: {
    addalarm: {
      addedalarm: 'Added alarm',
      nodeformat: 'Alarm will be added to {0}.',
      nodeformatwarning:
        'Alarm will be added to {0}. You have selected multiple signals, but the alarm will be added to the location. To add alarms for individual signals, select them one by one.',
      placeholder: 'Alarm comment',
      title: 'Add alarm'
    },
    addedsignalformat: 'Added {0} signal.',
    analyzeandexport: 'Analyze & export...',
    category: 'Category',
    currentvalue: 'Current value',
    deletesignalvalues: 'Delete signal values...',
    description: 'Description',
    displayname: 'Signal name',
    equipmenttype: 'Equipment type',
    errorfetchingprocessmap: 'An error occured when fetching the process map.',
    lastupdate: 'Last update',
    mappedsignals: 'Mapped signals',
    newvalue: 'New value',
    nodescription: 'No description',
    nosignalselected: 'No signal selected',
    novalue: '-',
    novalueset: 'No signal value set',
    removedsignalformat: 'Removed {0} signal.',
    searchbar: {
      clear: 'Clear all selected signals',
      datefrom: 'Time:',
      dateplaceholder: 'Now',
      export: 'Export...',
      searchplaceholder: 'Search signals...',
      selectedsignalsformat: 'Selected signals ({0})',
      showingraph: 'Show in graph...',
      showlogs: 'Show logs...'
    },
    setvalue: 'Update',
    setvaluefailure: 'Failed to update signal value',
    setvaluefailurenoconnection:
      'Failed to update signal value. Energy Manager could not be reached.',
    setvaluefailurenodevice:
      'Failed to update signal value. Signal is not connected to any Energy Manager.',
    setvalueformat: 'Set value for {0}',
    setvaluesuccess: 'Updated signal value',
    signaltype: 'Signal type',
    source: 'Source',
    systemname: 'Function',
    tooltipformat: 'Time: {0} | Step: {1}',
    unit: 'Unit',
    unmappedsignals: 'Other signals',
    updatethreshold: 'Update threshold',
    value: 'Value'
  },
  equipments: {
    equipments: 'equipments',
    locationswithdata: 'Here are some locations with equipment:',
    nodata: 'There are no equipments in this {0}.'
  },
  exportdownload: {
    error: 'Failed to download export of signals'
  },
  exportoptions: {
    daterange: 'Date'
  },
  exportpage: {
    confirmexport:
      'Do you want to export this data? You will receive an email once the data has been processed.',
    exportdata: 'Export data',
    exportdatafailed: 'Export data request failed.',
    exportdatasuccess: 'Export data request successful.',
    loadfromcollection: 'Load signals from graph...',
    title: 'Export data'
  },
  featureflag: {
    ectotableadmin: 'Ectotable admin',
    integrations: 'Integrations',
    newalarms: 'New alarm view'
  },
  geocodinginput: {
    error: {
      resolve: 'Failed to load coordinates.'
    },
    placeholder: 'Search for location...'
  },
  graphs: {
    aggregationformat: 'Aggregation: {0}',
    analytics: {
      help: 'Note that some data points might be optimized away if the chart contains a large amount of data.',
      title: 'Data analytics',
      tooltip:
        'Data analytics. Click to enable and then select an area of the graph to see statistics.'
    },
    autoupdate: 'Auto updates',
    autoupdateformat: '{0} (auto)',
    confirmdelete: {
      message: 'Are you sure you want to delete {0}?',
      title: 'Confirm delete'
    },
    confirmsave: {
      message:
        'There are multiple unsaved graphs. Do you wish to save all of them or just this one?',
      saveall: 'Save all',
      savecurrent: 'Save current',
      title: 'Save all graphs?'
    },
    custominterval: 'Custom interval',
    deletebutton: 'Delete graph',
    deletegraph: 'Delete graph',
    duplicatebutton: 'Duplicate graph',
    duplicatesuffix: 'copy',
    editmodal: {
      addtitle: 'Add new graph',
      errornoscatterxaxis:
        'If using a scatter chart type you must specify an x-axis signal.',
      settings: {
        numpointslabel: 'Number of points',
        numpointsplaceholder: 'Based on screen resolution',
        numpointsplaceholderwithsampling: 'Based on sampling interval',
        seriesinterval: 'Zoom level',
        typelabel: 'Graph type',
        xaxislabel: 'Scatter x-axis signal',
        xaxisplaceholder: 'Select x-axis signal'
      },
      title: 'Edit graph'
    },
    exportdialog: {
      aggregation: {
        count: 'Count',
        first: 'First',
        last: 'Last',
        max: 'Max',
        mean: 'Mean',
        median: 'Median',
        min: 'Min',
        mode: 'Mode',
        none: 'None',
        sum: 'Sum'
      },
      aggregationtitle: 'Aggregation',
      confirm: 'Export',
      export: {
        success: 'Successfully exported data.'
      },
      exportcvs: {
        failure: 'Unable to export data. Please try again later.'
      },
      exportformat: 'Format',
      failedtosavefile: 'Failed to save file',
      filename: 'Filename',
      imageheight: 'Image height',
      imagewidth: 'Image width',
      sampling: {
        day: 'Day',
        fifteenminutes: '15 minutes',
        fiveminutes: '5 minutes',
        hour: 'Hour',
        minute: 'Minute',
        month: 'Month',
        raw: 'Raw',
        week: 'Week'
      },
      samplinginterval: 'Sampling interval',
      title: 'Export graph'
    },
    failedtoload: 'Failed to load graphs.',
    failedtoloadsignals: 'Failed to load signals',
    failedtosave: 'Failed to save graphs.',
    minmax: {
      table: {
        max: 'Maximum value',
        min: 'Minimum value',
        placeholder: 'Automatic',
        unit: 'Unit'
      },
      title: 'Minimum and maximum values for axis'
    },
    newgraphname: 'New graph',
    nosignalsfound: 'No signals found matching the current settings',
    options: 'Graph options',
    panel: {
      unsavedchanges:
        'You have edited the graph you selected from a Dashboard. Do you wish to save it?'
    },
    pointsoverflow:
      'The data set for the chart is too large to draw interactively. Please review the settings to make sure you have proper aggregation settings.',
    select: {
      graph: {
        type: 'Select graph type'
      },
      xaxis: {
        signal: 'Select X-axis signal'
      }
    },
    settingplaceholder: 'From graph settings',
    signalcollection: 'Graph:',
    signalselector: {
      name: {
        placeholder: 'Name'
      }
    },
    signalselectorbutton: 'Change signals...',
    signalselectormodal: {
      title: 'Signal Picker'
    },
    tempsuffix: '(temporary)',
    timerange: {
      option: {
        all: 'All',
        custom: 'Optionally',
        day: 'Day',
        fiveyears: 'Five Years',
        hour: 'Hour',
        month: 'Month',
        week: 'Week',
        year: 'Year'
      },
      title: 'Time range',
      titlewithfilterdescription:
        'Time range (The signal will only be visible in this range)'
    },
    type: {
      line: 'Line chart',
      scatter: 'Scatter chart'
    },
    unsavedsuffix: '(unsaved)',
    zoom: 'Zoom:'
  },
  grids: {
    cooling: 'Cooling',
    ectogrid: 'ectogrid™',
    electricity: 'Electricity',
    generic: 'Generic',
    heating: 'Heating'
  },
  help: {
    missingfilemessage: 'Cant find the right help file.'
  },
  helpbutton: {
    error: 'Unable to fetch help text, please try again later.'
  },
  helptooltip: {
    gotohelp: 'Go to help'
  },
  highcharts: {
    contextbuttontitle: 'Chart context menu',
    decimalpoint: '.',
    downloadcsv: 'Download CSV',
    downloadjpeg: 'Download JPEG',
    downloadmidi: 'Download MIDI',
    downloadpdf: 'Download PDF',
    downloadpng: 'Download PNG',
    downloadsvg: 'Download SVG',
    downloadxls: 'Download XLS',
    error: 'An error occurred while fetching data, please try again later',
    exitfullscreen: 'Exit from full screen',
    exporting: 'Exporting...',
    loading: 'Loading data...',
    months: {
      april: 'April',
      august: 'August',
      december: 'December',
      february: 'February',
      january: 'January',
      july: 'July',
      june: 'June',
      march: 'March',
      may: 'May',
      november: 'November',
      october: 'October',
      september: 'September'
    },
    nodata: 'No data available.',
    printchart: 'Print chart',
    shortmonths: {
      april: 'Apr',
      august: 'Aug',
      december: 'Dec',
      february: 'Feb',
      january: 'Jan',
      july: 'Jul',
      june: 'Jun',
      march: 'Mar',
      may: 'May',
      november: 'Nov',
      october: 'Oct',
      september: 'Sep'
    },
    shortweekdays: {
      friday: 'Fri',
      monday: 'Mon',
      saturday: 'Sat',
      sunday: 'Sun',
      thursday: 'Thu',
      tuesday: 'Tue',
      wednesday: 'Wed'
    },
    viewfullscreen: 'View in full screen',
    weekdays: {
      friday: 'Friday',
      monday: 'Monday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      thursday: 'Thursday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday'
    }
  },
  jobs: {
    columns: {
      dataend: 'Data end',
      datastart: 'Data start',
      date: 'Date',
      expires: 'Expires',
      importance: 'Importance',
      lasttrained: 'Last trained',
      model: 'Model',
      name: 'Name',
      r2: 'R2',
      rmse: 'RMSE',
      scoring: 'Scoring'
    },
    compare: 'Compare',
    details: 'Details',
    earlierversions: 'Earlier versions',
    featureimportance: 'Feature importance',
    outputsignals: 'Output signals',
    predictedformat: '{0} Predicted',
    scoringhistory: 'Scoring history',
    titleformat: '{0} - {1} - Scoring at {2}'
  },
  language: {
    de: 'German',
    en: 'English',
    sv: 'Svenska'
  },
  loadingcontainer: {
    longerthanexpected: 'This is taking longer than normal, please wait...'
  },
  location: {
    button: {
      titleformat: 'Location: {0}'
    },
    error: {
      chooseotherlocation: 'Choose location',
      find: 'Unable to find location',
      nolocations:
        'There are no locations currently present in your ectocloud tenant'
    },
    page: {
      buildings: 'Buildings',
      controlpanel: 'Control panel',
      copybuttonitem: 'location name',
      help: 'Help',
      helptitle: 'User help',
      locations: 'Locations',
      provisioning: 'Energy Managers'
    },
    tabs: {
      alarms: 'Alarms',
      dashboard: 'Dashboard',
      equipment: 'Equipment',
      exportpage: 'Export data',
      graphs: 'Graphs',
      jobs: 'Data jobs',
      logs: 'Logs',
      powercontrol: 'Power Control',
      processmaps: 'Process Maps',
      remoteopt: 'Remote Optimization',
      signalproviders: 'Signals',
      tools: 'Tools',
      userdashboards: 'Personal dashboards'
    }
  },
  locationheader: {
    facilityid: 'Facility ID:',
    status: 'Status:',
    street: 'Address:',
    tags: 'Tags:'
  },
  locationtree: {
    advanced: 'Advanced...'
  },
  login: {
    action: {
      login: 'Log In',
      signup: 'Sign Up'
    },
    env: {
      development: 'Development',
      mock: 'Mock'
    },
    environment: 'Environment',
    password: {
      label: 'password',
      placeholder: 'Password'
    },
    toast: {
      error: {
        login: 'Unable to log in'
      },
      loggedout: 'You have been logged out',
      sessionexpired: 'Your session has expired, please log in again.'
    },
    username: {
      label: 'username',
      placeholder: 'Username'
    }
  },
  logtype: {
    alltypes: 'All log types',
    type: {
      signal: 'Setpoint change',
      system: 'System',
      user: 'Manual'
    }
  },
  logview: {
    addbutton: {
      title: 'Add Log Entry'
    },
    columns: {
      author: 'Author',
      location: 'Location',
      logtype: 'Log type',
      message: 'Message',
      valuechange: 'Details'
    },
    helpbutton: {
      title: 'Logs'
    },
    modal: {
      add: {
        action: {
          failure: 'Unable to save the log entry, please try again later.',
          title: 'Save'
        },
        info1:
          'Select a location for your log entry and use the free text field to add a comment',
        info2:
          'Note that this text will be available for everyone who have access to the selected location',
        text: {
          placeholder: 'Free text log entry'
        },
        title: 'Add Log Entry'
      },
      select: {
        location: 'Select location'
      }
    },
    nodatatext: 'No logs found',
    search: {
      placeholder: 'Search logs'
    },
    title: 'Logs',
    user: {
      unknown: 'Unknown'
    }
  },
  modeleditor: {
    failedtoloadsignaldata: 'Failed to load signal name.',
    locationformat: '{0} locations selected',
    selectcolor: 'Select color...'
  },
  navbar: {
    adduser: 'Add user',
    applanguage: 'Language',
    displayname: 'Name',
    edituser: 'Edit user',
    featureflags: 'Feature flags',
    logout: 'Log out',
    title: 'ectocloud™',
    username: 'User name',
    usersettings: 'Settings'
  },
  nodetypes: {
    building: 'Building',
    equipment: 'Equipment',
    site: 'Site'
  },
  operatorchart: {
    interval: {
      i1d: '1 day',
      i1h: '1 hour',
      i1m: '1 month',
      i1w: '1 week',
      i1y: '1 year',
      iall: 'All'
    },
    signalpicker: {
      addsignal: {
        error: 'No signal selected or it has already been added.'
      },
      button: {
        deleteallsignals: 'Delete all selected signals',
        loadprofile: 'Load profile',
        saveprofile: 'Save profile'
      },
      filter: {
        header: 'Filter:'
      },
      load: {
        error: 'Loading information failed.'
      },
      selectedsignals: {
        header: 'Selected signals:'
      }
    }
  },
  pagingfooter: {
    pageformat: 'Page {0}',
    pageformatwithtotal: 'Page {0} / {1}'
  },
  powercontrol: {
    dispatch: {
      automatic: {
        schedule: {
          failure: {
            disable: 'Failed to disable automatic scheduling',
            enable: 'Failed to enable automatic scheduling'
          },
          label: 'Automatic scheduling',
          success: {
            disable: 'Disabled automatic scheduling',
            enable: 'Enabled automatic scheduling'
          },
          verify: {
            disable: 'Are you sure you want to disable automatic scheduling?',
            enable: 'Are you sure you want to enable automatic scheduling?'
          }
        }
      },
      chart: {
        action: {
          clearsignals: 'Clear all selected signals',
          exportcsv: {
            toast: 'Exporting selected data in CSV format. Please wait...'
          },
          exportcvs: {
            success: 'Successfully exported data. Download should begin soon.'
          },
          signalpicker: 'Edit Signals'
        },
        button: {
          downloadcsv: 'Export',
          help: 'Telemetry'
        },
        equipments: {
          columnerror: 'Unknown columnItem'
        },
        hour: 'h',
        loadingdata: {
          failure:
            'An error occurred while fetching data, please try again later',
          inprogress: 'Loading data...'
        },
        nodata: 'No data available',
        nosignals: 'No signals selected',
        range: {
          all: 'All'
        },
        series: {
          nodata: 'No data'
        },
        signalselectormodal: {
          equipment: {
            heading: 'Signal Picker'
          },
          heading: 'Selected Signals',
          title: 'Signal Picker'
        },
        title: 'Telemetry',
        unknownunit: 'n/a'
      },
      download: {
        download: 'Download',
        item: {
          aggregate: 'Aggregation',
          period: 'Period'
        },
        nosignals: 'No signals selected.',
        options: {
          aggregate: 'Aggregate',
          average: 'Average',
          count: 'Count',
          day: '1 day',
          first: 'First',
          hour: '1 hour',
          last: 'Last',
          max: 'Max',
          mean: 'Mean',
          median: 'Median',
          min: 'Min',
          minute: '1 minute',
          mode: 'Mode',
          month: '1 month',
          none: 'None',
          raw: 'Raw',
          sum: 'Sum',
          week: '1 week'
        },
        range: "Selected time-range, from '{0}' to '{1}'",
        subtitle: 'Configure the signals',
        title: 'Configure export signals'
      },
      recommendedsettings: {
        outsidetemp: 'Outside temperature',
        title: 'Recommended settings',
        usebutton: 'Use recommended settings'
      },
      schedule: {
        acutestop: 'Acute stop',
        affectedbuildings: 'Updated {0} of {1} affected buildings.',
        dateerror:
          'Schedule must start at a date in the future to be valid. Please select a valid date to continue.',
        doaction: {
          question: 'Are you sure you want to {0} this schedule?',
          title: 'Please confirm action'
        },
        forecasting: {
          decrease:
            'At the start of the schedule the power will decrease by approximately {0}.',
          increase:
            'At the start of the schedule the power will increase by approximately {0}.'
        },
        header: {
          duration: 'Duration of the schedule',
          effect: 'Effect during schedule',
          impact: 'Temperature impact',
          start: 'Start of the schedule',
          type: 'Schedule type'
        },
        immediately: 'Immediately',
        invalidamplitude:
          'Please enter a valid value for the control parameter.',
        noforecasting:
          'We do not have enough forecasting data to supply a power estimate for this date.',
        option: {
          hours: 'hours',
          startoffset: 'Start schedule in',
          starttime: 'Start schedule at',
          stop: 'Stop schedule after'
        },
        power: 'Activated {0} MW of {1} MW available power.',
        schedule: 'Schedule',
        showmostaffectedbuilding: 'Show the most affected building',
        showmostaffectedbuildings: 'Show the {0} most affected buildings',
        startinterval: 'Schedule will start at {0} {1} and end at {2} {3}.',
        state: {
          abort: 'abort',
          confirm: 'Confirm',
          delete: 'delete'
        },
        stopschedulesettings: 'Stop schedule settings',
        temperatureaffectedbuildings: '{0} affected buildings.',
        temperatureimpact: {
          affectedbuildings: {
            empty: 'No affected buildings found.',
            title: 'Most affected buildings'
          }
        },
        temperaturerangewarning:
          'The setting affects the comfort of the building and must only be used in emergency situations.',
        testinfo: 'Power Control Test will start at {0}.',
        tooltip: {
          abortschedule: 'Abort schedule',
          deleteschedule: 'Delete Schedule',
          editschedule: 'Edit schedule',
          gotoschedule: 'Go to schedule'
        }
      },
      scheduled: {
        abort: {
          failure: {
            toast: 'Unable to abort schedule'
          },
          success: {
            toast: 'Successfully aborted schedule'
          }
        },
        action: {
          addschedule: 'Add Schedule',
          confirmremoveall:
            'Are you sure you want to delete all pending schedules?',
          removeall: 'Delete all pending schedules'
        },
        add: {
          failure: {
            create: 'Unable to add schedule',
            edit: 'Unable to edit schedule'
          },
          failuremulti: {
            create: 'Unable to add schedules',
            edit: 'Unable to edit schedules'
          },
          success: {
            create: 'Successfully added schedule',
            edit: 'Successfully edited schedule'
          },
          successmulti: {
            create: 'Successfully added schedules',
            edit: 'Successfully edited schedules'
          }
        },
        dateerror:
          'Start-date must be in the future and less than end-date. "Demand Forecast" must be less than minimun effect and greater than zero.',
        delete: {
          failure: {
            toast: 'Unable to delete schedule'
          },
          success: {
            toast: 'Successfully deleted schedule'
          }
        },
        deletemulti: {
          failure: {
            toast: 'Unable to delete schedules'
          },
          success: {
            toast: 'Successfully deleted schedules'
          }
        },
        events: 'Schedule events',
        fetch: {
          failure: 'Unable to fetch data, please try again later.'
        },
        noschedules: 'No schedules available.',
        refresh: {
          failure: {
            toast: 'Failed to refresh schedules'
          }
        }
      },
      schedulestatus: {
        column: {
          comment: 'Comment',
          date: 'Date',
          status: 'Status',
          user: 'User'
        }
      },
      scheduletype: {
        manual: 'Manual schedule',
        stop: 'Stop schedule'
      }
    },
    manualdispatch: {
      nodata: 'No data available',
      savebutton: {
        title: 'Save'
      }
    },
    nodata:
      "Power Control isn't available for this site. Pick a different site to utilize this tool.",
    schedule: {
      action: {
        title: 'Schedule Settings'
      },
      confirm: {
        above:
          'The schedule exceeds the threshold value. Do you want to proceed?',
        below:
          'The schedule is below the threshold value. Do you want to proceed?'
      },
      confirmtitle: 'Are you sure you want to add the schedule?',
      createschedule: {
        action: 'Add',
        title: 'Add schedule'
      },
      editschedule: {
        action: 'Edit',
        title: 'Edit schedule'
      },
      header: 'Schedule',
      now: 'Now',
      preset: {
        amplitude: 'Control',
        enddate: 'End time',
        endoffset: 'Ends after',
        header: 'Presets',
        startdate: 'Start time',
        startoffset: 'Starts in'
      },
      preset1: 'Preset 1',
      preset2: 'Preset 2',
      preset3: 'Preset 3'
    },
    status: {
      aborted: 'Aborted',
      finished: 'Finished',
      pending: 'Pending',
      running: 'Running'
    },
    tabs: {
      control: 'Control',
      manual: 'Manual',
      schedule: 'Schedule'
    },
    title: 'Power Control',
    unauthorized: {
      text: 'You do not have access rights to view power control.'
    }
  },
  relativetime: {
    hoursagoformat: '{0} hours ago',
    lessthanoneminutago: 'less than 1 minute ago',
    minutesagoformat: '{0} minutes ago',
    onehourago: '1 hour ago',
    oneminuteago: '1 minute ago'
  },
  remoteopt: {
    action: {
      enable: {
        failure:
          "Unable to enable remote optimisation for '{0}', please try again later."
      },
      save: {
        failure: {
          toast:
            "Unable to save remote optimisation settings for '{0}', please try again later."
        },
        success: {
          toast: 'Successfully saved new optimisation curve'
        }
      }
    },
    button: {
      save: {
        title: 'Save'
      }
    },
    fetch: {
      error: 'Unable to fetch data, please try again later.'
    },
    info: {
      title: 'Enable remote linear interpolation optimization'
    },
    nodata: 'No data available',
    notreedata: 'No data to show in remote-optimization view.',
    outsidetemplabel: 'Outside temperature:',
    tempoffsetlabel: 'Temperature offset:',
    title: 'Remote Optimization'
  },
  select: {
    creatableempty: 'Start typing to add new value'
  },
  selectsignalcollectiondialog: {
    tableheader: 'Graph',
    title: 'Select graph'
  },
  selectusersdialog: {
    title: 'Select Users'
  },
  sidebar: {
    location: {
      grid: {
        header: 'Grid:'
      },
      search: {
        placeholder: 'Search for location'
      },
      tags: {
        placeholder: 'Filter on tags...'
      }
    },
    sections: {
      home: 'Home',
      manage: 'Manage',
      user: 'User',
      visualize: 'Visualize'
    }
  },
  signalproviders: {
    emptylist: 'No signals available in the current location.',
    type: {
      AccumulatorTankEnergyModel: 'Accumulator Tank Energy Model',
      Alarm: 'Alarm',
      COPCoolingForecast: 'COP Cooling Forecast',
      COPHeatingForecast: 'COP Heating Forecast',
      CoolingForecast: 'Cooling Forecast',
      EctogridSetpointCooling: 'Ectogrid Setpoint Cooling',
      EctogridSetpointHeating: 'Ectogrid Setpoint Heating',
      EctogridSetpointModel: 'Ectogrid Setpoint Model',
      Equipment: 'Equipment',
      EroIntegration: 'EroIntegration',
      HeatingForecast: 'Heating Forecast',
      LinearOptimisation: 'Linear Optimisation',
      Meteorology: 'Meteorology',
      PowerControl: 'Power Control',
      ReversibleHeatPumpCOP: 'Reversible Heat Pump COP',
      ReversibleHeatPumpScheduler: 'Reversible Heat Pump Scheduler',
      SteerablePower: 'Steerable Power'
    }
  },
  signals: {
    category: 'Category',
    categoryplaceholder: 'Group signals into category',
    customcolor: 'Custom color',
    displayname: 'Name',
    displaynameplaceholder: 'Replaces signal name',
    equipmenttype: 'Equipment Type',
    filter: {
      matchall: 'Match all'
    },
    missingtype: 'Missing signal type',
    nocompatiblesignalsfound: 'No compatible signals found',
    nosignalsfound: 'No signals found',
    provider: 'Provider',
    selectsignal: 'Select signal...',
    signalid: {
      description:
        'Use signal type instead if you can. Should only be used if signal type is not specific enough.',
      label: 'Hardcoded signal'
    },
    signalprovidertype: 'Signal Provider Type',
    signaltype: 'Signal Type',
    systemname: 'System name'
  },
  signalsseriestable: {
    addsignals: 'Add signals',
    xsignal: 'X-signal',
    ysignal: 'Y-signal'
  },
  signaltypes: {
    selectfolder: 'Select folder...',
    selecttype: 'Select signal type...',
    selectunit: 'Select unit...'
  },
  tenants: {
    error: {
      failedtoload: 'Failed to load organisations'
    },
    noaccess: 'You do not have access to any organisations.',
    noadminrole: 'No administrator role',
    noadminroletext:
      'You do not have administrator rights for this part of the application.',
    noresources: 'No resources assigned to organisation',
    noresourcestext: 'Your organisation has no resources assigned to it .',
    title: 'Organisation'
  },
  toast: {
    addaction: {
      failformat: 'Failed to add {0}',
      successformat: 'Added {0}'
    },
    deleteaction: {
      failformat: 'Failed to delete {0}',
      successformat: 'Deleted {0}'
    },
    editaction: {
      failformat: 'Failed to update {0}',
      successformat: 'Updated {0}'
    },
    getaction: {
      failformat: 'Failed to fetch {0}'
    }
  },
  tools: {
    powercontrols: 'Power Control',
    title: 'Tools'
  },
  treeview: {
    moreresults: 'Load more...'
  },
  format: formatLangString
};

const langSv = {
  admin: {
    alarmrules: {
      activationdelay: 'Aktiveringsfördröjning',
      addrule: 'Lägg till regel',
      editrule: 'Redigera regel',
      resetoperator: 'Återställningsoperator',
      resetvalue: 'Återställningsvärde',
      thresholdoperator: 'Tröskelvärdesoperator',
      thresholdvalue: 'Tröskelvärde'
    },
    alarmselector: {
      placeholder: 'Välj mall för larm'
    },
    alarmstatuspanel: {
      tooltip: {
        alarmseverites: 'Klass {0} alarm',
        noactivealarms: 'Inga aktiva alarm'
      }
    },
    alarmtemplates: {
      addnew: 'Lägg till ny larm-mall',
      addsignal: 'Lägg till signal',
      delete: 'Ta bort mall',
      displayname: 'Visningsnamn',
      error: {
        couldnotremove: 'Kunde inte ta bort mall',
        couldnotremoveconflict:
          'Kunde inte ta bort mall. Den används fortfarande av andra resurser.',
        duplicate: 'Signalnamnet är ej unikt.',
        duplicates: 'Dubletter bland signalnamn. Välj unika signalnamn.',
        notallowed: 'Detta är en systemmall och kan inte tas bort.'
      },
      header: 'Mallar för larm',
      none: 'Ingen',
      severity: 'Klass',
      systemname: 'Namn',
      templatename: 'Namn på mall',
      type: 'Typ',
      unit: 'Enhet',
      writable: 'Skrivbar'
    },
    batterychargingthreshold: {
      maxchargerate: 'Högsta laddningshastighet kW',
      maxdischargerate: 'Högsta urladdningshastighet kW',
      powerelectricitygridsignal: 'Power Electricity Signal',
      stateofchargemax: 'Högsta laddningstillstånd %',
      stateofchargemin: 'Lägsta laddningstillstånd %',
      timeconstant: 'Tidskonstant'
    },
    buildings: {
      column: {
        districtheatingfacilityid: 'Anläggningsid',
        name: 'Namn',
        status: 'Status',
        statusdate: 'Statusdatum',
        tags: 'Taggar'
      },
      export: 'Exportera',
      nodatatext: 'Hittade inga byggnader',
      numberofbuildings: 'Antal byggnader',
      statusplaceholder: 'Status',
      tagsplaceholder: 'Taggar',
      title: 'Byggnader'
    },
    comfort: {
      askdeploymessage:
        'Verktyg har blivit uppdaterade. Vill du föra över konfiguration till enheten?',
      askdeploytitle: 'För över verktyg?',
      deploydialogtitle: 'Överför komfortverktyg',
      dialog: {
        create: {
          title: 'Lägg till komfortverktyg'
        },
        edit: {
          title: 'Redigera komfortverktyg'
        }
      },
      edittool: 'Redigera verktyg...',
      enum: {
        sign: {
          negative: 'Negativ',
          positive: 'Positiv'
        }
      },
      error: {
        loading: 'Misslyckades med att hämta information om Komfort-verktygen.',
        signalfetchfailed:
          'Misslyckades att hämta signalinformation. Signalnamn kan ej visas.'
      },
      name: 'Namn',
      parameters: {
        alarmconfiguration: {
          inputconfiguration: {
            maxdiffvalue: 'Max diff-värde',
            maxvalue: 'Max-värde',
            mindiffvalue: 'Min diff-värde',
            minvalue: 'Min-värde',
            setsignal: 'Jämförelsesignal',
            signal: 'Signal'
          },
          sectiontitle: 'Alarm',
          timedelay: 'Time delay'
        },
        highpass: {
          maxoutputvalue: 'Max output',
          minoutputvalue: 'Min output',
          sectiontitle: 'Högpass',
          sign: 'Tecken',
          timeconstant: 'Tidskonstant'
        },
        integralgain: {
          integralgain: 'Integral gain',
          integraltime: 'Integral time',
          maxoutputvalue: 'Max output',
          maxouttempvalue: 'Max out temp',
          maxroomtempsetpointvalue: 'Max rum-temp. setpoint',
          maxroomtempvalue: 'Max rum-temp.',
          maxwindspeedvalue: 'Max vindhastighet',
          minoutputvalue: 'Min output',
          minroomtempsetpointvalue: 'Min rum-temp. setpoint',
          minroomtempvalue: 'Min rum-temp.',
          minwindspeedvalue: 'Min vindhastighet',
          outtempmeanhours: 'Utetemperatur medeltemp medeltimmar',
          outtempmeanmaxintegration: 'Utetemperatur medeltemp max integration',
          outtempsectiontitle: 'Integral Gain Utetemp.',
          timeconstant: 'Tidskonstant',
          weekdaysectiontitle: 'Integral Gain Veckodag',
          weekendsectiontitle: 'Integral Gain Helg',
          windspeedsectiontitle: 'Integral Gain Vindhastigh.'
        },
        pid: {
          derivativetime: 'Derivative time',
          gain: 'Gain',
          inputdeadbanddifflower: 'Input deadband diff lower',
          inputdeadbanddiffupper: 'Input deadband diff upper',
          integraltime: 'Integral time',
          maxoutputvalue: 'Max output',
          minoutputvalue: 'Min output',
          sectiontitle: 'PID',
          sign: 'Tecken'
        },
        sectionstitle: 'Algoritminställningar'
      },
      signals: {
        error: {
          failedtoload: 'Failed to load tool data',
          failedtosetsignal: 'Failed to set signal'
        },
        outdoortemperature: 'Utetemperatur',
        roomtemperature: 'Rumstemperatur',
        signalgroup: 'Komfortsignaler',
        signalgroupformat: 'Komfortsignaler - {0} radiatorer',
        signalnamecolumn: 'Namn',
        tapwater: 'Tappvarmvatten',
        windspeed: 'Vindhastighet'
      }
    },
    createbuilding: {
      error: {
        creating: 'Misslyckades med att lägga till byggnad, okänt fel.',
        creatingsiblingnameconflict:
          'Misslyckades med att lägga till byggnad, det finns redan en annan byggnad med samma namn och förälder.'
      },
      state: {
        creating: 'Skapar byggnad...'
      },
      success: {
        noerrorsformat: 'Lade till ny byggnad {0}.',
        witherrorsformat:
          'Lade till ny byggnad {0}, men ett fel inträffade under konfigureringen. {1}'
      }
    },
    createlocation: {
      addanotherformat: 'Lägg till ytterligare en {0}',
      addressfieldplaceholder: 'Välj en plats',
      addresslabel: 'Adress:',
      addressplaceholder: 'Gata',
      choosetemplate: 'Välj mall',
      configurelocation: {
        title: 'Välj plats'
      },
      configurequipment: {
        title: 'Konfigurera utrustning'
      },
      configureweather: {
        searchplaceholder: 'Sök efter väderpunkt',
        sectionheader: 'Väderpunkt',
        title: 'Välj väderpunkt'
      },
      connectionformat: 'Uppkoppling {0}',
      connections: {
        manage: 'Ändra',
        name: 'Namn',
        newconnection: '(ny uppkoppling)',
        noconnectionsinfotext:
          'Det finns inga uppkopplingar i den valda mallen. Du kommer att behöva lägga till en ny uppkoppling för att skicka och ta emot data.',
        noexistingconnection:
          'Det finns inga uppkopplingar till den valda Energy Managern. Lägg till en uppkoppling i byggnaden som använder den för att kunna ansluta.',
        slaveid: 'Slav-ID',
        title: 'Uppkopplingar',
        type: 'Typ'
      },
      coordinate: 'Plats',
      createnewdevice: 'Lägg till ny Energy Manager',
      dashboardcollection: 'Dashboardsamling',
      devicelabel: 'Energy manager:',
      equipment: {
        enable: 'Använd',
        name: 'Namn',
        noequipment:
          'Denna mall har ingen utrustningskonfiguration. Redigera mallen innan du kan använda den.',
        type: 'Typ'
      },
      equipmentgrouptemplate: 'Byggnadsmall',
      error: {
        addingequipment: 'Misslyckades med att konfigurera utrustning.',
        creating: 'Misslyckades med att lägga till plats, okänt fel.',
        creatingsiblingnameconflict:
          'Misslyckades med att lägga till plats, det finns redan en annan plats med samma namn och förälder.',
        fetchdeviceconfig:
          'Misslyckades med att hämta konfiguration för Energy Manager, försök igen.',
        initconnection: 'Misslyckades med att konfigurera ny uppkoppling.',
        missingconnection: 'Uppkoppling är felkonfigurerad.'
      },
      existingconnectioninfo:
        'Eftersom du kopplar upp till en existerande enhet så kommer en ny uppkoppling läggas till (med ett nytt slav-ID) för att hantera utrustningen i denna byggnad.',
      loaddashboarderror:
        'Misslyckades med att läsa in dashboardsamling från byggnadsmallen',
      namelabel: 'Namn:',
      nameplaceholder: 'Namn',
      none: 'Ingen',
      parentlabel: 'Förälder:',
      selectdashboardcollection: 'Välj dashboardsamling',
      selectexistingdevice: 'Välj tidigare skapad EM',
      state: {
        addingdashboardcollectionrelation: 'Lägger till dashboardsamling...',
        addingequipment: 'Lägger till utrustning...',
        addingweather: 'Lägger till väderpunkt...',
        creating: 'Skapar plats...',
        initconnection: 'Konfigurerar ny uppkoppling...',
        updatingweather: 'Uppdaterar väderpunkt...'
      },
      success: {
        noerrorsformat: 'Lade till ny plats {0}.',
        witherrorsformat:
          'Lade till ny plats {0}, men ett fel inträffade under konfigureringen. {1}'
      },
      titleformat: 'Lägg till ny {0}'
    },
    createsite: {
      error: {
        creating: 'Misslyckades med att lägga till plats, okänt fel.',
        creatingsiblingnameconflict:
          'Misslyckades med att lägga till plats, det finns redan en annan plats med samma namn och förälder.'
      },
      state: {
        creating: 'Skapar plats...'
      },
      success: {
        noerrorsformat: 'Lade till ny plats {0}.',
        witherrorsformat:
          "Lade till ny plats {0}, men ett fel inträffade under konfigureringen. {1}'"
      }
    },
    dashboardcollection: {
      add: 'Lägg till dashboardsamling',
      addfailure: 'Misslyckade med att lägga till dashboardsamling',
      addsuccess: 'Lade till dashboardsamling',
      current: 'Vald samling',
      defaultdashboard: 'Standard',
      edit: 'Redigera dashboardsamling',
      editfailure: 'Misslyckade med att redigera dashboardsamling',
      editsuccess: 'Redigerade dashboardsamling',
      get: {
        failure: 'Misslyckades med att hämta dashboardsamlingar'
      },
      nodata: 'Det finns inga tillgänglia dashboardsamlingar',
      noselectedcollection: 'Ingen vald samling',
      noselecteddashboards: 'Finns inga valda dashboards',
      remove: {
        failure: 'Misslyckades med att ta bort dashboardsamling',
        success: 'Tog bort dashboardsamling'
      },
      select: 'Välj dashboardsamling',
      selectdashboards: 'Välj dashboards',
      selectincontext: 'Välj samling',
      setdefault: 'Välj standardsamling',
      setdefaultdashboard: 'Sätt som standard',
      title: 'Dashboardsamlingar'
    },
    dashboards: {
      add: 'Lägg till dashboard',
      added: {
        success: 'Lade till dashboard'
      },
      change: {
        failure: 'Misslyckades med att ändra dashboard',
        success: 'Ändrade dashboard'
      },
      column: {
        dashboard: {
          data: 'Dashboard data'
        },
        description: 'Beskrivning',
        name: 'Namn'
      },
      comparebarchart: {
        series: 'Värde'
      },
      configuredashboardcollection: 'Välj dashboardsamling',
      datasources: {
        lastvalue: {
          settings: 'Inställning för senaste värde',
          uselastbeforerange: 'Skippa delvärde i slutet av tidsintervallet'
        },
        node: 'Plats',
        proptexts: {
          defaulttotimerange: 'Tidsområdesbaserad',
          forceexactvalues: 'Tvinga exakta värden',
          hoursbackplaceholder: 'Använd tidsområde från dashboard',
          hoursbackward: 'Timmar bakåt',
          hoursforward: 'Timmar framåt',
          node: 'Hämta data från denna plats. Lämna tom för att använda nuvarande plats.',
          signalcurves: 'Signalkurvor',
          signals: 'Signaler'
        }
      },
      deletebutton: 'Ta bort dashboard',
      deletepanel: 'Ta bort panel',
      duplicate: 'Skapa kopia av panel',
      edit: 'Redigera dashboard',
      empty:
        'Denna dashboard är tom. Lägg till paneler för att visa information.',
      forms: {
        signaldetails: {
          editdialog: {
            addtitle: 'Lägg till signal',
            title: 'Redigera signalinställningar'
          }
        },
        signalnames: {
          addconstant: 'Lägg till konstant',
          addfromexisting: 'Lägg till från signal...',
          addsignal: 'Lägg till signalnamn',
          category: 'Category',
          curvedata: 'Signaler för kurvorna',
          empty:
            'Inga signaler tillagda. Tryck på en av "lägg till"-knapparna för att fortsätta.',
          modaltitle: 'Lägg till signaltyp från signal',
          name: 'Namn',
          timerange: 'För tidsintervall'
        }
      },
      getdashboards: {
        failure: 'Misslyckades med att hämta dashboards',
        nodata: 'Det finns inga tillgängliga dashboards'
      },
      initialdescription: 'Beskrivning',
      initialname: 'Ny dashboard',
      loadfile: {
        failure: 'Misslyckades med att läsa in dashboard-filen'
      },
      loadobject: {
        failure: 'Misslyckades med att läsa in dashboard'
      },
      panels: {
        add: 'Lägg till panel',
        alarmcount: 'Alarmräknare',
        alarmlist: 'Alarmlista',
        alarmmap: 'Alarmkarta',
        alarmstatuslist: 'Statuslista för alarm',
        barchart: 'Stapeldiagram',
        barchartcompare: 'Jämför värdediagram',
        buildingstatuses: 'Byggnadsstatus',
        customprocessmap: 'Egen processkarta',
        edit: 'Redigera panel',
        gauge: 'Mätare',
        id: 'ID',
        initialtitle: 'Titel',
        linechart: 'Linjediagram',
        locationmap: 'Karta',
        minmaxsettings: 'Min/max för grafvärden',
        nodename: 'Platsnamn',
        placeholders: {
          text: 'Texten som ska visas i panelen.'
        },
        processmap: 'Processkarta',
        scatterchart: 'Spridningsdiagram',
        scatterchartxaxis: 'Spridningsdiagram X-axel',
        signalcurveeditor: 'Signalkurvsredigerare',
        signalslist: 'Signallista',
        text: 'Text',
        title: 'Titel',
        type: 'Typ',
        types: {
          alarmcount: {
            seealarms: 'Visa alarm'
          },
          alarmlist: {
            pagesize: 'Max antal alarm'
          },
          barchart: {
            stacking: 'Stackning',
            stackingoption: {
              disabled: 'Avstängd',
              normal: 'Normal',
              percent: 'Procent'
            }
          },
          gauge: {
            fontsize: 'Textstorlek',
            hideunit: 'Dölj enhet',
            maxvalue: 'Största värde',
            minvalue: 'Minsta värde',
            type: 'Mätartyp',
            types: {
              bar: 'Stapel',
              meter: 'Mätare',
              solidgauge: 'Solid mätare',
              value: 'Värde'
            }
          },
          linechart: {
            chartlayout: 'Diagramlayout',
            hidelegend: 'Dölj grafinformation',
            paddingleft: 'Marginal till vänster',
            paddingright: 'Marginal till höger',
            refreshinterval: 'Uppdateringsfrekvens (minuter)',
            showcurrenttime: 'Visa aktuell tid'
          },
          map: {
            autoselect: 'Välj automatiskt första signal',
            hidecontrols: 'Dölj knappar'
          },
          nodename: {
            node: 'Plats',
            nodeplaceholder:
              'Hämta data från denna plats. Lämna tom för att använda nuvarande plats.'
          },
          scatterchart: {
            xaxisprovidername: 'Signalgruppsnamn för x-axel',
            xaxissignalname: 'Signalnamn för x-axel'
          }
        }
      },
      remove: {
        failure: 'Misslyckades med att ta bort dashboard',
        success: 'Tog bort dashboard'
      },
      save: {
        failure: 'Misslyckades med att spara dashboard',
        success: 'Sparade dashboard'
      },
      sections: {
        aggregation: 'Aggregering',
        alarm: 'Alarminställningar',
        barchart: 'Stapeldiagram',
        curverange: 'Kurvområde',
        curvexaxis: 'Vertikal X-axisvärde',
        datarange: 'Tidsintervall',
        gauge: 'Mätare',
        linechart: 'Linjediagram',
        map: 'Karta',
        signals: 'Signaler'
      },
      title: 'Dashboards'
    },
    deploytemplates: {
      deploy: 'Synkronisera',
      deploysuccessfulformat: 'Synkronisering utförd till: {0}',
      failedtoloadtenants: 'Misslyckades med att läsa in organisationer',
      tenants: 'Synkronisera mallar till organisationer'
    },
    deprovisionreason: {
      faulty: 'Trasig',
      other: 'Annan'
    },
    ectotable: {
      addroom: 'Lägg till rum...',
      addroomtitle: 'Lägg till ectotable-rum',
      addscreencontent: 'Lägg till skärminnehåll...',
      addscreencontentdialog: 'Lägg till skärminnehåll',
      background: 'Bakgrundsbild',
      company: 'Företag',
      contactperson: 'Kontaktperson',
      editroomtitle: 'Redigera ectotable-rum',
      editscreencontent: 'Redigera skärminnehåll',
      existingfile: 'Fil',
      fullscreentype: 'Full screen',
      location: 'Plats',
      name: 'Namn',
      rooms: 'Ectotable-rum',
      screencontent: 'Skärminnehåll',
      screencontents: 'Skärminnehåll',
      screenname: 'Skärmnamn',
      text: 'Text',
      title: 'Ectotable',
      typeformat: 'Skärm {0} typ',
      video: 'Video'
    },
    editbuilding: {
      conflict: {
        facilityid:
          'Konflikt: Det finns redan en byggnad med detta anläggningsid.'
      },
      delete: {
        title: 'Vill du verkligen ta bort denna byggnaden?'
      },
      deletelocation: {
        text: 'Om du tar bort denna byggnaden kommer även all utrustning och relaterade signaler att tas bort.',
        title: 'Ta bort byggnad'
      },
      savelocation: 'Spara byggnad',
      update: {
        failed: 'Misslyckades med att uppdatera byggnaden'
      },
      updated: {
        building: 'Uppdaterade byggnad'
      }
    },
    editlocation: {
      addlogdialog: {
        description: 'Mata in loggpost för {0}.',
        error: 'Misslyckades med att lägga till loggpost.',
        placeholder: 'Loggpost',
        success: 'Lade till loggpost för {0}.'
      },
      addlogentry: 'Lägg till loggpost',
      addnewbuilding: 'Lägg till ny byggnad',
      addnewrootsite: 'Lägg till ny rotsektor',
      addnewsite: 'Lägg till ny sektor',
      deletelocation: {
        button: 'Ta bort plats',
        text: 'Om du tar bort denna plats kommer även all utrustning och relaterade signaler att tas bort.',
        title: 'Vill du verkligen ta bort denna plats?'
      },
      details: 'Detaljer',
      editmeteorology: 'Redigera väderpunkt',
      editnotifications: {
        button: 'Redigera notiser'
      },
      editparents: {
        button: 'Ändra nodstruktur',
        title: 'Redigera föräldrar'
      },
      editprocessmap: 'Välj processkarta',
      edittools: 'Hantera verktyg',
      editusers: 'Ändra användare',
      error: {
        failedtoloadmappings: 'Misslyckades med att läsa in användardata.',
        noparentlocation: 'Måste ha åtminstone en föräldernod.'
      },
      fields: {
        activealarms: 'Aktiva larm:',
        buildingstatus: 'Byggnadsstatus:',
        childlocations: 'Underplatser:',
        districtheatingfacilityid: 'Anläggningsid:',
        equipment: 'Utrustning:',
        grids: 'Nät:',
        linearoptimisations: 'Linjäroptimeringar:',
        name: 'Namn:',
        parents: 'Föräldrar:',
        powercontrols: 'Effektstyrning:',
        street: 'Gata:',
        type: 'Typ:'
      },
      placeholder: {
        districtheatingfacilityid: 'Anläggningsid',
        name: 'Namn',
        street: 'Gata'
      },
      savelocation: 'Spara plats',
      tags: 'Taggar',
      tools: {
        comfort: {
          batchcommonsection: {
            title: 'Gemensamma inställningar'
          },
          batchdeploy: 'Överför verktyg',
          batchedittools: 'Redigera/lägg till flera',
          batchtitle: 'Redigera Komfortverktyg',
          columns: {
            radiatorname: 'Radiatornamn'
          },
          error: {
            batchmultipledevices:
              'Felaktig enhetskonfiguration. Alla radiatorer måste hanteras av samma enhet för att man ska kunna redigera flera.'
          },
          noradiators: 'Inga radiatorer hittades på denna platsen.',
          title: 'Komfort'
        },
        configured: 'Konfigurerad.',
        manage: 'Hantera',
        readytoadd: 'Redo att läggas till.',
        status: 'Status',
        temperatureimpact: 'Temperaturpåverkan',
        tooltype: 'Verktyg',
        unsupported: 'Stöds ej. Platsen saknar obligatoriska parametrar.'
      },
      users: {
        addtosublocations: 'Lägg till i underhierarki',
        failure: 'Misslyckades med att uppdatera användare',
        name: 'Namn',
        nousersfound: 'Inga användare hittades.',
        searchplaceholder: 'Sök efter användare',
        title: 'Redigera användare',
        updated: 'Uppdaterade användare',
        username: 'Användarnamn'
      }
    },
    editsite: {
      delete: {
        title: 'Vill du verkligen ta bort denna platsen?'
      },
      deletelocation: {
        text: 'Om du tar bort denna platsen kommer även all utrustning och relaterade signaler att tas bort.',
        title: 'Ta bort plats'
      },
      savelocation: 'Spara plats',
      update: {
        failed: 'Misslyckades med att uppdatera platsen'
      },
      updated: {
        site: 'Uppdaterade plats'
      }
    },
    elvaco: {
      dialog: {
        available: {
          signals: 'Tillgängliga signaler'
        },
        choose: {
          account: 'Välj Elvaco-konto'
        },
        create: {
          title: 'Lägg till Elvaco-verktyg'
        },
        edit: {
          title: 'Redigera Elvaco-verktyg'
        },
        no: {
          available: {
            signals: 'Det finns inga tillgängliga signaler'
          },
          selected: {
            signals: 'Det finns inga valda signaler'
          }
        },
        selected: {
          signals: 'Valda signaler'
        }
      },
      error: {
        addresswithoutcity: 'Du måste ange stad om du ska söka efter adress.',
        addresswithoutnumber:
          'Du måste ange ett gatunummer när du söker efter adress.',
        invalidsyntax: 'Ogiltig söksyntax.'
      },
      filter: {
        address: 'Adress',
        alarm: 'Alarm',
        clearall: 'Rensa allt',
        meterName: 'Mätarnamn',
        title: 'Filtrera'
      },
      get: {
        accounts: {
          failed: 'Misslyckades med att hämta Elvaco-konton'
        },
        signals: {
          failed: 'Misslyckades med att hämta signaler. Kontrollera sökfälten.'
        },
        tool: {
          failed: 'Misslyckades med att hämta Elvaco-verktyg'
        }
      }
    },
    energymanager: {
      application: 'Applikation',
      changeiotdevice: 'Byt till ny enhet',
      confirmunlink: 'Koppla ifrån enhet',
      confirmunlinktextformat:
        'Vill du koppla från enheten med MAC-address {0}?',
      connections: 'Uppkopplingar',
      createconfig: 'Lägg till enhetskonfiguration',
      currentmd5: 'Nuvarande MD5',
      deploy: 'Ladda upp',
      deploymd5: 'Uppladdad MD5',
      deployment: 'Konfigurationsuppladdning',
      deploymentdetails: 'Konfigurationsdetaljer',
      deploymenterrorformat: 'Fel (kod {0})',
      deploymentstatus: 'Uppladdningsstatus',
      deploynopairinginfoformat:
        'Det finns för tillfället ingen koppling mot en fysisk Energy Manager. Vänligen välj en enhet under fliken {0} för att möjliggöra överföring av konfigurationsfiler.',
      devicefile: 'Konfigurationsfil',
      devicemac: 'MAC-address',
      devicesettings: 'Energy Manager-inställningar',
      error: {
        nodeviceconfig:
          'Vi hittade ingen giltig enhetskonfiguration som är kopplad till denna Energy Manager. Du kan försöka att lägga till en ny konfiguration för att åtgärda detta.'
      },
      file: 'Fil',
      information: 'Information',
      loading: 'Läser in...',
      missingmac: 'Du måste ange en MAC-address.',
      missingmodbusaddressess:
        'Det finns signaler utan modbusadresser. Det behöver läggas till på givna signaler för att kunna göra en konfigurationsuppladdning.',
      noiothubdevice:
        'Ingen enhet hittades på IoT-hubben. Verifiera att MAC-addressen stämmer överens med en konfigurerad Energy Manager.',
      nopairinginfo:
        'Det finns för tillfället ingen koppling mot en fysisk Energy Manager. Vänligen välj en enhet för att aktivera dataöverföring.',
      pairingerror: 'Misslyckades med att koppla upp mot ny enhet.',
      pairingsuccess: 'Lyckades med att koppla upp mot ny enhet.',
      pairwithdeviceandcopybutton: 'Koppla upp och kopiera inställningar',
      pairwithdevicebutton: 'Koppla upp',
      pairwithdeviceformat:
        'Vill du koppla upp mot enheten med MAC-adress {0}?',
      pairwithdevicetitle: 'Koppla upp mot enhet',
      pairwithotherdeviceformat:
        'Vill du koppla från enheten med MAC-address {0} och koppla upp mot enheten med MAC-address {1}?',
      pairwithotherdevicerelinkformat:
        'Genom att bekräfta kopplar du från enheten med MAC-address {0} och kopplar istället upp mot enheten med MAC-address {1}. Om du vill kan du kopiera över inställningar (nätverksinställningar och taggar) från den gamla enheten till den nya.',
      pending: 'Under behandling',
      readytodeploy: 'Redo att ladda upp',
      reportedmd5: 'Rapporterad MD5',
      saveiotdevice: 'Spara enhet',
      selectiotdevice: 'Koppla upp mot enhet',
      showdevicefiles: 'Visa enhetsfiler',
      signalsfile: 'Signalfil',
      title: 'Energy Manager',
      toolsfile: 'Verktygsfil',
      unlinkiotdevice: 'Koppla bort nuvarande enhet',
      unpairingerror: 'Misslyckades med att koppla från enheten.',
      unpairingsuccess: 'Lyckades med att koppla ifrån enheten.',
      uptodate: 'Konfigurationsfilerna är uppdaterade till senaste versionen.',
      uptodatewaitingforprocessreload:
        'Konfigurationsfilerna är uppdaterade till senaste versionen, men vi väntar på att Energy Managern ska uppdatera sin databas.',
      uptodatewithchanges: 'Det finns ny konfiguration som behöver föras över.',
      validation: {
        error: {
          address_conflict: 'Addresskonflikt',
          duplicate_outtemp: 'Dublett av utomhustemperatursignal',
          missing_outtemp: 'Saknar utomhustemperatursignal',
          system_name_conflict: 'Signalnamnskonflikt'
        },
        fetchfailed:
          'Misslyckades med att hämta listan över eventuella konfigurationsfel, vänligen försök igen.',
        title: 'Konfigurationsfel'
      },
      version: 'Version',
      versions: 'Applikationsversioner'
    },
    energymanagers: {
      devicename: 'Enhetsnamn',
      failedtofetchsignalsconfig:
        'Misslyckades med att hämta signalkonfiguration för Energy Manager',
      heartbeat: 'Senaste svar',
      locations: 'Byggnader',
      nodevice: 'Ingen enhet',
      search: 'Sök efter enhetsnamn',
      searchplaceholder: 'Enhetsnamn',
      status: 'Status'
    },
    equipment: {
      add: 'Lägg till ny utrustning',
      addbutton: 'Lägg till',
      addfromtemplate: {
        addingconnection: 'Lägger till uppkoppling för utrustning...',
        error: 'Misslyckades med att lägga till utrustning från mall',
        errorafterinit:
          'Lyckades med att lägga till utrustning men kunde inte hämta ny data. Har du problem med uppkopplingen?',
        errorconnection: 'Misslyckades med att lägga till ny uppkoppling',
        instantiating: 'Lägger till utrustning från mall...',
        success: 'Lyckades med att lägga till utrustning.',
        title: 'Lägg till från mall'
      },
      addnewlinearoptimisation: 'Lägg till linjäroptimering',
      addnewpowercontrol: 'Lägg till effektstyrning',
      addsignal: {
        error: 'Misslyckades med att lägga till signal.',
        success: 'Lyckades med att lägga till signal.'
      },
      alarmtemplate: 'Mall för larm',
      algorithmtype: {
        basic: 'Basic',
        title: 'Algoritm',
        tracking: 'Tracking',
        trackingventilation: 'Tracking Ventilation',
        transmit: 'Transmit'
      },
      chooseexistingem: 'Välj tidigare skapad Energy Manager',
      configerror: 'Ett fel inträffade vid hämtning av konfiguration.',
      confirmdelete: {
        message: 'Är du säker på att du vill ta bort utrustningen?',
        title: 'Bekräfta borttagning'
      },
      confirmdeletesignal: {
        message: 'Är du säker på att du vill ta bort {0}?'
      },
      createnewem: 'Lägg till ny Energy Manager',
      delete: {
        error: 'Misslyckades med att ta bort utrustning.',
        success: 'Lyckades med att ta bort utrustning.'
      },
      deletedatapoint: {
        buttontitle: 'Radera signalvärden...',
        deleteformat: 'Är du säker på att du vill ta bort värden från {0}?',
        enddate: 'Slutdatum',
        nodata: 'Inga data hittades inom valt tidsintervall.',
        startdate: 'Startdatum',
        success: 'Raderade signalvärden',
        title: 'Radera signalvärden'
      },
      deleteequipment: 'Ta bort utrustning',
      deletesignal: {
        error: 'Misslyckades med att ta bort signal.',
        success: 'Lyckades med att ta bort signal.'
      },
      deploytool: 'Överför verktyg',
      description: 'Beskrivning',
      descriptionlabel: 'Beskrivning',
      deviceparent: 'Energy Manager',
      editlinearoptimisation: 'Redigera linjäroptimering',
      editpowercontrol: 'Redigera effektstyrning',
      energymanagerfromformat: 'Energy Manager från {0}',
      error: {
        mustspecifyname: 'Du måste ange ett namn.',
        nodevice:
          'Inga enheter. Vänligen lägg till utrustning från mall eller lägg till en Energy Manager för att fortsätta.',
        nogridequipment:
          'Nät innehåller inte utrustning. Gå till en plats för att hantera utrustning.',
        nositeequipment:
          'Det finns inget stöd för att hantera utrustning på sektor-nivå.'
      },
      files: {
        button: 'Konfigurationsfiler...',
        title: 'Konfigurationsfiler'
      },
      information: 'Information',
      linearoptimisation: 'Linjäroptimering',
      name: 'Utrustningsnamn',
      namelabel: 'Namn',
      nameplaceholder: 'Namn',
      none: 'Ingen',
      notools: 'Inga verktyg tillgängliga',
      numberofpoints: 'Antal punkter',
      powercontrolformat: 'Effektstyrning {0}',
      powercontroltype: {
        cooling: 'Kyla',
        heating: 'Värme',
        title: 'Typ'
      },
      processmap: {
        error: 'Misslyckades med att uppdatera processkartan på utrustningen',
        updatesuccess: 'Processkartan är uppdaterad på utrustningen'
      },
      remove: {
        error: {
          relationconflict:
            'Kunde inte ta bort verktyget för det används av något annat.'
        }
      },
      request: {
        fetchtoolsfailed: 'Misslyckades med att hämta verktyg',
        updatedem: 'Uppdaterade Energy Manager',
        updatedtool: 'Uppdaterade verktyg',
        updateemfailed: 'Misslyckades med uppdatering av Energy Manager',
        updatetoolfailed: 'Misslyckades med uppdatering av verktyg'
      },
      section: {
        connections: 'Uppkoppling',
        deployment: 'Överför konfiguration',
        details: 'Detaljer',
        files: 'Filer',
        iotdevice: 'Hårdvara',
        signals: 'Signaler',
        tools: 'Verktyg'
      },
      selectequipment: 'Välj en utrustning för att fortsätta.',
      title: 'Utrustning',
      tool: {
        batterychargingthreshold: 'Battery Charging Threshold',
        comfort: 'Komfort',
        dialog: {
          create: {
            title: 'Lägg till {0}-verktyg'
          },
          edit: {
            title: 'Redigera {0}-verktyg'
          }
        },
        ecoguard: 'Ecoguard',
        elvaco: 'Elvaco',
        linearoptimisation: 'Linjäroptimering',
        powercontrol: 'Effektstyrning',
        powerdeltacontrol: 'Power Delta Control'
      },
      tools: 'Verktyg',
      type: 'Utrustningstyp'
    },
    equipmentgroup: {
      addequipment: {
        add: 'Lägg till utrustning',
        alarms: 'Mall för larm',
        placeholder: 'Välj typ av utrustning',
        title: 'Lägg till utrustning',
        tools: 'Verktyg'
      },
      connections: 'Uppkopplingar',
      energymanager: {
        addconnection: 'Lägg till uppkoppling',
        connection: 'Uppkoppling',
        signoff: {
          button: 'Signera',
          error: 'Misslyckades att uppdatera uppkoppling',
          name: 'Namn',
          notes: 'Meddelande',
          signed: 'Signerad av {0}',
          title: 'Signera'
        }
      },
      equipment: {
        alarms: 'Mall för larm',
        description: 'Beskrivning',
        name: 'Namn',
        signaltype: 'Signaltyp',
        tools: 'Verktyg'
      }
    },
    equipmentgrouptemplates: {
      addnew: 'Lägg till ny byggnadsmall',
      confirmdelete: {
        button: 'Ta bort',
        text: 'Är du säker på att du vill ta bort denna byggnadsmallen? Byggnader som lagts till från denna mallen sedan tidigare påverkas inte.',
        title: 'Bekräfta borttagning'
      },
      delete: 'Ta bort byggnadsmall',
      deletefailed: 'Misslyckades med att ta bort mall.',
      deletesuccess: 'Tog bort mall.',
      error: {
        invalidname: 'Vänligen fyll i ett korrekt namn'
      },
      name: 'Namn',
      save: 'Spara byggnadsmall',
      templatename: 'Namn på mall',
      title: 'Byggnadsmallar'
    },
    equipmentsignal: {
      address: 'Adress',
      connection: 'Uppkoppling',
      description: 'Beskrivning',
      displayname: 'Visningsnamn',
      editformat: 'Redigera värdet för {0}',
      failedtoloadvalues: 'Misslyckades med att läsa in senaste signalvärdena.',
      failedtosetsignal: 'Misslyckades med att uppdatera signalvärdet.',
      noconnection: 'Ingen',
      signaltype: 'Signaltyp',
      systemname: 'Namn',
      title: 'Signaler',
      type: 'Typ',
      unit: 'Enhet',
      value: 'Värde'
    },
    equipmentsignaltemplates: {
      addnew: 'Lägg till en ny utrustningstyp',
      delete: 'Ta bort utrustningstyp',
      equipmentname: 'Namn',
      header: 'Utrustningstyper',
      name: 'Namn',
      parenttype: 'Bastyp (valfritt)',
      processmap: 'Processkarta'
    },
    equipmenttemplates: {
      addsignal: 'Lägg till signal',
      dataformat: 'Dataformat',
      defaultvalue: 'Startvärde',
      description: 'Beskrivning',
      displayname: 'Visningsnamn',
      error: {
        cannotremoveparentsignal: 'Kan inte ta bort signal från ursprungstyp.',
        displayname: 'Ange visningsnamn för signalen.',
        duplicate: 'Dubletter bland signalnamn. Välj unika signalnamn.',
        duplicates: 'Dubletter bland signalnamn. Välj unika signalnamn.',
        failedtofetch: 'Misslyckades med att hämta mall',
        missingfields: 'Ange alla obligatoriska fält innan du sparar.',
        missingname: 'Ange namn för signalen.',
        missingnames: 'Ange namn för alla signaler.',
        missingtype: 'Ange typ för signalen.',
        missingunit: 'Ange en enhet för signalen.',
        processmaps: 'Misslyckades med att hämta processkartor',
        processmapsrevisions:
          'Misslyckades med att hämta revisioner för processkartor',
        updatetemplate: 'Misslyckades att uppdatera mall'
      },
      graphicalrepresentation: 'Grafisk representation',
      none: 'Ingen',
      signaldirection: 'Signalriktning',
      signalfunction: 'Signalfunktion',
      signaltype: 'Signaltyp',
      source: 'Källa',
      systemname: 'Namn',
      type: 'Typ',
      unit: 'Enhet',
      usemodbus: 'Modbus',
      writable: 'Skrivbar'
    },
    equipmenttools: {
      error: {
        signalfetchfailed: 'Misslyckades med att hämta {0}-tool'
      },
      manage: 'Hantera',
      title: 'Utrustningsverktyg',
      tooltype: 'Verktygstyp'
    },
    equipmenttypes: {
      add: 'Lägg till en ny utrustningstyp',
      failed: {
        to: {
          fetch: {
            templates: 'Misslyckades med att hämta utrustningstypmallar'
          }
        }
      },
      processmaps: 'Processkartor',
      selectprocessmap: 'Välj en processkarta',
      signaltemplates: 'Signalmallar',
      title: 'Utrustningstyper',
      updated: 'Uppdaterade utrustningstyp'
    },
    form: {
      unsavedstate: 'Du har osparad data, vill du verkligen lämna sidan?'
    },
    integration: {
      ecoguard: {
        account: {
          editgroups: 'Redigera grupper...',
          editgroupstitle: 'Redigera grupper'
        },
        add: {
          account: 'Lägg till Ecoguard-konto'
        },
        column: {
          description: 'Beskrivning',
          groupname: 'Gruppnamn',
          keyvaultsecret: 'Key vault-hemlighet',
          name: 'Kontonamn'
        },
        confirmcreate: {
          text: 'För att kunna redigera grupper behöver du lägga till ditt nya konto.',
          title: 'Bekräfta lägg till konto'
        },
        edit: {
          account: 'Redigera Ecoguard-konto'
        },
        genericaccountcollectionname: 'Ecoguard-konton',
        genericaccountname: 'Ecoguard-kontot',
        genericgroupcollectionname: 'Ecoguard-grupper',
        genericgroupname: 'Ecoguard-gruppen',
        generictoolcollectionname: 'Ecoguard-verktygen',
        generictoolname: 'Ecoguard-verktyget',
        group: {
          add: {
            button: 'Lägg till grupp...',
            title: 'Lägg till grupp'
          },
          edit: {
            title: 'Redigera grupp'
          },
          nodata:
            'Inga grupper hittades. Tryck på "Lägg till grupp..." för att fortsätta.',
          nodataforpicker: 'Inga grupper hittades.'
        },
        integrationtype: {
          meanroomtemperature: 'Medelrumstemperatur',
          roomtemperature: 'Rumstemperatur',
          title: 'Integrationstyp'
        },
        nametaken: 'Gruppnamnet är redan upptaget på detta Ecoguard-kontot.',
        noaccountselected: 'Inget konto valt',
        selectaccount: 'Konto',
        selectaccountmodaltitle: 'Välj konto',
        selectaccountplaceholder: 'Välj konto...',
        selectgroup: 'Grupp',
        selectgroupmodaltitle: 'Välj grupp',
        selectgroupplaceholder: 'Välj grupp...',
        title: 'Ecoguard',
        tool: {
          add: 'Lägg till Ecoguard-verktyg',
          edit: 'Redigera Ecoguard-verktyg'
        }
      },
      elvaco: {
        account: {
          add: {
            failed: 'Misslyckades med att lägga till Elvaco-konto',
            success: 'Lade till nytt Elvaco-konto'
          },
          save: {
            failed: 'Misslyckades med att spara ändringar på Elvaco-kontot',
            success: 'Sparade ändringar på Elvaco-kontot'
          }
        },
        add: {
          account: 'Lägg till Elvaco-konto'
        },
        column: {
          description: 'Beskrivning',
          name: 'Kontonamn',
          status: 'Status'
        },
        countries: {
          sverige: 'Sverige'
        },
        edit: {
          account: 'Redigera Elvaco-konto'
        },
        form: {
          baseurl: 'Base URL',
          country: 'Land',
          description: 'Beskrivning',
          jwt: 'JWT-token',
          name: 'Namn',
          userid: 'Användar-ID'
        },
        get: {
          accounts: {
            error: 'Misslyckade med att hämta Elvaco-konton'
          }
        },
        nodata: 'Det finns inga tillgängliga Elvaco-konton',
        title: 'Elvaco'
      },
      generic: {
        get: {
          accounts: {
            empty: 'Inga konton tillgängliga',
            error: 'Misslyckades med att läsa in konton'
          }
        }
      },
      netmore: {
        account: 'Netmore-konto',
        add: {
          account: 'Lägg till Netmore-konto',
          tool: 'Lägg till Netmore-verktyg'
        },
        availablesignals: 'Tillgängliga signaler',
        edit: {
          account: 'Redigera Netmore-konto',
          tool: 'Redigera Netmore-verktyg'
        },
        error: {
          failedtoloadsignals: 'Misslyckades med att läsa in signaler för konto'
        },
        form: {
          account: 'Konto',
          description: 'Beskrivning',
          name: 'Namn',
          password: 'Lösenord',
          username: 'Användarnamn'
        },
        headers: {
          description: 'Beskrivning',
          euiserialnumber: 'DevEUI'
        },
        nodatatext:
          'Inga signaler hittades. Välj ett konto och skriv in ett giltigt DevEUI i sökfältet för att fortsätta.',
        nosignalsadded: 'Inga signaler tillagda.',
        selectedsignals: 'Valda signaler',
        title: 'Netmore'
      }
    },
    integrations: {
      addnodetopointformat: 'Lägg till {0} till {1}?',
      addpoint: 'Lägg till integrationspunkt...',
      addpointmodalformat: 'Lägg till {0}-integrationspunkt',
      addtopoint: 'Lägg till i integrationspunkt...',
      addtopointtitle: 'Lägg till plats till integrationspunkt',
      editpointmodalformat: 'Redigera {0}-integrationspunkt',
      integrationpointname: 'Integrationspunkt',
      integrationproxyname: 'Integrationsproxy',
      managepoints: 'Hantera integrationspunkter',
      nodeaddedtopoint: 'Platsen har lagts till i integrationspunkten',
      proxy: {
        form: {
          error: {
            invalidformat: 'Ogiltigt format, förväntade: {0}',
            missing: 'Värde förväntas'
          },
          name: 'Namn',
          nodeids: 'Platser'
        }
      }
    },
    iotdevicedetails: {
      activeinternetroute: 'Aktiv som internetanslutning',
      address: 'Adress',
      cancelled: 'Avbruten',
      configurehardware: 'Konfigurera hårdvara',
      currentgateway: 'Nuvarande gateway',
      currentipaddress: 'Nuvarande IP-adress',
      currentiptype: 'Nuvarande nätverksläge',
      currentnetmask: 'Nuvarande nätmask',
      defaultnetworkinterface: 'Standardnätverk',
      deprovisiondevice: 'Ta bort enhet',
      deprovisionreason: 'Anledning',
      desiredinternetroute: 'Önskad internetanslutning',
      devicedefaultnetworkinterface: 'Enhetens standardnätverk',
      deviceinfo: 'Enhetsinformation',
      devicename: 'MAC-adress',
      dhcp: 'DHCP',
      editnetworksettings: 'Redigera nätverksinställningar...',
      error: {
        failedtofetchdeviceinfo:
          'Misslyckades med att hämta information för enheten',
        failedtofetchtags: 'Misslyckades med att hämta taggar',
        failedtoupdate: 'Misslyckades med att hämta enheten'
      },
      gateway: 'Gateway',
      hardwareactions: 'Hårdvarufunktioner',
      heartbeat: 'Senast aktiv',
      heartbeataction: 'Begär heartbeat',
      heartbeaterror: 'Heartbeat misslyckades',
      heartbeatoutput: 'Heartbeat resultat',
      heartbeatresponseerror: 'Heartbeat misslyckades. Statuskod: {0}',
      heartbeatsuccess: 'Heartbeat lyckades',
      heartbeattitle: 'Heartbeat',
      ipaddress: 'IP-adress',
      iptype: 'Nätverksläge',
      lastcomms: 'Senaste kommunikation',
      locations: 'Platser',
      logs: 'Anrop',
      manufacturer: 'Tillverkare',
      model: 'Modell',
      modules: 'Moduler',
      modulesdetail: {
        exitCode: 'Kod vid avslut',
        lastExitTime: 'Senast avslut',
        lastRestartTime: 'Senaste omstart',
        lastStartTime: 'Senaste start',
        runtimeStatus: 'Status',
        status: {
          dead: 'Död',
          failed: 'Misslyckad',
          running: 'Kör',
          stopped: 'Stoppad',
          unknown: 'Okänd'
        },
        version: 'Version'
      },
      modulesname: 'Namn',
      modulesversion: 'Nuvarande version',
      modulesversionlatest: 'Senaste version',
      netmask: 'Nätmask',
      networksettings: 'Nätverksinställningar',
      noheartbeat: 'Ingen data rapporterades',
      notactiveinternetroute: 'Inte aktiv som internetanslutning',
      notes: 'Anteckningar',
      pairactions: 'Konfigurera kopplad enhet',
      performrequestheartbeat: 'Begär heartbeat',
      performrequestping: 'Anropar {0}',
      performrequestreboot: 'Rebooting',
      performrequestrestartservice: 'Startar om service {0}',
      ping: 'Ping',
      pingaction: 'Pinga address',
      pingoutput: 'Pingresultat',
      pingresponse: 'Responstid: {0}ms',
      pingresponseerror: 'Misslyckades att pinga. Statuskod: {0}',
      provisiondevice: 'Lägg till enhet',
      reboot: 'Starta om',
      rebootconfirm: 'Är du säker på att du vill starta om enheten?',
      rebootdevice: 'Starta om Energy Manager',
      rebooterror: 'Misslyckades att starta om enheten',
      rebootresponseerror: 'Misslyckades att starta om enheten. Statuskod: {0}',
      rebootsuccess: 'Enheten är omstartad',
      reportedaddress: 'Enhetens IP-adress',
      reportedgateway: 'Enhetens Gateway',
      reportediptype: 'Enhetens IP-typ',
      reportednetmask: 'Enhetens Nätmask',
      restartservice: 'Starta om service',
      restartserviceoutput: 'Restart service status',
      restartserviceresponse: 'Startade om service.',
      restartservicesresponseerror: 'Misslyckades att starta om service.',
      restartservicesresponseerrorentry:
        'Misslyckades att starta om service. Statuskod: {0}',
      savefailed: 'Misslyckades med att spara data för enheten.',
      savesuccess: 'Sparade data för enheten.',
      signalstrength: 'Signalstyrka',
      sim: 'SIM-kortnummer',
      static: 'Statisk',
      tab: {
        details: 'Detaljer',
        hardware: 'Hårdvara',
        hardwareactions: 'Funktioner',
        networksettings: 'Nätverk'
      },
      tags: 'Taggar',
      title: 'Nätverksinställningar',
      unknown: 'Okänd',
      updatenetworksettings: 'Uppdatera nätverksinställningar',
      uptime: 'Upptid',
      wiredconnection: 'Trådbunden anslutning',
      wirelessconnection: 'Trådlös anslutning'
    },
    lastdeployed: 'Den senaste konfigurationsuppladdning var:',
    meteorology: {
      action: {
        create: 'Lägg till ny punkt'
      },
      createnew: {
        header: 'Lägg till ny punkt'
      },
      error: {
        entername: 'Vänligen fyll i namn'
      },
      fetch: {
        failed: 'Misslyckades med att hämta meteorologisk data.'
      },
      header: {
        coordinates: 'Koordinater',
        name: 'Namn',
        numberoflocations: 'Antal platser'
      },
      input: {
        name: 'Namn'
      },
      locations: 'Platser',
      overview: {
        header: 'Väderpunkter'
      },
      value: {
        numberoflocationsformat: '{0} platser'
      }
    },
    modbusconnection: {
      byte_timeout_microseconds: 'Byte timeout (µs)',
      byte_timeout_seconds: 'Byte timeout (s)',
      connection_timeout: 'Timeout',
      debug: 'Debugnivå',
      delay_read_db: 'Delay read DB',
      delay_read_modbus: 'Delay read modbus',
      delay_write_db: 'Delay write DB',
      delay_write_modbus: 'Delay write modbus',
      mode: {
        master: 'Master',
        slave: 'Slave',
        title: 'Läge'
      },
      properties: 'Uppkopplingsattribut',
      queue_size_factor: 'Köstorleksfaktor',
      response_timeout_microseconds: 'Svarstimeout (µs)',
      response_timeout_seconds: 'Svarstimeout (s)',
      rtubaudrate: 'Baud rate',
      rtudatabits: 'Databitar',
      rtuparity: 'Paritet',
      rtuparityeven: 'Jämn',
      rtuparitynone: 'Ingen',
      rtuparityodd: 'Udda',
      rtuserialname: 'Serienummer',
      rtusettings: 'RTU-inställningar',
      rtustopbits: 'Stopbitar',
      slave_id: 'Slav-ID',
      tcpipaddress: 'IP address',
      tcpport: 'Port',
      tcpsettings: 'TCP-inställningar',
      titleformat: 'Redigera uppkoppling',
      type: {
        rtu: 'RTU',
        tcp: 'TCP',
        title: 'Typ'
      },
      watchdog_bms_address: 'Watchdog BMS Address',
      watchdog_modbus_alarm: 'Watchdog-larm',
      watchdog_modbus_timeout: 'Watchdog timeout'
    },
    modbuslayout: {
      config: 'Konfiguration',
      documentation: 'Dokumentation',
      downloadfile: 'Ladda ner fil',
      layout: 'Layout',
      signals: 'Signaler',
      title: 'Enhetsfiler',
      tools: 'Verktyg'
    },
    modbussignal: {
      bit: 'Bit',
      byteorder: {
        abcd: 'abcd',
        badc: 'badc',
        cdab: 'cdab',
        dcba: 'dcba',
        title: 'Byteordning'
      },
      coil: 'Coil',
      config: 'Konfiguration',
      connection: 'Uppkoppling',
      connectiondefault: 'Standard för anslutning',
      datatype: {
        bitmask: 'bitmask',
        int16: 'int16',
        int32: 'int32',
        int64: 'int64',
        real: 'real',
        title: 'Datatyp',
        uint16: 'uint16',
        uint32: 'uint32',
        uint64: 'uint64'
      },
      delay: 'Delay',
      delaydb: 'Delay DB',
      delaymodbus: 'Delay Modbus',
      disableconfig: 'Avaktivera modbusconfig',
      discreteinput: 'Discrete input',
      editformat: 'Redigera {0}',
      enableconfig: 'Aktivera modbusconfig',
      factor: 'Faktor',
      flag: {
        delay: 'Flag-delay',
        name: 'Flag-namn',
        timeout: 'Flag-timeout',
        title: 'Flag'
      },
      holdingregister: 'Holding register',
      inputregister: 'Input register',
      modbusmaster: 'Modbus Master',
      modbusslave: 'Modbus Slave',
      mode: 'Läge',
      read: 'Läs',
      readdelay: 'Read-delay',
      readname: 'Read-namn',
      readsetting: {
        title: 'Read'
      },
      readwrite: 'Läs/Skriv',
      signalproperties: 'Signalattribut',
      signaltype: 'Signaltyp',
      step: 'Steg',
      watchdog: {
        alarm: 'Larm',
        enablesignal: 'Aktivera',
        high: 'High',
        period: 'Period',
        title: 'Watchdog'
      },
      write: 'Skriv'
    },
    modeltypes: {
      signals: {
        numberselected: '{0} signaler',
        zeroselected: 'Inga signaler'
      },
      users: {
        numberselected: '{0} användare',
        zeroselected: 'Inga användare'
      }
    },
    notifications: {
      addnotification: 'Lägg till notis',
      deletenotification: 'Ta bort notis',
      editnotification: 'Redigera notis',
      error: {
        failedtoadd: 'Misslyckades med att lägga till notis.',
        failedtodelete: 'Misslyckades med att ta bort notis.',
        missingname: 'Ange namn för notisen.',
        nonodeselected: 'Ingen plats vald.',
        nousers: 'Inga användare valda.'
      },
      header: {
        location: 'Plats',
        name: 'Namn',
        severities: 'Allvarlighetsgrader',
        signals: 'Signaler',
        users: 'Användare'
      },
      selectsignals: 'Välj signaler',
      selectusers: 'Välj användare',
      title: 'Notiser'
    },
    powerdeltacontrol: {
      cooling: 'Kyla',
      error: {
        signalfetchfailed: 'Misslyckades med att hämta Power Delta Control-tool'
      },
      heating: 'Värme',
      returntemperaturesignal: 'Returtemperatursignal',
      timeconstant: 'Tidskonstant',
      type: 'Typ'
    },
    previewtenant: {
      previewnode: 'Datanod',
      previewnodetooltip:
        'Förhandsgranskningsnod. Ställ in denna för att förhandsgranska processkartan med signaler från förhandsgransknings-organisationen.',
      previewtenant: 'Organisation',
      previewtenanttooltip:
        'Förhandsgranskningsorganisation. Ställ in denna för att välja noder från en annan organisation som du kan förhansgranska processkartan med.'
    },
    processmaps: {
      add: 'Lägg till',
      addconnection: 'Lägg till anslutning',
      addmap: 'Lägg till processkarta',
      addsvgimage: 'SVG-bild...',
      addsvgsymbol: 'Lägg till symbol från SVG...',
      clickaction: {
        node: 'Plats'
      },
      comment: 'Kommentar för processkartan',
      commentrequired: 'Kommentar saknas',
      contextmenu: {
        addline: 'Linje',
        addrect: 'Rektangel',
        addsignal: 'Signal',
        addsymbol: 'Symbol',
        addtext: 'Text',
        deleteconnections: 'Ta bort anslutningar',
        deleteobject: 'Ta bort objekt',
        deletepoint: 'Ta bort punkt',
        sendback: 'Skicka bak',
        sendforward: 'Skicka fram',
        sendtoback: 'Skicka längst bak',
        sendtofront: 'Skicka längst fram'
      },
      copysource: 'Kopiera SVG-källa',
      created: 'Processkarta har lagts till',
      currentmap: 'Vald processkarta',
      customzoom: 'Anpassad zoom',
      default: 'Standard',
      deleteconnection: 'Ta bort anslutning',
      deleteitem: 'Radera symbol',
      deletemap: 'Ta bort processkarta',
      description: 'Beskrivning',
      download: 'Hämta',
      downloadsvg: 'Ladda ner SVG',
      edit: 'Redigera processkarta',
      editnodetyperelation: 'Redigera nodtyps relation',
      editsymbollibrary: 'Redigera symbolbibliotek',
      error: {
        create: 'Kunde inte lägga till processkarta',
        delete: 'Kunde inte ta bort processkarta',
        invalidfile: 'Felaktig fil',
        missingcomment: 'Du måste ange en kommentar',
        missingmap: 'Du måste lägga till en processkarta',
        missingname: 'Du måste ange ett giltigt namn',
        update: 'Kunde inte uppdatera processkarta'
      },
      failedtoload: 'Det gick inte att läsa in processkarta',
      failedtoparse: 'Det gick inte att läsa in processkarta',
      history: 'Versioner',
      map: 'Karta',
      mapheight: 'Höjd',
      maps: {
        error: 'Misslyckades med att hämta processkartor'
      },
      mapwidth: 'Bredd',
      name: 'Namn',
      newcomment: 'Ny kommentar',
      newmap: 'Ny karta',
      newsymbol: 'Ny symbol',
      nocurrentmap: 'Ingen processkarta är vald',
      nocurrentmapdefault:
        'Ingen processkarta är vald. Använder standardprocesskarta "{0}"',
      nofound: 'Hittade inga processkartor',
      nosignaltypeselected: 'Ingen signaltyp vald',
      notsvgerror:
        'Bilden är inte i SVG-format. Bara SVG-bilder stödjs för tillfället.',
      objecteditor: {
        actiontype: 'Hantering',
        addsymbolrule: 'Lägg till symbolregel',
        bold: 'Fetstil',
        changestatetitle: 'Ändra utseende baserat på signalvärde',
        clickaction: 'Klickhantering',
        cornerradius: 'Hörnradie',
        dashed: 'Streckad',
        dimensions: 'Mått',
        endendcapstyle: 'Stil på linjeslut',
        equipmentactionhelp:
          'Användaren kommer att navigeras till utrustningstyp på nuvarande plats som matchar den utrustningstyp som är vald.',
        fillcolor: 'Fyllnadsfärg',
        fillcolorrule: 'Fyllnadsfärgsregel',
        fliphorizontal: 'Vänd horisontellt',
        flipmidpoint: 'Växla mittpunkt',
        flipvertical: 'Vänd vertikalt',
        fontsize: 'Textstorlek',
        height: 'Höjd',
        hideitem: 'Dölj objekt',
        hidelabel: 'Göm text',
        hiderequiredsignal: 'Obligatorisk signal',
        hiderequiredsignalhelp:
          'Dölj objekt om inga signaler av denna typ kan hittas. Syns fortfarande vid redigering.',
        hiderequiredsignalid: 'Obligatorisk signal (hårdkodad)',
        hiderequiredsignalidhelp:
          'Använd endast denna om signaltypen inte är tillräckligt specifik.',
        hiderulesignals: 'Validera regler',
        hiderulesignalshelp:
          'Dölj objekt om ingen av de signaler som definieras i reglerna kan hittas i den aktuella noden.',
        hidevalue: 'Göm värde',
        italic: 'Kursiv',
        labeltextstyle: 'Etiketttextstil',
        linemode: 'Linjetyp',
        linemodes: {
          path: 'Linjesegment',
          straight: 'Rak linje'
        },
        lineobject: 'Linjeobjekt',
        linewidth: 'Linjebredd',
        nosymbolrules: 'Inga symbolregler definierade',
        pointformat: 'Punkt {0}',
        rectobject: 'Rektangelobjekt',
        rotation: 'Rotation',
        rounded: 'Avrundad',
        scale: 'Skala',
        signalidindexformat: 'Signal-id {0} (hårdkodad)',
        signalobject: 'Signalobjekt',
        signaltypeindexformat: 'Signaltyp {0}',
        startendcapstyle: 'Stil på linjestart',
        state: 'Utseende',
        strokecolor: 'Linjefärg',
        strokewidth: 'Linjebredd',
        symbol: 'Symbol',
        symbolobject: 'Symbolobjekt',
        symbolrules: 'Symbolregler',
        text: 'Text',
        textalignment: 'Textposition',
        textalignments: {
          center: 'Centrerad',
          left: 'Vänster',
          right: 'Höger'
        },
        textobject: 'Textobjekt',
        textstyle: 'Textstil',
        valuetextstyle: 'Värdetextstil',
        width: 'Bredd',
        x: 'X',
        y: 'Y'
      },
      previewsymbolstates: 'Förhandsgranska symbolen',
      projectsettings: {
        title: 'Projektinställningar',
        trimmap: 'Trimma processkartestorlek',
        updatesymbolsversions: 'Uppdatera symbolversioner',
        updatesymbolsversionstoast: 'Uppdaterade symbolversioner.'
      },
      removed: 'Processkartan är borttagen',
      revision: {
        error: 'Misslyckades med att hämta versioner'
      },
      svgmenu: {
        copyobjects: 'Importera alla objekt från annan processkarta',
        showpreview: 'Visa förhandsvisning av gammal processkarta'
      },
      symbolactions: 'Symboloperationer',
      templatename: 'Mallnamn',
      title: 'Processkartor',
      updated: 'Processkartan är uppdaterad',
      updatesvgsymbol: 'Uppdatera SVG-bild...',
      zoom: 'Zoom'
    },
    provisioning: {
      connectionstatus: 'Uppkopplingsstatus',
      filters: {
        tags: 'Taggar',
        types: 'Typer'
      },
      header: {
        heartbeat: 'Senast aktiv',
        locations: 'Platser',
        name: 'MAC',
        notes: 'Anteckningar',
        online: 'Uppkopplad',
        sim: 'SIM',
        tags: 'Taggar',
        type: 'Typ'
      },
      lastheartbeatformat: 'Senast uppkopplade: {0}',
      nodatatext: 'Inga enheter hittades.',
      nofreedevicesdatatext: 'Inga lediga enheter hittades.',
      query: {
        all: 'Alla',
        offline: 'Nedkopplad',
        online: 'Uppkopplad',
        paired: 'Installerade',
        unpaired: 'Tillgängliga'
      },
      summary: {
        online: 'Uppkoppplade:',
        total: 'Antal:',
        unprovisioned: 'Lediga:'
      },
      tagsplaceholder: 'Taggar...'
    },
    requests: {
      addequipment: {
        failure: 'Misslyckades med att lägga till utrustning.',
        success: 'Lade till utrustning.'
      },
      addequipmenttype: {
        failure: 'Misslyckades med att lägga till utrustningsstyp.',
        success: 'Lade till utrustningstyp.'
      },
      addweatherpoint: {
        failure: 'Misslyckades med att lägga till väderpunkt.',
        success: 'Lade till väderpunkt.'
      },
      createdeviceconfig: {
        failure: 'Misslyckades med att lägga till enhetskonfiguration.',
        success: 'Lade till enhetskonfiguration.'
      },
      createtemplate: {
        failure: 'Misslyckades med att lägga till mall.',
        success: 'Lade till mall.'
      },
      deletelocation: {
        failure:
          'Misslyckades med att ta bort plats. Ligger det andra platser under i hierarkin? Ta bort dom först i så fall.',
        success: 'Tog bort plats.'
      },
      updatedeviceconfig: {
        failure: 'Misslyckades med att uppdatera enhetskonfiguration.',
        success: 'Uppdaterade enhetskonfiguration.'
      },
      updatelocation: {
        failure: 'Misslyckades med att uppdatera plats.',
        success: 'Uppdaterade plats.'
      },
      updatesignals: {
        failure: 'Misslyckades med att uppdatera signaler.',
        success: 'Uppdaterade signaler.'
      },
      updatetemplate: {
        failure: 'Misslyckades med att uppdatera mall.',
        success: 'Uppdaterade mall.'
      },
      updateweatherpoint: {
        failure: 'Misslyckades med att uppdatera väderpunkt.',
        success: 'Uppdaterade väderpunkt.'
      }
    },
    selectdevice: {
      title: 'Välj Energy Manager'
    },
    selectequipment: {
      title: 'Välj utrustning'
    },
    selectlocationdialog: {
      title: 'Välj plats'
    },
    selectsignals: {
      noresult: 'Inga signaler',
      searchplaceholder: 'Sök efter signaler'
    },
    selectsignalsdialog: {
      title: 'Välj signaler'
    },
    selectusers: {
      error: {
        couldnotfindusers: 'Misslyckades med att hämta användare för platsen.'
      }
    },
    signalcategories: {
      loaderror: 'Misslyckades med att hämta signalkategorier',
      title: 'Signalkategorier',
      validationerror: {
        alarm: 'Denna signaltypen kräver kategorin Alarm',
        energymanager: 'Denna signaltypen kräver kategorin Energy Manager'
      }
    },
    signalmapping: {
      action: {
        noaction: 'Ingen handling',
        sendbasedoncycletime: 'Skicka baserat på cykeltid',
        sendonpush: 'Skicka vid push'
      },
      common: {
        name: 'Namn'
      },
      createmapping: 'Lägg till mappning',
      destination: {
        option: {
          timetolive: 'Time to live (sekunder)'
        },
        selectsignals: 'Välj destinationssignaler',
        title: 'Destination'
      },
      destinationsignals: {
        title: 'Destinationssignaler'
      },
      editdialog: {
        add: {
          title: 'Skapa signalmappning'
        },
        edit: {
          title: 'Redigera signalmappning'
        }
      },
      error: {
        destsourceoverlap:
          'Du kan inte ha samma signal i båda källan och destinationen.',
        missingaggregation:
          'Du behöver använda aggregering när du har fler än en signal.',
        nosignals: 'Lägg till signaler för att fortsätta.',
        ttl: 'Detta värdet behöver sättas till minst 60 sekunder.'
      },
      help: {
        action:
          'Ingen handling: Servern gör ingenting. Detta används främst när Energy Managern sköter mappningen.\nSkicka vid push: Servern skriver värden till destinationerna när nya värden kommer till källorna.\nSkicka baserat på cykeltid: Servern uppdaterar värden med ett intervall på x minuter (definieras av cykeltid).',
        cycletime:
          'Hur ofta som servern ska leta efter och skicka värden (i cykelbaserat läge).',
        hoursback:
          'Skicka äldre värden som missades på grund av cykeltid (eller äldre värden som behöver skickas oavsett).',
        hoursforward:
          'Om detta värdet är satt så skickas endast signalvärden så många timmar framåt.',
        ttl: 'Behöver vara satt då man skickar meddelanden till Energy Manager för att inte öka kön i onödan.',
        uselastvaluebeforestartdate:
          'Ignorera startdatumet och hämta senaste värdet innan startdatumet (om det finns).'
      },
      nodata:
        'Det finns inga signalmappningar för denna nod. Välj en annan nod.',
      request: {
        add: {
          failed: 'Misslyckades att lägga till signalmappning.',
          success: 'Signalmappning har lagts till.'
        },
        delete: {
          failed: 'Misslyckades att ta bort signalmappning.',
          success: 'Signalmappning borttagen'
        },
        update: {
          failed: 'Misslyckades att uppdatera signalmappning.',
          success: 'Signalmappning uppdaterad.'
        }
      },
      signalselection: {
        defaultvalue: 'Startvärde',
        timeoffset: 'Tidoffset (timmar)',
        title: 'Signaler'
      },
      source: {
        option: {
          action: 'Handling',
          aggregation: 'Destinationsaggregering',
          cycletime: 'Cykeltid (minuter)',
          hoursback: 'Timmar bakåt',
          hoursforward: 'Timmar framåt',
          onlyUpdateDestinationOnChangedValue:
            'Uppdatera bara destination vid ändrat värde',
          samplinginterval: 'Samplingsintervall',
          uselastvalue: 'Använd senaste värde innan startdatum'
        },
        selectsignals: 'Välj källsignaler',
        title: 'Källa'
      },
      sourcesignals: {
        title: 'Källsignaler'
      },
      title: 'Signalmappning'
    },
    signaltypes: {
      addbuttontext: 'Lägg till typ...',
      addfolderbuttontext: 'Lägg till mapp...',
      addfoldertitle: 'Lägg till mapp',
      addfromexisting: 'Välj från signal...',
      addtypetitle: 'Lägg till signaltyp',
      editbuttontext: 'Redigera...',
      editfoldertitle: 'Redigera mapp',
      edittypetitle: 'Redigera signaltyp',
      folderform: {
        folder: 'Mapp',
        nonemptyerror:
          'Misslyckades med att ta bort mapp. Det verkar som den inte är tom.'
      },
      form: {
        name: 'Namn'
      },
      newfolder: 'Ny mapp',
      newtype: 'Ny signaltyp',
      selectfolder: 'Välj mapp',
      selecttypedialogtitle: 'Välj signaltyp',
      typedescription: 'Typbeskrivning',
      typeform: {
        conflicterror:
          'Misslyckades med att spara signaltyp. Det verkar som det finns en annan typ med samma namn.',
        description: 'Beskrivning',
        folder: 'Mapp',
        nonemptyerror:
          'Misslyckades med att ta bort signaltyp. Det verkar som den används av andra objekt.',
        unit: 'Enhet'
      },
      typename: 'Typnamn'
    },
    tabs: {
      alarmrules: 'Alarmregler',
      alarmtemplates: 'Larm',
      dashboard: 'Dashboard',
      deploytemplates: 'Synkronisera mallar',
      devices: 'Energy Managers',
      ectotable: 'Ectotable',
      equipment: 'Utrustning',
      equipmentgrouptemplates: 'Byggnader',
      equipmenttemplates: 'Utrustning',
      installation: 'Installation',
      integrationaccounts: 'Integrationskonton',
      integrations: 'Integrationer',
      locations: 'Platser',
      managetenants: 'Alla organisationer',
      meteorology: 'Väderpunkter',
      notifications: 'Notiser',
      processmaps: 'Processkartor',
      signalmapping: 'Signalmappningar',
      signaltypes: 'Signaltyper',
      templates: 'Mallar',
      users: 'Användare'
    },
    templates: {
      error: {
        couldnotremove: 'Kunde inte ta bort utrustningstyp.',
        couldnotremoveconflict:
          'Kunde inte ta bort utrustningstyp. Den används fortfarande av andra resurser.'
      },
      removed: 'Mall borttagen'
    },
    tenants: {
      edit: {
        addtitle: 'Lägg till organisation',
        descriptions: {
          database:
            'Namnet på databasen som organisationen kommer använda. När organisationen är skapad kan detta fält ej redigeras.',
          environment:
            'Miljön i organisationen. När organisationen är skapad kan detta fält ej redigeras.',
          id: 'Detta namnet används som ID för din organisation. Måste vara minst 7 tecken långt och kan bara innehålla följande tecken: a-z, 0-9 och -. Får ej börja eller sluta med -. När organisationen är skapad kan detta fält ej redigeras.',
          isdisabled:
            'När en organisation är avaktiverad så är den inte synlig för dess användare. Datan lagras fortfarande och organisationen kan aktiveras igen när som helst.',
          timezone: 'Tidszonen i organisationen. Används inte för tillfället.'
        },
        fields: {
          databasename: 'Databasnamn',
          description: 'Beskrivning',
          environment: 'Miljö',
          id: 'ID',
          iothubhostname: 'Azure IoT Hub-värdnamn',
          isdisabled: 'Avaktiverad',
          name: 'Namn',
          resources: 'Resurser',
          timezone: 'Tidszon'
        },
        sections: {
          resources: 'Resurser',
          tenant: 'Organisation'
        },
        title: 'Redigera organisation'
      },
      editusers: {
        add: 'Lägg till användare',
        addexternaluser: 'Lägg till extern användare',
        addkiduser: 'Lägg till E.ON KID-användare',
        title: 'Redigera användare i organisationen',
        titleformat: 'Redigera användare i {0}'
      },
      manage: 'Hantera alla organisationer',
      roles: {
        admin: 'Administratör',
        read: 'Läsare',
        write: 'Operatör'
      },
      user: {
        confirmexternal:
          'Är du säker på att du vill lägga till en extern e-postadress som konto? Om du använder din KID-variant istället så kommer din inloggning vara säkrare. Vi rekommenderar dig att avbryta och att ange den istället.',
        errors: {
          invalidemail: 'Felaktigt e-postformat.',
          invalidkid: 'Felaktigt E.ON KID-användarnamn.',
          invalidkidemail: 'Använd inte externa konton med E.ON KID-adresser.'
        },
        fields: {
          accounttype: 'Kontotyp',
          accounttypeformat: 'Kontotyp: {0}',
          description: 'Beskrivning',
          eontype: 'E.ON',
          kid: 'E.ON KID-användarnamn',
          name: 'Namn',
          role: 'Roll',
          standardtype: 'Standard',
          username: 'E-post'
        },
        helptext: {
          external:
            'En vanlig e-postadress. Externa konton ska endast läggas till i undantagsfall, KID-konton är säkrare.',
          kid: 'Exempel på E.ON KID-användarnamn: A12345@eon.com. Skriv endast första delen (före @).'
        },
        nodisplayname:
          'Detta är första gången användaren läggs till i en organisation. Vänligen ange ett namn för användaren.',
        nonkidemail:
          'Du har angivit en E.ON-address, men den är inte i ett giltigt KID-format. Är du säker på att du vill använda denna e-postadressen? Om du använder din KID-variant istället så kommer din inloggning vara säkrare. Vi rekommenderar dig att avbryta och att ange den istället.',
        usertypes: {
          external: 'Extern användare',
          kid: 'E.ON KID-användare'
        }
      }
    },
    toolselector: {
      placeholder: 'Välj verktyg'
    },
    updatedbuilding: {
      success: {
        noerrorsformat: 'Uppdaterade byggnad {0}.'
      }
    },
    updatedlocation: {
      success: {
        noerrorsformat: 'Uppdaterade plats {0}.'
      }
    },
    updatedsite: {
      success: {
        noerrorsformat: 'Uppdaterade plats {0}.'
      }
    },
    users: {
      access: {
        activitylogs: 'Aktivitetsloggar',
        adminlocation: 'Admin plats',
        adminuser: 'Administrera användare',
        alarm: 'Larm',
        device: 'Enhet',
        devicefiles: 'Enhetsfiler',
        diagnostic: 'Diagnostik',
        equipment: 'Utrustning',
        location: 'Plats',
        meteorology: 'Meteorologi',
        powercontrol: 'Effektstyrning',
        remoteoptimisation: 'Fjärroptimering',
        schedulesrunner: 'Schemaläggare',
        telemetry: 'Telemetri',
        tools: 'Verktyg'
      },
      accessrights: {
        admin: 'Admin',
        custom: 'Anpassad',
        hasaccess: 'Har åtkomst',
        none: 'Ingen',
        user: 'Användare'
      },
      adduser: 'Lägg till ny användare',
      deleteuser: 'Ta bort användare',
      edituser: 'Redigera användare',
      error: {
        loadfailed: 'Misslyckades med att läsa in användarlistan.',
        usernameconflict: 'E-posten är redan kopplad till en annan användare.'
      },
      firstname: 'Förnamn',
      lastname: 'Efternamn',
      name: 'Namn',
      password: 'Lösenord',
      passwordhelp:
        'Lösenordet kräver minst en siffra, en stor bokstav, en liten bokstav och en symbol. Det måste också vara mellan 8 och 16 tecken långt.',
      request: {
        createduser: 'Lade till användare',
        createuserfailed: 'Misslyckades att lägga till användare',
        deleteduser: 'Tog bort användare',
        deleteuserfailed: 'Misslyckades att ta bort användare',
        getresourcesfailed: 'Misslyckades att hämta användarresurser',
        getrolesfailed: 'Misslyckades att hämta användarroller',
        updateduser: 'Uppdaterade användaren',
        updateuserfailed: 'Misslyckades att uppdatera användaren'
      },
      role: 'Roll',
      selectaccess: {
        access: 'Rättighet',
        name: 'Namn'
      },
      selectaccessrights: 'Välj rättigheter',
      selectcustom: 'Anpassad...',
      title: 'Hantera användare',
      username: 'E-post'
    }
  },
  alarm: {
    details: {
      acknowledged: 'Kvitterad',
      active: 'Aktiv',
      created: 'Tillagd',
      hidefullhistory: 'Dölj fullständig historik',
      history: 'Historik',
      name: 'Namn',
      no: 'Nej',
      notacknowledged: 'Ej kvitterad',
      showfullhistory: 'Visa fullständig historik',
      user: 'Användare',
      yes: 'Ja'
    }
  },
  alarms: {
    acknowledge: {
      action: 'Kvittera',
      all: {
        error:
          'Fel uppstod när alla larm skulle kvitteras, vänligen prova igen senare.',
        prompt:
          'Är du säker på att du vill kvittera ALLA larm? Du behöver skriva in en kommentar.',
        promptwithbuildingstatuses:
          'Är du säker på att du vill kvittera ALLA larm med byggnadstatus {0}? Du behöver skriva in en kommentar.',
        success: 'Lyckades kvittera alla larm.'
      },
      button: 'Kvittera larm',
      comment: 'Kommentar'
    },
    acknowledgeallstatus: {
      failure: 'Misslyckades med att kvittera alla alarm',
      success: 'Kvitterade alla alarm'
    },
    acknowledgebutton: 'Kvittera',
    acknowledged: 'Kvitterad',
    acknowledgedate: 'Kvitteringsdatum',
    acknowledgedby: 'Kvitterad av',
    acknowledgedtext: 'Kvitterad',
    acknowledgedwithoutcomment: 'Kvitterad utan kommentar',
    acknowledgeformat:
      'Är du säker på att du vill kvittera larmet {0} för {1}?',
    acknowledgestatus: {
      failure: 'Misslyckades med att kvittera alarm',
      success: 'Kvitterade alarm'
    },
    acknowledgetitle: 'Kvittera larm',
    activationdate: 'Aktiveringsdatum',
    active: 'Aktivt',
    alarmtriggeredformat: 'Alarm aktiverat: {0}',
    category: {
      activeacknowledged: 'Aktiva kvitterade',
      activenotacknowledged: 'Aktiva ej kvitterade',
      inactivenotacknowledged: 'Inakiva ej kvitterade'
    },
    clearall: 'Rensa allt',
    columns: {
      acknowledge: 'Kvittering',
      ackstatus: 'Kvitteringsstatus',
      comment: 'Kommentar',
      description: 'Beskrivning',
      equipment: 'Utrustning',
      firstactivedate: 'Startdatum',
      location: 'Plats',
      needsacknowledgement: 'Behöver kvittering',
      severity: 'Klass',
      signal: 'Signal',
      status: 'Status'
    },
    comment: 'Kommentar',
    commentplaceholder: 'Kommentar (frivillig)',
    datesubtitleformat: 'vid {0}',
    description: 'Beskrivning',
    equipmentname: 'Utrustning',
    eventid: 'Händelse',
    events: 'Händelser',
    filter: 'Filtrera',
    filterall: 'Alla',
    filterparams: {
      buildingstatus: 'Byggnadsstatus'
    },
    help: {
      title: 'Larm'
    },
    historymodal: {
      titleformat: 'Historik - {0}'
    },
    inactive: 'Inaktivt',
    noalarmswerefoundinthecurrentlocation:
      'Inga larm hittades på den nuvarande platsen.',
    nodata: 'Inga larm hittades på den nuvarande platsen.',
    nodatainsection: 'Denna kategorin saknar larm för nuvarande sidan.',
    notacknowledged: 'Ej kvitterad',
    notacknowledgedstatus: '-',
    numactivealarms: 'Antal aktiva larm',
    numberofactiveformat: '+ {0} fler',
    page: {
      heading: {
        acknowledgeallalarms: 'Kvittera alla',
        activealarms: 'Aktiva',
        allalarms: 'Historik'
      }
    },
    section: {
      eventlist: 'Händelselista',
      signalclusters: 'Aktuella alarm'
    },
    severityMax: 'Maximal klass',
    severityMin: 'Minimal klass',
    title: 'Larm',
    unacknowledgedtext: 'Ej kvitterad'
  },
  authenticate: {
    authentification: {
      failure: 'Autentisering misslyckades'
    },
    login: {
      again: 'Försök logga in igen'
    }
  },
  buildingstatus: {
    created: 'Tillagd',
    production: 'Produktion',
    testing: 'Test',
    timetype: {
      after: 'Efter',
      before: 'Före',
      exact: 'Exakt'
    }
  },
  common: {
    actionmodal: {
      cancel: 'Avbryt'
    },
    add: 'Lägg till',
    affected: 'Påverkad',
    all: 'Alla',
    allowednodetypes: 'Tillåtna nodtyper',
    and: 'och',
    back: 'Tillbaka',
    baserequesterror:
      'Fel vid anrop till server. Några funktioner kanske inte fungerar som avsett.',
    cancel: 'Avbryt',
    clear: 'Rensa',
    clearvalues: 'Rensa värden',
    clicktoedit: 'Klicka för att redigera',
    close: 'Stäng',
    color: 'Färg',
    column: {
      error: {
        render: "Din renderingsfuntion måste returnera RowItem, men fick '{0}'."
      }
    },
    columns: 'Kolumner',
    compare: {
      equal: 'Lika med',
      greaterthan: 'Större än',
      greaterthanorequal: 'Större än eller lika med',
      lessthan: 'Mindre än',
      lessthanorequal: 'Mindre än eller lika med',
      notequal: 'Skilt från'
    },
    confirmdelete: {
      message: 'Är du säker på att du vill ta bort {0}?',
      title: 'Bekräfta borttagning'
    },
    copytoclipboard: {
      copy: 'Kopiera',
      defaultitem: 'värde',
      failure: 'Misslyckades med att kopiera värde.',
      failureformat: 'Misslyckades med att kopiera {0}.',
      success: 'Kopierade värde.',
      successformat: 'Kopierade {0}.',
      tooltip: 'Kopiera värde'
    },
    dBm: 'dBm',
    datatable: {
      error: 'Fel uppstod vid hämtning, vänligen försök igen.',
      loadmore: 'Läs in mer',
      nodata: 'Ingen data hittades.'
    },
    date: 'Datum',
    dateinput: {
      from: 'Från:',
      to: 'Till:'
    },
    delete: 'Ta bort',
    description: 'Beskrivning',
    disable: 'Avaktivera',
    disabled: 'Ej aktiverat',
    discardchanges: 'Kasta ändringar',
    displayname: 'Visningsnamn',
    done: 'Klar',
    duplicate: 'Lägg till kopia',
    edit: 'Redigera',
    enable: 'Aktivera',
    enabled: 'Aktiverat',
    environmenterror: 'Kunde inte hämta konfiguration för nuvarande miljö.',
    error: 'Fel',
    exactmatch: 'Exakt matchning',
    hours: 'timmar',
    iconbutton: {
      add: {
        title: 'Lägg till'
      },
      help: {
        title: 'Hjälp'
      },
      search: {
        title: 'Sök'
      }
    },
    information: 'Information',
    loading: 'Laddar...',
    loadingtextlong: 'Laddar, vänligen vänta...',
    name: 'Namn',
    networkfailure: {
      error: 'Kunde inte utföra begärna på grund av problem med nätverket.'
    },
    next: 'Nästa',
    no: 'Nej',
    nodename: 'Namn',
    none: 'Ingen',
    notavailable: 'n/a',
    ok: 'OK',
    optional: 'Valfri',
    previous: 'Föregående',
    priority: 'Prioritet',
    range: {
      maxx: 'Maximum X',
      maxy: 'Maximum Y',
      minx: 'Minimum X',
      miny: 'Minimum Y'
    },
    reason: 'Orsak',
    redo: 'Gör om',
    save: 'Spara',
    search: {
      placeholder: 'Sök...'
    },
    selectcolor: 'Välj färg',
    selectsignal: 'Välj signal...',
    share: 'Dela...',
    shorthours: 'tim',
    status: 'Status',
    tags: 'Taggar',
    undo: 'Ångra',
    unit: 'Enhet',
    unknown: 'Okänt',
    unknownerror: 'Okänt fel.',
    unsavedchanges: 'Osparade ändringar',
    unsavedchangesmessage: 'Du har osparade ändringar. Vill du spara dom?',
    users: 'Användare',
    yes: 'Ja'
  },
  creatableselect: {
    create: 'Lägg till "{0}"',
    createnew: 'Lägg till ny...',
    placeholder: 'Välj...'
  },
  curves: {
    add: 'Lägg till kurva'
  },
  dashboard: {
    addpanel: {
      error: 'Misslyckades med att kopiera panel.',
      successformat: 'Kopierade panel till {0}.'
    },
    addpaneltopersonalbutton: 'Kopiera till egen dashboard',
    addpaneltopersonaltitleformat: 'Kopiera {0} till egen dashboard',
    alarms: {
      showall: 'Visa alla',
      title: 'Larm'
    },
    confirmsave: {
      message:
        'Det finns flera osparade dashboards. Vill du spara alla eller bara denna?',
      saveall: 'Spara alla',
      savecurrent: 'Spara nuvarande',
      title: 'Spara alla dashboards?'
    },
    enterfullscreen: 'Helskärmsläge',
    error: {
      nodashboardsfound: 'Inga dashboards hittades för denna plats.',
      personaldashboards: 'Ett fel uppstod när vi läste in dina dashboards.',
      personaldashboardsempty:
        'Inga dashboards hittades. Tryck på + för att lägga till en.',
      unsupportedpaneltype: 'Felaktig paneltyp.'
    },
    exitfullscreen: 'Stäng helskärmsläge',
    map: {
      namelabel: 'Namn:',
      streetlabel: 'Gata:'
    },
    missingdashboards: 'Den här dashboardsamlingen har inga dashboards',
    newpaneltitle: 'Paneltitel',
    newuserdashboard: {
      description: 'Beskrivning',
      name: 'Namn',
      title: 'Lägg till nytt eget dashboard'
    },
    selectdashboard: 'Välj dashboard...',
    selectuserdashboardplaceholder: 'Välj dashboard...',
    selectuserdashboardtitle: 'Dashboard',
    showingraphs: 'Visa i grafer',
    timerange: {
      custom: 'Eget',
      durationheader: 'Tidsområde',
      positionheader: 'Position i tidsområde'
    },
    titlewithchangesformat: '{0} (osparad)',
    usefixedlocation: 'Använd låst plats',
    usefixedlocationhelpformat: 'Aktivera för att alltid hämta data från {0}.'
  },
  durationtime: {
    daysformat: '{0} dagar',
    hoursformat: '{0} timmar',
    lessthanoneminute: 'mindre än 1 minut',
    minutesformat: '{0} minuter',
    onehour: '1 timme',
    oneminute: '1 minut'
  },
  ectoplanner: {
    addnewversion: 'Lägg till ny version',
    balancinghelp: {
      disclaimer:
        'Observera att detta bara är en uppskattning och att den faktiska kapaciteten kan variera.',
      statusinsufficientcooling:
        'Det verkar finnas otillräcklig kylkapacitet i nätet. Kylkapaciteten är ungefär {0}%.',
      statusinsufficientheating:
        'Det verkar finnas otillräcklig värmekapacitet i nätet. Uppvärmningskapaciteten är cirka {0}%.',
      statusinsufficientheatingandcooling:
        'Det verkar finnas otillräcklig värme- och kylkapacitet i nätet. Värmekapaciteten är cirka {0}% och kylkapaciteten är cirka {1}%.',
      statusok: 'Det verkar finnas tillräcklig termisk kapacitet i nätet.'
    },
    balancingunit: {
      maximumcoolingpowerbu: 'Maximal kyleffekt för BE: {0} kWth',
      maximumheatingpowerbu: 'Maximal värmeeffekt för BE: {0} kWth',
      recommendedpipediameter: 'Rekommenderad rördiameter vid BE: DN{0}-',
      resultingflowvelocity: '(resulterande flödeshastighet: {0} m/s)'
    },
    buildings: {
      add: 'Lägg till byggnad',
      edit: 'Edit building',
      energyoverview: {
        helptext:
          'When selecting demand you will get all energy sources for that category (space heating and domestic hot water for heating, space cooling and process cooling for cooling) - balancing is not included. When you select import, you will get the heating or cooling taken from the ectogrid (with balancing included).',
        label: 'Energy overview'
      }
    },
    buildingsummary: {
      balancedloadsbetweenbuildings: 'Balanserade laster mellan byggnader',
      balancingunitloads: 'Energicentralen laddar',
      buildingdemands: 'Byggnadskrav',
      buildingimports: 'Byggnadsimport',
      button: 'Aggregated building results...',
      coolequipment: {
        chiller: 'Chiller',
        directcooling: 'Direct cooling',
        peakchiller: 'Peak chiller'
      },
      coolingcapacity: 'Kylningskapacitet',
      coolingdemand: 'Kylningsbehov',
      coolingimportcapacity: 'Kylimportkapacitet',
      coolingloaddemand: 'Krav på kylbelastning',
      electricityimportcapacity: 'Elimportkapacitet',
      energyimports: {
        annualimport: 'Annual import',
        coolingfrom: 'Cooling from ectogrid network',
        demandfooter:
          '{0} waste heat from the cooling system is directly used at the evaporator of the heat pump. As a result, the heat and cold import from the network is reduced by {1}.',
        grid: 'Electricity grid',
        heatfrom: 'Heat from ectogrid network',
        name: 'Name',
        peakimport: 'Peak import'
      },
      graphnames: {
        cooling: 'Cooling',
        heating: 'Heating'
      },
      heatcapacity: 'Värmekapacitet',
      heatdemand: 'Värmebehov',
      heatequipment: {
        electricheatingrod: 'Electric heating rod',
        hotwaterhp: 'Hot water HP',
        spaceheatinghp: 'Space heating HP'
      },
      heatimportcapacity: 'Värmeimportkapacitet',
      heatloaddemand: 'Krav på värmebelastning',
      importedcooling: 'Importerad kylning',
      importedelectricity: 'Importerad el',
      importedheat: 'Importerad värme',
      importfromgrid: 'Importera från nät',
      moreinfo: 'More information...',
      rows: {
        balcoolloadsbldgs: 'Balanced cooling demands in buildings',
        balcoolloadsnetw: 'Balanced cooling demands in network',
        balheatloadsbldgs: 'Balanced heat demands in buildings',
        balheatloadsnetw: 'Balanced heat demands in network',
        colddemallbldgs: 'Cold demand of all buildings',
        coldgainsnet: 'Cooling gains (net)',
        coldimportallbldgs: 'Cold import of all buildings',
        coolgenpeakchillers: 'Cooling generation of air-cooled chillers',
        coolloadatenergyhub: 'Cold feed-in at energy center',
        coollossescoolingnetw: 'Thermal losses of cooling network',
        elecdemallbldgs: 'Electricity demand of all buildings',
        elecdemandchillers: 'Electricity demand of chillers',
        elecdemandelheatingrods: 'Heat generation of heating rods',
        elecdemandheatpumps: 'Electricity demand of heat pumps',
        elecdembes: 'Elec. demand of decentral units',
        elecimportallbldgs: 'Electricity import of all buildings',
        elecloadatenergyhub: 'Electricity load at energy center',
        energyimportallbuildings: 'Energy import of all buildings',
        heatdemallbldgs: 'Heat demand of all buildings',
        heatgainscoldnet: 'Heat gains (net)',
        heatgainsheatnet: 'Heat gains (net)',
        heatimportallbldgs: 'Heat import of all buildings',
        heatloadatenergyhub: 'Heat feed-in at energy center',
        heatlossesheatingnetw: 'Heat losses of heating network',
        heatlossnet: 'Heat losses (net)',
        loadatenergyhub: 'Load at energy center',
        pumpwork: 'Pump work',
        sharedhw: 'Share of domestic hot water',
        shareemobility: 'Share e-mobility',
        shareplugloads: 'Share plug loads',
        shareprocesscooling: 'Share process cooling',
        sharespacecooling: 'Share space cooling',
        sharespaceheating: 'Share space heating'
      },
      sections: {
        balancing: 'Balancing of thermal demands',
        cooling: 'Cooling',
        coolingpower: 'Cooling power in buildings',
        electricity: 'Electricity',
        energydemands: 'Energy demands',
        energyimports: 'Energy imports after internal balancing',
        feedin: 'Feed-in of energy center',
        heating: 'Heating',
        heatingpower: 'Heating power in buildings',
        loadprofiles: 'Load profiles in Ectogrid'
      },
      sectiontooltips: {
        balancing:
          'The visualization shows the demand balancing of heating and cooling demands in buildings and between buildings through the network. For more information, tap the info button at the bottom.',
        energyimports:
          'Heat, cold and electricity imports of the building from the thermal and electric network. The energy import depends on the energy system of the building.',
        feedin:
          'The visualization shows the monthly feed-in of heat and cold from the energy center into the network.'
      },
      series: {
        balanced: 'Balanserad',
        coldfed: 'Cold fed into the network by the energy center',
        electricity: 'Elektricitet',
        electricitydemand:
          'Electricity demand of decentral heat pumps,<br/> heating rods and chillers',
        electricityimport: 'El importerad',
        heatdemand: 'Heat demand at the evaporator of heat pumps',
        heatfed: 'Heat fed into the network by the energy center',
        heatimported: 'Heat imported from the Ectogrid network',
        totalspacecooling: 'Total space cooling and process cooling demand',
        totalspaceheating: 'Total space heating and domestic hot water demand',
        wasteheatdemand:
          'Waste heat at condenser of chillers<br/> or heat exchangers',
        wasteheatfed: 'Waste heat fed from buildings into the network'
      },
      tablecolumns: {
        annualdemand: 'Annual demand',
        annualenergy: 'Annual energy',
        cold: 'Cold',
        cop: 'COP',
        electricity: 'Electricity',
        electricitydemand: 'Electricity demand',
        floorarea: 'Floor area',
        generatedenergy: 'Generation',
        heat: 'Heat',
        label: 'Name',
        machinecapacity: 'Machine capacity',
        maxpower: 'Max power',
        name: 'Name',
        peakdemand: 'Peak demand',
        peakpower: 'Peak power',
        processcooling: 'Process cooling',
        seasonalcop: 'SCOP',
        spacecooling: 'Space cooling',
        spaceheating: 'Space heating',
        technology: 'Technology',
        thermalcapacity: 'Thermal capacity'
      },
      tabletooltips: {
        cold: 'Cooling energy imported from the network to cover net cooling demands of the building (after demand balancing in building).',
        electricity:
          'Electricity imported from the electricity grid to cover electricity demands for plug loads, e-mobility and decentral heat pumps, heating rods and chillers.',
        energyimportallbuildings:
          'Total heat, cold and electricity import of buildings. The heat/cold import describes the heat/cold transferred from the network to the building. The balancing of simultaneous heating and cooling demands in the building reduces the imported heating and cooling energy. The electricity import results from the electricity demands of the building (plug loads and e-mobility) as well as the electricity demand of the building energy system (for example for heat pumps). The power values (in kW) describe the annual peak load.',
        heat: 'Heat imported from the network to cover net heating demands of the building (after demand balancing in building).',
        loadatenergyhub:
          'Heat and cold feed-in at the energy center into the network (diversity factor and balancing of heating and cooling loads included). The electricity demand is the sum of the electricity imports of all buildings (plug loads, e-mobility and electricity for decentral heat pumps, heating rods and chillers) plus the pump work. The power values (in kW) describe the annual peak load.'
      },
      title: 'Aggregated building results',
      tooltips: {
        balcoolloadsbldgs:
          'If one building imports heat from the network and at the same time another building imports cold (this means feeds heat into the network), these demands partially balance each other out and thus, reduce the feed-in at the energy center.',
        balcoolloadsnetw:
          'If one building imports heat from the network and at the same time another building imports cold (this means feeds heat into the network), these demands partially balance each other out and thus, reduce the feed-in at the energy center.',
        balheatloadsbldgs:
          'Simultaneous heating and cooling demands in buildings can be partially balanced out by heat recovery: Waste heat from the cooling supply is used at the evaporator of the heat pump, thus reducing the buildings heat and cold demand from the network.',
        balheatloadsnetw:
          'If one building imports heat from the network and at the same time another building imports cold (this means feeds heat into the network), these demands partially balance each other out and thus, reduce the feed-in at the energy center.',
        coldgainsnet:
          'Annual energy by which the cooling load at the energy center decreases (due to heat losses to the ground and diversity effects).',
        coldimportallbldgs:
          'Total heat import of all buildings. The heat import describes the heat transferred from the network to the building.',
        coolgenpeakchillers:
          'Air-cooled chillers cover peak loads of the cooling demand in the building. This reduces the amount of cooling that the buildings need to import from the heat network.',
        coolloadatenergyhub:
          'Cooling load at the energy center for the supply of the network. Here, the diversity factor of the network as well as the balancing of simultaneous heating and cooling loads in the network is included. The load balancing in the network reduces the residual load at the energy center.',
        coollossescoolingnetw: 'Thermal losses of cooling network',
        elecdemandelheatingrods:
          'Electric heating rods cover peak loads of the space heating demand in buildings. This reduces the amount of heat that needs to be imported from the heating network by the buildings.',
        elecdemandheatpumps:
          'By using water-to-water heat pumps in the buildings, part of the heat demand is covered by the heat pump electricity. This reduces the amount of heat that needs to be imported from the heat network by the buildings.',
        elecdembes:
          'Annual electricity demand of all building energy systems, including heat pumps, chillers or electric heating rods.',
        elecimportallbldgs:
          'Total electricity import of all buildings. The electricity import results from the electricity demands of the building (plug loads and e-mobility) as well as the electricity demand of the building energy system (for example for heat pumps).',
        elecloadatenergyhub:
          'The electricity load at the energy center is the sum of the electricity imports of all buildings (plug loads, e-mobility and electricity  for the building energy systems including decentral heat pumps) plus the pump work of the network.',
        heatgainscoldnet:
          'Annual energy by which the cooling load at the energy center increases (due to a heat flow from the ground and diversity effects).',
        heatgainsheatnet:
          'Annual energy by which the heat load at the energy center decreases (due to heat gains from the ground and diversity effects).',
        heatimportallbldgs:
          'Total heat import of all buildings. The heat import describes the heat transferred from the network to the building. The balancing of simultaneous heating and cooling demands in the building reduces the imported heating and cooling energy.',
        heatloadatenergyhub:
          'Heating load at the energy center for the supply of the network. Here, the diversity factor of the network as well as the balancing of simultaneous heating and cooling loads in the network is included. The load balancing in the network reduces the residual load at the energy center.',
        heatlossnet:
          'Annual energy by which the heat load at the energy center increases (due to heat losses to the ground and diversity effects).'
      },
      xaxis: {
        demandofallbuildings: 'Demand of all buildings',
        demandofdecentralunits: 'Demand of decentral units',
        energycentersupply: 'energy center supply',
        importofallbuildings: 'Import of all buildings'
      }
    },
    calculate: 'Beräkna',
    calculateerror: 'Det gick inte att starta beräkningen',
    calculatetitle: 'Starta beräkning',
    calculations: {
      builds: {
        date: 'Datum',
        description: 'Beskrivning',
        status: 'Status'
      },
      calculating: 'Beräknar...',
      calculationfailed: 'Beräkningen misslyckades',
      copylinktoclipboard: 'Kopiera länken till urklipp',
      description: 'Beskrivning',
      editdescription: 'Redigera beskrivning',
      failedtoloadbuilds: 'Det gick inte att läsa in beräkningar',
      formerrorsetting: 'Miljö',
      formerrorstitle: 'Rätta fel före beräkning',
      genericbuildname: 'denna beräkning',
      newchangesinfo:
        'Du har gjort ändringar i projektet. Tryck på beräkna för att se uppdaterade resultat.',
      nosolutionfound:
        'Kunde inte hitta en godtagbar lösning. Vanligtvis beror detta på att energicentret inte täcka kunde täcka ectogridets termiska behov. Prova att lägga till mer värme- och kylkapacitet, t.ex. gaspanna.',
      notcalculated: 'Ingen beräkning',
      notcalculatedinfo:
        'Projektet är inte beräknat ännu. Tryck på "Beräkna" för att starta beräkningen.',
      status: {
        error: 'Fel',
        no_solution_found: 'Ingen lösning hittades',
        results_done: 'Klar',
        timedout: 'Timeout'
      },
      title: 'Ectoplanner-beräkning'
    },
    confirmdeleteproject:
      'Är du säker på att du vill ta bort det här projektet? Alla versioner kommer också att raderas.',
    confirmdeleteversion:
      'Är du säker på att du vill ta bort den här versionen?',
    confirmdisabletech: {
      removepassivebalancing:
        'Om den passiva balanseringstanken avmarkeras kommer ytterligare 10 000 euro automatiskt att läggas till projektets investeringskostnad, som täcker den hydrauliska anslutningen mellan varma och kalla rör, inklusive regulatorer och sensorer.',
      titleformat: 'Är du säker på att du vill avmarkera {0}?'
    },
    currentversion: 'Aktuell version',
    datacopyformat: 'Data kommer att kopieras från "{0}".',
    download: 'Ladda ner',
    energygraphs: {
      breakpointchiller: 'Breakpoint chiller backup',
      breakpointheatpump: 'Breakpoint heat pump backup',
      energydemand: 'Energibehov',
      energyoptions: {
        cop: 'COP',
        demand: 'Byggnadsbehov',
        import: 'Import från nät'
      },
      energytype: 'Grafdata',
      plotoptions: {
        annual: 'Årlig',
        durationcurve: 'Varaktighetskurva',
        monthly: 'Månatlig'
      },
      plottype: 'Plottyp'
    },
    export: 'Exportera',
    exportairtemp: 'Exportera lufttemperatur',
    exportcitydata: 'Exportera stadsdata',
    exportcsv: 'Exportera CSV',
    exportformdata: 'Exportera data med beräkningar',
    exportproject: 'Exportera data',
    failedtoloadproject: 'Det gick inte att läsa in projektet',
    fileeditor: {
      change: 'Ändra...',
      failedtoloadfile: 'Det gick inte att ladda filen',
      uploaderror: 'Det gick inte att ladda upp ny fil'
    },
    form: {
      accutankbu: {
        enabled: {
          helptext:
            'Passiv balanstank balanserar kvarvarande belastning från ectogrid.'
        },
        maxvol: {
          label: 'Största volym'
        },
        minvol: {
          label: 'Minsta volym'
        }
      },
      aquiferstoragebu: {
        enabled: {
          helptext:
            'Akviferlager för att injicera och utvinna värme till/från mark (säsongslagring).'
        },
        maxchenergy: {
          helptext: 'Maximal mängd värme lagras i marken.',
          label: 'Maximal värmeenergi'
        },
        maxchpower: {
          helptext: 'Maximal termisk effekt injiceras till jord.',
          label: 'Maximal termisk effekt'
        },
        pumpwork: {
          helptext:
            'Ytterligare elbehov för varje kWh av värme som utvinns från eller injiceras till marken.',
          label: 'Pumparbete'
        },
        roundeff: {
          helptext:
            'Förhållandet mellan extraherad och injicerad värme från/till marken.',
          label: 'Effektivitet tur och retur'
        },
        roundeffenabled: {
          label: 'Round trip calculation'
        },
        roundeffmax: {
          label: 'Round trip efficiency max.'
        },
        roundeffmin: {
          label: 'Round trip efficiency min.'
        }
      },
      batterybu: {
        enabled: {
          helptext:
            'Batteriet kan laddas med överskottseffekt från kraftvärmeaggregat eller solcellsinstallation.'
        }
      },
      building: {
        bldgspeccops: {
          label: 'Använd byggnadsspecifika COP-värden'
        },
        buildingformat: 'Byggnad {0}',
        calcdemands: {
          label: 'Uppskatta behov'
        },
        coolingtimeseries: {
          label: 'Kylbehov (kWh/h)'
        },
        floorarea: {
          label: 'Uppvärmd/kyld golvyta'
        },
        heatingtimeseries: {
          label: 'Rumsuppvärmning (kWh/h)'
        },
        machinecapacitytooltip:
          'Maskinkapacitet är maskinens dimensionerade kapacitet. I de flesta fall blir det samma sak med den maximala värme- eller kylproduktionen. Maskinkapaciteten kan dock skilja sig från max värme- eller kylproduktion i transversala värmepumpar.',
        params: {
          anndemspec: {
            helptext:
              'Annual demand of energy kind per square meter of floor area.',
            label: 'Specific annual demand'
          },
          anndemtot: {
            helptext: 'Total annual demand of energy kind.',
            label: 'Total annual demand'
          },
          beslowexelheaterenabled: {
            helptext:
              'If enabled, an electric heating rod covers the peak heat loads. For the heating rod, an efficiency of 100 % is assumed.',
            label: 'Electric heating rod'
          },
          beslowexelheatermaxload: {
            helptext:
              'Share of the maximum heating load that is covered by the heating rod (bivalent-parallel operation)',
            label: 'Coverage of maximum heat load'
          },
          beslowexhpcop: {
            label: 'Constant COP value'
          },
          beslowexhpdhwcop: {
            label: 'Domestic hot water (heat pump) COP'
          },
          beslowexpeakchillercop: {
            helptext:
              'Coefficient of performance of the cooling supply (air-cooled chiller and cooling tower)',
            label: 'COP of the cooling generation'
          },
          beslowexpeakchillerenabled: {
            helptext:
              'If enabled, an air-cooled chiller is installed to cover peak cooling loads. The waste heat of the chiller is released into the environment using a cooling tower. The chiller reduces the peak loads of the thermal network in summer.',
            label: 'Air-cooled chiller for peak-loads'
          },
          beslowexpeakchillermaxload: {
            helptext:
              'Share of the maximum cooling load that is covered by the air-cooled chiller (bivalent-parallel operation)',
            label: 'Coverage of maximum cooling load'
          },
          buildingsubtype: {
            label: 'Building subtype'
          },
          buildingtype: {
            helptext:
              'Overall category of building. Use subtype to specify further. Changing the building types will insert default values and affect heating and cooling profile calculations.',
            label: 'Building type'
          },
          carnoteta: {
            label: 'Exergy efficiency'
          },
          carnotetachiller: {
            helptext:
              'The exergy efficiency is used as pre-factor of the Carnot efficiency. Chillers usually have exergy efficiencies between 40 (small-scale, low efficiency) and 60 (large-scale, high efficiency).'
          },
          carnotetahp: {
            helptext:
              'The exergy efficiency is used as pre-factor of the Carnot efficiency. Heat pumps usually have exergy efficiencies between 40 (small-scale, low efficiency) and 60 (large-scale, high efficiency).'
          },
          considercurve: {
            helptext:
              'If enabled, the supply temperature of the heating system in the building decreases linearly as the outdoor air temperature increases (otherwise it is constant all year round). When the heating curve is considered, a supply temperature of 25 °C is assumed at an outdoor temperature of 15 °C (or higher). The maximum supply temperature is reached when the outdoor air temperature is at its minimum (coldest hour of the year).',
            label: 'Consider supply temperature curve'
          },
          consumptionemob: {
            label: 'Route consumption'
          },
          cop: {
            label: 'COP calculation'
          },
          copoptions: {
            carnot: 'Calculate COP (Carnot)',
            const: 'Constant COP',
            productdata: 'Product data',
            upload: 'Upload COP time series'
          },
          customprofile: {
            helptext:
              'Specify a custom demand profile for this energy kind. It will replace the algorithm that calculates a profile based on the input values.',
            label: 'Custom demand profile'
          },
          distanceemob: {
            label: 'Charged driving distance per day'
          },
          enableformat: 'Enable {0}',
          enablehelptext:
            'If enabled, the demand for this energy kind is considered in the calculations.',
          energysystem: {
            copoptions: {
              helptext:
                'Specify the way COP (Coefficent Of Performance) is calculated for the heat pump or chiller. You can choose to calculate using the Carnot formula, a constant value or by specifying a product. If you specify a product the COP is calculated with manufacturer data based on the standard EN 14825.'
            },
            sections: {
              coolingsupply: 'Cooling supply',
              dhw: 'Domestic hot water (heat pump)',
              spaceheating: 'Space heating (heat pump)'
            }
          },
          floorarea: {
            helptext:
              'Heated or cooled floor area. The floor area is only used to convert area-specific demand values (kWh/m²) into annual demands (MWh) and vice versa.',
            label: 'Floor area'
          },
          fullloadhours: {
            helptext: 'Full load hours of energy generation.',
            label: 'Full load hours'
          },
          individualcosts: {
            helptext:
              'Enable individual costs for the installed energy technologies in this building. If not set, it will use the default values from the Model section.',
            label: 'Individual costs'
          },
          lowexcc: {
            label: 'Chiller'
          },
          lowexcoolingoption: {
            helptext:
              'Specifies the supply type of the chiller. With passive cooling, the cooling demand is covered by a heat exchanger (no electricity needed). The waste heat is fed into the network. With chiller (water-cooled) chillers cover the cooling demands and feed the waste heat into the network. A chiller may be necessary if the network temperatures are too high to provide the cooling directly with a heat exchanger. Learn',
            label: 'Supply type'
          },
          lowexdrc: {
            label: 'Passive cooling (heat exchanger)'
          },
          peakdemspec: {
            helptext: 'Specific maximum load for energy kind.',
            label: 'Specific peak demand'
          },
          peakdemtot: {
            helptext: 'Maximum load for energy kind.',
            label: 'Total peak demand'
          },
          productdata: {
            formatstring: 'COP: {0} (for temperature lift from {1} to {2} °C)',
            label: 'Product'
          },
          seasonbegin: {
            helptextcooling:
              'Begin of the cooling season (typically in spring).',
            helptextheating:
              'Begin of the heating season (typically in autumn).',
            label: 'Beginning of season'
          },
          seasonend: {
            helptextcooling: 'End of the cooling season (typically in autumn)',
            helptextheating: 'End of the heating season (typically in spring).',
            label: 'End of season'
          },
          sections: {
            building: 'Building',
            cooling: 'Cooling',
            dhw: 'Domestic hot water',
            electricity: 'Electricity',
            emob: 'E-mobility',
            energysystem: 'Substation equipment',
            heating: 'Heating',
            heatingcooling: 'Uppvärmning avkylning',
            investment: 'Investment',
            plugloads: 'Plug loads',
            processcool: 'Process cooling',
            spacecooling: 'Space cooling',
            spaceheating: 'Space heating',
            summary: 'Summary',
            transversal: 'Transversalt energisystem'
          },
          setpeakload: {
            helptext:
              'Specify parameters for maximum load and amount of full load hours for energy kind.',
            label: 'Set peak load'
          },
          setseason: {
            helptext:
              'A season can be set. In the off-season, the demand for this energy kind is zero.',
            label: 'Set season'
          },
          sharedhw: {
            helptext:
              'Share of domestic hot water heating demand in relation to total heating demand (space heating and domestic hot water).',
            label: 'Share of domestic hot water'
          },
          supplytempchiller: {
            helptext:
              'Supply temperature of the cooling system in the building (for example 16 °C for underfloor cooling or 6 °C for conventional cooling systems).',
            label: 'Supply temperature'
          },
          supplytempdhw: {
            helptext:
              'Domestic hot water temperature (usually between 55 and 60 °C)',
            label: 'Supply temperature'
          },
          supplytempheatpump: {
            helptext:
              'Maximum supply temperature of the heating system in the building (for example 35 °C for underfloor heating or 50-90 °C for radiators).',
            label: '(Max) supply temperature'
          },
          textareaplaceholder:
            'Enter a time series or paste text from Excel or a text editor. You can also drag and drop a text file into this area.\n\nValid number of values:\n- 8760 (hours),\n- 365 (days),\n- 12 (months),\n- 4 (seasons), or\n- 1 (constant value over year).\nExample input:  5.2, 4.8, 3.6, 4.9',
          transversal: {
            coolsupplytemp: 'Kylning framledningstemperatur',
            supplytempdwh: 'Temperatur för tappvarmvatten',
            usetransversal: {
              helptext:
                'Välj mellan det transversella energisystemet och det traditionella energisystemet. Det transversala energisystemet använder en enda värmepump för att tillhandahålla värme och kyla. Det traditionella energisystemet har en dedikerad värmepump för uppvärmning och en dedikerad kylare för kylning.',
              label: 'Använd transversalt energisystem'
            }
          }
        },
        showsummary: 'Sammanfattning...',
        specCOPheat: {
          label: 'COP för rumsuppvärmning'
        },
        specCOPtapwater: {
          label: 'COP för tappvarmvattenuppvärmning'
        },
        speccooldem: {
          label: 'Specifikt utrymmeskylningsbehov'
        },
        specheatdem: {
          label: 'Specifikt uppvärmningsbehov'
        },
        tapwaterdem: {
          label: 'Tappvarmvattenbehov i procent av totalt värmebehov'
        },
        tapwatertimeseries: {
          helptext:
            'Uppladdning av tappvarmvattenfil är inte obligatoriskt. Om ingen fil laddas upp antas inget tappvarmvattenbehov.',
          label: 'Tappvarmvattenförbrukning (kWh/h)'
        }
      },
      chiller: {
        copconst: {
          helptext: 'Konstant COP för kylare under hela året.'
        },
        copwithcarnot: {
          helptext:
            'Beräkna COP baserat på Carnot-effektivitet med kyltillförseltemperatur i byggnaden som värmekälla och kallt ectogrid-rör som kylfläns.'
        },
        enabled: {
          label: 'Installera kylare'
        },
        tempcooling: {
          label: 'Framledningstemperatur utrymmeskylning'
        },
        title: 'Kylare'
      },
      chpbu: {
        etael: {
          helptext:
            'For small CHP units with small capacities (&lt;100 kW), the electric efficiency is up to 35 %. Large CHP units with a capacity of larger than 1 MW reach electric efficiencies of 40 % and higher.',
          label: 'Elektrisk effektivitet'
        }
      },
      coldstoragebu: {
        enabled: {
          helptext:
            'Kyllagring kan laddas med kompressions- och absorptionskylare.'
        }
      },
      common: {
        solararealist: {
          label: 'Areas'
        }
      },
      compressionchiller: {
        passive_cooling_enabled: 'Enable passive cooling'
      },
      compressionchillerbu: {
        copcarnoteff: {
          helptext: 'Beräkna COP med ectogridtemperatur och uteluftstemperatur.'
        }
      },
      districtcoolingbu: {
        cap: {
          label: 'Kapacitet för fjärrkylaanslutning'
        }
      },
      districtheatingbu: {
        cap: {
          label: 'Kapacitet för fjärrvärmeanslutning'
        }
      },
      ecologicalimpact: {
        co2el: {
          helptext: 'Specifika CO₂-ekvivalenter av elmixen i elnätet.',
          label: 'CO₂-utsläpp elnät'
        },
        co2gas: {
          helptext:
            'Specifika CO₂-ekvivalenter som uppstår vid förbränning av naturgas.',
          label: 'CO₂-utsläpp naturgas'
        },
        district_cooling_co2: 'District cooling CO₂',
        district_cooling_primary_energy: 'District cooling primary energy',
        district_heating_co2: 'District heating CO₂',
        district_heating_primary_energy: 'District heating primary energy',
        gas_primary_energy: 'Gas primary energy',
        grid_primary_energy: 'Grid primary energy',
        title: 'Miljöparametrar',
        waste_cold_co2: 'Waste cold CO₂',
        waste_cold_primary_energy: 'Waste cold primary energy',
        waste_heat_co2: 'Waste heat CO₂',
        waste_heat_primary_energy: 'Waste heat primary energy'
      },
      economicparameters: {
        enablescenario: {
          helptext:
            'Använd ekonomiska parametrar från ett fördefinierat scenario',
          label: 'Aktivera scenario'
        },
        envsurcharge: {
          helptext: 'För det tyska fallet motsvarar detta EEG-avgiften.',
          label: 'Miljötillägg'
        },
        interestrate: {
          helptext:
            'Räntan påverkar annualisering av investeringarna enligt VDI 2067.',
          label: 'Ränta'
        },
        othsurcharge: {
          label: 'Övriga tillägg'
        },
        priceexel: {
          helptext: 'Grossistpris för el för närvarande.'
        },
        priceexgas: {
          helptext: 'Grossistpriset för gas för närvarande.'
        },
        projectlifetime: {
          helptext:
            'Projektets livslängd påverkar annualisering av investeringar enligt VDI 2067.',
          label: 'Projektets livslängd'
        },
        scenario: {
          helptext:
            'Välj ett av de fördefinierade scenarierna. Du kan justera scenarioparametrarna ytterligare om det behövs.',
          label: 'Scenario valt'
        },
        section: {
          electricity: 'Elpris',
          gas: 'Gaspris',
          general: 'Ekonomiska parametrar'
        },
        taxrate: {
          label: 'Skattenivå'
        }
      },
      electricalheater: {
        enabled: {
          label: 'Installera extra elpatron'
        },
        peakcover: {
          helptext:
            'Andel av värmebehovet som täcks av elpatronen när maximalt värmebehov inträffar (t.ex. kallaste vinterdag).'
        },
        title: 'Extra elpatron'
      },
      emptyform: {
        label:
          'To run calculations you will need to select a location and add some buildings.'
      },
      energycosts: {
        enablefeedinel: {
          helptext:
            'Om aktiverat tillåt att överskottsel, t.ex. av kraftvärme eller PV, kan matas in i elnätet. Om ej aktiverat används genererad ström endast på plats. Solcellsbegränsning är möjlig.',
          label: 'Aktivera inmatning'
        },
        price_el_profile: {
          label: 'Electricity price profile'
        },
        price_el_uploaded: {
          label: 'Upload price profile'
        },
        priceel: {
          label: 'Årligt elpris'
        },
        pricegas: {
          label: 'Naturgaspris på årsbasis'
        },
        revenuefeedinel: {
          helptext: 'Intäkter för elinmatning till elnät.',
          label: 'Inmatningstaxa'
        },
        title: 'Energikostnader'
      },
      freecooling: {
        enabled: {
          helptext:
            'Aktivera direkt kylning med ectogrid. Värmeväxlare installeras i byggnader istället för kylare.',
          label: 'Installera direktkylning'
        },
        invvar: {
          helptext: 'Investering för värmeväxlare.'
        }
      },
      gasboiler: {
        enabled: {
          label: 'Installera en extra gaspanna'
        },
        peakcover: {
          helptext:
            'Andel av värmebehovet som täcks av gaspannan när maximalt värmebehov inträffar (t.ex. kallaste vinterdag).'
        },
        title: 'Extra gaspanna'
      },
      gsheatpumpbu: {
        copconstcool: {
          label: 'Kylnings-COP (konstant)'
        }
      },
      heatpump: {
        copconstspaceheating: {
          label: 'COP uppvärmning'
        },
        copconsttapwater: {
          label: 'COP tappvarmvatten'
        },
        tempspaceheating: {
          label: 'Framledningstemperatur rumsuppvärmning'
        },
        temptapwater: {
          label: 'Framledningstemperatur tappvarmvatten'
        },
        title: 'Värmepumpar i byggnader'
      },
      heatstoragebu: {
        enabled: {
          helptext:
            'Värmelagring kan laddas med gaspanna, kraftvärme eller elpanna.'
        }
      },
      investment: {
        cc: {
          label: 'Chiller'
        },
        drc: {
          label: 'Direct cooling'
        },
        eh: {
          label: 'Electrical heater'
        },
        helptext:
          'These are the general investment settings for the energy system. You can also specify individual costs for each building in the building section.',
        hp: {
          label: 'Heat pump'
        },
        peak_chiller: {
          label: 'Peak chiller'
        },
        title: 'Investment building equipment'
      },
      locationhelp:
        'Please note that changing locations will update the specific annual demands for each energy kind in all buildings to reflect the demands of the new location.',
      network: {
        buttontitle: 'Network parameters...',
        costsearthwork: {
          label: 'Kostnad markarbeten och installation'
        },
        costspipes: {
          label: 'Kostnad rörmaterial'
        },
        diversityfactor: {
          helptext:
            'The diversity factor describes how much the peak load at the energy center is reduced. The total demand of the annual load profile is not changed by the smoothing. For a conservative calculation, a diversity factor of 1 can be assumed (no smoothing and therefore no reduction of the peak load).',
          label: 'Diversity factor'
        },
        enablenetwcosts: {
          helptext:
            'Nätverkskostnader är en konstant kompensation för endast systemkostnader. Det påverkar inte energiflödesberäkningen.',
          label: 'Beakta nätverkskostnader'
        },
        enabletempupload: {
          label: 'Ladda upp medelvärde nät. temp. profil (°C)'
        },
        estimated_volume: {
          helptext:
            'Estimated volume of the network and passive balancing tank',
          label: 'Estimated volume'
        },
        gridmeantemperature: 'Grid mean temperature',
        gridmeantempgraph: {
          helptext:
            'The grid mean temperature is the setpoint for the ectogrid. The warm pipe temperature is the mean temperature plus the ½ of delta T, while the cool pipe temperature is the mean temperature minus the ½ of delta T.',
          label: 'Grid mean temperature'
        },
        gridtemptablehelp:
          'Active balancing working hours: the amount of the lowest operating hours to supply the grid with the required energy. The optimization algorithm will give the optimal solution.',
        gridtmethod: {
          constant: 'Constant value',
          label: 'Grid temperature method',
          suggestion: 'Grid mean temperature suggestion',
          userupload: 'User upload profile'
        },
        gridweightedavgtempgraph: {
          helptext:
            'The weighted average temperature considers the volumes of warm and cool water in the pipes and tank, providing a more accurate indication of the stored energy level and cycles',
          label: 'Grid weighted average temperature'
        },
        groundtempoption: {
          const: 'Constant',
          label: 'Ground temperature method',
          variable: 'Calculation'
        },
        heatlosspipediameter: {
          helptext:
            'Mean inner diameter of all pipes of the thermal network. Since the mean pipe diameter only moderately affects heat losses and gains, it can be estimated. It is typically around 250 mm.',
          label: 'Mean pipe diameter'
        },
        initial_kpis_weighted_t_after_balancing: {
          label:
            'Grid weighted average temperature with user input mean temperature'
        },
        installationdepth: {
          helptext:
            'Depth at which the network is installed. The installation depth influences the ground temperature. Ground temperature profiles can only be calculated up to a depth of 2 m.',
          label: 'Installation depth'
        },
        max_grid_t: {
          helptext: 'Maximum allowed grid temperature',
          label: 'Max grid temperature'
        },
        mean_t_profile: {
          label: 'User input grid mean temperature'
        },
        min_grid_t: {
          helptext: 'Minimum allowed grid temperature',
          label: 'Min grid temperature'
        },
        netwinsulation: {
          helptext:
            "If significant heat gains and losses are expected, the calculation should be based on the pipe type 'Uninsulated plastic pipe'. Please note that the calculation of heat gains and losses could not be validated with real-world data yet and is therefore only an estimation.",
          insulated: 'Insulated pipes (no heat losses/gains)',
          label: 'Pipe insulation',
          uninsulated: 'Uninsulated plastic pipe'
        },
        networklength: {
          helptext:
            'Dikeslängd förutsatt att båda rören är installerade i ett dike.',
          label: 'Nätverkslängd'
        },
        netwtempcoldpipe: {
          helptext:
            'Nätverkstemperaturer påverkar COP-beräkningen om COP baseras på Carnot-verkningsgrader samt passiva balanstankens lagringskapacitet.',
          label: 'Temperatur på kallrör'
        },
        netwtempdiff: {
          helptext: 'Konstant design delta T mellan det varma och kalla röret.',
          label: 'Temp. skillnad (varmt/kallt rör)'
        },
        netwtempwarmpipe: {
          label: 'Temperaturen på varmröret'
        },
        suggested_kpis_weighted_t_after_balancing: {
          label:
            'Grid weighted average temperature with suggested grid mean temperature'
        },
        suggested_mean_t_profile: {
          label: 'Suggested grid mean temperature'
        },
        suggestedcoldpipetemp: {
          label: 'Suggested cold pipe temperature'
        },
        suggestedgridmeantemp: {
          label: 'Suggested grid mean temperature​'
        },
        suggestedwarmpipetemp: {
          label: 'Suggested warm pipe temperature'
        },
        table: {
          activebalancingtankworkinghours: 'Active balancing working hours',
          gridandtankvolume: 'Grid and tank volume'
        },
        tempgroundconst: {
          helptext:
            'Ground temperature at installation depth of thermal network. The ground temperature influences heat losses or gains.',
          label: 'Ground temperature'
        },
        temptimeseries: {
          label: 'Temperatur tidsserie'
        },
        thconductivityground: {
          helptext:
            'The thermal conductivity of the soil depends on the local conditions. The German standard VDI 4640 indicates 1.2 W/mK for sand, 1.5 W/mK for clay and silt, and 1.8 W/mK for sandy clay.',
          label: 'Thermal conductivity ground'
        },
        title: 'Nätverksparametrar',
        use_grid_t_suggestion: {
          label: 'Use grid mean temperature suggestion'
        },
        userinputgridmeantemp: {
          label: 'User input grid mean temperature​'
        }
      },
      nobuildings: {
        label: 'No buildings added.'
      },
      notechs: {
        label: 'Please add energy center technologies to calculate.'
      },
      photovoltaic: {
        efficiency: {
          label: 'Efficiency'
        },
        enabled: {
          label: 'Solceller'
        },
        etael: {
          label: 'Moduleffektivitet'
        },
        headers: {
          area: 'Area (m²)',
          azimuth: 'Azimuth (°)',
          elevation: 'Elevation (°)'
        },
        maxarea: {
          label: 'Största area'
        },
        minarea: {
          label: 'Minsta area'
        },
        pvprofile: {
          label: 'Profile series'
        },
        pvprofileuploaded: {
          label: 'Upload profile series'
        }
      },
      referencesystem: {
        ashp_cop_dhw: 'COP of air source heat pump domestic hot tap water',
        ashp_cop_space_heat: 'COP of air source heat pump',
        boiler_eff: 'Thermal efficiency of boiler in buildings',
        central_gas_boiler_eta_th:
          'Thermal efficiency of central gas boiler in heat network scenario',
        central_gas_boiler_inv:
          'Investment for central gas boiler in heat network scenario',
        central_gas_boiler_om:
          'Maintenance for central gas boiler in heat network scenario',
        chiller_cop: 'COP of chillers in buildings',
        gshp_cop_dhw: 'COP of ground source heat pump domestic hot tap water',
        gshp_cop_space_heat: 'COP of ground source heat pump',
        inv_ashp: 'Investment for air source heat pump in buildings',
        inv_chiller: 'Investment for chiller in buildings',
        inv_gas_boiler: 'Investment for gas boilers in buildings',
        inv_gshp: 'Investment for ground source heat pump in buildings',
        netw_losses: 'Network losses of conventional 4GDH heat network',
        om_costs:
          'Share of maintenance costs (for all buildings devices the same)',
        title: 'Reference system',
        tooltips: {
          ashp_cop_dhw:
            'COP of air-source heat pumps for covering domestic hot water demands',
          ashp_cop_space_heat:
            'COP of air-source heat pumps for covering space heating demands',
          boiler_eff:
            'Thermal efficiency of decentral gas boilers for covering space heating and domestic hot water demands',
          central_gas_boiler_eta_th:
            'Thermal efficiency of central gas boiler that supplies the network',
          central_gas_boiler_inv:
            'Investment cost for central gas boiler that supplies the network',
          central_gas_boiler_om:
            'Maintenance costs as share of the investments for the central gas boiler that supplies the heat network',
          chiller_cop:
            'COP for covering cooling demands in buildings (with air-source heat pump, ground-source heat pump or chillers - depending on the scenario).',
          gshp_cop_dhw:
            'COP of ground-source heat pumps for covering domestic hot water demands',
          gshp_cop_space_heat:
            'COP of ground-source heat pumps for covering space heating demands',
          inv_chiller: 'Investment cost for decentral chillers.',
          netw_losses:
            'Thermal losses as share of the supplied heat by the network',
          om_costs:
            'Maintenance costs as share of the investments for decentral heat pumps, chillers and boilers in the buildings.'
        }
      },
      revheatpumpbu: {
        consider_defrosting: {
          helptext:
            'If enabled, the Carnot efficiency is reduced by 30 % during times in which the heat pump switches between regular operation and defrost mode. It is assumed that the defrost mode occurs when the ambient air temperature is below 5 °C and the relative humidity is larger than 50 %.',
          label: 'Consider defrosting'
        },
        cop: {
          label: 'COP calculation'
        },
        cop_const_cool: {
          label: 'Constant COP value cool'
        },
        cop_const_heat: {
          label: 'Constant COP value heat'
        },
        cop_cool_timeseries: {
          label: 'Custom cool COP time series'
        },
        cop_heat_timeseries: {
          label: 'Custom heat COP time series'
        },
        maxCOPcool: {
          label: 'Maximal COP (kylning)'
        },
        maxCOPheat: {
          label: 'Maximal COP (uppvärmning)'
        }
      },
      saveandcalculate: 'Save and calculate',
      sensitivity: {
        title: 'CO₂ sensitivity analysis',
        value: 'Value (€/t)'
      },
      shared: {
        ASHPcarnoteff: {
          label: 'Carnot-effektivitet'
        },
        absorptionchiller: 'Absorptionskylare',
        accumulatortank: 'Passiv balanstank',
        addproject: 'Lägg till projekt',
        airsourceheatpump: 'Luftvärmepump',
        ambientcoldsource: 'Fri kylenergi',
        ambientheatsource: 'Fri värmeenergi',
        aquiferstorage: 'Akviferlager',
        battery: 'Batteri',
        building: 'Byggnad',
        buildingname: 'Byggnadsnamn',
        buildings: 'Byggnader',
        capacity: 'Kapacitet',
        category: 'Kategori',
        changeratehelp:
          'Förändringstakt givet att grossistpriset minskar eller ökar varje år in i framtiden.',
        chiller: 'Kylmaskin',
        chpunit: 'Kraftvärmeverk',
        co2emissions: 'CO2-utsläpp',
        coldstorage: 'Kylförvaring',
        compressionchiller: 'Kompressionskylare',
        copconst: {
          label: 'Konstant COP'
        },
        coptimeseries: {
          label: 'COP-tidsserie'
        },
        costom: {
          label: 'Kostnad drift & underhåll'
        },
        coverofpeakdemand: 'Täckningsgrad',
        dhw: 'Domestic hot tap water',
        directcooling: 'Frikyla',
        districtcooling: 'Fjärrkyla',
        districtheating: 'Fjärrvärme',
        electricboiler: 'Elpanna',
        electricheatingrod: 'Elpatron',
        electricity: 'Elektricitet',
        electricityfeedin: 'Elinmatning',
        electricityfromgrid: 'El från elnät',
        electricityimport: 'Elimport',
        enablecopupload: {
          label: 'Ladda upp COP-profil'
        },
        enablesupplylimit: {
          label: 'Räkna på den årliga utbudsgränsen'
        },
        endenergy: 'Huvudsaklig energikälla',
        equipmenttype: 'Utrustningstyp',
        error: 'Fel',
        etath: {
          helptext:
            'Usually the sum of electric efficiency and thermal efficiency is around 90 %.',
          label: 'Termisk effektivitet'
        },
        feedin: 'Feed-in',
        feedintogrid: 'Mata in till nät',
        fullloadhours: 'Full-lasttimmar',
        gasboiler: 'Gaspanna',
        generation: 'Generation',
        groundsourceheatpump: 'Bergvärmepump',
        heatingcopconstant: 'Uppvärmning COP (konstant)',
        heatpump: 'Värmepump',
        heatpumpheatingonly: 'Värmepump (endast värme)',
        heatstorage: 'Värmelagring',
        initialgridsurcharge: 'Initialt nättillägg',
        initialwholesalemarketprice: 'Indikativt grossistpris',
        invvar: {
          helptext: 'Specific investment costs for the technology.',
          label: 'Investering'
        },
        lifetime: {
          helptext: 'Expected lifetime of the technology.',
          label: 'Livstid'
        },
        maxcap: {
          label: 'Maximal kapacitet'
        },
        maximumcop: 'Max COP',
        mincap: {
          label: 'Minsta kapacitet'
        },
        naturalgas: 'Naturgas',
        naturalgasbalancingunit: 'Naturgas (energicenter)',
        naturalgasbuildings: 'Naturgas (byggnader)',
        nodatafound: 'Ingen information hittad',
        peakloadchiller: 'Peak-load chiller',
        pipeinstallation: 'Rörinstallation',
        potentialtimeseries: {
          label: 'Potentiella tidsserier'
        },
        price: 'Pris',
        spacecooling: 'Space cooling',
        spaceheating: 'Rumsuppvärmning',
        storageloss: 'Förlust av lagring',
        supplylimit: {
          label: 'Årlig utbudsgräns'
        },
        technology: 'Teknologi',
        thermalpoweravailableovertheyear:
          'Termisk kraft tillgänglig över året.',
        transversalhp: 'Transversal värmepump',
        usecarnotefficiency: 'Använd Carnot-effektivitet',
        useconstantcop: 'Använd konstant COP',
        wasteheatsource: 'Spillvärme',
        yearlygridsurchargegrowthrate: 'Årlig tillväxttakt för nättillägg',
        yearlywholesalepricechangerate: 'Årlig förändring av grossistpriset'
      },
      simpleheatpumpbu: {
        copisconst: {
          label: 'Bergvärmepump'
        },
        isASHP: {
          label: 'Luftvärmepump'
        }
      },
      solarthermal: {
        coll_temp: {
          label: 'Collector temperature'
        },
        enabled: {
          label: 'Solar thermal'
        }
      },
      wastecooling: {
        availablepower: {
          label: 'Tillgänglig kyleffekt'
        },
        copchiller: {
          label: 'COP kylare'
        },
        enablechiller: {
          label: 'Installera ytterligare kylare'
        },
        enablepotentialupload: {
          label: 'Ladda upp kylpotential (kW)'
        }
      },
      wasteheat: {
        availablepower: {
          label: 'Tillgänglig värmeeffekt'
        },
        cophp: {
          label: 'COP värmepump'
        },
        enable_temp_upload: {
          label: 'Upload temp. time series'
        },
        enablehp: {
          label: 'Installera boostervärmepump'
        },
        enablepotentialupload: {
          label: 'Ladda upp värmepotential (kW)'
        },
        temp_time_series: {
          label: 'Temp. time series'
        }
      }
    },
    graphs: {
      aggregation: 'Aggregering',
      aggregations: {
        average: 'Genomsnitt',
        sum: 'Belopp'
      },
      allsignals: 'All signals',
      clear: 'Klar',
      collection: 'Samling:',
      editgraph: 'Redigera graf',
      editsignalsettings: 'Redigera signalinställningar',
      graphtype: 'Graftyp',
      graphtypes: {
        column: 'Stapeldiagram',
        durationcurve: 'Varaktighetskurva',
        line: 'Linjediagram',
        scatter: 'Spridningsdiagram'
      },
      import: 'Import data...',
      newgraph: 'Ny graf',
      nosignals: 'Inga signaler valda',
      nosignalsselected:
        'Inga signaler valda. Redigera grafen för att välja signaler',
      notimeseries: 'No time series available for this version.',
      referenceyear: 'Referensår',
      scatterxaxisseriesid: 'Spridningsdiagram X-axelsignal',
      selectedsignals: 'Valda signaler',
      selectgraph: 'Select time series to import',
      title: 'Grafer',
      unsavedsuffix: '(osparad)',
      zoomlevels: {
        day: 'Dag',
        month: 'Månad',
        week: 'Vecka',
        year: 'År'
      },
      zoomout: 'Zooma ut'
    },
    invalidform: {
      label:
        'There are errors in your form. Before you correct them, no visualizations are updated and calculations cannot run. To see a summary of the errors, you can click on Calculate.'
    },
    location: {
      city: 'Stad',
      country: 'Land',
      title: 'Plats'
    },
    newbuildname: 'Ny version',
    newprojectname: 'Nytt projekt',
    newtype: 'Typ',
    newtypes: {
      blankproject: 'Tomt projekt',
      copyproject: 'Kopiera data till nytt projekt',
      version: 'Lägg till ny version'
    },
    noprojectshelp:
      'Det finns inga tillgängliga projekt. Tryck på "Lägg till..." för att skapa ett nytt projekt, eller be en kollega att dela ett projekt.',
    pipes: {
      button: 'Visa rördimensionering...',
      form: {
        buildings: 'Byggnader',
        pipediameters: 'Rördiametrar',
        piperoughness: 'Rörsträvhet',
        tempdiff: 'Temperaturskillnad'
      },
      pipeformat: 'DN{0}',
      recommendeddiameterformat: 'Rekommenderad diameter: DN{0}',
      results: 'Rördimensioneringsresultat',
      suggestpipediameters: 'Föreslå rördiametrar',
      summarymaxmassflowrate: 'Max. massflödeshastighet',
      summarymaxvolumeflowrate: 'Max. volymflöde',
      title: 'Rördimensionering'
    },
    profileeditor: {
      action: 'Set',
      annualsum: 'Årlig summa',
      invalidformaterror:
        'Comma and dot cannot be used at the same time in the same series. Use space or nothing as thousand separator.',
      invalidnumlineserror: 'Invalid number of lines: {0}',
      invalidtimeseriesformat: 'Time series format invalid{0}',
      manualcurve: 'Manuell kurva',
      notimeseriesnotice: 'Please configure a time series to continue',
      range: 'Område',
      select: {
        file: {
          button: 'Fälj fil...',
          title: 'Ladda upp fil'
        },
        timeseriesdata: 'Tidseriedata',
        unit: 'Enhet'
      },
      timeseries: 'Tidserier',
      types: {
        consumptionm3: 'Consumption (m³)',
        cop: 'COP',
        emissions: 'Emissions (gCO2/kWh)',
        price: 'Pris (€/kWh)',
        pricemwh: 'Pris (€/MWh)',
        temperature: 'Temperatur (°C)'
      },
      typetitles: {
        consumption: 'Consumption profile',
        cop: 'COP profil',
        emissions: 'Emission profile',
        energy: 'Energiprofil',
        price: 'Prisprofil',
        temperature: 'Temperaturprofil'
      },
      validatetimeseries: 'Validera tidsserie'
    },
    project: {
      edittitle: 'Redigera projekt',
      name: 'Namn',
      newprojectname: 'Nytt projekt',
      type: 'Project type',
      types: {
        ectoplanner: 'Ectoplanner',
        secosim: 'Secosim (beta)'
      }
    },
    projectstitle: 'Ectoplanner-projekt',
    resultgraphs: {
      balancingunitload4mwh: 'Energicenterbelastning ④ (MWh)',
      coolingdemand: 'Kylningsbehov',
      coolingdemandmwh: 'Kylbehov (MWh)',
      electricityimportmwh: 'Elimport (MWh)',
      energydemandmwh: 'Energibehov (MWh)',
      energyimportmwh: 'Energi-import (MWh)',
      equipmentinbalancingunit: 'Utrustning i energicenter',
      equipmentinbuildings: 'Utrustning i byggnader',
      heatingdemand: 'Uppvärmningsbehov',
      heatingdemandmwh: 'Uppvärmningsbehov (MWh)',
      operationcostseura: 'Driftkostnader (EUR/a)',
      operationmaintenancecosts: 'Driftsunderhållskostnader',
      pvpower: 'Solcellseffekt',
      tapwaterdemand: 'Tappvarmvattenbehov'
    },
    results: {
      balancingunit: {
        coolinggeneration: 'Kylgenerering',
        heatingandcooling: 'Uppvärmning och kylning',
        heatinggeneration: 'Värmegenerering',
        renewablesandstorage: 'Förnybar energi och lagring'
      },
      balancingunitload: 'Energicenterbelastning',
      balancingunitsecondary: {
        area: 'Område'
      },
      buildingdemandsinput: {
        annualdemandmwha: 'Årlig efterfrågan (MWh/a)'
      },
      buildingenergysystems: 'Bygga energisystem',
      buildings: {
        titles: {
          buildingequipment: 'Byggnadsutrustning',
          capexbuildings: 'CAPEX-byggnader',
          cumulated: 'Summerade installationer',
          energydemands: 'Energikrav'
        }
      },
      buildingsinputdata: 'Byggnader (indata)',
      burningnaturalgas: 'Naturgasförbränning',
      capitalrecoveryfactor: 'Kapitalåtervinningsfaktor',
      cooling: 'Kyla',
      costs: {
        annualcostseura: 'Årliga kostnader (EUR/a)',
        investmentseur: 'Investeringar (EUR)'
      },
      cumulatedinstallations: {
        meancop: 'Genomsnittlig COP',
        powergasdemand: 'Kraft/gasbehov'
      },
      demandbalancing: {
        balanceddemands: 'Balanserade krav',
        balanceddemandsmwha: 'Balanserade behov (MWh/a)',
        colddemandmwha: 'Kallbehov (MWh/a)',
        heatdemandmwha: 'Värmebehov (MWh/a)',
        overview: 'Översikt'
      },
      district_cooling: 'District cooling',
      district_heating: 'District heating',
      download: 'Excel-fil',
      ecologickpis: {
        primary_energy_demand: 'Primary energy demand',
        title: 'Miljö-KPI:er'
      },
      endenergydemand: {
        annualenergymwha: 'Årlig energi (MWh/a)',
        energysources: 'Energikällor',
        peakpowerkw: 'Toppeffekt (kW)'
      },
      energydemands: {
        balanceddemandsinbuilding: 'Balansering i byggnad',
        coolingdemandmwha: 'Kylbehov (MWh/a)',
        electricitydemandmwha: 'Elbehov (MWh/a)',
        gasdemandmwha: 'Gasbehov (MWh/a)',
        meanheatpumpcop: 'Medelvärmepump COP',
        spaceheatingdemandmwha: 'Uppvärmningsbehov (MWh/a)',
        tapwaterdemandmwha: 'Tappvarmvattenbehov (MWh/a)'
      },
      failedtoload: 'Misslyckades med att läsa in resultat',
      genericresulterror:
        'Resultaten är inte längre giltiga. Kontrollera om det finns fel och räkna om.',
      investmentbalancingunit: 'Investering för energicenter',
      investmentbuildings: 'Byggnadsinvestering',
      locationerror: 'Ett okänt fel uppstod vid hämtning av plats.',
      locationnotfound:
        'Platsen som används är inte längre giltig. Välj en ny plats och kör om beräkningen.',
      netbuildingdemands: 'Nettobyggnadskrav',
      operationandmaintenancecosts: 'Drift & underhållskostnader',
      pagetitle: '{0}-resultat',
      photovoltaics: 'Solceller',
      print: 'Skriva ut',
      pvgeneration: 'Solcells-generering',
      sections: {
        balancingunit: 'Energicenter',
        buildingdemands: 'Byggnadskrav och utsläpp',
        costs: 'Kostar',
        demandbalancing: 'Behovsbalansering'
      },
      solarthermal: 'Solar thermal collectors',
      storage: {
        chargingcycles: 'Laddningscykler',
        volume: 'Volym'
      },
      ta: 't/a',
      tapwater: 'Tappvarmvatten',
      technologies: {
        feedinkwha: 'Inmatning (kWh/a)',
        selfconsumptionkwha: 'Egen förbrukning (kWh/a)'
      },
      title: 'Resultat',
      totalemissions: 'Totala utsläpp',
      waste_cooling: 'Waste cooling',
      waste_heat: 'Waste heat'
    },
    saveerror: 'Det gick inte att spara formuläret',
    secosim: {
      columns: {
        description: 'Description',
        kpi: 'KPI',
        total: 'Total'
      },
      kpis: {
        cumulative_co2_emission: 'Cumulative CO2 emission',
        cumulative_energy_savings:
          'Cumulative energy savings due to self-consumption',
        cumulative_eur_savings:
          'Cumulative Eur savings due to self-consumption',
        cumulative_opex: 'Cumulative OPEX',
        descriptions: {
          cumulative_co2_emission: 'The tonns of CO2 emissions',
          cumulative_energy_savings:
            'The energy that we are saving by self consuming PV generated energy, this amount would have been otherwise purchased from the grid',
          cumulative_eur_savings:
            'The money we are saving by self consuming PV generated energy, this money would have been otherwise used to purchase energy from the grid',
          cumulative_opex: 'The energy expenditure',
          feed_in_energy: 'The energy that we have sold to the grid',
          feed_in_revenues: 'The money that we made by selling to the grid',
          peak_power_absorption_grid:
            'How close did we get during the peak demand to the contracted power capacity, E.g. 80% = the peak power was 80% of the contracted limit',
          pv_generated_going_to_grid_export:
            'The energy generated from PV that was used to grid export demand',
          pv_self_consumption:
            'The % of energy from PV that we are self consuming (the remaining goes to grid export)',
          pv_to_heating_and_cooling_demand:
            'The % of energy from PV that powers the heating and cooling demand',
          savings_building_demand:
            'The % of energy powering the building that is coming from PV self consumption',
          savings_co2_vs_historical:
            'compared to benchmark, E.g. &gt;0 = savings = lower CO2 emissions',
          savings_cooling_demand:
            'The % of energy powering the cooling that is coming from PV self consumption',
          savings_dhw_demand:
            'The % of energy powering the DHW that is coming from PV self consumption',
          savings_heating_demand:
            'The % of energy powering the heating that is coming from PV self consumption',
          savings_opex:
            'compared to benchmark historical data, E.g. &gt;0 = savings = lower OPEX'
        },
        energyflow: 'Energy flow',
        feed_in_energy: 'Feed in energy',
        feed_in_revenues: 'Feed in revenues',
        graphs: {
          sankey: {
            energyflowformat: '{0} to {1}'
          },
          series: {
            airsourceheatpumps: 'Air source heat pumps',
            batterycharge: 'Battery Charge',
            batterydischarge: 'Battery Discharge',
            buffertanktemperature: 'Buffer tank temperature',
            co2historical: 'CO2 historical',
            co2savings: 'CO2 savings',
            co2withsectorcoupling: 'CO2 with sector coupling',
            cooling: 'Cooling',
            coolingpumps: 'Cooling pumps',
            dailypeakpower: 'Daily peak power',
            dhw: 'DHW',
            dhwairheatpump: 'DHW air heat pump',
            dhweboiler: 'DHW e-boiler',
            dhwwellsystem: 'DHW well system',
            eboilerheating: 'E-boiler heating',
            electricityimportcost: 'Electricity import cost',
            energycosthistorical: 'Energy cost historical',
            energycostwithsectorcoupling: 'Energy cost with sector coupling',
            feedin: 'Feed in',
            feedinenergy: 'Feed in energy',
            feedinrevenues: 'Feed in revenue',
            generalbuilding: 'General building',
            heating: 'Heating',
            heatingdemand: 'Heating demand',
            heatingwellnominalpower: 'Heating well nominal power',
            heatingwellsystemgeneration: 'Heating well system generation',
            maxweeklypowerabsorptionashp:
              'Max weekly power absorption air source heat pumps',
            opexsavings: 'OPEX savings',
            outdoortemperature: 'Outdoor temperature',
            powerlimit: 'Power limit',
            pvcontribution: 'PV contribution',
            pvgeneration: 'PV generation',
            pvnominalpeakpower: 'PV nominal peak power',
            totalheatpumpgeneration: 'Total heat pump generation',
            watersourceheatpumps: 'Water source heat pumps',
            weeklyoutdooravgtemp: 'Weekly outdoor average temperature'
          },
          titles: {
            atesconservation: 'ATES conservation',
            batterychargeanddischarge: 'Battery charge and discharge',
            energyusage: 'Energy usage',
            energyusagebyasset: 'Energy usage by asset',
            feedinenergyandrevenue: 'Feed in energy and revenue',
            heatingoff: 'Heating off - T down',
            heatpumpoperation: 'Heat pump operation',
            impactofsectorcouplingonco2: 'Impact of sector coupling on CO2',
            impactofsectorcouplingoncost: 'Impact of sector coupling on cost',
            opexandco2savings: 'OPEX and CO2 savings',
            outdoortemperaturevsenergyprices:
              'Outdoor temperature vs energy prices',
            powerpeakfromthegrid: 'Power peak from the grid',
            pvcontributiontotheenergyneeds:
              'PV contribution to the energy needs',
            pvgeneration: 'PV generation',
            savingspersector: 'Savings per sector',
            thermaldemandsplit: 'Thermal demand split'
          }
        },
        peak_power_absorption_grid: 'Peak power absorption from grid',
        pv_generated_going_to_grid_export: 'PV generated going to Grid export',
        pv_self_consumption: 'PV self consumption',
        pv_to_heating_and_cooling_demand: 'PV to heating and cooling demand',
        savings_building_demand:
          'Savings in building demand due to self-consumption',
        savings_co2_vs_historical: 'Savings CO2 vs Historical Data- 2022',
        savings_cooling_demand:
          'Savings in cooling demand due to self-consumption',
        savings_dhw_demand: 'Savings in DHW demand due to self-consumption',
        savings_heating_demand:
          'Savings in heating demand due to self-consumption',
        savings_opex: 'Savings OPEX vs Historical Data- 2022'
      },
      models: {
        air_hp: 'Heating from ambient air',
        ates: {
          title: 'ATES balancing'
        },
        battery: 'Battery',
        battery_capacity: {
          title: 'Battery capacity'
        },
        battery_efficiency: {
          title: 'Round trip efficiency'
        },
        battery_power: {
          title: 'Rated power'
        },
        co2_data: {
          title: 'CO2 data'
        },
        cooling_capacity: {
          title: 'Cooling capacity'
        },
        cooling_direct: 'Cooling',
        demand_cooling: {
          title: 'Building cooling demand'
        },
        demand_dhw: {
          title: 'Building DHW demand'
        },
        demand_heating: {
          title: 'Building heating demand'
        },
        demand_temperature: {
          title: 'Building min. heating temperature'
        },
        dhw_air_hp: 'DHW from ambient air',
        dhw_buffer: 'DHW buffer',
        dhw_buffering: 'Enable DHW buffering',
        dhw_capacity: {
          title: 'DHW capacity'
        },
        dhw_hp: 'DHW from ATES',
        e_boiler: 'Electrical boilers',
        el_demand: {
          title: 'Building eLoad historical'
        },
        el_export_price: {
          title: 'Electricity export cost'
        },
        el_import_price: {
          title: 'Electricity import cost'
        },
        feed_in_capacity: {
          title: 'Feed in capacity'
        },
        gas_boiler: 'Gas boilers',
        gas_prices: {
          title: 'Gas prices'
        },
        goal: {
          co2: 'CO2',
          cost: 'Cost',
          reference: 'Reference',
          title: 'Goal'
        },
        grid_capacity: {
          title: 'Grid connection capacity'
        },
        grid_connection: 'Grid connection',
        heat_buffer: 'Heat buffer',
        heat_buffering: 'Enable heat buffering',
        heat_hp: 'Heating from ATES',
        heat_to_ates_capacity: {
          title: 'Heat to ATES capacity'
        },
        heating_buffer: {
          title: 'Enable heating buffer'
        },
        heating_capacity: {
          title: 'Heating capacity'
        },
        hist_gas_h_consumption_m3: {
          title: 'Historical gas consumption'
        },
        hp_capacity: {
          title: 'Capacity'
        },
        hp_eta: {
          title: 'Nominal heating eta'
        },
        hp_max_temp: {
          title: 'Max temp'
        },
        hp_pump_cop: {
          title: 'COP'
        },
        indoors_cooling_buffer: {
          title: 'Cooling buffer'
        },
        indoors_dhw_buffer: {
          title: 'DHW Buffer'
        },
        loss_buffer: {
          title: 'Loss'
        },
        max_temp_buffer: {
          title: 'Max temp'
        },
        min_temp_buffer: {
          title: 'Min temp'
        },
        pv_capacity: {
          title: 'PV capacity'
        },
        pv_gen: {
          title: 'PV power output'
        },
        scenario_definition: 'Scenario definition',
        timeseries: 'Time series',
        volume_buffer: {
          title: 'Volume'
        }
      },
      releasenote:
        'This is a preview release with limited functionality. Expect large changes to the user interface and functionality.',
      sections: {
        co2: 'CO2',
        feedin: 'Feed in',
        opex: 'OPEX',
        peakpower: 'Peak power',
        pv: 'PV',
        savings: 'Savings'
      },
      tabs: {
        graphs: 'Graphs',
        input: 'Input',
        kpigraphs: 'KPI Graphs',
        result: 'Result',
        table: 'Table'
      },
      title: 'Secosim'
    },
    sections: {
      balancingunit: 'Aktiv balans',
      bms: 'Energi',
      calculations: 'Beräkningar',
      energystorages: 'Lagring',
      grid: 'Grid',
      modelparams: 'Affärsmodell',
      othersources: 'Övrigt'
    },
    share: {
      adduser: 'Lägg till användare',
      displayname: 'Användare',
      failedtoaddusers:
        'Det gick inte att lägga till användare. Är e-postadressen giltigt?',
      failedtodeleteproject:
        'Kunde inte ta bort projektet. Är det fler än en användare av projektet?',
      failedtoremoveuser:
        'Kunde inte ta bort användaren. Är användaren ägare av projektet?',
      mail: 'E-post',
      placeholder: 'Användarens e-post',
      title: 'Dela {0}'
    },
    technologies: 'Tekniker',
    title: 'Ectoplanner',
    unauthorized: 'Obehörig. Du har inte tillgång till denna sida.',
    unitdescriptions: {
      maxflowvel: 'Max. flödeshastighet (m/s)',
      maxpam: 'Max. tryckgradient (Pa/m)',
      minflowvel: 'Min. flödeshastighet (m/s)',
      pam: 'Tryckgradient (Pa/m)',
      specannpumpwork: 'Spec. årligt pumparbete (kWh/m/a)'
    },
    units: {
      btuh: 'Btuh',
      degc: '°C',
      eur: 'EUR',
      eura: 'EUR/a',
      eurkw: '€/kW',
      eurkwel: (
        <span>
          €/kW<sub>el</sub>
        </span>
      ),
      eurkwh: '€/kWh',
      eurkwp: '€/kWp',
      eurkwth: (
        <span>
          €/kW<sub>th</sub>
        </span>
      ),
      eurm: '€/m',
      eurm3: '€/m³',
      eurmwh: '€/MWh',
      gco2kwh: 'gCO2/kWh',
      gkwh: 'g/kWh',
      ha: 'h/a',
      hyear: 'h/year',
      k: 'K',
      kgCO2eq: 'kg CO₂eq',
      kgkwh: 'kg/kWh',
      kgs: 'kg/s',
      km: 'km',
      kmday: 'km/day',
      kw: 'kW',
      kwel: (
        <span>
          kW<sub>el</sub>
        </span>
      ),
      kwh: 'kWh',
      kwh100km: 'kWh/100 km',
      kwha: 'kWh/a',
      kwhel: 'kWh_el',
      kwhkwh: 'kWh/kWh',
      kwhm2: 'kWh/m²',
      kwhm2year: 'kWh/m²/year',
      kwp: 'kWp',
      kwth: (
        <span>
          kW<sub>th</sub>
        </span>
      ),
      ls: 'l/s',
      m: 'm',
      m2: 'm²',
      m3: 'm³',
      m3h: 'm³/h',
      mm: 'mm',
      mw: 'MW',
      mwel: (
        <span>
          MW<sub>el</sub>
        </span>
      ),
      mwh: 'MWh',
      mwha: 'MWh/a',
      mwhel: (
        <span>
          MWh<sub>el</sub>
        </span>
      ),
      mwhth: (
        <span>
          MWh<sub>th</sub>
        </span>
      ),
      mwhyear: 'MWh/år',
      mwth: (
        <span>
          MW<sub>th</sub>
        </span>
      ),
      pam: 'Pa/m',
      percent: '%',
      percenta: '%/a',
      percentdoc: '% (DOC)',
      percentofheatdemand: '% av värmebehovet',
      percentofinvest: '% av investering',
      percentperhour: '% per timme',
      th: 't/h',
      tonnco2: 'tonnCO2',
      w: 'W',
      wm2: 'W/m²',
      wmk: 'W/(m K)',
      years: 'år'
    },
    validationerror: {
      maxformat: 'Värdet måste vara mindre än eller lika med {0}',
      minformat: 'Värdet måste vara större än eller lika med {0}',
      notset: 'Värdet måste anges'
    }
  },
  editsignalvalue: {
    dialogtitle: 'Ange orsak för värdeändring'
  },
  equipment: {
    addalarm: {
      addedalarm: 'Lade till larm',
      nodeformat: 'Alarmet kommer läggas till på {0}.',
      nodeformatwarning:
        'Alarm kommer att läggas till {0}. Du har valt flera signaler, men larmet kommer att läggas till platsen. För att lägga till larm för individuella signaler, välj dem en efter en.',
      placeholder: 'Larmkommentar',
      title: 'Lägg till larm'
    },
    addedsignalformat: 'Lade till signalen {0}.',
    analyzeandexport: 'Analysera & exportera...',
    category: 'Kategori',
    currentvalue: 'Nuvarande värde',
    deletesignalvalues: 'Radera signalvärden...',
    description: 'Beskrivning',
    displayname: 'Signalnamn',
    equipmenttype: 'Utrustningstyp',
    errorfetchingprocessmap: 'Ett fel uppstod när processkartan hämtades.',
    lastupdate: 'Senaste uppdatering',
    mappedsignals: 'Kopplade signaler',
    newvalue: 'Nytt värde',
    nodescription: 'Ingen beskrivning',
    nosignalselected: 'Ingen signal vald',
    novalue: '-',
    novalueset: 'Signalen saknar värde',
    removedsignalformat: 'Tog bort till signalen {0}.',
    searchbar: {
      clear: 'Rensa alla valda signaler',
      datefrom: 'Tid:',
      dateplaceholder: 'Nu',
      export: 'Exportera...',
      searchplaceholder: 'Sök bland signaler...',
      selectedsignalsformat: 'Valda signaler ({0})',
      showingraph: 'Visa i graf...',
      showlogs: 'Visa loggar...'
    },
    setvalue: 'Uppdatera',
    setvaluefailure: 'Misslyckades med att uppdatera signalvärde',
    setvaluefailurenoconnection:
      'Misslyckades med att uppdatera signalvärde. Signalens Energy Manager kunde inte nås.',
    setvaluefailurenodevice:
      'Misslyckades med att uppdatera signalvärde. Signalen är inte kopplad till någon Energy Manager.',
    setvalueformat: 'Uppdatera värde för {0}',
    setvaluesuccess: 'Signalvärde uppdaterat',
    signaltype: 'Signaltyp',
    source: 'Källa',
    systemname: 'Funktion',
    tooltipformat: 'Tid: {0} | Steg: {1}',
    unit: 'Enhet',
    unmappedsignals: 'Övriga signaler',
    updatethreshold: 'Uppdateringsgräns',
    value: 'Värde'
  },
  equipments: {
    equipments: 'utrustningar',
    locationswithdata: 'Här är några platser med utrustning:',
    nodata: 'Det finns ingen utrustning i denna {0}.'
  },
  exportdownload: {
    error: 'Misslyckades med att ladda ned export av signaler'
  },
  exportoptions: {
    daterange: 'Datum'
  },
  exportpage: {
    confirmexport:
      'Exportera data? Du kommer få e-post när datan är behandlad.',
    exportdata: 'Exportera data',
    exportdatafailed: 'Export av datan misslyckades.',
    exportdatasuccess: 'Export av data begärd.',
    loadfromcollection: 'Läs in signaler från graf...',
    title: 'Exportera data'
  },
  featureflag: {
    ectotableadmin: 'Ectotable admin',
    integrations: 'Integrationer',
    newalarms: 'Ny alarmvy'
  },
  geocodinginput: {
    error: {
      resolve: 'Misslyckades med att läsa in koordinater.'
    },
    placeholder: 'Sök efter plats...'
  },
  graphs: {
    aggregationformat: 'Aggregering: {0}',
    analytics: {
      help: 'Observera att vissa datapunkter kan optimeras bort om diagrammet innehåller en stor mängd data.',
      title: 'Dataanalys',
      tooltip:
        'Dataanalys. Klicka för att aktivera och välj sedan ett område av grafen för att se statistik.'
    },
    autoupdate: 'Auto-uppdatering',
    autoupdateformat: '{0} (auto)',
    confirmdelete: {
      message: 'Är du säker på att du vill ta bort {0}?',
      title: 'Bekräfta borttagning'
    },
    confirmsave: {
      message:
        'Det finns flera osparade grafer. Vill du spara alla eller bara denna?',
      saveall: 'Spara alla',
      savecurrent: 'Spara nuvarande',
      title: 'Spara alla grafer?'
    },
    custominterval: 'Eget intervall',
    deletebutton: 'Ta bort graf',
    deletegraph: 'Ta bort graf',
    duplicatebutton: 'Skapa kopia av graf',
    duplicatesuffix: 'kopia',
    editmodal: {
      addtitle: 'Lägg till ny graf',
      errornoscatterxaxis:
        'Om du använder ett spridningsdiagram måste du ange en signal för x-axeln.',
      settings: {
        numpointslabel: 'Antal punkter',
        numpointsplaceholder: 'Baserad på skärmupplösning',
        numpointsplaceholderwithsampling: 'Baserad på samplingsintervall',
        seriesinterval: 'Zoom-nivå',
        typelabel: 'Graftyp',
        xaxislabel: 'Spridningssignal x-axel',
        xaxisplaceholder: 'Välj signal för x-axeln'
      },
      title: 'Redigera graf'
    },
    exportdialog: {
      aggregation: {
        count: 'Antal',
        first: 'Första',
        last: 'Sista',
        max: 'Max',
        mean: 'Medelvärde',
        median: 'Median',
        min: 'Min',
        mode: 'Mode',
        none: 'Ingen',
        sum: 'Summa'
      },
      aggregationtitle: 'Aggregering',
      confirm: 'Exportera',
      export: {
        success: 'Exporten är klar.'
      },
      exportcvs: {
        failure: 'Exporten misslyckades. Vänligen försök igen senare.'
      },
      exportformat: 'Format',
      failedtosavefile: 'Misslyckades med att spara fil',
      filename: 'Filnamn',
      imageheight: 'Bildens höjd',
      imagewidth: 'Bildens bredd',
      sampling: {
        day: 'Dag',
        fifteenminutes: '15 minuter',
        fiveminutes: '5 minuter',
        hour: 'Timme',
        minute: 'Minut',
        month: 'Månad',
        raw: 'Rådata',
        week: 'Vecka'
      },
      samplinginterval: 'Samplingsintervall',
      title: 'Exportera graf'
    },
    failedtoload: 'Misslyckades med att hämta grafer.',
    failedtoloadsignals: 'Misslyckades att hämta signaler',
    failedtosave: 'Misslyckades med att spara grafer.',
    minmax: {
      table: {
        max: 'Största värde',
        min: 'Minsta värde',
        placeholder: 'Automatisk',
        unit: 'Enhet'
      },
      title: 'Minsta och största värde för axlar'
    },
    newgraphname: 'Ny graf',
    nosignalsfound: 'Inga signaler hittades med nuvarande inställningarna',
    options: 'Grafalternativ',
    panel: {
      unsavedchanges:
        'Du har redigerat i en graf som du valde från dashboard. Vill du spara den?'
    },
    pointsoverflow:
      'Datan för grafen är för stor för att kunna ritas ut på ett interaktivt sätt. Vänligen gå igenom inställningarna för att verifiera att du har korrekta aggregeringsinställningar.',
    select: {
      graph: {
        type: 'Välj graftyp'
      },
      xaxis: {
        signal: 'Välj signal för X-axel'
      }
    },
    settingplaceholder: 'Från grafinställningar',
    signalcollection: 'Graf:',
    signalselector: {
      name: {
        placeholder: 'Namn'
      }
    },
    signalselectorbutton: 'Redigera signaler...',
    signalselectormodal: {
      title: 'Signalväljare'
    },
    tempsuffix: '(tillfällig)',
    timerange: {
      option: {
        all: 'Alla',
        custom: 'Valfritt',
        day: 'Dag',
        fiveyears: 'Fem år',
        hour: 'Timme',
        month: 'Månad',
        week: 'Vecka',
        year: 'År'
      },
      title: 'Tidsområde',
      titlewithfilterdescription:
        'Tidsområde (Signalen kommer bara vara synlig inom detta område)'
    },
    type: {
      line: 'Linjediagram',
      scatter: 'Spridningsdiagram'
    },
    unsavedsuffix: '(osparad)',
    zoom: 'Zoom:'
  },
  grids: {
    cooling: 'Kyla',
    ectogrid: 'ectogrid™',
    electricity: 'El',
    generic: 'Generic',
    heating: 'Värme'
  },
  help: {
    missingfilemessage: 'Kan inte hitta rätt hjälpfil.'
  },
  helpbutton: {
    error: 'Kunde inte hämta hjälptext, försök igen senare.'
  },
  helptooltip: {
    gotohelp: 'Gå till hjälpsida'
  },
  highcharts: {
    contextbuttontitle: 'Kontextmeny för diagram',
    decimalpoint: ',',
    downloadcsv: 'Ladda ned CSV',
    downloadjpeg: 'Ladda ned JPEG',
    downloadmidi: 'Ladda ned MIDI',
    downloadpdf: 'Ladda ned PDF',
    downloadpng: 'Ladda ned PNG',
    downloadsvg: 'Ladda ned SVG',
    downloadxls: 'Ladda ned XLS',
    error: 'Fel uppstod vid inläsning, vänligen prova igen senare.',
    exitfullscreen: 'Avsluta fullskärm',
    exporting: 'Exporterar...',
    loading: 'Laddar data...',
    months: {
      april: 'April',
      august: 'Augusti',
      december: 'December',
      february: 'Februari',
      january: 'Januari',
      july: 'Juli',
      june: 'Juni',
      march: 'Mars',
      may: 'Maj',
      november: 'November',
      october: 'Oktober',
      september: 'September'
    },
    nodata: 'Ingen data tillgänglig.',
    printchart: 'Skriv ut diagram',
    shortmonths: {
      april: 'Apr',
      august: 'Aug',
      december: 'Dec',
      february: 'Feb',
      january: 'Jan',
      july: 'Jul',
      june: 'Jun',
      march: 'Mar',
      may: 'Maj',
      november: 'Nov',
      october: 'Okt',
      september: 'Sep'
    },
    shortweekdays: {
      friday: 'Fre',
      monday: 'Mon',
      saturday: 'Lör',
      sunday: 'Sön',
      thursday: 'Tors',
      tuesday: 'Tis',
      wednesday: 'Ons'
    },
    viewfullscreen: 'Visa i fullskärm',
    weekdays: {
      friday: 'Fredag',
      monday: 'Måndag',
      saturday: 'Lördag',
      sunday: 'Söndag',
      thursday: 'Torsdag',
      tuesday: 'Tisdag',
      wednesday: 'Onsdag'
    }
  },
  jobs: {
    columns: {
      dataend: 'Slut av data',
      datastart: 'Start av data',
      date: 'Datum',
      expires: 'Giltig till',
      importance: 'Vikt',
      lasttrained: 'Senast tränad',
      model: 'Modell',
      name: 'Namn',
      r2: 'R2',
      rmse: 'RMSE',
      scoring: 'Rankning'
    },
    compare: 'Jämför',
    details: 'Detaljer',
    earlierversions: 'Tidigare versioner',
    featureimportance: 'Funktionsvikt',
    outputsignals: 'Utsignaler',
    predictedformat: '{0} Prognos',
    scoringhistory: 'Rankhistorik',
    titleformat: '{0} - {1} - Rankning vid {2}'
  },
  language: {
    de: 'Tyska',
    en: 'English',
    sv: 'Svenska'
  },
  loadingcontainer: {
    longerthanexpected: 'Detta tar längre tid än normalt, vänligen vänta...'
  },
  location: {
    button: {
      titleformat: 'Plats: {0}'
    },
    error: {
      chooseotherlocation: 'Välj plats',
      find: 'Hittade inte platsen',
      nolocations: 'Det finns inte några platser i din ectocloud organisation.'
    },
    page: {
      buildings: 'Byggnader',
      controlpanel: 'Kontrollpanel',
      copybuttonitem: 'platsnamn',
      help: 'Hjälp',
      helptitle: 'Användarhjälp',
      locations: 'Platser',
      provisioning: 'Energy Managers'
    },
    tabs: {
      alarms: 'Larm',
      dashboard: 'Dashboard',
      equipment: 'Utrustning',
      exportpage: 'Exportera data',
      graphs: 'Grafer',
      jobs: 'Data-jobb',
      logs: 'Loggar',
      powercontrol: 'Effektstyrning',
      processmaps: 'Processkartor',
      remoteopt: 'Fjärroptimering',
      signalproviders: 'Signaler',
      tools: 'Verktyg',
      userdashboards: 'Egna dashboards'
    }
  },
  locationheader: {
    facilityid: 'Anläggnings-ID:',
    status: 'Status:',
    street: 'Adress:',
    tags: 'Taggar:'
  },
  locationtree: {
    advanced: 'Avancerat...'
  },
  login: {
    action: {
      login: 'Logga in',
      signup: 'Registrera dig'
    },
    env: {
      development: 'Development',
      mock: 'Mock'
    },
    environment: 'Miljö',
    password: {
      label: 'lösenord',
      placeholder: 'Lösenord'
    },
    toast: {
      error: {
        login: 'Inloggning misslyckades'
      },
      loggedout: 'Du har loggat ut',
      sessionexpired:
        'Din session är inte längre giltig, vänligen logga in igen.'
    },
    username: {
      label: 'användarnamn',
      placeholder: 'Användarnamn'
    }
  },
  logtype: {
    alltypes: 'Alla logtyper',
    type: {
      signal: 'Börvärdesändring',
      system: 'System',
      user: 'Manuell'
    }
  },
  logview: {
    addbutton: {
      title: 'Lägg till loggpost'
    },
    columns: {
      author: 'Person',
      location: 'Plats',
      logtype: 'Logtyp',
      message: 'Meddelande',
      valuechange: 'Detaljer'
    },
    helpbutton: {
      title: 'Loggar'
    },
    modal: {
      add: {
        action: {
          failure:
            'Misslyckaes med att spara loggposten, vänligen försök igen.',
          title: 'Spara'
        },
        info1:
          'Välj en plats för din loggpost och använd fritextfältet för att lägga till en kommentar',
        info2:
          'Observera att denna text kommer att vara tillgänglig för alla som har tillgång till den valda platsen',
        text: {
          placeholder: 'Fritextfält för loggposttexten'
        },
        title: 'Lägg till loggpost'
      },
      select: {
        location: 'Välj plats'
      }
    },
    nodatatext: 'Inga loggar hittade',
    search: {
      placeholder: 'Sök loggar'
    },
    title: 'Loggar',
    user: {
      unknown: 'Okänd person'
    }
  },
  modeleditor: {
    failedtoloadsignaldata: 'Misslyckades med att läsa in signalnamn.',
    locationformat: '{0} platser valda',
    selectcolor: 'Välj färg...'
  },
  navbar: {
    adduser: 'Lägg till användare',
    applanguage: 'Språk',
    displayname: 'Namn',
    edituser: 'Redigera användare',
    featureflags: 'Funktionsflaggor',
    logout: 'Logga ut',
    title: 'ectocloud™',
    username: 'Användarnamn',
    usersettings: 'Inställningar'
  },
  nodetypes: {
    building: 'Byggnad',
    equipment: 'Utrustning',
    site: 'Sektor'
  },
  operatorchart: {
    interval: {
      i1d: '1 dag',
      i1h: '1 timme',
      i1m: '1 månad',
      i1w: '1 vecka',
      i1y: '1 år',
      iall: 'Alla'
    },
    signalpicker: {
      addsignal: {
        error: 'Ingen signal vald eller så är signalen redan tillagd.'
      },
      button: {
        deleteallsignals: 'Radera alla valda signaler',
        loadprofile: 'Ladda användarsignaler',
        saveprofile: 'Spare användarsignaler'
      },
      filter: {
        header: 'Filter:'
      },
      load: {
        error: 'Nerladdning av information misslyckades.'
      },
      selectedsignals: {
        header: 'Valda signaler:'
      }
    }
  },
  pagingfooter: {
    pageformat: 'Sida {0}',
    pageformatwithtotal: 'Sida {0} / {1}'
  },
  powercontrol: {
    dispatch: {
      automatic: {
        schedule: {
          failure: {
            disable:
              'Misslyckades med att avaktivera automatisk schemaläggning',
            enable: 'Misslyckades med att aktivera automatisk schemaläggning'
          },
          label: 'Automatisk schemaläggning',
          success: {
            disable: 'Automatisk schemaläggning avaktiverad',
            enable: 'Automatisk schemaläggning aktiverad'
          },
          verify: {
            disable:
              'Är du säker på att du vill avaktivera automatisk schemaläggning?',
            enable:
              'Är du säker på att du vill aktivera automatisk schemaläggning?'
          }
        }
      },
      chart: {
        action: {
          clearsignals: 'Rensa valda signaler',
          exportcsv: {
            toast: 'Exporterar vald data i CSV format. Vänligen vänta...'
          },
          exportcvs: {
            success: 'Exporten lyckades. Nerladdning påbörjas snart.'
          },
          signalpicker: 'Välj Signaler'
        },
        button: {
          downloadcsv: 'Exportera',
          help: 'Telemetri'
        },
        equipments: {
          columnerror: 'Okänd kolumn'
        },
        hour: 't',
        loadingdata: {
          failure: 'Fel uppstod vid inläsning, vänligen prova igen senare.',
          inprogress: 'Laddar data...'
        },
        nodata: 'Ingen data tillgänglig',
        nosignals: 'Inga valda signaler',
        range: {
          all: 'Allt'
        },
        series: {
          nodata: 'Ingen data'
        },
        signalselectormodal: {
          equipment: {
            heading: 'Signalväljare'
          },
          heading: 'Valda signaler',
          title: 'Signalväljare'
        },
        title: 'Telemetri',
        unknownunit: 'n/a'
      },
      download: {
        download: 'Ladda ner',
        item: {
          aggregate: 'Aggregering',
          period: 'Period'
        },
        nosignals: 'Inga signaler valda.',
        options: {
          aggregate: 'Aggregerat',
          average: 'Medel',
          count: 'Count',
          day: '1 dag',
          first: 'First',
          hour: '1 timme',
          last: 'Last',
          max: 'Max',
          mean: 'Mean',
          median: 'Median',
          min: 'Min',
          minute: '1 minut',
          mode: 'Mode',
          month: '1 månad',
          none: 'None',
          raw: 'Rådata',
          sum: 'Sum',
          week: '1 vecka'
        },
        range: "Valt tidsspann, från '{0}' till '{1}'",
        subtitle: 'Konfigurera signalerna',
        title: 'Konfigurera signalexporten'
      },
      recommendedsettings: {
        outsidetemp: 'Utomhustemperatur',
        title: 'Rekommenderade inställningar',
        usebutton: 'Använd rekommenderade inställningar'
      },
      schedule: {
        acutestop: 'Akutstopp',
        affectedbuildings: 'Uppdaterat {0} av {1} påverkade byggnader.',
        dateerror:
          'Schemat måste starta på en framtida tidspunkt för att vara giltigt. Välj ett korrekt datum för att fortsätta.',
        doaction: {
          question: 'Vill du {0} detta schema?',
          title: 'Vänligen bekräfta åtgärd'
        },
        forecasting: {
          decrease:
            'Vid början av schemat kommer effekten minska med ungefär {0}.',
          increase: 'Vid början av schemat kommer effekten öka med ungefär {0}.'
        },
        header: {
          duration: 'Varaktighet för schema',
          effect: 'Effekt under schema',
          impact: 'Temperaturpåverkan',
          start: 'Starttid för schema',
          type: 'Schematyp'
        },
        immediately: 'Direkt',
        invalidamplitude:
          'Ange ett giltigt värde för schemats effekt-parameter.',
        noforecasting:
          'Vi saknar prognosdata för att beräkna ett effektestimat för schemats datum.',
        option: {
          hours: 'timmar',
          startoffset: 'Starta schema om',
          starttime: 'Tidpunkt för schemastart',
          stop: 'Stoppa schema efter'
        },
        power: 'Aktiverade {0}MW av {1}MW tillgänglig effekt',
        schedule: 'Schema',
        showmostaffectedbuilding: 'Visa den mest påverkade byggnaden',
        showmostaffectedbuildings: 'Visa de {0} mest påverkade byggnaderna',
        startinterval: 'Schemat kommer starta {0} {1} och sluta {2} {3}.',
        state: {
          abort: 'avbryta',
          confirm: 'Bekräfta',
          delete: 'radera'
        },
        stopschedulesettings: 'Inställningar för stoppschema',
        temperatureaffectedbuildings: '{0} påverkade byggnader.',
        temperatureimpact: {
          affectedbuildings: {
            empty: 'Inga påverkade byggnader hittades.',
            title: 'Mest påverkade byggnader'
          }
        },
        temperaturerangewarning:
          'Inställningen påverkar komforten i fastigheten och får bara användas i akuta situationer.',
        testinfo: 'Schemat kommer starta {0}.',
        tooltip: {
          abortschedule: 'Avbryt schema',
          deleteschedule: 'Radera schema',
          editschedule: 'Redigera schema',
          gotoschedule: 'Gå till schema'
        }
      },
      scheduled: {
        abort: {
          failure: {
            toast: 'Misslyckades med att avbryta schema'
          },
          success: {
            toast: 'Avbröt schema'
          }
        },
        action: {
          addschedule: 'Lägg till schema',
          confirmremoveall:
            'Är du säker på att du vill ta bort alla icke startade scheman?',
          removeall: 'Ta bort alla icke startade scheman'
        },
        add: {
          failure: {
            create: 'Misslyckades med att lägga till schema',
            edit: 'Misslyckades med att redigera schema'
          },
          failuremulti: {
            create: 'Misslyckades med att lägga till scheman',
            edit: 'Misslyckades med att redigera scheman'
          },
          success: {
            create: 'Lade till schema',
            edit: 'Redigerade schema'
          },
          successmulti: {
            create: 'Lade till scheman',
            edit: 'Redigerade scheman'
          }
        },
        dateerror:
          'Startdatum måste vara i framtiden och före slutdatum. Schemalagd effektsänkning måste vara mindre än minimi effekten för perioden och större än noll.',
        delete: {
          failure: {
            toast: 'Radera schema misslyckades.'
          },
          success: {
            toast: 'Schemat har raderats.'
          }
        },
        deletemulti: {
          failure: {
            toast: 'Radera scheman misslyckades.'
          },
          success: {
            toast: 'Scheman har raderats.'
          }
        },
        events: 'Schemahändelser',
        fetch: {
          failure: 'Hämtning av scheman misslyckades, vänligen prova igen.'
        },
        noschedules: 'Inga scheman tillgängliga.',
        refresh: {
          failure: {
            toast: 'Misslyckades med att ladda om scheman'
          }
        }
      },
      schedulestatus: {
        column: {
          comment: 'Kommentar',
          date: 'Datum',
          status: 'Status',
          user: 'Användare'
        }
      },
      scheduletype: {
        manual: 'Manuellt schema',
        stop: 'Stoppschema'
      }
    },
    manualdispatch: {
      nodata: 'Ingen data tillgänlig',
      savebutton: {
        title: 'Spara'
      }
    },
    nodata:
      'Inga scheman är tillgängliga för den här noden. Välj en annan nod för att använda det här verktyget.',
    schedule: {
      action: {
        title: 'Schema inställningar'
      },
      confirm: {
        above: 'Schemat överskrider tröskelvärdet. Vill du fortsätta?',
        below: 'Schemat underskrider tröskelvärdet. Vill du fortsätta?'
      },
      confirmtitle: 'Är du säker på att du vill lägga till schemat?',
      createschedule: {
        action: 'Skapa',
        title: 'Lägg till schema'
      },
      editschedule: {
        action: 'Redigera',
        title: 'Redigera schema'
      },
      header: 'Schema',
      now: 'Nu',
      preset: {
        amplitude: 'Styrning',
        enddate: 'Sluttidpunkt',
        endoffset: 'Slutar efter',
        header: 'Förkonfigurationer',
        startdate: 'Starttidpunkt',
        startoffset: 'Startar om'
      },
      preset1: 'Konfiguration 1',
      preset2: 'Konfiguration 2',
      preset3: 'Konfiguration 3'
    },
    status: {
      aborted: 'Avbruten',
      finished: 'Avslutad',
      pending: 'Schemalagd',
      running: 'Aktiv'
    },
    tabs: {
      control: 'Kontroll',
      manual: 'Manuell',
      schedule: 'Schema'
    },
    title: 'Power Control',
    unauthorized: {
      text: 'Du saknar rättigheter för att kolla på effektstyrning.'
    }
  },
  relativetime: {
    hoursagoformat: '{0} timmar sen',
    lessthanoneminutago: 'mindre än 1 minut sen',
    minutesagoformat: '{0} minuter sen',
    onehourago: '1 timme sen',
    oneminuteago: '1 minut sen'
  },
  remoteopt: {
    action: {
      enable: {
        failure:
          "Misslyckades aktivera optimeringskurvan för '{0}', vänligen försök igen."
      },
      save: {
        failure: {
          toast:
            "Misslyckades spara optimeringskurvan för '{0}', vänligen försök igen."
        },
        success: {
          toast: 'Optimeringskurvan sparades.'
        }
      }
    },
    button: {
      save: {
        title: 'Spara'
      }
    },
    fetch: {
      error: 'Hämtning misslyckades, vänligen försök igen.'
    },
    info: {
      title: 'Aktivera linjär fjärroptimering'
    },
    nodata: 'Ingen data tillgänglig',
    notreedata: 'Ingen data tillgänglig för visning i fjärroptimeringsvyn.',
    outsidetemplabel: 'Utomhustemperatur:',
    tempoffsetlabel: 'Temperaturförskjutning:',
    title: 'Fjärroptimering'
  },
  select: {
    creatableempty: 'Börja skriv för att lägga till ett värde'
  },
  selectsignalcollectiondialog: {
    tableheader: 'Graf',
    title: 'Välj graf'
  },
  selectusersdialog: {
    title: 'Välj användare'
  },
  sidebar: {
    location: {
      grid: {
        header: 'Nät:'
      },
      search: {
        placeholder: 'Sök efter plats'
      },
      tags: {
        placeholder: 'Filtrera på taggar...'
      }
    },
    sections: {
      home: 'Hem',
      manage: 'Hantera',
      user: 'Användare',
      visualize: 'Visualisera'
    }
  },
  signalproviders: {
    emptylist: 'Inga signaler är tillgängliga för den nuvarande platsen.',
    type: {
      AccumulatorTankEnergyModel: 'Accumulator Tank Energy Model',
      Alarm: 'Alarm',
      COPCoolingForecast: 'COP Kylaprognos',
      COPHeatingForecast: 'COP Värmeprognos',
      CoolingForecast: 'Kylaprognos',
      EctogridSetpointCooling: 'Ectogrid börvärde kyla',
      EctogridSetpointHeating: 'Ectogrid börvärde värme',
      EctogridSetpointModel: 'Ectogrid börvärde modell',
      Equipment: 'Utrustning',
      EroIntegration: 'EroIntegration',
      HeatingForecast: 'Värmeprognos',
      LinearOptimisation: 'Linjäroptimering',
      Meteorology: 'Meteorologi',
      PowerControl: 'Effektstyrning',
      ReversibleHeatPumpCOP: 'Reversible Heat Pump COP',
      ReversibleHeatPumpScheduler: 'Reversible Heat Pump Scheduler',
      SteerablePower: 'Styrbar effekt'
    }
  },
  signals: {
    category: 'Kategori',
    categoryplaceholder: 'Grupper signaler i kategori',
    customcolor: 'Anpassad färg',
    displayname: 'Namn',
    displaynameplaceholder: 'Ersätter signalnamn',
    equipmenttype: 'Utrustningstyp',
    filter: {
      matchall: 'Matcha alla'
    },
    missingtype: 'Saknar signaltyp',
    nocompatiblesignalsfound: 'Inga kompatibla signaler hittade',
    nosignalsfound: 'Inga signaler hittade',
    provider: 'Grupp',
    selectsignal: 'Välj signal...',
    signalid: {
      description:
        'Använd signaltyp istället om du kan. Bör endast användas om signaltypen inte är tillräckligt specifik.',
      label: 'Hårdkodad signal'
    },
    signalprovidertype: 'Signalgrupp',
    signaltype: 'Signaltyp',
    systemname: 'Systemnamn'
  },
  signalsseriestable: {
    addsignals: 'Lägg till signaler',
    xsignal: 'X-signal',
    ysignal: 'Y-signal'
  },
  signaltypes: {
    selectfolder: 'Välj mapp...',
    selecttype: 'Välj signaltyp...',
    selectunit: 'Välj enhet...'
  },
  tenants: {
    error: {
      failedtoload: 'Misslyckades med att läsa in dina organisationer'
    },
    noaccess: 'Du har inte tillgång till någon organisation.',
    noadminrole: 'Du är inte administratör',
    noadminroletext:
      'Du har inte rättigheter att administrera denna delen av applikationen.',
    noresources: 'Inga resurser tillagda i organisationen.',
    noresourcestext: 'Din organisation har inga konfigurerade resurser.',
    title: 'Organisation'
  },
  toast: {
    addaction: {
      failformat: 'Misslyckades med att lägga till {0}',
      successformat: 'Lade till {0}'
    },
    deleteaction: {
      failformat: 'Misslyckade med att ta bort {0}',
      successformat: 'Tog bort {0}'
    },
    editaction: {
      failformat: 'Misslyckades med att uppdatera {0}',
      successformat: 'Uppdaterade {0}'
    },
    getaction: {
      failformat: 'Misslyckades med att hämta {0}'
    }
  },
  tools: {
    powercontrols: 'Power Control',
    title: 'Verktyg'
  },
  treeview: {
    moreresults: 'Läs in fler...'
  },
  format: formatLangString
};

const langDe = {
  admin: {
    alarmrules: {
      activationdelay: 'Activation delay',
      addrule: 'Add rule',
      editrule: 'Edit rule',
      resetoperator: 'Reset operator',
      resetvalue: 'Reset value',
      thresholdoperator: 'Threshold operator',
      thresholdvalue: 'Threshold value'
    },
    alarmselector: {
      placeholder: 'Alarmsignalvorlage auswählen'
    },
    alarmstatuspanel: {
      tooltip: {
        alarmseverites: 'Klasse {0} Alarme',
        noactivealarms: 'Keine aktiven Alarme'
      }
    },
    alarmtemplates: {
      addnew: 'Neue Alarmvorlage hinzufügen',
      addsignal: 'Signal hinzufügen',
      delete: 'Alarmvorlage löschen',
      displayname: 'Anzeigename',
      error: {
        couldnotremove: 'Die Vorlage konnte nicht gelöscht werden.',
        couldnotremoveconflict:
          'Die Vorlage konnte nicht gelöscht werden. Sie wird derzeit von anderen Ressourcen verwendet.',
        duplicate: 'Doppelter Signalname.',
        duplicates:
          'Doppelte Signalnamen. Geben Sie individuelle Signalnamen ein.',
        notallowed:
          'Dies ist eine Systemvorlage und kann nicht entfernt werden.'
      },
      header: 'Alarmvorlagen',
      none: 'Keine',
      severity: 'Schweregrad',
      systemname: 'Name',
      templatename: 'Name der Vorlage',
      type: 'Typ',
      unit: 'Einheit',
      writable: 'Beschreibbar'
    },
    batterychargingthreshold: {
      maxchargerate: 'Maximale Ladeleistung kW',
      maxdischargerate: 'Maximale Entladungsrate kW',
      powerelectricitygridsignal: 'Power Electricity Signal',
      stateofchargemax: 'Maximaler Ladezustand %',
      stateofchargemin: 'Mindestladezustand %',
      timeconstant: 'Zeitkonstante'
    },
    buildings: {
      column: {
        districtheatingfacilityid: 'Gebäude-ID',
        name: 'Name',
        status: 'Status',
        statusdate: 'Datum des Status',
        tags: 'Tags'
      },
      export: 'Exportieren',
      nodatatext: 'Keine Gebäude gefunden',
      numberofbuildings: 'Anzahl der Gebäude',
      statusplaceholder: 'Status',
      tagsplaceholder: 'Tags',
      title: 'Gebäude'
    },
    comfort: {
      askdeploymessage:
        'Die Tools wurden aktualisiert. Möchten Sie die Konfiguration auf das Gerät übertragen?',
      askdeploytitle: 'Tools bereitstellen?',
      deploydialogtitle: 'Komfort-Tools einrichten',
      dialog: {
        create: {
          title: 'Komfort-Tool hinzufügen'
        },
        edit: {
          title: 'Redigieren comfort tool'
        }
      },
      edittool: 'Werkzeug bearbeiten...',
      enum: {
        sign: {
          negative: 'Negativ',
          positive: 'Positiv'
        }
      },
      error: {
        loading: 'Die Daten des Komfort Tools konnten nicht geladen werden.',
        signalfetchfailed:
          'Es konnten keine Signalinformationen abgerufen werden. Die Signalnamen werden nicht angezeigt.'
      },
      name: 'Name',
      parameters: {
        alarmconfiguration: {
          inputconfiguration: {
            maxdiffvalue: 'Max. Differenzwert',
            maxvalue: 'Max-Wert',
            mindiffvalue: 'Min-Differenzwert',
            minvalue: 'Minimalwert',
            setsignal: 'Signal vergleichen',
            signal: 'Signal'
          },
          sectiontitle: 'Alarme',
          timedelay: 'Zeitverzögerung'
        },
        highpass: {
          maxoutputvalue: 'Max Ausgang',
          minoutputvalue: 'Min Ausgang',
          sectiontitle: 'Hochpass',
          sign: 'Zeichen',
          timeconstant: 'Zeitkonstante'
        },
        integralgain: {
          integralgain: 'Integrale Verstärkung',
          integraltime: 'Integrale Zeit',
          maxoutputvalue: 'Max Ausgang',
          maxouttempvalue: 'Max out temp',
          maxroomtempsetpointvalue: 'Max Raumtemperatur-Sollwert',
          maxroomtempvalue: 'Max. Raumtemperatur',
          maxwindspeedvalue: 'Max Windgeschwindigkeit',
          minoutputvalue: 'Min Ausgang',
          minroomtempsetpointvalue: 'Min Raumtemperatur-Sollwert',
          minroomtempvalue: 'Min Raumtemperatur',
          minwindspeedvalue: 'Min Windgeschwindigkeit',
          outtempmeanhours: 'Außentemp. mittlere Stunden',
          outtempmeanmaxintegration: 'Außentemp. Mittelwert max Integration',
          outtempsectiontitle: 'Integralverstärkung Außentemperatur',
          timeconstant: 'Zeitkonstante',
          weekdaysectiontitle: 'Integralverstärkung Wochentag',
          weekendsectiontitle: 'Integralverstärkung Wochenende',
          windspeedsectiontitle: 'Integrale Verstärkung Windgeschwindigkeit'
        },
        pid: {
          derivativetime: 'Abgeleitete Zeit',
          gain: 'Verstärkung',
          inputdeadbanddifflower: 'Eingangstotzone Diff. unten',
          inputdeadbanddiffupper: 'Eingangstotzone Diff. oben',
          integraltime: 'Integralzeit',
          maxoutputvalue: 'Maximaler Ausgang',
          minoutputvalue: 'Min-Ausgang',
          sectiontitle: 'PID',
          sign: 'Zeichen'
        },
        sectionstitle: 'Algorithmus-Einstellungen'
      },
      signals: {
        error: {
          failedtoload: 'Tool Daten konnten nicht geladen werden',
          failedtosetsignal: 'Signal kann nicht gesetzt werden'
        },
        outdoortemperature: 'Außentemperatur',
        roomtemperature: 'Raumtemperatur',
        signalgroup: 'Komfort-Signale',
        signalgroupformat: 'Komfortsignale - {0} Heizkörper',
        signalnamecolumn: 'Name',
        tapwater: 'Leitungswasser',
        windspeed: 'Windgeschwindigkeit'
      }
    },
    createbuilding: {
      error: {
        creating:
          'Das Hinzufügen eines Gebäudes ist fehlgeschlagen, unbekannter Fehler.',
        creatingsiblingnameconflict:
          'Das Hinzufügen eines Gebäudes ist fehlgeschlagen, da es bereits ein anderes Gebäude mit demselben Namen als übergeordnetes Gebäude gibt.'
      },
      state: {
        creating: 'Gebäude erstellen...'
      },
      success: {
        noerrorsformat: 'Erfolgreich neues Gebäude {0} hinzugefügt.',
        witherrorsformat:
          'Neues Gebäude {0} hinzugefügt, aber bei der Initialisierung ist ein Fehler aufgetreten. {1}'
      }
    },
    createlocation: {
      addanotherformat: 'Weitere {0} hinzufügen',
      addressfieldplaceholder: 'Wählen Sie einen Ort',
      addresslabel: 'Adresse:',
      addressplaceholder: 'Straße',
      choosetemplate: 'Vorlage auswählen',
      configurelocation: {
        title: 'Standort auswählen'
      },
      configurequipment: {
        title: 'Ausrüstung konfigurieren'
      },
      configureweather: {
        searchplaceholder: 'Suche nach Standort',
        sectionheader: 'Wetterpunkt',
        title: 'Wetterpunkt wählen'
      },
      connectionformat: 'Verbindung {0}',
      connections: {
        manage: 'Verwalten',
        name: 'Name:',
        newconnection: '(neue Verbindung)',
        noconnectionsinfotext:
          'Keine Verbindungen in der Vorlage gefunden. Sie müssen eine neue Verbindung hinzufügen, um Daten zu empfangen und zu senden.',
        noexistingconnection:
          'Das vorhandene Gerät hat keine Verbindungen. Um dieses Gerät zu verwenden, richten Sie eine Verbindung für das bestehende Gebäude ein.',
        slaveid: 'Slave-ID',
        title: 'Verbindungen',
        type: 'Typ'
      },
      coordinate: 'Standort',
      createnewdevice: 'Neues Gerät hinzufügen',
      dashboardcollection: 'Dashboard-Sammlung',
      devicelabel: 'Energiemanager:',
      equipment: {
        enable: 'Aktivieren',
        name: 'Name',
        noequipment:
          'Diese Vorlage enthält keine Gerätekonfiguration. Bearbeiten Sie diese Vorlage, um Gebäude hinzuzufügen, bevor Sie sie verwenden können.',
        type: 'Typ'
      },
      equipmentgrouptemplate: 'Vorlage erstellen',
      error: {
        addingequipment: 'Initialisierung der Ausrüstung fehlgeschlagen.',
        creating: 'Ort konnte nicht hinzugefügt werden, unbekannter Fehler.',
        creatingsiblingnameconflict:
          'Das Hinzufügen eines Ortes ist fehlgeschlagen, da es bereits einen anderen Ort mit demselben Namen als übergeordneten Ort gibt.',
        fetchdeviceconfig:
          'Die Gerätekonfiguration für das Gerät konnte nicht abgerufen werden, versuchen Sie es erneut.',
        initconnection: 'Neue Verbindung konnte nicht initialisiert werden.',
        missingconnection: 'Verbindung wurde nicht richtig instanziiert.'
      },
      existingconnectioninfo:
        'Da Sie eine Verbindung zu einem bestehenden Gerät herstellen, wird automatisch eine neue Verbindung mit einer neuen Slave-ID hinzugefügt, um die Geräte an diesem Standort zu verwalten.',
      loaddashboarderror:
        'Dashboard-Sammlung konnte nicht aus der Gebäudevorlage geladen werden',
      namelabel: 'Name:',
      nameplaceholder: 'Name',
      none: 'Keine',
      parentlabel: 'Übergeordnet:',
      selectdashboardcollection: 'Dashboard-Sammlung auswählen',
      selectexistingdevice: 'Vorhandenes EM auswählen',
      state: {
        addingdashboardcollectionrelation:
          'Hinzufügen der Dashboard-Sammlung...',
        addingequipment: 'Ausrüstung hinzufügen...',
        addingweather: 'Wetterpunkt hinzufügen...',
        creating: 'Standort erstellen...',
        initconnection: 'Neue Verbindung initialisieren...',
        updatingweather: 'Wetterpunkt aktualisieren...'
      },
      success: {
        noerrorsformat: 'Erfolgreich neuen Standort {0} hinzugefügt.',
        witherrorsformat:
          'Neuen Standort {0} hinzugefügt, aber während der Initialisierung ist ein Fehler aufgetreten. {1}'
      },
      titleformat: 'Neues hinzufügen {0}'
    },
    createsite: {
      error: {
        creating:
          'Hinzufügen eines Standorts fehlgeschlagen, unbekannter Fehler.',
        creatingsiblingnameconflict:
          'Das Hinzufügen eines Standortes ist fehlgeschlagen, da es bereits einen anderen Standort mit demselben Namen als übergeordneten Standort gibt.'
      },
      state: {
        creating: 'Ost erstellen...'
      },
      success: {
        noerrorsformat: 'Neuen Standort erfolgreich hinzugefügt {0}.',
        witherrorsformat:
          'Neuer Standort {0} hinzugefügt, aber während der Initialisierung ist ein Fehler aufgetreten. {1}'
      }
    },
    dashboardcollection: {
      add: 'Dashboard-Sammlung hinzufügen',
      addfailure: 'Hinzufügen der Dashboard-Sammlung fehlgeschlagen',
      addsuccess: 'Dashboard-Sammlung hinzugefügt',
      current: 'Ausgewählte Sammlung',
      defaultdashboard: 'Standard',
      edit: 'Dashboard-Sammlung bearbeiten',
      editfailure: 'Aktualisierung der Dashboard-Sammlung fehlgeschlagen',
      editsuccess: 'Aktualisierte Dashboard-Sammlung',
      get: {
        failure: 'Dashboard-Sammlungen können nicht abgerufen werden'
      },
      nodata: 'Es sind keine Dashboard-Sammlungen verfügbar',
      noselectedcollection: 'Keine Sammlung ausgewählt',
      noselecteddashboards: 'Keine ausgewählten Dashboards',
      remove: {
        failure: 'Dashboard-Sammlung kann nicht gelöscht werden',
        success: 'Entfernte Dashboard-Sammlung'
      },
      select: 'Dashboard-Sammlung auswählen',
      selectdashboards: 'Dashboards auswählen',
      selectincontext: 'Sammlung auswählen',
      setdefault: 'Standard-Sammlung festlegen',
      setdefaultdashboard: 'Auf Standard eingestellt',
      title: 'Dashboard-Sammlungen'
    },
    dashboards: {
      add: 'Dashboard hinzufügen',
      added: {
        success: 'Dashboard hinzugefügt'
      },
      change: {
        failure: 'Dashboard konnte nicht aktualisiert werden',
        success: 'Dashboard aktualisiert'
      },
      column: {
        dashboard: {
          data: 'Dashboard-Daten'
        },
        description: 'Beschreibung',
        name: 'Name'
      },
      comparebarchart: {
        series: 'Wert'
      },
      configuredashboardcollection: 'Dashboard-Sammlung auswählen',
      datasources: {
        lastvalue: {
          settings: 'Einstellungen für den letzten Wert',
          uselastbeforerange: 'Teilwert am Ende des Bereichs überspringen'
        },
        node: 'Standort',
        proptexts: {
          defaulttotimerange: 'Zeitbereich-basiert',
          forceexactvalues: 'Exakte Werte erzwingen',
          hoursbackplaceholder: 'Zeitbereich vom Dashboard verwenden',
          hoursbackward: 'Stunden rückwärts',
          hoursforward: 'Stunden vorwärts',
          node: 'Der zum Abrufen von Daten verwendete Knoten. Leer lassen, um den aktuellen Knoten zu verwenden.',
          signalcurves: 'Signal-Kurven',
          signals: 'Signale'
        }
      },
      deletebutton: 'Dashboard löschen',
      deletepanel: 'Panel löschen',
      duplicate: 'Panel duplizieren',
      edit: 'Dashboard bearbeiten',
      empty:
        'Dieses Dashboard ist leer. Fügen Sie Panels hinzu, um Informationen anzuzeigen.',
      forms: {
        signaldetails: {
          editdialog: {
            addtitle: 'Signal hinzufügen',
            title: 'Signaleinstellungen bearbeiten'
          }
        },
        signalnames: {
          addconstant: 'Konstante hinzufügen',
          addfromexisting: 'Basierend auf Signal hinzufügen...',
          addsignal: 'Signalname hinzufügen',
          category: 'Kategorie',
          curvedata: 'Kurvensignale',
          empty:
            'Keine Signale hinzugefügt. Drücken Sie eine der Schaltflächen zum Hinzufügen, um zu beginnen.',
          modaltitle: 'Signaltyp aus Signal hinzufügen',
          name: 'Name',
          timerange: 'Für Zeitbereich'
        }
      },
      getdashboards: {
        failure: 'Dashboards können nicht abgerufen werden',
        nodata: 'Es gibt keine verfügbaren Dashboards'
      },
      initialdescription: 'Beschreibung',
      initialname: 'Neues Dashboard',
      loadfile: {
        failure: 'Dashboard-Dokument konnte nicht geladen werden'
      },
      loadobject: {
        failure: 'Dashboard konnte nicht geladen werden'
      },
      panels: {
        add: 'Panel hinzufügen',
        alarmcount: 'Alarmzähler',
        alarmlist: 'Alarmliste',
        alarmmap: 'Alarmkarte',
        alarmstatuslist: 'Alarmstatus-Liste',
        barchart: 'Balkendiagramm',
        barchartcompare: 'Diagramm der Vergleichswerte',
        buildingstatuses: 'Gebäudezustände',
        customprocessmap: 'Benutzerdefinierte Prozesslandkarte',
        edit: 'Bedienfeld bearbeiten',
        gauge: 'Messgerät',
        id: 'ID',
        initialtitle: 'Titel',
        linechart: 'Liniendiagramm',
        locationmap: 'Karte',
        minmaxsettings: 'Min/max for chart values',
        nodename: 'Name des Standorts',
        placeholders: {
          text: 'Der Text, der im Panel angezeigt werden soll.'
        },
        processmap: 'Prozesskarte',
        scatterchart: 'Punktediagramm',
        scatterchartxaxis: 'Punktediagramm X-Achse',
        signalcurveeditor: 'Signalkurven-Editor',
        signalslist: 'Signalliste',
        text: 'Text',
        title: 'Titel',
        type: 'Typ',
        types: {
          alarmcount: {
            seealarms: 'Siehe Alarme'
          },
          alarmlist: {
            pagesize: 'Maximale Anzahl von Alarmen'
          },
          barchart: {
            stacking: 'Stapeln',
            stackingoption: {
              disabled: 'Deaktiviert',
              normal: 'Normal',
              percent: 'Prozentsatz'
            }
          },
          gauge: {
            fontsize: 'Schriftgröße',
            hideunit: 'Einheit ausblenden',
            maxvalue: 'Maximaler Wert',
            minvalue: 'Min-Wert',
            type: 'Typ des Messgeräts',
            types: {
              bar: 'Balken',
              meter: 'Messgerät',
              solidgauge: 'Massives Messgerät',
              value: 'Wert'
            }
          },
          linechart: {
            chartlayout: 'Layout des Diagramms',
            hidelegend: 'Legende ausblenden',
            paddingleft: 'Füllung links',
            paddingright: 'Füllung rechts',
            refreshinterval: 'Aktualisierungsintervall (Minuten)',
            showcurrenttime: 'Aktuelle Zeit anzeigen'
          },
          map: {
            autoselect: 'Automatisch erstes Signal auswählen',
            hidecontrols: 'Steuerelemente ausblenden'
          },
          nodename: {
            node: 'Standort',
            nodeplaceholder:
              'Der zum Abrufen von Daten verwendete Knoten. Leer lassen, um den aktuellen Knoten zu verwenden.'
          },
          scatterchart: {
            xaxisprovidername: 'X-Achse: Name des Anbieters',
            xaxissignalname: 'X-Achse Signalname'
          }
        }
      },
      remove: {
        failure: 'Dashboard konnte nicht gelöscht werden',
        success: 'Dashboard entfernt'
      },
      save: {
        failure: 'Dashboard kann nicht hinzugefügt werden',
        success: 'Dashboard hinzugefügt'
      },
      sections: {
        aggregation: 'Aggregation',
        alarm: 'Alarmeinstellungen',
        barchart: 'Balkendiagramm',
        curverange: 'Kurvenbereich',
        curvexaxis: 'Vertikale X-Achse',
        datarange: 'Zeitbereich',
        gauge: 'Messgerät',
        linechart: 'Liniendiagramm',
        map: 'Karte',
        signals: 'Signale'
      },
      title: 'Dashboards'
    },
    deploytemplates: {
      deploy: 'Bereitstellen',
      deploysuccessfulformat:
        'Bereitstellung erfolgreich abgeschlossen bis: {0}',
      failedtoloadtenants: 'Fehler beim Laden der Mieter',
      tenants: 'Bereitstellung von Vorlagen für Mieter'
    },
    deprovisionreason: {
      faulty: 'Fehlerhaft',
      other: 'Andere'
    },
    ectotable: {
      addroom: 'Raum hinzufügen...',
      addroomtitle: 'Ectotable Raum hinzufügen',
      addscreencontent: 'Bildschirminhalt hinzufügen',
      addscreencontentdialog: 'Bildschirminhalt hinzufügen',
      background: 'Hintergrundbild',
      company: 'Unternehmen',
      contactperson: 'Kontaktperson',
      editroomtitle: 'Ectotable Raum bearbeiten',
      editscreencontent: 'Bildschirminhalt bearbeiten',
      existingfile: 'Datei',
      fullscreentype: 'Vollbild',
      location: 'Standort',
      name: 'Name',
      rooms: 'Ectotable Räume',
      screencontent: 'Bildschirminhalt',
      screencontents: 'Bildschirminhalte',
      screenname: 'Bildschirmname',
      text: 'Text',
      title: 'Ectotable',
      typeformat: 'Bildschirm {0} Typ',
      video: 'Video'
    },
    editbuilding: {
      conflict: {
        facilityid:
          'Konflikt: Es gibt bereits ein Gebäude mit dieser Facility id.'
      },
      delete: {
        title: 'Wollen Sie das Gebäude wirklich löschen?'
      },
      deletelocation: {
        text: 'Durch das Löschen dieses Gebäudes werden alle Ausrüstungen und zugehörigen Signale entfernt.',
        title: 'Gebäude löschen'
      },
      savelocation: 'Gebäude speichern',
      update: {
        failed: 'Aktualisierung des Gebäudes fehlgeschlagen'
      },
      updated: {
        building: 'Gebäude aktualisiert'
      }
    },
    editlocation: {
      addlogdialog: {
        description: 'Log-Eintrag für {0} hinzufügen.',
        error: 'Log-Eintrag konnte nicht hinzugefügt werden.',
        placeholder: 'Eintrag',
        success: 'Log-Eintrag zu {0} hinzugefügt.'
      },
      addlogentry: 'Log-Eintrag hinzufügen',
      addnewbuilding: 'Neues Gebäude hinzufügen',
      addnewrootsite: 'Neue Root-Site hinzufügen',
      addnewsite: 'Neuen Standort hinzufügen',
      deletelocation: {
        button: 'Standort löschen',
        text: 'Durch das Löschen dieses Standortes werden alle Ausrüstungen und zugehörigen Signale entfernt.',
        title: 'Wollen Sie den Standort wirklich löschen?'
      },
      details: 'Einzelheiten',
      editmeteorology: 'Meteorologiepunkt bearbeiten',
      editnotifications: {
        button: 'Benachrichtigungen bearbeiten'
      },
      editparents: {
        button: 'Parents bearbeiten',
        title: 'Übergeordnete Standorte bearbeiten'
      },
      editprocessmap: 'Prozesskarte auswählen',
      edittools: 'Tools verwalten',
      editusers: 'Benutzer bearbeiten',
      error: {
        failedtoloadmappings: 'Benutzerdaten konnten nicht geladen werden.',
        noparentlocation: 'Muss mindestens einen übergeordneten Standort haben.'
      },
      fields: {
        activealarms: 'Aktive Alarme:',
        buildingstatus: 'Gebäudestatus:',
        childlocations: 'Untergeordnete Orte:',
        districtheatingfacilityid: 'Einrichtungs-ID:',
        equipment: 'Ausrüstung:',
        grids: 'Netze:',
        linearoptimisations: 'Lineare Optimierungen:',
        name: 'Name:',
        parents: 'Übergeordnete:',
        powercontrols: 'Leistungsregelungen:',
        street: 'Straße:',
        type: 'Typ:'
      },
      placeholder: {
        districtheatingfacilityid: 'Einrichtungs-ID',
        name: 'Name',
        street: 'Straße'
      },
      savelocation: 'Standort speichern',
      tags: 'Tags',
      tools: {
        comfort: {
          batchcommonsection: {
            title: 'Gemeinsame Einstellungen'
          },
          batchdeploy: 'Tools bereitstellen',
          batchedittools: 'Stapelweise bearbeiten/hinzufügen',
          batchtitle: 'Komfort-Tools bearbeiten',
          columns: {
            radiatorname: 'Name des Heizkörpers'
          },
          error: {
            batchmultipledevices:
              'Falsche Gerätekonfiguration. Heizkörper müssen von demselben Gerät verwaltet werden, damit die Stapelbearbeitung funktioniert.'
          },
          noradiators: 'An diesem Ort wurden keine Heizkörper gefunden.',
          title: 'Komfort'
        },
        configured: 'Konfiguriert.',
        manage: 'Verwalten',
        readytoadd: 'Bereit zum Hinzufügen.',
        status: 'Status',
        temperatureimpact: 'Temperaturauswirkung',
        tooltype: 'Werkzeugtyp',
        unsupported:
          'Nicht unterstützt. Der Standort hat nicht alle erforderlichen Parameter.'
      },
      users: {
        addtosublocations: 'Zu Unterorten hinzufügen',
        failure: 'Aktualisierung der Benutzer fehlgeschlagen',
        name: 'Name',
        nousersfound: 'Keine Benutzer gefunden.',
        searchplaceholder: 'Nach Benutzer suchen',
        title: 'Benutzer am Ort bearbeiten',
        updated: 'Benutzer aktualisiert',
        username: 'Benutzername'
      }
    },
    editsite: {
      delete: {
        title: 'Wollen Sie den Standort wirklich löschen?'
      },
      deletelocation: {
        text: 'Durch das Löschen dieses Standortes werden alle Ausrüstungen und zugehörigen Signale entfernt.',
        title: 'Standort löschen'
      },
      savelocation: 'Standort speichern',
      update: {
        failed: 'Aktualisierung des Standortes fehlgeschlagen'
      },
      updated: {
        site: 'Standort aktualisiert'
      }
    },
    elvaco: {
      dialog: {
        available: {
          signals: 'Verfügbare Signale'
        },
        choose: {
          account: 'Elvaco-Konto auswählen'
        },
        create: {
          title: 'Elvaco-Werkzeug hinzufügen'
        },
        edit: {
          title: 'Elvaco-Werkzeug bearbeiten'
        },
        no: {
          available: {
            signals: 'Es sind keine Signale verfügbar'
          },
          selected: {
            signals: 'Es gibt keine ausgewählten Signale'
          }
        },
        selected: {
          signals: 'Ausgewählte Signale'
        }
      },
      error: {
        addresswithoutcity:
          'Bei der Suche nach einer Adresse müssen Sie eine Stadt eingeben.',
        addresswithoutnumber:
          'Sie müssen eine Straßennummer eingeben, wenn Sie nach einer Adresse suchen.',
        invalidsyntax: 'Ungültige Suchsyntax.'
      },
      filter: {
        address: 'Adresse',
        alarm: 'Alarm',
        clearall: 'Alle löschen',
        meterName: 'Name des Zählers',
        title: 'Filter'
      },
      get: {
        accounts: {
          failed: 'Abruf von Elvaco-Konten fehlgeschlagen'
        },
        signals: {
          failed:
            'Signale konnten nicht abgerufen werden. Überprüfen Sie die Suchfelder.'
        },
        tool: {
          failed: 'Elvaco-Tool konnte nicht abgerufen werden'
        }
      }
    },
    energymanager: {
      application: 'Anwendung',
      changeiotdevice: 'Zu neuem Gerät wechseln',
      confirmunlink: 'Verbindung zum Gerät trennen',
      confirmunlinktextformat:
        'Möchten Sie die Verbindung zu dem Gerät mit der MAC-Adresse {0} trennen?',
      connections: 'Verbindungen',
      createconfig: 'Gerätekonfiguration hinzufügen',
      currentmd5: 'Aktuelle MD5',
      deploy: 'Bereitstellen',
      deploymd5: 'MD5 bereitstellen',
      deployment: 'Bereitstellung',
      deploymentdetails: 'Bereitstellungsdetails',
      deploymenterrorformat: 'Aktualisieren fehlgeschlagen (Code {0})',
      deploymentstatus: 'Bereitstellungsstatus',
      deploynopairinginfoformat:
        'Dieses Gerät ist zur Zeit mit keinem echten Energiemanager verbunden. Bitte wählen Sie im Reiter {0} ein echtes Gerät aus, um die Bereitstellung der Konfigurationsdatei zu aktivieren.',
      devicefile: 'Gerätedatei',
      devicemac: 'Device MAC',
      devicesettings: 'Geräteeinstellungen',
      error: {
        nodeviceconfig:
          'Wir konnten keine gültige Gerätekonfiguration finden, die mit diesem Energiemanager verbunden ist. Sie können versuchen, eine neue Gerätekonfiguration hinzuzufügen, um das Problem zu beheben.'
      },
      file: 'Datei',
      information: 'Information',
      loading: 'Laden...',
      missingmac: 'Sie müssen eine MAC-Adresse eingeben.',
      missingmodbusaddressess:
        'Es gibt Signale ohne Modbus-Adresse. Sie werden bei bestimmten Signalen benötigt, um eingesetzt werden zu können.',
      noiothubdevice:
        'Kein IoT-Hub-Gerät gefunden. Überprüfen Sie, ob die MAC-Adresse einem konfigurierten Energiemanager entspricht.',
      nopairinginfo:
        'Derzeit besteht keine Verbindung zu einem realen Energiemanager. Bitte wählen Sie ein Gerät aus, um den Datenfluss zu aktivieren.',
      pairingerror:
        'Die Verbindung zu dem neuen Gerät konnte nicht hergestellt werden.',
      pairingsuccess: 'Erfolgreich mit neuem Gerät verbunden.',
      pairwithdeviceandcopybutton: 'Verbinden und Einstellungen kopieren',
      pairwithdevicebutton: 'Verbinden',
      pairwithdeviceformat:
        'Möchten Sie eine Verbindung zu einem Gerät mit der MAC-Adresse {0} herstellen?',
      pairwithdevicetitle: 'Mit Gerät verbinden',
      pairwithotherdeviceformat:
        'Möchten Sie die Verbindung zum Gerät mit der MAC-Adresse {0} trennen und sich mit dem Gerät mit der MAC-Adresse {1} verbinden?',
      pairwithotherdevicerelinkformat:
        'Wenn Sie dies bestätigen, trennen Sie die Verbindung zum Gerät mit der MAC-Adresse {0} und verbinden sich mit dem Gerät mit der MAC-Adresse {1}. Wenn Sie möchten, können Sie die Einstellungen (Netzwerkkonfiguration und Tags) vom alten Gerät auf das neue Gerät kopieren.',
      pending: 'Ausstehend',
      readytodeploy: 'Bereit zum Einsatz',
      reportedmd5: 'Gemeldete MD5',
      saveiotdevice: 'Gerät speichern',
      selectiotdevice: 'Mit Gerät verbinden',
      showdevicefiles: 'Gerätedateien anzeigen',
      signalsfile: 'Signaldatei',
      title: 'Energymanager',
      toolsfile: 'Tools-Datei',
      unlinkiotdevice: 'Verbindung zum aktuellen Gerät aufheben',
      unpairingerror: 'Die Verbindung zum Gerät konnte nicht getrennt werden.',
      unpairingsuccess: 'Die Verbindung zum Gerät wurde erfolgreich getrennt.',
      uptodate: 'Die Konfigurationsdateien sind auf dem neuesten Stand.',
      uptodatewaitingforprocessreload:
        'Die Konfigurationsdateien sind jetzt auf dem neuesten Stand, aber wir warten darauf, dass der Energiemanager seine Datenbank neu lädt.',
      uptodatewithchanges:
        'Es gibt neue Änderungen, die umgesetzt werden müssen.',
      validation: {
        error: {
          address_conflict: 'Adressenkonflikt',
          duplicate_outtemp: 'Doppeltes Außentemperatursignal',
          missing_outtemp: 'Fehlendes Außentemperatursignal',
          system_name_conflict: 'Signalnamenskonflikt'
        },
        fetchfailed:
          'Fehler beim Abrufen der Liste möglicher Konfigurationsfehler, bitte versuchen Sie es erneut.',
        title: 'Konfigurationsfehler'
      },
      version: 'Version',
      versions: 'Anwendungsversionen'
    },
    energymanagers: {
      devicename: 'Gerätename',
      failedtofetchsignalsconfig:
        'Konfiguration der Energiemanager-Signale konnte nicht abgerufen werden',
      heartbeat: 'Heartbeat',
      locations: 'Standorte',
      nodevice: 'Kein Gerät',
      search: 'Nach Gerätenamen suchen',
      searchplaceholder: 'Gerätename',
      status: 'Status'
    },
    equipment: {
      add: 'Neue Ausrüstung hinzufügen',
      addbutton: 'Hinzufügen',
      addfromtemplate: {
        addingconnection: 'Hinzufügen von Verbindungen für die Ausrüstung...',
        error: 'Hinzufügen von Ausrüstungen aus der Vorlage fehlgeschlagen',
        errorafterinit:
          'Neue Ausrüstungen wurden erfolgreich hinzugefügt, aber es konnten keine neuen Daten abgerufen werden. Haben Sie Probleme mit der Verbindung?',
        errorconnection: 'Neue Verbindung konnte nicht erstellt werden',
        instantiating: 'Hinzufügen von Ausrüstungen aus der Vorlage...',
        success: 'Neue Ausrüstungen wurden erfolgreich hinzugefügt.',
        title: 'Aus Vorlage hinzufügen'
      },
      addnewlinearoptimisation: 'Lineare Optimierung hinzufügen',
      addnewpowercontrol: 'Leistungsregelung hinzufügen',
      addsignal: {
        error: 'Signal konnte nicht hinzugefügt werden.',
        success: 'Signal erfolgreich hinzugefügt.'
      },
      alarmtemplate: 'Alarmvorlage',
      algorithmtype: {
        basic: 'Basic',
        title: 'Algorithmus',
        tracking: 'Tracking',
        trackingventilation: 'Tracking Ventilation',
        transmit: 'Übertragen'
      },
      chooseexistingem: 'Wählen Sie einen vorhandenen Energy Manager',
      configerror:
        'Beim Abrufen der Konfigurationsdaten ist ein Fehler aufgetreten.',
      confirmdelete: {
        message: 'Sind Sie sicher, dass Sie diese Ausrüstung löschen wollen?',
        title: 'Löschen bestätigen'
      },
      confirmdeletesignal: {
        message: 'Sind Sie sicher, dass Sie {0} löschen wollen?'
      },
      createnewem: 'Neuen Energy Manager hinzufügen',
      delete: {
        error: 'Ausrüstung konnte nicht gelöscht werden.',
        success: 'Die Ausrüstung wurde erfolgreich gelöscht.'
      },
      deletedatapoint: {
        buttontitle: 'Signalwerte löschen...',
        deleteformat:
          'Sind Sie sicher, dass Sie Werte aus {0} entfernen möchten?',
        enddate: 'Endtermin',
        nodata: 'Im ausgewählten Zeitraum wurden keine Daten gefunden.',
        startdate: 'Startdatum',
        success: 'Signalwerte erfolgreich gelöscht',
        title: 'Signalwerte löschen'
      },
      deleteequipment: 'Ausrüstung löschen',
      deletesignal: {
        error: 'Signal konnte nicht gelöscht werden.',
        success: 'Das Signal wurde erfolgreich gelöscht.'
      },
      deploytool: 'Tool bereitstellen',
      description: 'Beschreibung',
      descriptionlabel: 'Beschreibung',
      deviceparent: 'Gerät',
      editlinearoptimisation: 'Lineare Optimierung bearbeiten',
      editpowercontrol: 'Leistungsregelung bearbeiten',
      energymanagerfromformat: 'Energy Manager von {0}',
      error: {
        mustspecifyname: 'Sie müssen einen Namen angeben.',
        nodevice:
          'Keine Geräte. Bitte fügen Sie Geräte aus einer Vorlage hinzu oder fügen Sie einen Energy Manager hinzu, um fortzufahren.',
        nogridequipment:
          'Das Netz der Stammebene enthält keine Ausrüstung. Besuchen Sie einen untergeordneten Standort, um die Ausrüstung dort zu verwalten.',
        nositeequipment:
          'Das Hinzufügen oder Verwalten der Ausrüstung für Standorte wird nicht unterstützt.'
      },
      files: {
        button: 'Gerätedateien...',
        title: 'Gerätedateien'
      },
      information: 'Information',
      linearoptimisation: 'Lineare Optimierung',
      name: 'Name der Ausrüstung',
      namelabel: 'Name',
      nameplaceholder: 'Name',
      none: 'Keine',
      notools: 'Keine Tools verfügbar',
      numberofpoints: 'Anzahl der Punkte',
      powercontrolformat: 'Leistungsregelung {0}',
      powercontroltype: {
        cooling: 'Kühlung',
        heating: 'Heizung',
        title: 'Typ'
      },
      processmap: {
        error: 'Fehler beim Aktualisieren der Prozesskarte auf Ausrüstung',
        updatesuccess: 'Prozesskarte aktualisiert auf Ausrüstung'
      },
      remove: {
        error: {
          relationconflict:
            'Dieses Tool konnte nicht gelöscht werden, weil es von etwas anderem verwendet wird.'
        }
      },
      request: {
        fetchtoolsfailed: 'Tools konnten nicht abgerufen werden',
        updatedem: 'Energy Manager erfolgreich aktualisiert',
        updatedtool: 'Tool erfolgreich aktualisiert',
        updateemfailed: 'Energy Manager konnte nicht aktualisiert werden',
        updatetoolfailed: 'Tool konnte nicht aktualisiert werden'
      },
      section: {
        connections: 'Verbindung',
        deployment: 'Einstellungen einrichten',
        details: 'Details',
        files: 'Dateien',
        iotdevice: 'Hardware',
        signals: 'Signale',
        tools: 'Werkzeuge'
      },
      selectequipment: 'Bitte wählen Sie eine Ausrüstung, um fortzufahren.',
      title: 'Ausrüstung',
      tool: {
        batterychargingthreshold: 'Schwellenwert für die Batterieladung',
        comfort: 'Komfort',
        dialog: {
          create: {
            title: '{0} Tool hinzufügen'
          },
          edit: {
            title: '{0} Tool bearbeiten'
          }
        },
        ecoguard: 'Ecoguard',
        elvaco: 'Elvaco',
        linearoptimisation: 'Lineare Optimierung',
        powercontrol: 'Leistungsregelung',
        powerdeltacontrol: 'Power Delta Control'
      },
      tools: 'Werkzeuge',
      type: 'Art der Ausrüstung'
    },
    equipmentgroup: {
      addequipment: {
        add: 'Ausrüstung hinzufügen',
        alarms: 'Alarmvorlage',
        placeholder: 'Ausrüstungstyp auswählen',
        title: 'Ausrüstung hinzufügen',
        tools: 'Tools'
      },
      connections: 'Verbindungen',
      energymanager: {
        addconnection: 'Verbindung hinzufügen',
        connection: 'Verbindung',
        signoff: {
          button: 'Abmelden',
          error: 'Verbindung konnte nicht aktualisiert werden',
          name: 'Name',
          notes: 'Anmerkungen',
          signed: 'Abgezeichnet von {0}',
          title: 'Abmelden'
        }
      },
      equipment: {
        alarms: 'Alarmvorlage',
        description: 'Beschreibung',
        name: 'Name',
        signaltype: 'Signaltyp',
        tools: 'Werkzeuge'
      }
    },
    equipmentgrouptemplates: {
      addnew: 'Neue Gebäudevorlage hinzufügen',
      confirmdelete: {
        button: 'Löschen',
        text: 'Sind Sie sicher, dass Sie diese Gebäudevorlage löschen möchten? Gebäude, die mit dieser Vorlage hinzugefügt wurden, sind davon nicht betroffen.',
        title: 'Löschen bestätigen'
      },
      delete: 'Gebäudevorlage löschen',
      deletefailed: 'Löschen der Vorlage fehlgeschlagen.',
      deletesuccess: 'Vorlage gelöscht.',
      error: {
        invalidname: 'Bitte geben Sie einen gültigen Namen ein'
      },
      name: 'Name',
      save: 'Gebäudevorlage speichern',
      templatename: 'Name der Vorlage',
      title: 'Gebäudevorlagen'
    },
    equipmentsignal: {
      address: 'Adresse',
      connection: 'Verbindung',
      description: 'Beschreibung',
      displayname: 'Anzeigename',
      editformat: 'Wert {0} bearbeiten',
      failedtoloadvalues: 'Die Signalwerte konnten nicht geladen werden.',
      failedtosetsignal: 'Signalwert konnte nicht aktualisiert werden.',
      noconnection: 'Keine',
      signaltype: 'Signaltyp',
      systemname: 'Name',
      title: 'Signale',
      type: 'Typ',
      unit: 'Einheit',
      value: 'Wert'
    },
    equipmentsignaltemplates: {
      addnew: 'Neuen Gerätetyp hinzufügen',
      delete: 'Gerätetyp löschen',
      equipmentname: 'Name',
      header: 'Ausrüstungsarten',
      name: 'Name',
      parenttype: 'Übergeordneter Typ (optional)',
      processmap: 'Prozesskarte'
    },
    equipmenttemplates: {
      addsignal: 'Signal hinzufügen',
      dataformat: 'Datenformat',
      defaultvalue: 'Standardwert',
      description: 'Beschreibung',
      displayname: 'Anzeigename',
      error: {
        cannotremoveparentsignal:
          'Das Signal der übergeordneten Vorlage kann nicht gelöscht werden.',
        displayname: 'Anzeigename für Signal eingeben',
        duplicate:
          'Doppelter Signalname. Geben Sie einen individuellen Signalnamen ein.',
        duplicates:
          'Doppelte Signalnamen. Geben Sie individuelle Signalnamen ein.',
        failedtofetch: 'Vorlage kann nicht abgerufen werden',
        missingfields:
          'Füllen Sie alle erforderlichen Felder aus, bevor Sie speichern.',
        missingname: 'Name für Signal eingeben',
        missingnames: 'Name für alle Signale eingeben',
        missingtype: 'Typ für Signal eingeben',
        missingunit: 'Signal muss eine Einheit haben',
        processmaps: 'Prozesskarten konnten nicht abgerufen werden',
        processmapsrevisions:
          'Prozesskartenrevisionen konnten nicht abgerufen werden',
        updatetemplate: 'Vorlage konnte nicht aktualisiert werden'
      },
      graphicalrepresentation: 'Grafische Darstellung',
      none: 'Keine',
      signaldirection: 'Signalrichtung',
      signalfunction: 'Signalfunktion',
      signaltype: 'Signaltyp',
      source: 'Quelle',
      systemname: 'Name',
      type: 'Typ',
      unit: 'Einheit',
      usemodbus: 'Modbus',
      writable: 'Beschreibbar'
    },
    equipmenttools: {
      error: {
        signalfetchfailed: 'Das {0} Tool konnte nicht abgerufen werden.'
      },
      manage: 'Verwalten',
      title: 'Ausrüstungstools',
      tooltype: 'Tooltyp'
    },
    equipmenttypes: {
      add: 'Neuen Ausrüstungstyp hinzufügen',
      failed: {
        to: {
          fetch: {
            templates:
              'Vorlagen für Ausrüstungstypen konnten nicht abgerufen werden'
          }
        }
      },
      processmaps: 'Prozesskarten',
      selectprocessmap: 'Prozesskarte auswählen',
      signaltemplates: 'Signalvorlagen',
      title: 'Ausrüstungstypen',
      updated: 'Ausrüstungstyp aktualisiert'
    },
    form: {
      unsavedstate:
        'Sie haben nicht gespeicherte Daten, möchten Sie wirklich gehen?'
    },
    integration: {
      ecoguard: {
        account: {
          editgroups: 'Gruppen bearbeiten...',
          editgroupstitle: 'Gruppen bearbeiten'
        },
        add: {
          account: 'Ecoguard-Konto hinzufügen'
        },
        column: {
          description: 'Beschreibung',
          groupname: 'Name der Gruppe',
          keyvaultsecret: 'Mit persönlichem Zugang gesichert',
          name: 'Name des Kontos'
        },
        confirmcreate: {
          text: 'Um Gruppen zu bearbeiten, müssen Sie das neue Konto hinzufügen, bevor Sie fortfahren.',
          title: 'Konto hinzufügen bestätigen'
        },
        edit: {
          account: 'Ecoguard-Konto bearbeiten'
        },
        genericaccountcollectionname: 'Ecoguard Konten',
        genericaccountname: 'Ecoguard-Konto',
        genericgroupcollectionname: 'Ecoguard-Gruppen',
        genericgroupname: 'Ecoguard-Gruppe',
        generictoolcollectionname: 'Ecoguard-Werkzeuge',
        generictoolname: 'Ecoguard-Werkzeug',
        group: {
          add: {
            button: 'Gruppe hinzufügen...',
            title: 'Gruppe hinzufügen'
          },
          edit: {
            title: 'Gruppe bearbeiten'
          },
          nodata:
            'Keine Gruppen gefunden. Drücken Sie "Gruppe hinzufügen...", um zu beginnen.',
          nodataforpicker: 'Es wurden keine Gruppen gefunden.'
        },
        integrationtype: {
          meanroomtemperature: 'Mittlere Raumtemperatur',
          roomtemperature: 'Raumtemperatur',
          title: 'Art der Integration'
        },
        nametaken:
          'Der Gruppenname ist für dieses Ecoguard-Konto bereits vergeben',
        noaccountselected: 'Kein Konto ausgewählt',
        selectaccount: 'Konto',
        selectaccountmodaltitle: 'Konto auswählen',
        selectaccountplaceholder: 'Konto auswählen...',
        selectgroup: 'Gruppe',
        selectgroupmodaltitle: 'Gruppe auswählen',
        selectgroupplaceholder: 'Gruppe auswählen...',
        title: 'Ecoguard',
        tool: {
          add: 'Ecoguard-Tool hinzufügen',
          edit: 'Ecoguard-Werkzeug bearbeiten'
        }
      },
      elvaco: {
        account: {
          add: {
            failed: 'Hinzufügen des Elvaco-Kontos fehlgeschlagen',
            success: 'Erfolgreich hinzugefügtes Elvaco-Konto'
          },
          save: {
            failed: 'Elvaco-Konto konnte nicht gespeichert werden',
            success: 'Erfolgreich gespeichertes Elvaco-Konto'
          }
        },
        add: {
          account: 'Elvaco-Konto hinzufügen'
        },
        column: {
          description: 'Beschreibung',
          name: 'Name des Kontos',
          status: 'Status'
        },
        countries: {
          sverige: 'Schweden'
        },
        edit: {
          account: 'Elvaco-Konto bearbeiten'
        },
        form: {
          baseurl: 'Basis-URL',
          country: 'Land',
          description: 'Beschreibung',
          jwt: 'JWT-Token',
          name: 'Name',
          userid: 'Benutzer-ID'
        },
        get: {
          accounts: {
            error: 'Abruf von Elvaco-Konten fehlgeschlagen'
          }
        },
        nodata: 'Es sind keine Elvaco-Konten verfügbar',
        title: 'Elvaco'
      },
      generic: {
        get: {
          accounts: {
            empty: 'Keine Konten verfügbar',
            error: 'Konten können nicht abgerufen werden'
          }
        }
      },
      netmore: {
        account: 'Netmore-Konto',
        add: {
          account: 'Netmore-Konto hinzufügen',
          tool: 'Netmore-Tool hinzufügen'
        },
        availablesignals: 'Verfügbare Signale',
        edit: {
          account: 'Netmore-Konto bearbeiten',
          tool: 'Netmore-Werkzeug bearbeiten'
        },
        error: {
          failedtoloadsignals: 'Signale für Konto konnten nicht geladen werden'
        },
        form: {
          account: 'Konto',
          description: 'Beschreibung',
          name: 'Name',
          password: 'Kennwort',
          username: 'Name des Benutzers'
        },
        headers: {
          description: 'Beschreibung',
          euiserialnumber: 'DevEUI'
        },
        nodatatext:
          'Keine Signale gefunden. Bitte wählen Sie ein Konto und geben Sie eine gültige DevEUI in das Suchfeld ein, um fortzufahren.',
        nosignalsadded: 'Keine Signale hinzugefügt.',
        selectedsignals: 'Ausgewählte Signale',
        title: 'Netmore'
      }
    },
    integrations: {
      addnodetopointformat: 'Hinzufügen von {0} zu {1}?',
      addpoint: 'Integrationspunkt hinzufügen...',
      addpointmodalformat: 'Integrationspunkt {0} hinzufügen',
      addtopoint: 'Zum Integrationspunkt hinzufügen...',
      addtopointtitle: 'Standort zum Integrationspunkt hinzufügen',
      editpointmodalformat: 'Integrationspunkt bearbeiten {0}',
      integrationpointname: 'Integrationspunkt',
      integrationproxyname: 'Integration Proxy',
      managepoints: 'Integrationspunkte verwalten',
      nodeaddedtopoint: 'Ort zum Integrationspunkt hinzugefügt',
      proxy: {
        form: {
          error: {
            invalidformat: 'Ungültiges Format, erwartet: {0}',
            missing: 'Erforderlicher Wert'
          },
          name: 'Name',
          nodeids: 'Standorte'
        }
      }
    },
    iotdevicedetails: {
      activeinternetroute: 'Als Internet-Route aktiv',
      address: 'Adresse',
      cancelled: 'Abgebrochen',
      configurehardware: 'Hardware konfigurieren',
      currentgateway: 'Aktueller Gateway',
      currentipaddress: 'Aktuelle IP-Adresse',
      currentiptype: 'Aktueller Netzwerkmodus',
      currentnetmask: 'Aktuelle Netzmaske',
      defaultnetworkinterface: 'Standard-Netzwerkschnittstelle',
      deprovisiondevice: 'Gerät löschen',
      deprovisionreason: 'Grund',
      desiredinternetroute: 'Gewünschte Internet-Route',
      devicedefaultnetworkinterface: 'Netzwerkschnittstelle des Geräts',
      deviceinfo: 'Geräteinformationen',
      devicename: 'MAC-Adresse',
      dhcp: 'DHCP',
      editnetworksettings: 'Netzwerkeinstellungen bearbeiten...',
      error: {
        failedtofetchdeviceinfo:
          'Geräteinformationen konnten nicht abgerufen werden',
        failedtofetchtags: 'Abrufen von Tags fehlgeschlagen',
        failedtoupdate: 'Geräteeinstellungen konnten nicht abgerufen werden'
      },
      gateway: 'Gateway',
      hardwareactions: 'Hardware-Aktionen',
      heartbeat: 'Zuletzt aktiv',
      heartbeataction: 'Heartbeat anfordern',
      heartbeaterror: 'Heartbeat fehlgeschlagen',
      heartbeatoutput: 'Heartbeat-Ergebnis',
      heartbeatresponseerror: 'Heartbeat fehlgeschlagen. Status-Code: {0}',
      heartbeatsuccess: 'Heartbeat erfolgreich',
      heartbeattitle: 'Heartbeat',
      ipaddress: 'IP-Adresse',
      iptype: 'Netzwerk-Modus',
      lastcomms: 'Letzte Kommunikation',
      locations: 'Standorte',
      logs: 'Fordert  an',
      manufacturer: 'Hersteller',
      model: 'Modell',
      modules: 'Module',
      modulesdetail: {
        exitCode: 'Exit-Code',
        lastExitTime: 'Letzte Ausfahrt',
        lastRestartTime: 'Letzter Neustart',
        lastStartTime: 'Letzter Start',
        runtimeStatus: 'Status',
        status: {
          dead: 'Tot',
          failed: 'Fehlgeschlagen',
          running: 'Läuft',
          stopped: 'Gestoppt',
          unknown: 'Unbekannt'
        },
        version: 'Ausführung'
      },
      modulesname: 'Bezeichnung',
      modulesversion: 'Aktuelle Version',
      modulesversionlatest: 'Letzte Version',
      netmask: 'Netzmaske',
      networksettings: 'Netzwerk-Einstellungen',
      noheartbeat: 'Keine Daten gemeldet',
      notactiveinternetroute: 'Nicht als Internet-Route aktiv',
      notes: 'Anmerkungen',
      pairactions: 'Gerätekopplung konfigurieren',
      performrequestheartbeat: 'Anforderung eines Heartbeats',
      performrequestping: 'Abfrage {0}',
      performrequestreboot: 'Neustart',
      performrequestrestartservice: 'Neustart des Dienstes {0}',
      ping: 'Ping',
      pingaction: 'Ping-Adresse',
      pingoutput: 'Ping-Ergebnise',
      pingresponse: 'Antwortzeit: {0}ms',
      pingresponseerror: 'Ping fehlgeschlagen. Status-Code: {0}',
      provisiondevice: 'Gerät hinzufügen',
      reboot: 'Neustart',
      rebootconfirm: 'Sind Sie sicher, dass Sie das Gerät neu starten wollen?',
      rebootdevice: 'Energy Manager neu starten',
      rebooterror: 'Neustart des Geräts fehlgeschlagen',
      rebootresponseerror:
        'Neustart des Geräts fehlgeschlagen. Statuscode: {0}',
      rebootsuccess: 'Gerät wurde neu gebootet',
      reportedaddress: 'Geräte-IP-Adresse',
      reportedgateway: 'Geräte-Gateway',
      reportediptype: 'Geräte-IP-Modus',
      reportednetmask: 'Gerät Netzmaske',
      restartservice: 'Dienst neu starten',
      restartserviceoutput: 'Status des neu gestarteten Dienstes',
      restartserviceresponse: 'Dienst wurde neu gestartet.',
      restartservicesresponseerror: 'Neustart des Dienstes fehlgeschlagen.',
      restartservicesresponseerrorentry:
        'Neustart des Dienstes fehlgeschlagen. Statuscode: {0}',
      savefailed: 'Gerätedaten konnten nicht gespeichert werden.',
      savesuccess: 'Gerätedaten wurden erfolgreich gespeichert.',
      signalstrength: 'Signalstärke',
      sim: 'Nummer der SIM-Karte',
      static: 'Statisch',
      tab: {
        details: 'Einzelheiten',
        hardware: 'Hardware',
        hardwareactions: 'Aktionen',
        networksettings: 'Netzwerk'
      },
      tags: 'Tags',
      title: 'Netzwerk-Einstellungen',
      unknown: 'Unbekannt',
      updatenetworksettings: 'Netzwerkeinstellungen aktualisieren',
      uptime: 'Betriebszeit',
      wiredconnection: 'Kabelgebundene Verbindung',
      wirelessconnection: 'Mobile Verbindung'
    },
    lastdeployed: 'Der letzte Einsatz war:',
    meteorology: {
      action: {
        create: 'Neuen Punkt hinzufügen'
      },
      createnew: {
        header: 'Neuen Punkt hinzufügen'
      },
      error: {
        entername: 'Bitte geben Sie einen Namen ein'
      },
      fetch: {
        failed: 'Prozessmeteorologiedaten konnten nicht abgerufen werden'
      },
      header: {
        coordinates: 'Koordinaten',
        name: 'Name',
        numberoflocations: 'Anzahl der Standorte'
      },
      input: {
        name: 'Name'
      },
      locations: 'Standorte',
      overview: {
        header: 'Wetterpunkte'
      },
      value: {
        numberoflocationsformat: '{0} Standorte'
      }
    },
    modbusconnection: {
      byte_timeout_microseconds: 'Byte timeout (µs)',
      byte_timeout_seconds: 'Byte timeout (s)',
      connection_timeout: 'Zeitüberschreitung',
      debug: 'Debug-Ebene',
      delay_read_db: 'Delay read DB',
      delay_read_modbus: 'Delay read modbus',
      delay_write_db: 'Delay write DB',
      delay_write_modbus: 'Delay write modbus',
      mode: {
        master: 'Master',
        slave: 'Slave',
        title: 'Modus'
      },
      properties: 'Eigenschaften der Verbindung',
      queue_size_factor: 'Queue size factor',
      response_timeout_microseconds: 'Antwort-Timeout (µs)',
      response_timeout_seconds: 'Antwort-Timeout (s)',
      rtubaudrate: 'Baudrate',
      rtudatabits: 'Datenbits',
      rtuparity: 'Parität',
      rtuparityeven: 'Gerade',
      rtuparitynone: 'Keine',
      rtuparityodd: 'Ungerade',
      rtuserialname: 'Serienname',
      rtusettings: 'RTU-Einstellungen',
      rtustopbits: 'Stoppbits',
      slave_id: 'Slave ID',
      tcpipaddress: 'IP-Adresse',
      tcpport: 'Port',
      tcpsettings: 'TCP-Einstellungen',
      titleformat: 'Verbindung bearbeiten',
      type: {
        rtu: 'RTU',
        tcp: 'TCP',
        title: 'Typ'
      },
      watchdog_bms_address: 'Watchdog BMS Adresse',
      watchdog_modbus_alarm: 'Watchdog Alarm',
      watchdog_modbus_timeout: 'Watchdog timeout'
    },
    modbuslayout: {
      config: 'Konfiguration',
      documentation: 'Dokumentation',
      downloadfile: 'Datei herunterladen',
      layout: 'Layout',
      signals: 'Signale',
      title: 'Gerätedateien',
      tools: 'Werkzeuge'
    },
    modbussignal: {
      bit: 'Bit',
      byteorder: {
        abcd: 'abcd',
        badc: 'badc',
        cdab: 'cdab',
        dcba: 'dcba',
        title: 'Byte-Reihenfolge'
      },
      coil: 'Coil',
      config: 'Konfiguration',
      connection: 'Verbindung',
      connectiondefault: 'Verbindungsstandard',
      datatype: {
        bitmask: 'Bitmaske',
        int16: 'int16',
        int32: 'int32',
        int64: 'int64',
        real: 'real',
        title: 'Datentyp',
        uint16: 'uint16',
        uint32: 'uint32',
        uint64: 'uint64'
      },
      delay: 'Delay',
      delaydb: 'Delay DB',
      delaymodbus: 'Delay Modbus',
      disableconfig: 'Modbus-Konfiguration deaktivieren',
      discreteinput: 'Discrete input',
      editformat: 'Bearbeiten {0}',
      enableconfig: 'Modbus-Konfiguration aktivieren',
      factor: 'Faktor',
      flag: {
        delay: 'Flaggenverzögerung',
        name: 'Flaggenname',
        timeout: 'Flaggen-Timeout',
        title: 'Flagge'
      },
      holdingregister: 'Holding register',
      inputregister: 'Input register',
      modbusmaster: 'Modbus Master',
      modbusslave: 'Modbus Slave',
      mode: 'Modus',
      read: 'Lesen',
      readdelay: 'Read delay',
      readname: 'Read name',
      readsetting: {
        title: 'Lesen'
      },
      readwrite: 'Lesen/Schreiben',
      signalproperties: 'Signaleigenschaften',
      signaltype: 'Signaltyp',
      step: 'Schritt',
      watchdog: {
        alarm: 'Alarm',
        enablesignal: 'Aktivieren',
        high: 'Hoch',
        period: 'Zeitraum',
        title: 'Watchdog'
      },
      write: 'Schreiben'
    },
    modeltypes: {
      signals: {
        numberselected: '{0} Signale',
        zeroselected: 'Keine Signale'
      },
      users: {
        numberselected: '{0} Benutzer',
        zeroselected: 'Keine Benutzer'
      }
    },
    notifications: {
      addnotification: 'Benachrichtigung hinzufügen',
      deletenotification: 'Meldung löschen',
      editnotification: 'Benachrichtigung bearbeiten',
      error: {
        failedtoadd: 'Meldung konnte nicht hinzugefügt werden.',
        failedtodelete: 'Benachrichtigung konnte nicht gelöscht werden.',
        missingname: 'Name für die Meldung eingeben',
        nonodeselected: 'Kein Ort ausgewählt.',
        nousers: 'Keine Benutzer ausgewählt.'
      },
      header: {
        location: 'Standort',
        name: 'Name',
        severities: 'Schweregrad',
        signals: 'Signale',
        users: 'Benutzer'
      },
      selectsignals: 'Signale auswählen',
      selectusers: 'Benutzer auswählen',
      title: 'Benachrichtigungen'
    },
    powerdeltacontrol: {
      cooling: 'Kühlung',
      error: {
        signalfetchfailed:
          'Das Power Delta Control-Tool konnte nicht abgerufen werden.'
      },
      heating: 'Heizung',
      returntemperaturesignal: 'Rücklauftemperatursignal',
      timeconstant: 'Zeitkonstante',
      type: 'Typ'
    },
    previewtenant: {
      previewnode: 'Knoten "Vorschau"',
      previewnodetooltip:
        'Vorschau-Knoten. Legen Sie diese Option fest, um die Prozesslandkarte mithilfe von Signalen von einem Knoten im Vorschaumandanten anzuzeigen.',
      previewtenant: 'Vorschau des Mandanten',
      previewtenanttooltip:
        'Vorschau des Mandanten. Legen Sie diese Option fest, um Knoten in einem anderen Mandanten auszuwählen, von denen aus eine Vorschau der Prozesslandkarte angezeigt werden soll.'
    },
    processmaps: {
      add: 'Hinzufügen',
      addconnection: 'Verbindung hinzufügen',
      addmap: 'Prozesskarte hinzufügen',
      addsvgimage: 'SVG-Bild...',
      addsvgsymbol: 'Symbol aus SVG hinzufügen...',
      clickaction: {
        node: 'Standort'
      },
      comment: 'Kommentar zur Karte',
      commentrequired: 'Ein Kommentar ist erforderlich',
      contextmenu: {
        addline: 'Linie',
        addrect: 'Rectangle',
        addsignal: 'Signal',
        addsymbol: 'Symbol',
        addtext: 'Text',
        deleteconnections: 'Verbindungen löschen',
        deleteobject: 'Objekt löschen',
        deletepoint: 'Punkt löschen',
        sendback: 'Zurücksenden',
        sendforward: 'Vorwärts senden',
        sendtoback: 'Nach hinten senden',
        sendtofront: 'Nach vorne senden'
      },
      copysource: 'SVG-Quelle kopieren',
      created: 'Prozesskarte hinzugefügt',
      currentmap: 'Ausgewählte Prozesskarte',
      customzoom: 'Benutzerdefinierter Zoom',
      default: 'Standard',
      deleteconnection: 'Verbindung löschen',
      deleteitem: 'Symbol löschen',
      deletemap: 'Prozesskarte löschen',
      description: 'Beschreibung',
      download: 'Herunterladen',
      downloadsvg: 'Laden Sie SVG herunter',
      edit: 'Prozesskarte bearbeiten',
      editnodetyperelation: 'Edit node type relations',
      editsymbollibrary: 'Symbolbibliothek bearbeiten',
      error: {
        create: 'Prozesskarte konnte nicht hinzugefügt werden',
        delete: 'Prozesskarte konnte nicht gelöscht werden',
        invalidfile: 'Ungültige Datei',
        missingcomment: 'Sie müssen einen Kommentar hinzufügen',
        missingmap: 'Sie müssen eine Prozesskarte hinzufügen',
        missingname: 'Sie müssen einen gültigen Namen eingeben',
        update: 'Prozesskarte konnte nicht aktualisiert werden'
      },
      failedtoload: 'Prozesszuordnung konnte nicht geladen werden',
      failedtoparse: 'Prozesszuordnung konnte nicht gelesen werden',
      history: 'Historie',
      map: 'Karte',
      mapheight: 'Höhe',
      maps: {
        error: 'Abrufen von Prozesskarten fehlgeschlagen'
      },
      mapwidth: 'Breite',
      name: 'Name',
      newcomment: 'Neuer Kommentar zur Karte',
      newmap: 'Neue Karte',
      newsymbol: 'Neues Symbol',
      nocurrentmap: 'Keine Prozesskarte ist ausgewählt',
      nocurrentmapdefault:
        'Es ist keine Prozesskarte ausgewählt. Standard-Prozesskarte "{0}" verwenden',
      nofound: 'Keine Prozesskarten gefunden',
      nosignaltypeselected: 'Kein Signaltyp ausgewählt',
      notsvgerror:
        'Das Bild liegt nicht im SVG-Format vor. Derzeit werden nur SVG-Bilder unterstützt.',
      objecteditor: {
        actiontype: 'Aktionstyp',
        addsymbolrule: 'Symbolregel hinzufügen',
        bold: 'Kühn',
        changestatetitle: 'Ändern des Zustands basierend auf dem Signalwert',
        clickaction: 'Klicken Sie auf Aktion',
        cornerradius: 'Ecken-Radius',
        dashed: 'Gestrichelt',
        dimensions: 'Dimensionen',
        endendcapstyle: 'Endkappen-Stil',
        equipmentactionhelp:
          'Der Benutzer wird zu dem Gerätetyp am aktuellen Standort navigiert, der dem von Ihnen ausgewählten Typ entspricht',
        fillcolor: 'Füllfarbe',
        fillcolorrule: 'Regel für Füllfarbe',
        fliphorizontal: 'Horizontal spiegeln',
        flipmidpoint: 'Mittelpunkt umkehren',
        flipvertical: 'Vertikal spiegeln',
        fontsize: 'Schriftgrad',
        height: 'Höhe',
        hideitem: 'Objekt ausblenden',
        hidelabel: 'Beschriftung ausblenden',
        hiderequiredsignal: 'Erforderliches Signal',
        hiderequiredsignalhelp:
          'Objekt ausblenden, wenn keine Signale dieses Typs gefunden werden können. Beim Bearbeiten immer noch sichtbar.',
        hiderequiredsignalid: 'Erforderliches Signal (hartcodiert)',
        hiderequiredsignalidhelp:
          'Verwenden Sie dies nur, wenn der Signaltyp nicht spezifisch genug ist.',
        hiderulesignals: 'Regeln validieren',
        hiderulesignalshelp:
          'Objekt ausblenden, wenn keines der in den Regeln definierten Signale im aktuellen Knoten gefunden werden kann.',
        hidevalue: 'Wert ausblenden',
        italic: 'Kursiv',
        labeltextstyle: 'Textstil der Beschriftung',
        linemode: 'Linien-Modus',
        linemodes: {
          path: 'Liniensegment',
          straight: 'Gerade'
        },
        lineobject: 'Line-Objekt',
        linewidth: 'Linienbreite',
        nosymbolrules: 'Keine Symbolregeln definiert',
        pointformat: 'Punkt {0}',
        rectobject: 'Rectangle-Objekt',
        rotation: 'Derhung',
        rounded: 'Abgerundete',
        scale: 'Maßstab',
        signalidindexformat: 'Signal-{0}-ID (hartcodiert)',
        signalobject: 'Signal-Objekt',
        signaltypeindexformat: 'Typ Signal {x}',
        startendcapstyle: 'Stil der Start-Endkappe',
        state: 'Zustand',
        strokecolor: 'Farbe des Strichs',
        strokewidth: 'Strichstärke',
        symbol: 'Symbol',
        symbolobject: 'Symbol-Objekt',
        symbolrules: 'Symbol-Regeln',
        text: 'Text',
        textalignment: 'Textausrichtung',
        textalignments: {
          center: 'Zentriert',
          left: 'Links',
          right: 'Rechts'
        },
        textobject: 'Text-Objekt',
        textstyle: 'Textstil',
        valuetextstyle: 'Wert-Textstil',
        width: 'Breite',
        x: 'X',
        y: 'Y'
      },
      previewsymbolstates: 'Vorschau der Symbolzustände',
      projectsettings: {
        title: 'Projekteinstellungen',
        trimmap: 'Trimmen der Größe der Prozesslandkarte',
        updatesymbolsversions: 'Aktualisieren von Symbolversionen',
        updatesymbolsversionstoast: 'Aktualisierte Versionen von Symbolen.'
      },
      removed: 'Prozesskarte entfernt',
      revision: {
        error: 'Kartenverlauf konnte nicht abgerufen werden'
      },
      svgmenu: {
        copyobjects:
          'Importieren Sie alle Objekte aus einer anderen Prozesslandkarte',
        showpreview: 'Vorschau der alten Prozesslandkarte anzeigen'
      },
      symbolactions: 'Symbol-Aktionen',
      templatename: 'Vorlagenname',
      title: 'Prozesskarten',
      updated: 'Prozesskarte aktualisiert',
      updatesvgsymbol: 'SVG-Bild aktualisieren…',
      zoom: 'Zoomen'
    },
    provisioning: {
      connectionstatus: 'Status der Verbindung',
      filters: {
        tags: 'Schlagwörter',
        types: 'Typen'
      },
      header: {
        heartbeat: 'Zuletzt aktiv',
        locations: 'Standorte',
        name: 'MAC',
        notes: 'Anmerkungen',
        online: 'Online',
        sim: 'SIM',
        tags: 'Schlagwörter',
        type: 'Typ'
      },
      lastheartbeatformat: 'Zuletzt online {0}',
      nodatatext: 'Keine Geräte gefunden.',
      nofreedevicesdatatext: 'Keine verfügbaren Geräte gefunden.',
      query: {
        all: 'Alle',
        offline: 'Offline',
        online: 'Online',
        paired: 'Gekoppelt',
        unpaired: 'Verfügbar'
      },
      summary: {
        online: 'Online:',
        total: 'Geräte:',
        unprovisioned: 'Verfügbar:'
      },
      tagsplaceholder: 'Tags...'
    },
    requests: {
      addequipment: {
        failure: 'Hinzufügen von Ausrüstung fehlgeschlagen.',
        success: 'Ausrüstung hinzugefügt.'
      },
      addequipmenttype: {
        failure: 'Gerätetyp konnte nicht hinzugefügt werden.',
        success: 'Ausrüstungsart hinzugefügt.'
      },
      addweatherpoint: {
        failure: 'Hinzufügen eines Wetterpunktes fehlgeschlagen.',
        success: 'Wetterpunkt hinzugefügt.'
      },
      createdeviceconfig: {
        failure: 'Gerätekonfiguration konnte nicht hinzugefügt werden.',
        success: 'Gerätekonfiguration hinzugefügt.'
      },
      createtemplate: {
        failure: 'Vorlage kann nicht hinzugefügt werden.',
        success: 'Vorlage hinzugefügt.'
      },
      deletelocation: {
        failure:
          'Das Löschen des Ortes ist fehlgeschlagen. Hat er untergeordnete Orte? Wenn ja, löschen Sie diese zuerst.',
        success: 'Ort erfolgreich gelöscht.'
      },
      updatedeviceconfig: {
        failure: 'Aktualisierung der Gerätekonfiguration fehlgeschlagen.',
        success: 'Gerätekonfiguration aktualisiert.'
      },
      updatelocation: {
        failure: 'Aktualisierung des Ortes fehlgeschlagen.',
        success: 'Ort wurde erfolgreich aktualisiert.'
      },
      updatesignals: {
        failure: 'Aktualisierung der Signale fehlgeschlagen.',
        success: 'Signale aktualisiert.'
      },
      updatetemplate: {
        failure: 'Vorlage konnte nicht aktualisiert werden.',
        success: 'Aktualisierte Vorlage.'
      },
      updateweatherpoint: {
        failure: 'Aktualisierung des Wetterpunktes fehlgeschlagen.',
        success: 'Wetterpunkt aktualisiert.'
      }
    },
    selectdevice: {
      title: 'Gerät auswählen'
    },
    selectequipment: {
      title: 'Ausrüstung auswählen'
    },
    selectlocationdialog: {
      title: 'Ort auswählen'
    },
    selectsignals: {
      noresult: 'Keine Signale',
      searchplaceholder: 'Suche nach Signalen'
    },
    selectsignalsdialog: {
      title: 'Signale auswählen'
    },
    selectusers: {
      error: {
        couldnotfindusers:
          'Es konnten keine Benutzer für den Standort gefunden werden.'
      }
    },
    signalcategories: {
      loaderror: 'Signalkategorien konnten nicht geladen werden',
      title: 'Signalkategorien',
      validationerror: {
        alarm: 'Für diesen Signaltyp muss die Alarmkategorie eingestellt sein',
        energymanager:
          'Für diesen Signaltyp muss die Energy Manager Kategorie eingestellt sein'
      }
    },
    signalmapping: {
      action: {
        noaction: 'Keine Aktion',
        sendbasedoncycletime: 'Senden auf Basis der Zykluszeit',
        sendonpush: 'Senden auf Knopfdruck'
      },
      common: {
        name: 'Name'
      },
      createmapping: 'Kartierung hinzufügen',
      destination: {
        option: {
          timetolive: 'Time to live (Sekunden)'
        },
        selectsignals: 'Auswahl der Zielsignale',
        title: 'Ziel'
      },
      destinationsignals: {
        title: 'Zielsignale'
      },
      editdialog: {
        add: {
          title: 'Signalkartierung hinzufügen'
        },
        edit: {
          title: 'Signalkartierung bearbeiten'
        }
      },
      error: {
        destsourceoverlap:
          'Es kann nicht dasselbe Signal in den Ziel- und Quellkonfigurationen verwendet werden.',
        missingaggregation:
          'Wenn Sie mehr als ein Quellsignal verwenden, müssen Sie die Aggregation aktivieren.',
        nosignals: 'Bitte fügen Sie Signale hinzu, um fortzufahren.',
        ttl: 'Dieser Wert muss auf mindestens 60 Sekunden gesetzt werden.'
      },
      help: {
        action:
          'Keine Aktion: Der Server wird keine Aktion durchführen. Dies ist hauptsächlich dann der Fall, wenn der Energiemanager die Zuordnung vornimmt. \nSenden bei Push: Der Server leitet die Daten an die Ziele weiter, wenn neue Werte an den Quellen eintreffen. \nSend based on cycle time: Der Server aktualisiert die Werte in einem Intervall von x Minuten (definiert durch die Zykluszeit).',
        cycletime:
          'Wie oft wird nach Werten gesucht und wie oft werden diese gesendet (beim Senden auf Basis der Zykluszeit).',
        hoursback:
          'Senden älterer Werte, die aufgrund der Zykluszeit fehlen (oder ältere Werte, deren Senden sinnvoll ist).',
        hoursforward:
          'Wenn der Wert eingestellt ist, wird nur eine bestimmte Anzahl von Stunden in die Signalzeitreihe aufgenommen.',
        ttl: 'Muss festgelegt werden, wenn Nachrichten an einen Energiemanager gesendet werden, um dessen Warteschlange nicht unnötig zu vergrößern.',
        uselastvaluebeforestartdate:
          'Überschreibt das Startdatum und versucht, den letzten Wert vor dem angegebenen Startdatum zu erhalten, falls vorhanden.'
      },
      nodata:
        'Es wurden keine Signalkarten für diesen Standort gefunden. Bitte wählen Sie einen anderen Standort.',
      request: {
        add: {
          failed: 'Signalkartierung konnte nicht hinzugefügt werden.',
          success: 'Signalzuordnung hinzugefügt.'
        },
        delete: {
          failed: 'Signalkartierung konnte nicht gelöscht werden.',
          success: 'Signalzuordnung gelöscht.'
        },
        update: {
          failed: 'Aktualisierung der Signalzuordnung fehlgeschlagen.',
          success: 'Signalzuordnung aktualisiert.'
        }
      },
      signalselection: {
        defaultvalue: 'Standardwert',
        timeoffset: 'Zeitversatz (Stunden)',
        title: 'Signale'
      },
      source: {
        option: {
          action: 'Aktion',
          aggregation: 'Ziel-Aggregation',
          cycletime: 'Zykluszeit (Minuten)',
          hoursback: 'Stunden rückwärts',
          hoursforward: 'Stunden vorwärts',
          onlyUpdateDestinationOnChangedValue:
            'Ziel nur bei geändertem Wert aktualisieren',
          samplinginterval: 'Stichprobenintervall',
          uselastvalue: 'Letzten Wert vor Startdatum verwenden'
        },
        selectsignals: 'Quellensignale auswählen',
        title: 'Quelle'
      },
      sourcesignals: {
        title: 'Quellensignale'
      },
      title: 'Signal Mapping'
    },
    signaltypes: {
      addbuttontext: 'Typ hinzufügen...',
      addfolderbuttontext: 'Ordner hinzufügen...',
      addfoldertitle: 'Ordner hinzufügen',
      addfromexisting: 'Von Signal hinzufügen...',
      addtypetitle: 'Signaltyp hinzufügen',
      editbuttontext: 'Bearbeiten...',
      editfoldertitle: 'Ordner bearbeiten',
      edittypetitle: 'Signaltyp bearbeiten',
      folderform: {
        folder: 'Ordner',
        nonemptyerror:
          'Der Signalordner konnte nicht gelöscht werden. Es scheint, dass der Ordner nicht leer ist.'
      },
      form: {
        name: 'Name'
      },
      newfolder: 'Neuer Ordner',
      newtype: 'Neuer Signaltyp',
      selectfolder: 'Ordner auswählen',
      selecttypedialogtitle: 'Signaltyp auswählen',
      typedescription: 'Beschreibung des Typs',
      typeform: {
        conflicterror:
          'Der Signaltyp konnte nicht gespeichert werden. Es scheint einen anderen Signaltyp mit demselben Namen zu geben.',
        description: 'Beschreibung',
        folder: 'Ordner',
        nonemptyerror:
          'Der Signaltyp konnte nicht gelöscht werden. Er scheint in Gebrauch zu sein.',
        unit: 'Einheit'
      },
      typename: 'Name des Typs'
    },
    tabs: {
      alarmrules: 'Alarm rules',
      alarmtemplates: 'Alarme',
      dashboard: 'Dashboard',
      deploytemplates: 'Vorlagen bereitstellen',
      devices: 'Energiemanager',
      ectotable: 'Ectotable',
      equipment: 'Ausrüstung',
      equipmentgrouptemplates: 'Gebäude',
      equipmenttemplates: 'Ausrüstungen',
      installation: 'Installation',
      integrationaccounts: 'Integration von Konten',
      integrations: 'Integrationen',
      locations: 'Standorte',
      managetenants: 'Alle Organisationen',
      meteorology: 'Wetterpunkte',
      notifications: 'Benachrichtigungen',
      processmaps: 'Prozesskarten',
      signalmapping: 'Signalzuordnungen',
      signaltypes: 'Signaltypen',
      templates: 'Vorlagen',
      users: 'Benutzer'
    },
    templates: {
      error: {
        couldnotremove: 'Der Ausrüstungstyp konnte nicht gelöscht werden.',
        couldnotremoveconflict:
          'Der Ausrüstungstyp konnte nicht gelöscht werden. Er wird derzeit von anderen Ressourcen verwendet.'
      },
      removed: 'Vorlage wurde entfernt'
    },
    tenants: {
      edit: {
        addtitle: 'Organisation hinzufügen',
        descriptions: {
          database:
            'Der Name der Datenbank, die Ihre Organisation verwenden wird. Sobald Sie die Organisation erstellt haben, kann dieses Feld nicht mehr geändert werden.',
          environment:
            'Die Umgebung der Organisation. Sobald Sie die Organisation erstellt haben, kann dieses Feld nicht mehr geändert werden.',
          id: 'Dieser Name wird als individuelle ID für Ihre Organisation verwendet. Er muss mindestens 7 Zeichen lang sein und darf nur die folgenden Zeichen enthalten: a-z, 0-9 und -. Er darf nicht mit - beginnen oder enden. Sobald Sie den Mieter erstellt haben, kann dieses Feld nicht mehr geändert werden.',
          isdisabled:
            'Wenn die Organisation deaktiviert ist, ist sie für die Mieter nicht sichtbar. Die Daten bleiben jedoch erhalten und die Organisation kann jederzeit wieder aktiviert werden.',
          timezone: 'Die Zeitzone der Organisation. Derzeit nicht verwendet.'
        },
        fields: {
          databasename: 'Datenbankname',
          description: 'Beschreibung',
          environment: 'Umwelt',
          id: 'ID',
          iothubhostname: 'Azure IoT Hub Hostname',
          isdisabled: 'Deaktiviert',
          name: 'Name',
          resources: 'Ressourcen',
          timezone: 'Zeitzone'
        },
        sections: {
          resources: 'Ressourcen',
          tenant: 'Organisation'
        },
        title: 'Organisation bearbeiten'
      },
      editusers: {
        add: 'Benutzer hinzufügen',
        addexternaluser: 'Externen Benutzer hinzufügen',
        addkiduser: 'E.ON KID-Benutzer hinzufügen',
        title: 'Benutzer der Organisation bearbeiten',
        titleformat: '{0} Benutzer bearbeiten'
      },
      manage: 'Alle Organisationen verwalten',
      roles: {
        admin: 'Administrator',
        read: 'Leser',
        write: 'Betreiber'
      },
      user: {
        confirmexternal:
          'Sind Sie sicher, dass Sie ein externes E-Mail-Konto hinzufügen möchten? Wenn Sie stattdessen ein KID-Konto hinzufügen, erhalten Sie eine sicherere Anmeldefunktion. Wir empfehlen Ihnen dringend, zu stornieren und dies auch zu tun.',
        errors: {
          invalidemail: 'Ungültiges Email-Format.',
          invalidkid: 'Ungültiger E.ON KID-Benutzername.',
          invalidkidemail:
            'Verwenden Sie keine externen Konten mit E.ON KID-E-Mail-Adressen.'
        },
        fields: {
          accounttype: 'Konto Typ',
          accounttypeformat: 'Konto-Typ: {0}',
          description: 'Beschreibung',
          eontype: 'E.ON',
          kid: 'E.ON KID user name',
          name: 'Name',
          role: 'Rolle',
          standardtype: 'Standard',
          username: 'E-Mail'
        },
        helptext: {
          external:
            'Eine normale E-Mail-Adresse. Externe Konten sollten nur in Ausnahmefällen hinzugefügt werden, KID-Konten sind sicherer.',
          kid: 'Beispiel für einen E.ON KID-Benutzernamen: A12345@eon.com. Geben Sie nur den ersten Teil ein (vor @).'
        },
        nodisplayname:
          'Dies ist das erste Mal, dass dieser Benutzer zu einer Organisation hinzugefügt wird. Bitte geben Sie einen Namen für den Benutzer an.',
        nonkidemail:
          'Sie haben eine E.ON-E-Mail-Adresse eingegeben, aber es handelt sich nicht um eine gültige KID-E-Mail-Adresse. Sind Sie sicher, dass Sie diese E-Mail-Adresse verwenden möchten? Wenn Sie stattdessen Ihre KID-E-Mail-Adresse verwenden, haben Sie eine sicherere Anmeldefunktion. Wir raten Ihnen dringend, sich abzumelden und dies zu tun.',
        usertypes: {
          external: 'Externer Benutzer',
          kid: 'E.ON KID-Benutzer'
        }
      }
    },
    toolselector: {
      placeholder: 'Tools auswählen'
    },
    updatedbuilding: {
      success: {
        noerrorsformat: 'Gebäude {0} erfolgreich aktualisiert.'
      }
    },
    updatedlocation: {
      success: {
        noerrorsformat: 'Erfolgreich Standort {0} aktualisiert.'
      }
    },
    updatedsite: {
      success: {
        noerrorsformat: 'Standort {0} erfolgreich aktualisiert.'
      }
    },
    users: {
      access: {
        activitylogs: 'Aktivitätsprotokolle',
        adminlocation: 'Admin Standort',
        adminuser: 'Benutzer verwalten',
        alarm: 'Alarm',
        device: 'Gerät',
        devicefiles: 'Gerätedateien',
        diagnostic: 'Diagnostik',
        equipment: 'Ausrüstung',
        location: 'Standort',
        meteorology: 'Meteorologie',
        powercontrol: 'Leistungsregelung',
        remoteoptimisation: 'Fernoptimierung',
        schedulesrunner: 'Zeitplaner',
        telemetry: 'Telemetrie',
        tools: 'Werkzeuge'
      },
      accessrights: {
        admin: 'Admin',
        custom: 'Benutzerdefiniert',
        hasaccess: 'Hat Zugriff',
        none: 'Keine',
        user: 'Benutzer'
      },
      adduser: 'Neuen Benutzer hinzufügen',
      deleteuser: 'Benutzer löschen',
      edituser: 'Benutzer bearbeiten',
      error: {
        loadfailed: 'Die Benutzer konnten nicht geladen werden.',
        usernameconflict:
          'Diese E-Mail ist bereits mit einem anderen Benutzer verknüpft.'
      },
      firstname: 'Vorname',
      lastname: 'Nachname',
      name: 'Name',
      password: 'Passwort',
      passwordhelp:
        'Das Passwort muss mindestens 1 Ziffer, 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Symbol enthalten. Außerdem muss es zwischen 8 und 16 Zeichen lang sein.',
      request: {
        createduser: 'Benutzer hinzugefügt',
        createuserfailed: 'Benutzer kann nicht hinzugefügt werden',
        deleteduser: 'Benutzer gelöscht',
        deleteuserfailed: 'Benutzer kann nicht gelöscht werden',
        getresourcesfailed: 'Benutzerressourcen konnten nicht abgerufen werden',
        getrolesfailed: 'Abrufen von Benutzerrollen fehlgeschlagen',
        updateduser: 'Benutzer aktualisiert',
        updateuserfailed: 'Benutzer kann nicht aktualisiert werden'
      },
      role: 'Rolle',
      selectaccess: {
        access: 'Zugang',
        name: 'Name'
      },
      selectaccessrights: 'Zugriffsrechte auswählen',
      selectcustom: 'Anpassen…',
      title: 'Benutzer verwalten',
      username: 'E-Mail'
    }
  },
  alarm: {
    details: {
      acknowledged: 'Bestätigt',
      active: 'Aktiv',
      created: 'Hinzugefügt',
      hidefullhistory: 'Vollständige Historie ausblenden',
      history: 'Historie',
      name: 'Name',
      no: 'Nein',
      notacknowledged: 'Nicht bestätigt',
      showfullhistory: 'Vollständige Historie anzeigen',
      user: 'Benutzer',
      yes: 'Ja'
    }
  },
  alarms: {
    acknowledge: {
      action: 'Bestätigen',
      all: {
        error:
          'Es konnten nicht alle Alarme bestätigt werden, bitte versuchen Sie es später noch einmal',
        prompt:
          'Sind Sie sicher, dass Sie ALLE Alarme bestätigen wollen? Sie müssen einen Kommentar eingeben.',
        promptwithbuildingstatuses:
          'Sind Sie sicher, dass Sie ALLE Alarme mit dem Gebäudestatus {0} bestätigen möchten? Sie müssen einen Kommentar eingeben.',
        success: 'Alle Alarme wurden erfolgreich bestätigt.'
      },
      button: 'Alarm bestätigen',
      comment: 'Kommentar'
    },
    acknowledgeallstatus: {
      failure: 'Alle Alarme konnten nicht bestätigt werden',
      success: 'Alle Alarme bestätigt'
    },
    acknowledgebutton: 'Bestätigen',
    acknowledged: 'Bestätigt',
    acknowledgedate: 'Bestätigungsdatum',
    acknowledgedby: 'Bestätigt durch',
    acknowledgedtext: 'Bestätigt',
    acknowledgedwithoutcomment: 'Ohne Kommentar bestätigt',
    acknowledgeformat:
      'Sind Sie sicher, dass Sie den Alarm {0} für {1} bestätigen möchten?',
    acknowledgestatus: {
      failure: 'Alarm konnte nicht bestätigt werden',
      success: 'Bestätigter Alarm'
    },
    acknowledgetitle: 'Alarm bestätigen',
    activationdate: 'Datum der Aktivierung',
    active: 'Aktiv',
    alarmtriggeredformat: 'Alarm ausgelöst: {0}',
    category: {
      activeacknowledged: 'Aktiv bestätigt',
      activenotacknowledged: 'Aktiv nicht bestätigt',
      inactivenotacknowledged: 'Inaktiv nicht bestätigt'
    },
    clearall: 'Alle löschen',
    columns: {
      acknowledge: 'Bestätigung',
      ackstatus: 'Status der Bestätigung',
      comment: 'Kommentar',
      description: 'Beschreibung',
      equipment: 'Ausrüstung',
      firstactivedate: 'Startdatum',
      location: 'Standort',
      needsacknowledgement: 'Benötigt Bestätigung',
      severity: 'Schweregrad',
      signal: 'Signal',
      status: 'Status'
    },
    comment: 'Kommentar',
    commentplaceholder: 'Optionaler Kommentar',
    datesubtitleformat: 'bei {0}',
    description: 'Beschreibung',
    equipmentname: 'Name der Ausrüstung',
    eventid: 'Event',
    events: 'Veranstaltungen',
    filter: 'Filtern',
    filterall: 'Alle',
    filterparams: {
      buildingstatus: 'Gebäudestatus'
    },
    help: {
      title: 'Alarme'
    },
    historymodal: {
      titleformat: 'Historie - {0}'
    },
    inactive: 'Inaktiv',
    noalarmswerefoundinthecurrentlocation:
      'Am aktuellen Standort wurden keine Alarme gefunden.',
    nodata: 'Am aktuellen Standort wurden keine Alarme gefunden.',
    nodatainsection: 'Diese Seite hat keine Alarme in dieser Kategorie.',
    notacknowledged: 'Nicht bestätigt',
    notacknowledgedstatus: '-',
    numactivealarms: 'Anzahl der aktiven Alarme',
    numberofactiveformat: '+ {0} mehr',
    page: {
      heading: {
        acknowledgeallalarms: 'Alle bestätigen',
        activealarms: 'Aktiv',
        allalarms: 'Historie'
      }
    },
    section: {
      eventlist: 'Event-Liste',
      signalclusters: 'Aktuelle Alarme'
    },
    severityMax: 'Maximum severity',
    severityMin: 'Minimum severity',
    title: 'Alarme',
    unacknowledgedtext: 'Nicht bestätigt'
  },
  authenticate: {
    authentification: {
      failure: 'Authentifizierung fehlgeschlagen'
    },
    login: {
      again: 'Versuchen Sie, sich erneut anzumelden'
    }
  },
  buildingstatus: {
    created: 'hinzugefügt',
    production: 'Produktion',
    testing: 'Prüfung',
    timetype: {
      after: 'Nachher',
      before: 'Vor',
      exact: 'Genau'
    }
  },
  common: {
    actionmodal: {
      cancel: 'Abbrechen'
    },
    add: 'Hinzufügen',
    affected: 'Betroffen',
    all: 'Alle',
    allowednodetypes: 'Zulässige Knotentypen',
    and: 'und',
    back: 'Zurück',
    baserequesterror:
      'Fehler bei der Kommunikation mit dem Server. Einige Funktionen funktionieren möglicherweise nicht wie vorgesehen.',
    cancel: 'Abbrechen',
    clear: 'Löschen',
    clearvalues: 'Werte löschen',
    clicktoedit: 'Zum Bearbeiten anklicken',
    close: 'Schließen',
    color: 'Farbe',
    column: {
      error: {
        render:
          "Ihre Renderfunktion muss ein RowItem zurückgeben. Stattdessen wurde '{0}' empfangen."
      }
    },
    columns: 'Spalten',
    compare: {
      equal: 'Equal',
      greaterthan: 'Greater than',
      greaterthanorequal: 'Greater than or equal',
      lessthan: 'Less than',
      lessthanorequal: 'Less than or equal',
      notequal: 'Not equal'
    },
    confirmdelete: {
      message: 'Sind Sie sicher, dass Sie {0} löschen möchten?',
      title: 'Löschen bestätigen'
    },
    copytoclipboard: {
      copy: 'Kopieren',
      defaultitem: 'Wert',
      failure: 'Der Wert konnte nicht in Ihre Zwischenablage kopiert werden.',
      failureformat: '{0} konnte nicht in Ihre Zwischenablage kopiert werden.',
      success: 'Wert in Ihre Zwischenablage kopiert.',
      successformat: '{0} in Ihre Zwischenablage kopiert.',
      tooltip: 'In Zwischenablage kopieren'
    },
    dBm: 'dBm',
    datatable: {
      error:
        'Beim Anzeigen der Daten ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut',
      loadmore: 'Mehr laden',
      nodata: 'Keine Daten verfügbar'
    },
    date: 'Datum',
    dateinput: {
      from: 'Von:',
      to: 'An:'
    },
    delete: 'Löschen',
    description: 'Beschreibung',
    disable: 'Deaktivieren',
    disabled: 'Deaktiviert',
    discardchanges: 'Änderungen verwerfen',
    displayname: 'Anzeigename',
    done: 'Fertig',
    duplicate: 'Eine Kopie hinzufügen',
    edit: 'Bearbeiten',
    enable: 'Aktivieren',
    enabled: 'Aktiviert',
    environmenterror: 'Die Umgebungskonfiguration konnte nicht gelesen werden.',
    error: 'Fehler',
    exactmatch: 'Genaue Übereinstimmung',
    hours: 'Stunden',
    iconbutton: {
      add: {
        title: 'Hinzufügen'
      },
      help: {
        title: 'Hilfe'
      },
      search: {
        title: 'Suche'
      }
    },
    information: 'Information',
    loading: 'Lädt...',
    loadingtextlong: 'Wird geladen, bitte warten...',
    name: 'Name',
    networkfailure: {
      error:
        'Die Anfrage konnte aufgrund von Netzwerkproblemen nicht ausgeführt werden.'
    },
    next: 'Nächste',
    no: 'Nein',
    nodename: 'Name',
    none: 'Keine',
    notavailable: 'n/a',
    ok: 'OK',
    optional: 'Optional',
    previous: 'Vorherige',
    priority: 'Priorität',
    range: {
      maxx: 'Maximum X',
      maxy: 'Maximum Y',
      minx: 'Minimum X',
      miny: 'Minimum Y'
    },
    reason: 'Ursache',
    redo: 'Wiederholen',
    save: 'Speichern',
    search: {
      placeholder: 'Suche...'
    },
    selectcolor: 'Farbe auswählen',
    selectsignal: 'Signal auswählen...',
    share: 'Teilen...',
    shorthours: 'h',
    status: 'Status',
    tags: 'Tags',
    undo: 'Rückgängig machen',
    unit: 'Einheit',
    unknown: 'Unbekannt',
    unknownerror: 'Unbekannter Fehler.',
    unsavedchanges: 'Nicht gespeicherte Änderungen',
    unsavedchangesmessage:
      'Du hast nicht gespeicherte Änderungen. Möchten Sie sie retten?',
    users: 'Benutzer',
    yes: 'Ja'
  },
  creatableselect: {
    create: '"{0}" hinzufügen',
    createnew: 'Neu hinzufügen...',
    placeholder: 'Auswählen...'
  },
  curves: {
    add: 'Kurve hinzufügen'
  },
  dashboard: {
    addpanel: {
      error: 'Fehler beim Kopieren des Panels.',
      successformat: 'Panel nach {0} kopiert.'
    },
    addpaneltopersonalbutton: 'In persönliches Dashboard kopieren',
    addpaneltopersonaltitleformat: '{0} in persönliches Dashboard kopieren',
    alarms: {
      showall: 'Alle anzeigen',
      title: 'Alarm'
    },
    confirmsave: {
      message:
        'Es gibt mehrere ungespeicherte Dashboards. Möchten Sie sie alle speichern oder nur dieses eine?',
      saveall: 'Alle speichern',
      savecurrent: 'Aktuelles speichern',
      title: 'Alle Dashboards speichern?'
    },
    enterfullscreen: 'Vollbildmodus',
    error: {
      nodashboardsfound:
        'Für diesen Standort wurden keine Dashboards gefunden.',
      personaldashboards: 'Fehler beim Laden Ihrer Dashboards.',
      personaldashboardsempty:
        'Keine Dashboards gefunden. Drücken Sie + um ein Dashboard hinzuzufügen.',
      unsupportedpaneltype: 'Falscher Panel Typ.'
    },
    exitfullscreen: 'Vollbildmodus beenden',
    map: {
      namelabel: 'Name:',
      streetlabel: 'Straße:'
    },
    missingdashboards: 'Diese Dashboard-Sammlung ist leer',
    newpaneltitle: 'Panel Titel',
    newuserdashboard: {
      description: 'Beschreibung',
      name: 'Name',
      title: 'Neues persönliches Dashboard hinzufügen'
    },
    selectdashboard: 'Dashboard auswählen...',
    selectuserdashboardplaceholder: 'Dashboard auswählen...',
    selectuserdashboardtitle: 'Dashboard',
    showingraphs: 'In Diagrammen anzeigen',
    timerange: {
      custom: 'Benutzerdefiniert',
      durationheader: 'Zeitbereich',
      positionheader: 'Position im Zeitbereich'
    },
    titlewithchangesformat: '{0} (nicht gespeichert)',
    usefixedlocation: 'Gesperrten Standort verwenden',
    usefixedlocationhelpformat: 'Aktivieren, um Daten immer von {0} zu laden.'
  },
  durationtime: {
    daysformat: '{0} Tage',
    hoursformat: '{0} Stunden',
    lessthanoneminute: 'weniger als 1 Minute',
    minutesformat: '{0} Minuten',
    onehour: '1 Stunde',
    oneminute: '1 Minute'
  },
  ectoplanner: {
    addnewversion: 'Neue Version hinzufügen',
    balancinghelp: {
      disclaimer:
        'Beachten Sie, dass dies nur eine Annäherung ist und die tatsächliche Kapazität abweichen kann.',
      statusinsufficientcooling:
        'Die Kühlkapazität im Netz scheint unzureichend zu sein. Die Kühlkapazität liegt bei ca. {0} %.',
      statusinsufficientheating:
        'Die Heizkapazität im Netz scheint unzureichend zu sein. Die Heizkapazität liegt bei ca. {0} %.',
      statusinsufficientheatingandcooling:
        'Die Heiz- und Kühlkapazität im Netz scheint unzureichend zu sein. Die Heizkapazität liegt bei ca. {0} % und die Kühlkapazität bei ca. {1} %.',
      statusok: 'Die Wärmekapazität im Netz scheint ausreichend zu sein.'
    },
    balancingunit: {
      maximumcoolingpowerbu: 'Maximale Kühlleistung der BU: {0} kWth',
      maximumheatingpowerbu: 'Maximale Heizleistung der BU: {0} kWth',
      recommendedpipediameter: 'Empfohlener Rohrdurchmesser an der BU: DN{0}',
      resultingflowvelocity: '(resultierende Strömungsgeschwindigkeit: {0} m/s)'
    },
    buildings: {
      add: 'Gebäude hinzufügen',
      edit: 'Gebäude bearbeiten',
      energyoverview: {
        helptext:
          'Wenn Sie Bedarf auswählen, erhalten Sie alle Energiequellen für diese Kategorie (Raumheizung und Warmwasser für Heizung, Raumkühlung und Prozesskühlung für Kühlung) - der Ausgleich ist nicht enthalten. Wenn Sie Import wählen, erhalten Sie die aus dem ectogrid entnommene Wärme oder Kälte (einschließlich Ausgleich).',
        label: 'Energie-Übersicht'
      }
    },
    buildingsummary: {
      balancedloadsbetweenbuildings: 'Ausgewogene Lasten zwischen Gebäuden',
      balancingunitloads: 'Energiezentrale lädt',
      buildingdemands: 'Bauanforderungen',
      buildingimports: 'Gebäudeimporte',
      button: 'Aggregierte Gebäudeergebnisse...',
      coolequipment: {
        chiller: 'Kältemaschine',
        directcooling: 'Direkte Kühlung',
        peakchiller: 'Spitzenkühler'
      },
      coolingcapacity: 'Kühlkapazität',
      coolingdemand: 'Kühlbedarf',
      coolingimportcapacity: 'Kühlimportkapazität',
      coolingloaddemand: 'Kühllastbedarf',
      electricityimportcapacity: 'Stromimportkapazität',
      energyimports: {
        annualimport: 'Jährlicher Import',
        coolingfrom: 'Kühlung aus dem ectogrid-Netz',
        demandfooter:
          '{0} Abwärme aus dem Kühlsystem wird direkt am Verdampfer der Wärmepumpe genutzt. Dadurch wird der Wärme- und Kältebezug aus dem Netz um {1} reduziert.',
        grid: 'Stromnetz',
        heatfrom: 'Wärme aus ectogrid-Netz',
        name: 'Name',
        peakimport: 'Spitzenwert Import'
      },
      graphnames: {
        cooling: 'Kühlen',
        heating: 'Heizen'
      },
      heatcapacity: 'Wärmekapazität',
      heatdemand: 'Wärmebedarf',
      heatequipment: {
        electricheatingrod: 'Elektrische Heizstäbe',
        hotwaterhp: 'Warmwasser HP',
        spaceheatinghp: 'Raumheizung HP'
      },
      heatimportcapacity: 'Wärmeimportkapazität',
      heatloaddemand: 'Wärmelastbedarf',
      importedcooling: 'Importierte Kühlung',
      importedelectricity: 'Importierter Strom',
      importedheat: 'Importierte Wärme',
      importfromgrid: 'Aus Raster importieren',
      moreinfo: 'Weitere Informationen…',
      rows: {
        balcoolloadsbldgs: 'Ausgeglichener Kältebedarf in Gebäuden',
        balcoolloadsnetw: 'Ausgeglichener Kühlbedarf im Netz',
        balheatloadsbldgs: 'Ausgeglichener Wärmebedarf in Gebäuden',
        balheatloadsnetw: 'Ausgeglichene Wärmenachfrage im Netz',
        colddemallbldgs: 'Kältebedarf aller Gebäude',
        coldgainsnet: 'Kühlungsgewinne (netto)',
        coldimportallbldgs: 'Kälteimport aller Gebäude',
        coolgenpeakchillers: 'Kälteerzeugung von luftgekühlten Kältemaschinen',
        coolloadatenergyhub: 'Kälteeinspeisung in der Energiezentrale',
        coollossescoolingnetw: 'Wärmeverluste des Kältenetzes',
        elecdemallbldgs: 'Strombedarf aller Gebäude',
        elecdemandchillers: 'Strombedarf von Kältemaschinen',
        elecdemandelheatingrods: 'Wärmeerzeugung von Heizstäben',
        elecdemandheatpumps: 'Strombedarf der Wärmepumpen',
        elecdembes: 'Strombedarf der dezentralen Einheiten',
        elecimportallbldgs: 'Stromimport aller Gebäude',
        elecloadatenergyhub: 'Stromlast in der Energiezentrale',
        energyimportallbuildings: 'Energieimport aller Gebäude',
        heatdemallbldgs: 'Wärmebedarf aller Gebäude',
        heatgainscoldnet: 'Wärmegewinne (netto)',
        heatgainsheatnet: 'Wärmegewinne (netto)',
        heatimportallbldgs: 'Wärmeimport aller Gebäude',
        heatloadatenergyhub: 'Wärmeeinspeisung in der Energiezentrale',
        heatlossesheatingnetw: 'Wärmeverluste des Wärmenetzes',
        heatlossnet: 'Wärmeverluste (netto)',
        loadatenergyhub: 'Last in der Energiezentrale',
        pumpwork: 'Pumpenarbeit',
        sharedhw: 'Anteil Brauchwarmwasser',
        shareemobility: 'Anteil E-Mobilität',
        shareplugloads: 'Steckdosenlasten teilen',
        shareprocesscooling: 'Anteil Prozesskühlung',
        sharespacecooling: 'Gemeinsame Raumkühlung',
        sharespaceheating: 'Anteil Raumheizung'
      },
      sections: {
        balancing: 'Bilanzierung des Wärmebedarfs',
        cooling: 'Kühlen',
        coolingpower: 'Kühlleistung in Gebäuden',
        electricity: 'Strom',
        energydemands: 'Energiebedarf',
        energyimports: 'Energieimporte nach internem Ausgleich',
        feedin: 'Einspeisung der Energiezentrale',
        heating: 'Heizung',
        heatingpower: 'Heizleistung in Gebäuden',
        loadprofiles: 'Lastprofile in Ectogrid'
      },
      sectiontooltips: {
        balancing:
          'Die Visualisierung zeigt den Ausgleich des Wärme- und Kältebedarfs in den Gebäuden und zwischen den Gebäuden über das Netz. Für weitere Informationen tippen Sie auf die Info-Schaltfläche am unteren Rand.',
        energyimports:
          'Wärme-, Kälte- und Stromimporte des Gebäudes aus dem Wärme- und Stromnetz. Der Energieimport hängt von der Energieanlage des Gebäudes ab.',
        feedin:
          'Die Visualisierung zeigt die monatliche Einspeisung von Wärme und Kälte aus der Energiezentrale in das Netz.'
      },
      series: {
        balanced: 'Ausgewogen',
        coldfed: 'Einspeisung von Kälte in das Netz durch die Energiezentrale',
        electricity: 'Elektrizität',
        electricitydemand:
          'Strombedarf von dezentralen Wärmepumpen,<br/> Heizstäben und Kältemaschinen',
        electricityimport: 'Strom importiert',
        heatdemand: 'Wärmebedarf am Verdampfer von Wärmepumpen',
        heatfed: 'Einspeisung von Wärme in das Netz durch die Energiezentrale',
        heatimported: 'Wärmeimport aus dem ectogrid-Netz',
        totalspacecooling: 'Gesamtbedarf an Raum- und Prozesskühlung',
        totalspaceheating:
          'Gesamtbedarf an Raumheizung und Warmwasserbereitung',
        wasteheatdemand:
          'Abwärme am Verflüssiger von Kältemaschinen<br/> oder Wärmetauschern',
        wasteheatfed: 'Einspeisung von Abwärme aus Gebäuden in das Netz'
      },
      tablecolumns: {
        annualdemand: 'Jährlicher Bedarf',
        annualenergy: 'Jährliche Energie',
        cold: 'Kälte',
        cop: 'COP',
        electricity: 'Strom',
        electricitydemand: 'Strombedarf',
        floorarea: 'Bodenfläche',
        generatedenergy: 'Erzeugung',
        heat: 'Wärme',
        label: 'Name',
        machinecapacity: 'Maschinenkapazität',
        maxpower: 'Maximale Leistung',
        name: 'Name',
        peakdemand: 'Spitzennachfrage',
        peakpower: 'Spitzenleistung',
        processcooling: 'Prozesskühlung',
        seasonalcop: 'SCOP',
        spacecooling: 'Raumkühlung',
        spaceheating: 'Raumheizung',
        technology: 'Technologie',
        thermalcapacity: 'Thermische Kapazität'
      },
      tabletooltips: {
        cold: 'Aus dem Netz importierte Kühlenergie zur Deckung des Nettokältebedarfs des Gebäudes (nach Bedarfsausgleich im Gebäude).',
        electricity:
          'Aus dem Stromnetz importierter Strom zur Deckung des Strombedarfs für Steckerleisten, E-Mobilität und dezentrale Wärmepumpen, Heizstäbe und Kältemaschinen.',
        energyimportallbuildings:
          'Gesamter Wärme-, Kälte- und Stromimport von Gebäuden. Der Wärme-/Kälteimport beschreibt die vom Netz an das Gebäude übertragene Wärme/Kälte. Der Ausgleich des gleichzeitigen Wärme- und Kältebedarfs im Gebäude reduziert die importierte Wärme- und Kälteenergie. Der Stromimport ergibt sich aus dem Strombedarf des Gebäudes (Steckdosenlasten und E-Mobilität) sowie dem Strombedarf der Gebäudeenergieanlage (zum Beispiel für Wärmepumpen). Die Leistungswerte (in kW) beschreiben die jährliche Spitzenlast.',
        heat: 'Aus dem Netz importierte Wärme zur Deckung des Nettowärmebedarfs des Gebäudes (nach Bedarfsausgleich im Gebäude).',
        loadatenergyhub:
          'Wärme- und Kälteeinspeisung an der Energiezentrale in das Netz (inklusive Gleichzeitigkeitsfaktor und Ausgleich der Heiz- und Kühllasten). Der Strombedarf ist die Summe der Stromimporte aller Gebäude (Plugloads, E-Mobilität und Strom für dezentrale Wärmepumpen, Heizstäbe und Kältemaschinen) plus die Pumpenarbeit. Die Leistungswerte (in kW) beschreiben die jährliche Spitzenlast.'
      },
      title: 'Aggregierte Gebäudeergebnisse',
      tooltips: {
        balcoolloadsbldgs:
          'Wenn ein Gebäude Wärme aus dem Netz bezieht und gleichzeitig ein anderes Gebäude Kälte importiert (d.h. Wärme in das Netz einspeist), gleichen sich diese Bedarfe teilweise aus und reduzieren so die Einspeisung in der Energiezentrale.',
        balcoolloadsnetw:
          'Wenn ein Gebäude Wärme aus dem Netz bezieht und gleichzeitig ein anderes Gebäude Kälte importiert (d.h. Wärme in das Netz einspeist), gleichen sich diese Bedarfe teilweise aus und reduzieren somit die Einspeisung in der Energiezentrale.',
        balheatloadsbldgs:
          'Der gleichzeitige Heiz- und Kühlbedarf in Gebäuden kann teilweise durch Wärmerückgewinnung ausgeglichen werden: Die Abwärme der Kälteversorgung wird im Verdampfer der Wärmepumpe genutzt, wodurch der Wärme- und Kältebedarf der Gebäude aus dem Netz reduziert wird.',
        balheatloadsnetw:
          'Wenn ein Gebäude Wärme aus dem Netz importiert und gleichzeitig ein anderes Gebäude Kälte importiert (d.h. Wärme in das Netz einspeist), gleichen sich diese Bedarfe teilweise aus und reduzieren somit die Einspeisung in der Energiezentrale.',
        coldgainsnet:
          'Jährliche Energie, um die die Kühllast in der Energiezentrale sinkt (aufgrund von Wärmeverlusten an das Erdreich und Gleichzeitigkeitseffekten).',
        coldimportallbldgs:
          'Gesamter Wärmeimport aller Gebäude. Der Wärmeimport beschreibt die vom Netz an das Gebäude übertragene Wärme.',
        coolgenpeakchillers:
          'Luftgekühlte Kältemaschinen decken Spitzenlasten des Kältebedarfs im Gebäude ab. Dadurch verringert sich die Menge an Kälte, die die Gebäude aus dem Wärmenetz beziehen müssen.',
        coolloadatenergyhub:
          'Kühllast in der Energiezentrale für die Versorgung des Netzes. Dabei wird der Gleichzeitigkeitsfaktor des Netzes sowie der Ausgleich von gleichzeitigen Heiz- und Kühllasten im Netz berücksichtigt. Der Lastausgleich im Netz reduziert die Residuallast in der Energiezentrale.',
        coollossescoolingnetw: 'Thermische Verluste des Kältenetzes',
        elecdemandelheatingrods:
          'Elektrische Heizstäbe decken Spitzenlasten des Raumwärmebedarfs in Gebäuden ab. Dadurch verringert sich die Wärmemenge, die von den Gebäuden aus dem Wärmenetz importiert werden muss.',
        elecdemandheatpumps:
          'Durch den Einsatz von Wasser/Wasser-Wärmepumpen in den Gebäuden wird ein Teil des Wärmebedarfs durch den Strom der Wärmepumpe gedeckt. Dadurch verringert sich die Wärmemenge, die von den Gebäuden aus dem Wärmenetz importiert werden muss.',
        elecdembes:
          'Jährlicher Strombedarf aller Gebäudeenergieanlagen, einschließlich Wärmepumpen, Kältemaschinen oder elektrischen Heizstäben.',
        elecimportallbldgs:
          'Gesamtstromimport aller Gebäude. Der Stromimport ergibt sich aus dem Strombedarf des Gebäudes (Steckdosenlasten und E-Mobilität) sowie dem Strombedarf der Gebäudeenergieanlage (zum Beispiel für Wärmepumpen).',
        elecloadatenergyhub:
          'Die Stromlast in der Energiezentrale ist die Summe der Stromimporte aller Gebäude (Steckdosenlasten, E-Mobilität und Strom für die Gebäudeenergieanlagen einschließlich dezentraler Wärmepumpen) plus die Pumpenarbeit des Netzes.',
        heatgainscoldnet:
          'Jährliche Energie, um die die Kühllast in der Energiezentrale ansteigt (aufgrund eines Wärmestroms aus dem Erdreich und Gleichzeitigkeitseffekten).',
        heatgainsheatnet:
          'Jährliche Energie, um die die Wärmelast in der Energiezentrale sinkt (durch Wärmegewinne aus dem Erdreich und Gleichzeitigkeitseffekte).',
        heatimportallbldgs:
          'Gesamter Wärmeimport aller Gebäude. Der Wärmeimport beschreibt die vom Netz an das Gebäude übertragene Wärme. Der Ausgleich des gleichzeitigen Heiz- und Kühlbedarfs im Gebäude reduziert die importierte Heiz- und Kühlenergie.',
        heatloadatenergyhub:
          'Heizlast in der Energiezentrale zur Versorgung des Netzes. Dabei wird der Gleichzeitigkeitsfaktor des Netzes sowie der Ausgleich von gleichzeitigen Heiz- und Kühllasten im Netz berücksichtigt. Der Lastausgleich im Netz reduziert die Residuallast in der Energiezentrale.',
        heatlossnet:
          'Jährliche Energie, um die die Wärmelast an der Energiezentrale steigt (durch Wärmeverluste an das Erdreich und Gleichzeitigkeitseffekte).'
      },
      xaxis: {
        demandofallbuildings: 'Bedarf aller Gebäude',
        demandofdecentralunits: 'Bedarf der dezentralen Einheiten',
        energycentersupply: 'Versorgung der Energiezentrale',
        importofallbuildings: 'Import von allen Gebäuden'
      }
    },
    calculate: 'Berechnen',
    calculateerror: 'Berechnung konnte nicht gestartet werden',
    calculatetitle: 'Berechnung starten',
    calculations: {
      builds: {
        date: 'Datum',
        description: 'Beschreibung',
        status: 'Status'
      },
      calculating: 'Berechnen...',
      calculationfailed: 'Berechnung fehlgeschlagen',
      copylinktoclipboard: 'Link in die Zwischenablage kopieren',
      description: 'Beschreibung',
      editdescription: 'Beschreibung bearbeiten',
      failedtoloadbuilds: 'Lastberechnung fehlgeschlagen',
      formerrorsetting: 'Einstellung',
      formerrorstitle: 'Korrigieren Sie die Fehler vor der Berechnung',
      genericbuildname: 'diese Berechnung',
      newchangesinfo:
        'Sie haben Änderungen am Projekt vorgenommen. Klicken Sie auf „Berechnen“, um die aktualisierten Ergebnisse anzuzeigen.',
      nosolutionfound:
        'Keine praktikable Lösung gefunden. Normalerweise konnte die Energiezentrum den Wärmebedarf des ectogrid nicht decken. Bitte planen Sie mehr Wärme- und Kälteerzeugungskapazität ein, z.B. einen Gaskessel.',
      notcalculated: 'Keine Berechnung',
      notcalculatedinfo:
        'Das Projekt wurde noch nicht berechnet. Drücken Sie „Berechnen“, um die Berechnung zu starten.',
      status: {
        error: 'Fehler',
        no_solution_found: 'Keine Lösung gefunden',
        results_done: 'Erledigt',
        timedout: 'Zeitüberschreitung'
      },
      title: 'Ectoplanner-Berechnung'
    },
    confirmdeleteproject:
      'Möchten Sie dieses Projekt wirklich löschen? Alle Versionen werden ebenfalls gelöscht.',
    confirmdeleteversion:
      'Sind Sie sicher, dass Sie diese Version löschen möchten?',
    confirmdisabletech: {
      removepassivebalancing:
        'Wenn der passive Ausgleichsbehälter deaktiviert wird, werden den Projektinvestitionskosten automatisch weitere 10.000 Euro hinzugefügt, die die hydraulische Verbindung zwischen Warm- und Kaltrohren, einschließlich Reglern und Sensoren, abdecken.',
      titleformat: 'Möchten Sie {0} wirklich abwählen?'
    },
    currentversion: 'Aktuelle Version',
    datacopyformat: 'Daten werden von „{0}“ kopiert.',
    download: 'Herunterladen',
    energygraphs: {
      breakpointchiller: 'Haltepunkt Kältemaschinen-Backup',
      breakpointheatpump: 'Haltepunkt Wärmepumpen-Backup',
      energydemand: 'Energiebedarf',
      energyoptions: {
        cop: 'COP',
        demand: 'Gebäudebedarf',
        import: 'Einfuhr aus netz'
      },
      energytype: 'Energieart',
      plotoptions: {
        annual: 'Jährlich',
        durationcurve: 'Dauer der Kurve',
        monthly: 'Monatlich'
      },
      plottype: 'Plot-Typ'
    },
    export: 'Exportieren',
    exportairtemp: 'Lufttemperatur exportieren',
    exportcitydata: 'Stadtdaten exportieren',
    exportcsv: 'CSV exportieren',
    exportformdata: 'Daten mit Berechnungen exportieren',
    exportproject: 'Daten exportieren',
    failedtoloadproject: 'Projekt konnte nicht geladen werden',
    fileeditor: {
      change: 'Ändern...',
      failedtoloadfile: 'Datei konnte nicht geladen werden',
      uploaderror: 'Neue Datei kann nicht hochgeladen werden'
    },
    form: {
      accutankbu: {
        enabled: {
          helptext:
            'Passiver Ausgleichstank gleicht die Restlast des ectogrids aus.'
        },
        maxvol: {
          label: 'Maximales Volumen'
        },
        minvol: {
          label: 'Minimales Volumen'
        }
      },
      aquiferstoragebu: {
        enabled: {
          helptext:
            'Aquiferspeicher zur Einspeisung und Entnahme von Wärme in/aus dem Boden (saisonale Speicherung).'
        },
        maxchenergy: {
          helptext: 'Maximale Menge der im Boden gespeicherten Wärme.',
          label: 'Maximale Wärmeenergie'
        },
        maxchpower: {
          helptext: 'Maximale in den Boden eingespeiste Wärmeleistung.',
          label: 'Maximale thermische Leistung'
        },
        pumpwork: {
          helptext:
            'Zusätzlicher Strombedarf für jede kWh Wärme, die dem Erdreich entzogen oder in dieses eingespeist wird.',
          label: 'Pumpenarbeit'
        },
        roundeff: {
          helptext:
            'Verhältnis von entnommener zu eingespeister Wärme aus dem/ins Erdreich.',
          label: 'Rücklaufeffizienz'
        },
        roundeffenabled: {
          label: 'Berechnung des Umkreises'
        },
        roundeffmax: {
          label: 'Effizienz des Umkreises max.'
        },
        roundeffmin: {
          label: 'Wirkungsgrad des Umkreises min.'
        }
      },
      batterybu: {
        enabled: {
          helptext:
            'Die Batterie kann mit überschüssigem Strom aus KWK- oder PV-Anlagen geladen werden.'
        }
      },
      building: {
        bldgspeccops: {
          label: 'Gebäudespezifische COPs verwenden'
        },
        buildingformat: 'Gebäude {0}',
        calcdemands: {
          label: 'Geschätzter Bedarf'
        },
        coolingtimeseries: {
          label: 'Kühlungsbedarf (kWh/h)'
        },
        floorarea: {
          label: 'Beheizte/gekühlte Bodenfläche'
        },
        heatingtimeseries: {
          label: 'Raumheizung (kWh/h)'
        },
        machinecapacitytooltip:
          'Unter Maschinenkapazität versteht man die dimensionierte Kapazität der Maschine. In den meisten Fällen ist dies auch bei maximaler Heiz- oder Kühlleistung der Fall. Allerdings kann die Maschinenkapazität von der maximalen Wärme- oder Kälteproduktion in Querwärmepumpen abweichen.',
        params: {
          anndemspec: {
            helptext:
              'Jährlicher Bedarf an Energieart pro Quadratmeter Bodenfläche.',
            label: 'Spezifischer Jahresbedarf'
          },
          anndemtot: {
            helptext: 'Gesamter Jahresbedarf der Energieart.',
            label: 'Jährlicher Gesamtbedarf'
          },
          beslowexelheaterenabled: {
            helptext:
              'Wenn aktiviert, deckt ein elektrischer Heizstab die Spitzenwärmelasten ab. Für den Heizstab wird ein Wirkungsgrad von 100 % angenommen.',
            label: 'Elektrischer Heizstab'
          },
          beslowexelheatermaxload: {
            helptext:
              'Anteil der maximalen Heizlast, der durch den Heizstab abgedeckt wird (bivalent-paralleler Betrieb)',
            label: 'Deckung der maximalen Wärmelast'
          },
          beslowexhpcop: {
            label: 'Konstanter COP-Wert'
          },
          beslowexhpdhwcop: {
            label: 'Brauchwarmwasser (Wärmepumpe) COP'
          },
          beslowexpeakchillercop: {
            helptext:
              'Leistungskoeffizient der Kälteversorgung (luftgekühlte Kältemaschine und Kühlturm)',
            label: 'COP der Kälteerzeugung'
          },
          beslowexpeakchillerenabled: {
            helptext:
              'Falls aktiviert, wird eine luftgekühlte Kältemaschine zur Abdeckung von Kühlspitzenlasten installiert. Die Abwärme der Kältemaschine wird über einen Kühlturm an die Umgebung abgegeben. Die Kältemaschine reduziert die Spitzenlasten des Wärmenetzes im Sommer.',
            label: 'Luftgekühlte Kältemaschine für Spitzenlasten'
          },
          beslowexpeakchillermaxload: {
            helptext:
              'Anteil der maximalen Kühllast, der von der luftgekühlten Kältemaschine abgedeckt wird (bivalent-paralleler Betrieb)',
            label: 'Deckung der maximalen Kühllast'
          },
          buildingsubtype: {
            label: 'Gebäude-Subtyp'
          },
          buildingtype: {
            helptext:
              'Allgemeine Kategorie des Gebäudes. Verwenden Sie den Untertyp, um weitere Angaben zu machen. Das Ändern der Gebäudetypen fügt Standardwerte ein und wirkt sich auf die Berechnungen des Heiz- und Kühlprofils aus.',
            label: 'Gebäudetyp'
          },
          carnoteta: {
            label: 'Exergie-Wirkungsgrad'
          },
          carnotetachiller: {
            helptext:
              'Der Exergie-Wirkungsgrad wird als Vorfaktor für den Carnot-Wirkungsgrad verwendet. Kaltwassersätze haben in der Regel Exergie-Wirkungsgrade zwischen 40 (kleine Anlagen, niedriger Wirkungsgrad) und 60 (große Anlagen, hoher Wirkungsgrad).'
          },
          carnotetahp: {
            helptext:
              'Der Exergie-Wirkungsgrad wird als Vorfaktor des Carnot-Wirkungsgrads verwendet. Wärmepumpen haben in der Regel Exergie-Wirkungsgrade zwischen 40 (kleiner Wirkungsgrad, niedriger Wirkungsgrad) und 60 (großer Wirkungsgrad, hoher Wirkungsgrad).'
          },
          considercurve: {
            helptext:
              'Wenn diese Option aktiviert ist, sinkt die Vorlauftemperatur der Heizungsanlage im Gebäude linear mit steigender Außenlufttemperatur (ansonsten ist sie das ganze Jahr über konstant). Bei Berücksichtigung der Heizkurve wird eine Vorlauftemperatur von 25 °C bei einer Außentemperatur von 15 °C (oder höher) angenommen. Die maximale Vorlauftemperatur wird erreicht, wenn die Außenlufttemperatur ihr Minimum erreicht hat (kälteste Stunde des Jahres).',
            label: 'Vorlauftemperaturkurve berücksichtigen'
          },
          consumptionemob: {
            label: 'Verbrauch der Strecke'
          },
          cop: {
            label: 'COP-Berechnung'
          },
          copoptions: {
            carnot: 'COP berechnen (Carnot)',
            const: 'Konstanter COP',
            productdata: 'Produktdaten',
            upload: 'COP-Zeitreihe hochladen'
          },
          customprofile: {
            helptext:
              'Geben Sie ein benutzerdefiniertes Bedarfsprofil für diese Energieart an. Es ersetzt den Algorithmus, der ein Profil auf der Grundlage der Eingabewerte berechnet.',
            label: 'Benutzerdefiniertes Bedarfsprofil'
          },
          distanceemob: {
            label: 'Geladene Fahrstrecke pro Tag'
          },
          enableformat: 'Aktivieren {0}',
          enablehelptext:
            'Wenn diese Option aktiviert ist, wird die Nachfrage nach dieser Energieart bei den Berechnungen berücksichtigt.',
          energysystem: {
            copoptions: {
              helptext:
                'Geben Sie an, wie der COP (Coefficent Of Performance) für die Wärmepumpe oder den Kaltwassersatz berechnet werden soll. Sie können wählen, ob Sie die Carnot-Formel, einen konstanten Wert oder die Angabe eines Produkts zur Berechnung verwenden möchten. Wenn Sie ein Produkt angeben, wird der COP anhand der Herstellerdaten auf der Grundlage der Norm EN 14825 berechnet.'
            },
            sections: {
              coolingsupply: 'Versorgung mit Kühlung',
              dhw: 'Brauchwarmwasser (Wärmepumpe)',
              spaceheating: 'Raumheizung (Wärmepumpe)'
            }
          },
          floorarea: {
            helptext:
              'Beheizte oder gekühlte Bodenfläche. Die Geschossfläche wird nur zur Umrechnung von flächenspezifischen Bedarfswerten (kWh/m²) in Jahresbedarf (MWh) und umgekehrt verwendet.',
            label: 'Bodenfläche'
          },
          fullloadhours: {
            helptext: 'Volllaststunden der Energieerzeugung.',
            label: 'Volllaststunden'
          },
          individualcosts: {
            helptext:
              'Aktivieren Sie individuelle Kosten für die installierten Energietechnologien in diesem Gebäude. Ist dies nicht der Fall, werden die Standardwerte aus dem Abschnitt Modell verwendet.',
            label: 'Individuelle Kosten'
          },
          lowexcc: {
            label: 'Kältemaschine'
          },
          lowexcoolingoption: {
            helptext:
              'Gibt die Art der Versorgung der Kältemaschine an. Bei passiver Kühlung wird der Kühlbedarf durch einen Wärmetauscher gedeckt (kein Strom erforderlich). Die Abwärme wird in das Netz eingespeist. Bei Kältemaschine (wassergekühlt) decken die Kältemaschinen den Kühlbedarf und speisen die Abwärme in das Netz ein. Eine Kältemaschine kann erforderlich sein, wenn die Netztemperaturen zu hoch sind, um die Kälte direkt mit einem Wärmetauscher zu erzeugen. Erfahren Sie mehr',
            label: 'Versorgungsart'
          },
          lowexdrc: {
            label: 'Passive Kühlung (Wärmetauscher)'
          },
          peakdemspec: {
            helptext: 'Spezifische Höchstlast für die Energieart.',
            label: 'Spezifischer Spitzenbedarf'
          },
          peakdemtot: {
            helptext: 'Maximale Last für die Energieart.',
            label: 'Gesamter Spitzenbedarf'
          },
          productdata: {
            formatstring:
              'COP: {0} (für Temperaturerhöhung von {1} bis {2} °C)',
            label: 'Produkt'
          },
          seasonbegin: {
            helptextcooling:
              'Beginn der Abkühlungssaison (normalerweise im Frühjahr).',
            helptextheating:
              'Beginn der Heizperiode (typischerweise im Herbst).',
            label: 'Beginn der Saison'
          },
          seasonend: {
            helptextcooling: 'Ende der Kühlsaison (typischerweise im Herbst)',
            helptextheating: 'Ende der Heizsaison (in der Regel im Frühjahr).',
            label: 'Ende der Saison'
          },
          sections: {
            building: 'Gebäude',
            cooling: 'Kühlung',
            dhw: 'Brauchwarmwasser',
            electricity: 'Strom',
            emob: 'E-Mobilität',
            energysystem: 'Umspannwerksausrüstung',
            heating: 'Heizung',
            heatingcooling: 'Heizen und Kühlen',
            investment: 'Investition',
            plugloads: 'Steckerleisten',
            processcool: 'Prozesskühlung',
            spacecooling: 'Raumkühlung',
            spaceheating: 'Raumheizung',
            summary: 'Zusammenfassung',
            transversal: 'Transversales Energiesystem'
          },
          setpeakload: {
            helptext:
              'Geben Sie die Parameter für die maximale Last und die Anzahl der Volllaststunden für die Energieart an.',
            label: 'Spitzenlast einstellen'
          },
          setseason: {
            helptext:
              'Es kann eine Saison eingestellt werden. In der Nebensaison ist der Bedarf für diese Energieart gleich Null.',
            label: 'Eingestellte Saison'
          },
          sharedhw: {
            helptext:
              'Anteil des Warmwasserheizungsbedarfs im Verhältnis zum gesamten Heizungsbedarf (Raumheizung und Warmwasser).',
            label: 'Anteil der Warmwasserbereitung'
          },
          supplytempchiller: {
            helptext:
              'Vorlauftemperatur des Kühlsystems im Gebäude (z. B. 16 °C bei Fußbodenkühlung oder 6 °C bei konventionellen Kühlsystemen).',
            label: 'Vorlauftemperatur'
          },
          supplytempdhw: {
            helptext:
              'Temperatur des Brauchwassers (normalerweise zwischen 55 und 60 °C)',
            label: 'Vorlauftemperatur'
          },
          supplytempheatpump: {
            helptext:
              'Maximale Vorlauftemperatur der Heizungsanlage im Gebäude (z. B. 35 °C bei Fußbodenheizung oder 50-90 °C bei Heizkörpern).',
            label: '(Maximale) Vorlauftemperatur'
          },
          textareaplaceholder:
            '"Geben Sie eine Zeitreihe ein oder fügen Sie Text aus Excel oder einem Texteditor ein. Sie können auch eine Textdatei per Drag & Drop in diesen Bereich ziehen.\n\nGültige Anzahl von Werten:\n- 8760 (Stunden),\n- 365 (Tage),\n- 12 (Monate),\n- 4 (Jahreszeiten), oder\n- 1 (konstanter Wert über das Jahr).\nBeispieleingabe:  5.2, 4.8, 3.6, 4.9',
          transversal: {
            coolsupplytemp: 'Kühlvorlauftemperatur',
            supplytempdwh: 'Temperatur der Warmwasserversorgung',
            usetransversal: {
              helptext:
                'Wählen Sie zwischen dem transversalen Energiesystem und dem traditionellen Energiesystem. Das transversale Energiesystem nutzt eine einzige Wärmepumpe für die Heizung und Kühlung. Das traditionelle Energiesystem verfügt über eine eigene Wärmepumpe zum Heizen und einen eigenen Kühler zum Kühlen.',
              label: 'Nutzen Sie ein transversales Energiesystem'
            }
          }
        },
        showsummary: 'Zusammenfassung...',
        specCOPheat: {
          label: 'COP für Raumheizung'
        },
        specCOPtapwater: {
          label: 'COP für die Brauchwassererwärmung'
        },
        speccooldem: {
          label: 'Spezifischer Raumkühlungsbedarf'
        },
        specheatdem: {
          label: 'Spezifischer Raumwärmebedarf'
        },
        tapwaterdem: {
          label:
            'Brauchwarmwasserbedarf als Prozentsatz des gesamten Heizbedarfs'
        },
        tapwatertimeseries: {
          helptext:
            'Der Upload von Warmwasser ist nicht obligatorisch. Wenn keine Datei hochgeladen wird, wird kein Warmwasserbedarf angenommen.',
          label: 'Brauchwarmwasser (kWh/h)'
        }
      },
      chiller: {
        copconst: {
          helptext:
            'Konstanter COP der Kältemaschine während des ganzen Jahres.'
        },
        copwithcarnot: {
          helptext:
            'Berechnung des COP auf der Grundlage des Carnot-Wirkungsgrads mit der Kühlvorlauftemperatur im Gebäude als Wärmequelle und dem kalten ectogrid Rohr als Wärmesenke.'
        },
        enabled: {
          label: 'Installation von Kältemaschinen'
        },
        tempcooling: {
          label: 'Vorlauftemperatur Raumkühlung'
        },
        title: 'Kältemaschinen'
      },
      chpbu: {
        etael: {
          helptext:
            'Bei kleinen KWK-Anlagen mit geringer Leistung (&lt;100 kW) liegt der elektrische Wirkungsgrad bei bis zu 35 %. Große KWK-Anlagen mit einer Kapazität von mehr als 1 MW erreichen elektrische Wirkungsgrade von 40 % und mehr.',
          label: 'Elektrischer Wirkungsgrad'
        }
      },
      coldstoragebu: {
        enabled: {
          helptext:
            'Der Kältespeicher kann mit Kompressions- und Absorptionskältemaschinen geladen werden.'
        }
      },
      common: {
        solararealist: {
          label: 'Flächen'
        }
      },
      compressionchiller: {
        passive_cooling_enabled: 'Ermöglichung passiver Kühlung'
      },
      compressionchillerbu: {
        copcarnoteff: {
          helptext:
            'COP mit ectogrid Temperatur und Außenlufttemperatur berechnen.'
        }
      },
      districtcoolingbu: {
        cap: {
          label: 'Kapazität des Fernkälteanschlusses'
        }
      },
      districtheatingbu: {
        cap: {
          label: 'Kapazität des Fernwärmeanschlusses'
        }
      },
      ecologicalimpact: {
        co2el: {
          helptext:
            'Spezifische CO₂-Äquivalente des Strommixes des Stromnetzes.',
          label: 'CO₂-Emissionen Stromnetz'
        },
        co2gas: {
          helptext:
            'Spezifische CO₂-Äquivalente, die bei der Verbrennung von Erdgas entstehen.',
          label: 'CO₂-Emissionen Erdgas'
        },
        district_cooling_co2: 'Fernkälte CO₂',
        district_cooling_primary_energy: 'Fernkälte Primärenergie',
        district_heating_co2: 'Fernwärme CO₂',
        district_heating_primary_energy: 'Fernwärme-Primärenergie',
        gas_primary_energy: 'Primärenergie Gas',
        grid_primary_energy: 'Primärenergie aus dem Netz',
        title: 'Umgebungsparameter',
        waste_cold_co2: 'Abwärme Kälte CO₂',
        waste_cold_primary_energy: 'Abwärme Primärenergie',
        waste_heat_co2: 'Abwärme CO₂',
        waste_heat_primary_energy: 'Abwärme Primärenergie'
      },
      economicparameters: {
        enablescenario: {
          helptext:
            'Wirtschaftliche Parameter aus einem vordefinierten Szenario laden',
          label: 'Szenario einschalten'
        },
        envsurcharge: {
          helptext: 'Für den deutschen Fall entspricht dies der EEG-Umlage.',
          label: 'Umweltaufschlag'
        },
        interestrate: {
          helptext:
            'Der Zinssatz beeinflusst die Annualisierung der Investitionen nach VDI 2067.',
          label: 'Zinssatz'
        },
        othsurcharge: {
          label: 'Andere Zuschläge'
        },
        priceexel: {
          helptext: 'Stromgroßhandelspreis zum aktuellen Zeitpunkt.'
        },
        priceexgas: {
          helptext: 'Großhandelspreis für Gas zum aktuellen Zeitpunkt.'
        },
        projectlifetime: {
          helptext:
            'Die Projektlaufzeit wirkt sich auf die Annualisierung von Investitionen nach VDI 2067 aus.',
          label: 'Projektlaufzeit'
        },
        scenario: {
          helptext:
            'Wählen Sie eines der vordefinierten Szenarien aus. Sie können die Parameter des Szenarios bei Bedarf weiter anpassen.',
          label: 'Szenario ausgewählt'
        },
        section: {
          electricity: 'Elektrizitätspreis',
          gas: 'Gaspreis',
          general: 'Wirtschaftliche Parameter'
        },
        taxrate: {
          label: 'Steuersatz'
        }
      },
      electricalheater: {
        enabled: {
          label: 'Installation eines elektrischen Zusatzheizstabs'
        },
        peakcover: {
          helptext:
            'Anteil des Heizbedarfs, der bei maximalem Heizbedarf (z. B. kältester Wintertag) durch den Elektroheizstab gedeckt wird.'
        },
        title: 'Elektrischer Zusatzheizstab'
      },
      emptyform: {
        label:
          'Um Berechnungen durchzuführen, müssen Sie einen Standort auswählen und einige Gebäude hinzufügen.'
      },
      energycosts: {
        enablefeedinel: {
          helptext:
            'Wenn diese Option aktiviert ist, kann überschüssiger Strom, z. B. durch KWK oder PV, in das Stromnetz eingespeist werden. Wenn die Einspeisung nicht erlaubt ist, wird der erzeugte Strom nur vor Ort genutzt. PV-Abschaltungen sind möglich.',
          label: 'Einspeisung ermöglichen'
        },
        price_el_profile: {
          label: 'Strompreisprofil'
        },
        price_el_uploaded: {
          label: 'Upload-Preisprofil'
        },
        priceel: {
          label: 'Annualisierter Strompreis'
        },
        pricegas: {
          label: 'Annualisierter Erdgaspreis'
        },
        revenuefeedinel: {
          helptext: 'Einnahmen für die Einspeisung in das Stromnetz.',
          label: 'Einspeisevergütung'
        },
        title: 'Energiekosten'
      },
      freecooling: {
        enabled: {
          helptext:
            'Aktivieren Sie die direkte Kühlung mit dem ectogrid. Wärmetauscher werden in Gebäuden anstelle von Kältemaschinen installiert.',
          label: 'Direkte Kühlung installieren'
        },
        invvar: {
          helptext: 'Investition für Wärmetauscher.'
        }
      },
      gasboiler: {
        enabled: {
          label: 'Installation eines zusätzlichen Gaskessels'
        },
        peakcover: {
          helptext:
            'Anteil des Heizbedarfs, der durch den Gaskessel gedeckt wird, wenn der maximale Heizbedarf auftritt (z. B. an einem kalten Wintertag).'
        },
        title: 'Gas-Zusatzheizkessel'
      },
      gsheatpumpbu: {
        copconstcool: {
          label: 'COP (Kühlen) (konstant)'
        }
      },
      heatpump: {
        copconstspaceheating: {
          label: 'COP Raumheizung'
        },
        copconsttapwater: {
          label: 'COP Brauchwarmwasser'
        },
        tempspaceheating: {
          label: 'Vorlauftemperatur Raumheizung'
        },
        temptapwater: {
          label: 'Vorlauftemperatur Brauchwarmwasser'
        },
        title: 'Wärmepumpen in Gebäuden'
      },
      heatstoragebu: {
        enabled: {
          helptext:
            'Wärmespeicher kann mit Gaskessel, KWK oder Elektrokessel aufgeladen werden.'
        }
      },
      investment: {
        cc: {
          label: 'Kältemaschine'
        },
        drc: {
          label: 'Direkte Kühlung'
        },
        eh: {
          label: 'Elektrische Heizung'
        },
        helptext:
          'Dies sind die allgemeinen Investitionseinstellungen für das Energiesystem. Sie können auch individuelle Kosten für jedes Gebäude im Gebäudeabschnitt angeben.',
        hp: {
          label: 'Wärmepumpe'
        },
        peak_chiller: {
          label: 'Kältemaschine Spitzenlast'
        },
        title: 'Investition Haustechnik'
      },
      locationhelp:
        'Bitte beachten Sie, dass bei einem Standortwechsel der spezifische Jahresbedarf für jede Energieart in allen Gebäuden aktualisiert wird, um den Bedarf des neuen Standorts widerzuspiegeln.',
      network: {
        buttontitle: 'Vorgeschlagene mittlere Netztemperatur',
        costsearthwork: {
          label: 'Kosten für Erdarbeiten und Verlegung'
        },
        costspipes: {
          label: 'Kosten Rohrmaterial'
        },
        diversityfactor: {
          helptext:
            'Der Gleichzeitigkeitsfaktor beschreibt, wie stark die Spitzenlast in der Energiezentrale reduziert wird. Der Gesamtbedarf des Jahreslastprofils wird durch die Glättung nicht verändert. Für eine konservative Berechnung kann ein Gleichzeitigkeitsfaktor von 1 angenommen werden (keine Glättung und damit keine Reduzierung der Spitzenlast).',
          label: 'Gleichzeitigkeitsfaktor'
        },
        enablenetwcosts: {
          helptext:
            'Die Netzkosten sind eine konstante Gegenposition zu den reinen Systemkosten. Sie haben keinen Einfluss auf die Energieflussberechnung.',
          label: 'Netzkosten berücksichtigen'
        },
        enabletempupload: {
          label: 'Hochladen des mittleren Netztemperaturprofils (°C)'
        },
        estimated_volume: {
          helptext:
            'Geschätztes Volumen des Netzes und des passiven Ausgleichsbehälters',
          label: 'Durchschnittliche Netztemperatur'
        },
        gridmeantemperature: '',
        gridmeantempgraph: {
          helptext:
            'Die durchschnittliche Netztemperatur ist der Sollwert für das Ectogrid. Die warme Rohrtemperatur ist die Durchschnittstemperatur plus ½ von Delta T, während die kalte Rohrtemperatur die Durchschnittstemperatur minus ½ von Delta T ist.',
          label: 'Durchschnittliche Netztemperatur'
        },
        gridtemptablehelp:
          'Aktive Ausgleichsarbeitsstunden: die Anzahl der Betriebsstunden mit den geringsten Betriebsstunden, um das Netz mit der benötigten Energie zu versorgen. Der Optimierungsalgorithmus liefert die optimale Lösung.',
        gridtmethod: {
          constant: 'Konstanter Wert',
          label: 'Netztemperaturmethode',
          suggestion: 'Vorschlag für mittlere Netztemperatur',
          userupload: 'Benutzer-Uploadprofil'
        },
        gridweightedavgtempgraph: {
          helptext:
            'Die gewichtete Durchschnittstemperatur berücksichtigt die Volumina von warmem und kaltem Wasser in den Rohren und im Tank und bietet eine genauere Angabe des gespeicherten Energieniveaus und der Zyklen.',
          label: 'Gewichtete Netzdurchschnittstemperatur'
        },
        groundtempoption: {
          const: 'Konstante',
          label: 'Bodentemperaturmethode',
          variable: 'Berechnung'
        },
        heatlosspipediameter: {
          helptext:
            'Mittlerer Innendurchmesser aller Rohre des Wärmenetzes. Da der mittlere Rohrdurchmesser die Wärmeverluste und -gewinne nur mäßig beeinflusst, kann er geschätzt werden. Typischerweise beträgt sie etwa 250 mm.',
          label: 'Mittlerer Rohrdurchmesser'
        },
        initial_kpis_weighted_t_after_balancing: {
          label: 'Vorgeschlagene mittlere Netztemperatur'
        },
        installationdepth: {
          helptext:
            'Tiefe, in der das Netzwerk installiert ist. Die Einbautiefe beeinflusst die Bodentemperatur. Bodentemperaturprofile können nur bis zu einer Tiefe von 2 m berechnet werden.',
          label: 'Einbautiefe'
        },
        max_grid_t: {
          helptext: 'Maximale zulässige Netztemperatur',
          label: 'Maximale zulässige Netztemperatur'
        },
        mean_t_profile: {
          label: 'Mindest zulässige mittlere Netztemperatur'
        },
        min_grid_t: {
          helptext: 'Mindest zulässige Netztemperatur',
          label: 'Mindest Netztemperatur'
        },
        netwinsulation: {
          helptext:
            'Wenn erhebliche Wärmegewinne und -verluste zu erwarten sind, sollte die Berechnung auf der Grundlage des Rohrtyps "Ungedämmtes Kunststoffrohr" erfolgen. Bitte beachten Sie, dass die Berechnung der Wärmegewinne und -verluste noch nicht mit realen Daten validiert werden konnte und daher nur eine Schätzung darstellt.',
          insulated: 'Isolierte Rohre (keine Wärmeverluste/-gewinne)',
          label: 'Isolierung der Rohre',
          uninsulated: 'Ungedämmte Kunststoffrohre'
        },
        networklength: {
          helptext:
            'Länge des Grabens unter der Annahme, dass beide Rohre in einem einzigen Graben verlegt sind.',
          label: 'Länge des Netzes'
        },
        netwtempcoldpipe: {
          helptext:
            'Die Netztemperaturen wirken sich auf die COP-Berechnung aus, wenn die COPs auf Carnot-Wirkungsgraden sowie der Speicherkapazität des passiven Ausgleichsbehälters basieren.',
          label: 'Temperatur der kalten Leitung'
        },
        netwtempdiff: {
          helptext:
            'Konstantes Auslegungsdelta T zwischen warmer und kalter Leitung.',
          label: 'Temperaturdifferenz (warme/kalte Leitung)'
        },
        netwtempwarmpipe: {
          label: 'Temperatur der warmen Leitung'
        },
        suggested_kpis_weighted_t_after_balancing: {
          label:
            'Gewichtete mittlere Netztemperatur mit vom Benutzer eingegebener mittlerer Temperatur'
        },
        suggested_mean_t_profile: {
          label: 'Benutzer eingegebene mittlere Netztemperatur'
        },
        suggestedcoldpipetemp: {
          label: 'Vorschlag für kalte Rohrtemperatur'
        },
        suggestedgridmeantemp: {
          label: ''
        },
        suggestedwarmpipetemp: {
          label: 'Vorschlag für warme Rohrtemperatur'
        },
        table: {
          activebalancingtankworkinghours:
            'Arbeitsstunden für aktiven Ausgleich',
          gridandtankvolume: 'Netz- und Tankvolumen'
        },
        tempgroundconst: {
          helptext:
            'Bodentemperatur in der Einbautiefe des Wärmenetzes. Die Bodentemperatur beeinflusst Wärmeverluste oder -gewinne.',
          label: 'Bodentemperatur'
        },
        temptimeseries: {
          label: 'Temperatur-Zeitreihen'
        },
        thconductivityground: {
          helptext:
            'Die Wärmeleitfähigkeit des Bodens hängt von den örtlichen Gegebenheiten ab. Die deutsche Norm VDI 4640 gibt für Sand 1,2 W/mK, für Ton und Schluff 1,5 W/mK und für sandigen Ton 1,8 W/mK an.',
          label: 'Thermal conductivity ground'
        },
        title: 'Netzparameter',
        use_grid_t_suggestion: {
          label: 'Netzwerkparameter...'
        },
        userinputgridmeantemp: {
          label: 'Vom Benutzer eingegebene mittlere Netztemperatur'
        }
      },
      nobuildings: {
        label: 'Keine Gebäude hinzugefügt.'
      },
      notechs: {
        label:
          'Bitte fügen Sie die zu berechnenden Energiezentrumtechnologien hinzu.'
      },
      photovoltaic: {
        efficiency: {
          label: 'Wirkungsgrad'
        },
        enabled: {
          label: 'Photovoltaik'
        },
        etael: {
          label: 'Wirkungsgrad der Module'
        },
        headers: {
          area: 'Fläche (m²)',
          azimuth: 'Azimut (°)',
          elevation: 'Höhe (°)'
        },
        maxarea: {
          label: 'Maximale Fläche'
        },
        minarea: {
          label: 'Minimale Fläche'
        },
        pvprofile: {
          label: 'Profilserie'
        },
        pvprofileuploaded: {
          label: 'Profilserie hochladen'
        }
      },
      referencesystem: {
        ashp_cop_dhw: 'COP der Luft-Wärmepumpe für warmes Leitungswasser',
        ashp_cop_space_heat: 'COP der Luft-Wärmepumpe',
        boiler_eff: 'Thermischer Wirkungsgrad von Heizkesseln in Gebäuden',
        central_gas_boiler_eta_th:
          'Thermischer Wirkungsgrad des zentralen Gaskessels im Wärmenetzszenario',
        central_gas_boiler_inv:
          'Investitionen für einen zentralen Gaskessel im Wärmenetzszenario',
        central_gas_boiler_om:
          'Wartung für zentralen Gaskessel im Wärmenetzszenario',
        chiller_cop: 'COP von Kältemaschinen in Gebäuden',
        gshp_cop_dhw: 'COP der Erdwärmepumpe für warmes Leitungswasser',
        gshp_cop_space_heat: 'COP der Erdwärmepumpe',
        inv_ashp: 'Investitionen für Luftwärmepumpen in Gebäuden',
        inv_chiller: 'Investitionen für Kältemaschinen in Gebäuden',
        inv_gas_boiler: 'Investitionen für Gasheizkessel in Gebäuden',
        inv_gshp: 'Investitionen für Erdwärmepumpen in Gebäuden',
        netw_losses: 'Netzverluste eines konventionellen 4GDH-Wärmenetzes',
        om_costs:
          'Anteil der Wartungskosten (für alle Gebäude die gleichen Geräte)',
        title: 'Referenzsystem',
        tooltips: {
          ashp_cop_dhw:
            'COP von Luft/Wasser-Wärmepumpen zur Deckung des Warmwasserbedarfs',
          ashp_cop_space_heat:
            'COP von Luft-Wärmepumpen zur Deckung des Raumwärmebedarfs',
          boiler_eff:
            'Thermischer Wirkungsgrad von dezentralen Gaskesseln zur Deckung des Raumwärme- und Warmwasserbedarfs',
          central_gas_boiler_eta_th:
            'Thermischer Wirkungsgrad des zentralen Gaskessels, der das Netz versorgt',
          central_gas_boiler_inv:
            'Investitionskosten für den zentralen Gaskessel, der das Netz versorgt',
          central_gas_boiler_om:
            'Wartungskosten als Anteil der Investitionen für den zentralen Gaskessel, der das Wärmenetz versorgt',
          chiller_cop:
            'COP zur Deckung des Kältebedarfs in den Gebäuden (mit Luftwärmepumpe, Erdwärmepumpe oder Kältemaschinen - je nach Szenario).',
          gshp_cop_dhw:
            'COP von Erdwärmepumpen zur Deckung des Warmwasserbedarfs',
          gshp_cop_space_heat:
            'COP von Erdwärmepumpen zur Deckung des Raumwärmebedarfs',
          inv_chiller: 'Investitionskosten für dezentrale Kältemaschinen.',
          netw_losses:
            'Thermische Verluste als Anteil der über das Netz gelieferten Wärme',
          om_costs:
            'Wartungskosten als Anteil der Investitionen für dezentrale Wärmepumpen, Kältemaschinen und Heizkessel in den Gebäuden.'
        }
      },
      revheatpumpbu: {
        consider_defrosting: {
          helptext:
            'Wenn diese Funktion aktiviert ist, wird der Carnot-Wirkungsgrad in den Zeiten, in denen die Wärmepumpe zwischen Normalbetrieb und Abtaubetrieb umschaltet, um 30 % reduziert. Es wird davon ausgegangen, dass der Abtaubetrieb stattfindet, wenn die Raumlufttemperatur unter 5 °C und die relative Luftfeuchtigkeit über 50 % liegt.',
          label: 'Abtauung berücksichtigen'
        },
        cop: {
          label: 'COP-Berechnung'
        },
        cop_const_cool: {
          label: 'Konstanter COP-Wert Kälte'
        },
        cop_const_heat: {
          label: 'Konstanter COP-Wert Wärme'
        },
        cop_cool_timeseries: {
          label: 'Benutzerdefinierte COP-Zeitreihe für Kälte'
        },
        cop_heat_timeseries: {
          label: 'Benutzerdefinierte COP-Zeitreihe für Wärme'
        },
        maxCOPcool: {
          label: 'Maximaler COP (Kühlen)'
        },
        maxCOPheat: {
          label: 'Maximaler COP (Heizung)'
        }
      },
      saveandcalculate: 'Sparen und Berechnen',
      sensitivity: {
        title: 'CO₂-Empfindlichkeitsanalyse',
        value: 'Wert (€/t)'
      },
      shared: {
        ASHPcarnoteff: {
          label: 'Carnot-Wirkungsgrad'
        },
        absorptionchiller: 'Absorptionskältemaschine',
        accumulatortank: 'Passiver Ausgleichsbehälter',
        addproject: 'Projekt hinzufügen',
        airsourceheatpump: 'Luft-Wärmepumpe',
        ambientcoldsource: 'Kältequelle in der Umgebung',
        ambientheatsource: 'Umgebungswärmequelle',
        aquiferstorage: 'Aquifer-Speicher',
        battery: 'Batterie',
        building: 'Gebäude',
        buildingname: 'Name des Gebäudes',
        buildings: 'Gebäude',
        capacity: 'Kapazität',
        category: 'Kategorie',
        changeratehelp:
          'Änderungsrate, mit der der Großhandelspreis jedes Jahr in die Zukunft sinkt oder steigt.',
        chiller: 'Kältemaschine',
        chpunit: 'Blockheizkraftwerk',
        co2emissions: 'CO2-Emissionen',
        coldstorage: 'Kältespeicher',
        compressionchiller: 'Kompressionskältemaschine',
        copconst: {
          label: 'Konstanter COP'
        },
        coptimeseries: {
          label: 'COP-Zeitreihe'
        },
        costom: {
          label: 'Kosten für Betrieb und Wartung'
        },
        coverofpeakdemand: 'Deckung des Spitzenbedarfs',
        dhw: 'Warmes Brauchwasser im Haushalt',
        directcooling: 'Direkte Kühlung',
        districtcooling: 'Fernkälte',
        districtheating: 'Fernwärme',
        electricboiler: 'Elektrischer Heizkessel',
        electricheatingrod: 'Elektrischer Heizstab',
        electricity: 'Elektrizität',
        electricityfeedin: 'Stromeinspeisung',
        electricityfromgrid: 'Strom aus dem Netz',
        electricityimport: 'Elektrizitätsimport',
        enablecopupload: {
          label: 'COP-Profil hochladen'
        },
        enablesupplylimit: {
          label: 'Jährliche Versorgungsgrenze berücksichtigen'
        },
        endenergy: 'Primäre Energiequelle',
        equipmenttype: 'Gerätetyp',
        error: 'Fehler',
        etath: {
          helptext:
            'In der Regel liegt die Summe aus elektrischem Wirkungsgrad und thermischem Wirkungsgrad bei etwa 90 %.',
          label: 'Thermischer Wirkungsgrad'
        },
        feedin: 'Einspeisung',
        feedintogrid: 'Einspeisung ins Netz',
        fullloadhours: 'Volllaststunden',
        gasboiler: 'Gasheizkessel',
        generation: 'Erzeugung',
        groundsourceheatpump: 'Erdreich-Wärmepumpe',
        heatingcopconstant: 'Heizungs-COP (konstant)',
        heatpump: 'Wärmepumpe',
        heatpumpheatingonly: 'Wärmepumpe (nur Heizung)',
        heatstorage: 'Wärmespeicher',
        initialgridsurcharge: 'Anfänglicher Netzzuschlag',
        initialwholesalemarketprice: 'Ursprünglicher Großhandelsmarktpreis',
        invvar: {
          helptext: 'Spezifische Investitionskosten für die Technologie',
          label: 'Investition'
        },
        lifetime: {
          helptext: 'Erwartete Lebensdauer der Technologie',
          label: 'Lebenszeit'
        },
        maxcap: {
          label: 'Maximale Kapazität'
        },
        maximumcop: 'Maximaler COP',
        mincap: {
          label: 'Minimale Kapazität'
        },
        naturalgas: 'Erdgas',
        naturalgasbalancingunit: 'Erdgas (Energiezentrum)',
        naturalgasbuildings: 'Erdgas (Gebäude)',
        nodatafound: 'Keine Daten gefunden',
        peakloadchiller: 'Spitzenlast-Kältemaschine',
        pipeinstallation: 'Verlegung der Rohre',
        potentialtimeseries: {
          label: 'Potenzielle Zeitreihen'
        },
        price: 'Preis',
        spacecooling: 'Raumkühlung',
        spaceheating: 'Raumheizung',
        storageloss: 'Speicherverlust',
        supplylimit: {
          label: 'Jährliche Versorgungsgrenze'
        },
        technology: 'Technologie',
        thermalpoweravailableovertheyear:
          'Über das Jahr hinweg verfügbare Wärmeleistung.',
        transversalhp: 'Transversale Wärmepumpe',
        usecarnotefficiency: 'Carnot-Wirkungsgrad verwenden',
        useconstantcop: 'Konstante COP verwenden',
        wasteheatsource: 'Abwärme',
        yearlygridsurchargegrowthrate:
          'Jährliche Wachstumsrate des Netzzuschlags',
        yearlywholesalepricechangerate:
          'Jährliche Änderungsrate des Großhandelspreises'
      },
      simpleheatpumpbu: {
        copisconst: {
          label: 'Erdwärmepumpe'
        },
        isASHP: {
          label: 'Luft-Wärmepumpe'
        }
      },
      solarthermal: {
        coll_temp: {
          label: 'Kollektortemperatur'
        },
        enabled: {
          label: 'Solarthermie'
        }
      },
      wastecooling: {
        availablepower: {
          label: 'Verfügbare Kühlleistung'
        },
        copchiller: {
          label: 'COP Kältemaschine'
        },
        enablechiller: {
          label: 'Zusätzliche Kältemaschine installieren'
        },
        enablepotentialupload: {
          label: 'Kühlpotenzial hochladen (kW)'
        }
      },
      wasteheat: {
        availablepower: {
          label: 'Verfügbare Heizleistung'
        },
        cophp: {
          label: 'COP-Wärmepumpe'
        },
        enable_temp_upload: {
          label: 'Upload Temp.-Zeitreihe'
        },
        enablehp: {
          label: 'Installation einer Druckerhöhungswärmepumpe'
        },
        enablepotentialupload: {
          label: 'Aufgeladenes Wärmepotenzial (kW)'
        },
        temp_time_series: {
          label: 'Temp.-Zeitreihe'
        }
      }
    },
    graphs: {
      aggregation: 'Anhäufung',
      aggregations: {
        average: 'Durchschnitt',
        sum: 'Summe'
      },
      allsignals: 'Alle Signale',
      clear: 'Klar',
      collection: 'Sammlung:',
      editgraph: 'Diagramm bearbeiten',
      editsignalsettings: 'Signaleinstellungen bearbeiten',
      graphtype: 'Diagrammtyp',
      graphtypes: {
        column: 'Balkendiagramm',
        durationcurve: 'Dauer der Kurve',
        line: 'Liniendiagramm',
        scatter: 'Punktediagramm'
      },
      import: 'Daten importieren...',
      newgraph: 'Neue Grafik',
      nosignals: 'Keine Signale ausgewählt',
      nosignalsselected:
        'Keine Signale ausgewählt. Bearbeiten Sie das Diagramm, um Signale auszuwählen',
      notimeseries: 'Für diese Version sind keine Zeitreihen verfügbar.',
      referenceyear: 'Referenzjahr',
      scatterxaxisseriesid: 'Punktediagramm-X-Achsensignale',
      selectedsignals: 'Ausgewählte Signale',
      selectgraph: 'Zeitreihe zum Importieren auswählen',
      title: 'Grafiken',
      unsavedsuffix: '(nicht gespeichert)',
      zoomlevels: {
        day: 'Tag',
        month: 'Monat',
        week: 'Woche',
        year: 'Jahr'
      },
      zoomout: 'Rauszoomen'
    },
    invalidform: {
      label:
        'In Ihrem Formular sind Fehler enthalten. Bevor Sie diese nicht korrigiert haben, werden keine Visualisierungen aktualisiert und die Berechnungen können nicht ausgeführt werden. Um eine Zusammenfassung der Fehler zu sehen, können Sie auf Berechnen klicken.'
    },
    location: {
      city: 'Stadt',
      country: 'Land',
      title: 'Standort'
    },
    newbuildname: 'Neue Version',
    newprojectname: 'Neues Projekt',
    newtype: 'Typ',
    newtypes: {
      blankproject: 'Leeres Projekt',
      copyproject: 'Daten in neues Projekt kopieren',
      version: 'Neue Version hinzufügen'
    },
    noprojectshelp:
      'Es sind keine Projekte verfügbar. Klicken Sie auf „Hinzufügen…“, um ein neues Projekt zu erstellen, oder bitten Sie einen Kollegen, ein Projekt zu teilen.',
    pipes: {
      button: 'Rohrdimensionierung anzeigen...',
      form: {
        buildings: 'Gebäude',
        pipediameters: 'Rohrdurchmesser',
        piperoughness: 'Rauheit der Rohre',
        tempdiff: 'Temperaturunterschied'
      },
      pipeformat: 'DN{0}',
      recommendeddiameterformat: 'Empfohlener Durchmesser: DN{0}',
      results: 'Ergebnisse der Rohrdimensionierung',
      suggestpipediameters: 'Schlagen Sie Rohrdurchmesser vor',
      summarymaxmassflowrate: 'Max. Massendurchfluss',
      summarymaxvolumeflowrate: 'Max. Volumendurchfluss',
      title: 'Rohrdimensionierung'
    },
    profileeditor: {
      action: 'Einstellen',
      annualsum: 'Jährliche Summe',
      invalidformaterror:
        'Komma und Punkt können nicht gleichzeitig in der gleichen Reihe verwendet werden. Verwenden Sie ein Leerzeichen oder keine Tausendertrennzeichen.',
      invalidnumlineserror: 'Ungültige Anzahl von Zeilen: {0}',
      invalidtimeseriesformat: 'Zeitreihenformat ungültig{0}',
      manualcurve: 'Manuelle Kurve',
      notimeseriesnotice:
        'Bitte konfigurieren Sie eine Zeitreihe, um fortzufahren',
      range: 'Bereich',
      select: {
        file: {
          button: 'Datei auswählen...',
          title: 'Hochladen aus Datei'
        },
        timeseriesdata: 'Daten der Zeitreihe',
        unit: 'Einheit'
      },
      timeseries: 'Zeitreihe',
      types: {
        consumptionm3: 'Verbrauch (m³)',
        cop: 'COP',
        emissions: 'Emissionen (gCO2/kWh)',
        price: 'Preis (€/kWh)',
        pricemwh: 'Preis (€/MWh)',
        temperature: 'Temperatur (°C)'
      },
      typetitles: {
        consumption: 'Verbrauchsprofil',
        cop: 'COP-Profil',
        emissions: 'Emissionsprofil',
        energy: 'Energie-Profil',
        price: 'Preisprofil',
        temperature: 'Temperatur-Profil'
      },
      validatetimeseries: 'Zeitreihen validieren'
    },
    project: {
      edittitle: 'Projekt bearbeiten',
      name: 'Name',
      newprojectname: 'Neues Projekt',
      type: 'Projekttyp',
      types: {
        ectoplanner: 'Ectoplanner',
        secosim: 'Secosim (beta)'
      }
    },
    projectstitle: 'Ectoplanner-Projekte',
    resultgraphs: {
      balancingunitload4mwh: 'Last der Energiezentrum ④ (MWh)',
      coolingdemand: 'Bedarf an Kühlung',
      coolingdemandmwh: 'Bedarf an Kühlung (MWh)',
      electricityimportmwh: 'Elektrizitätseinfuhr (MWh)',
      energydemandmwh: 'Energiebedarf (MWh)',
      energyimportmwh: 'Energieeinfuhr (MWh)',
      equipmentinbalancingunit: 'Anlagen in der Energiezentrum',
      equipmentinbuildings: 'Anlagen in Gebäuden',
      heatingdemand: 'Wärmebedarf',
      heatingdemandmwh: 'Heizbedarf (MWh)',
      operationcostseura: 'Betriebskosten (EUR/a)',
      operationmaintenancecosts: 'Betriebs- und Wartungskosten',
      pvpower: 'PV-Leistung',
      tapwaterdemand: 'Bedarf an Warmwasser im Haushalt'
    },
    results: {
      balancingunit: {
        coolinggeneration: 'Erzeugung von Kühlung',
        heatingandcooling: 'Heizung und Kühlung',
        heatinggeneration: 'Erzeugung von Wärme',
        renewablesandstorage: 'Erneuerbare Energien und Speicherung'
      },
      balancingunitload: 'Ausgleichslast der Energiezentrum',
      balancingunitsecondary: {
        area: 'Bereich'
      },
      buildingdemandsinput: {
        annualdemandmwha: 'Jährlicher Bedarf (MWh/a)'
      },
      buildingenergysystems: 'Gebäude-Energiesysteme',
      buildings: {
        titles: {
          buildingequipment: 'Gebäudeausrüstung',
          capexbuildings: 'CAPEX Gebäude',
          cumulated: 'Kumulierte Installationen',
          energydemands: 'Energiebedarf'
        }
      },
      buildingsinputdata: 'Gebäude (Eingangsdaten)',
      burningnaturalgas: 'Verbrennung von Erdgas',
      capitalrecoveryfactor: 'Kapitalrückflussfaktor',
      cooling: 'Kühlung',
      costs: {
        annualcostseura: 'Jährliche Kosten (EUR/a)',
        investmentseur: 'Investitionen (EUR)'
      },
      cumulatedinstallations: {
        meancop: 'Mittlerer COP',
        powergasdemand: 'Strom-/Gasbedarf'
      },
      demandbalancing: {
        balanceddemands: 'Ausgeglichener Bedarf',
        balanceddemandsmwha: 'Ausgeglichene Nachfrage (MWh/a)',
        colddemandmwha: 'Kältebedarf (MWh/a)',
        heatdemandmwha: 'Wärmebedarf (MWh/a)',
        overview: 'Übersicht'
      },
      district_cooling: 'Fernkälte',
      district_heating: 'Fernwärme',
      download: 'Excel-Datei',
      ecologickpis: {
        primary_energy_demand: 'Primärenergiebedarf',
        title: 'Umwelt-KPI:s'
      },
      endenergydemand: {
        annualenergymwha: 'Jährliche Energie (MWh/a)',
        energysources: 'Energiequellen',
        peakpowerkw: 'Spitzenleistung (kW)'
      },
      energydemands: {
        balanceddemandsinbuilding: 'Ausgeglichener Bedarf im Gebäude',
        coolingdemandmwha: 'Kühlungsbedarf (MWh/a)',
        electricitydemandmwha: 'Elektrizitätsbedarf (MWh/a)',
        gasdemandmwha: 'Gasbedarf (MWh/a)',
        meanheatpumpcop: 'Mittlerer COP der Wärmepumpe',
        spaceheatingdemandmwha: 'Raumwärmebedarf (MWh/a)',
        tapwaterdemandmwha: 'Bedarf an Haushaltswarmwasser (MWh/a)'
      },
      failedtoload: 'Ergebnisse konnten nicht geladen werden',
      genericresulterror:
        'Ergebnisse nicht mehr gültig. Bitte auf Fehler prüfen und neu berechnen.',
      investmentbalancingunit: 'Investition Energiezentrum',
      investmentbuildings: 'Investition Gebäude',
      locationerror:
        'Beim Abrufen des Standorts ist ein unbekannter Fehler aufgetreten.',
      locationnotfound:
        'Der verwendete Standort ist nicht mehr gültig. Bitte wählen Sie einen neuen Standort aus und führen Sie die Berechnung erneut durch.',
      netbuildingdemands: 'Netto-Gebäudebedarf',
      operationandmaintenancecosts: 'Betriebs- und Wartungskosten',
      pagetitle: '{0} Ergebnisse',
      photovoltaics: 'Photovoltaik',
      print: 'Drucken',
      pvgeneration: 'PV-Erzeugung',
      sections: {
        balancingunit: 'Energiezentrum',
        buildingdemands: 'Gebäudebedarf & Emissionen',
        costs: 'Kosten',
        demandbalancing: 'Bedarfsbilanzierung'
      },
      solarthermal: 'Solarthermische Kollektoren',
      storage: {
        chargingcycles: 'Ladezyklen',
        volume: 'Volumen'
      },
      ta: 't/a',
      tapwater: 'Brauchwasser',
      technologies: {
        feedinkwha: 'Einspeisung (kWh/a)',
        selfconsumptionkwha: 'Eigenverbrauch (kWh/a)'
      },
      title: 'Ergebnisse',
      totalemissions: 'Emissionen insgesamt',
      waste_cooling: 'Abkühlung',
      waste_heat: 'Abwärme'
    },
    saveerror: 'Formular konnte nicht gespeichert werden',
    secosim: {
      columns: {
        description: 'Beschreibung',
        kpi: 'KPI',
        total: 'Gesamt'
      },
      kpis: {
        cumulative_co2_emission: 'Kumulative CO2-Emission',
        cumulative_energy_savings:
          'Kumulative Energieeinsparungen durch Eigenverbrauch',
        cumulative_eur_savings:
          'Kumulative EUR-Einsparungen durch Eigenverbrauch',
        cumulative_opex: 'Kumulative OPEX',
        descriptions: {
          cumulative_co2_emission: 'Die Tonnen CO2-Emissionen',
          cumulative_energy_savings:
            'Die Energie, die wir durch den Eigenverbrauch von PV-erzeugter Energie einsparen, hätte andernfalls aus dem Netz gekauft werden müssen',
          cumulative_eur_savings:
            'Das Geld, das wir durch den Eigenverbrauch von PV-erzeugter Energie sparen, hätte andernfalls für den Kauf von Energie aus dem Netz verwendet werden müssen',
          cumulative_opex: 'Der Energieaufwand',
          feed_in_energy: 'Die Energie, die wir an das Netz verkauft haben',
          feed_in_revenues:
            'Das Geld, das wir durch den Verkauf an das Netz verdient haben',
          peak_power_absorption_grid:
            'Wie nahe kamen wir während der Spitzennachfrage an die vertraglich vereinbarte Stromkapazität heran, z. B. 80 % = die Spitzenleistung betrug 80 % des vertraglich vereinbarten Limits',
          pv_generated_going_to_grid_export:
            'Die aus PV erzeugte Energie, die für den Netzexportbedarf verwendet wurde',
          pv_self_consumption:
            'Der Prozentsatz der Energie aus PV, den wir selbst verbrauchen (der Rest geht in den Netzexport)',
          pv_to_heating_and_cooling_demand:
            'Der Prozentsatz der Energie aus PV, der den Heiz- und Kühlbedarf deckt',
          savings_building_demand:
            'Der Prozentsatz der Energie, die das Gebäude mit Strom versorgt, der aus PV-Eigenverbrauch stammt',
          savings_co2_vs_historical:
            'im Vergleich zum Benchmark, z. B. &gt;0 = Einsparungen = niedrigere CO2-Emissionen',
          savings_cooling_demand:
            'Der Prozentsatz der Energie, die die Kühlung mit Strom versorgt, der aus PV-Eigenverbrauch stammt',
          savings_dhw_demand:
            'Der Prozentsatz der Energie, die das Warmwasser mit Strom versorgt, der aus PV-Eigenverbrauch stammt',
          savings_heating_demand:
            'Der Prozentsatz der Energie, die die Heizung mit Strom versorgt, der aus PV-Eigenverbrauch stammt',
          savings_opex:
            'im Vergleich zum historischen Benchmark Daten, z. B. &gt;0 = Einsparungen = niedrigere Betriebskosten'
        },
        energyflow: 'Energiefluss',
        feed_in_energy: 'Einspeisung von Energie',
        feed_in_revenues: 'Einspeisung von Einnahmen',
        graphs: {
          sankey: {
            energyflowformat: '{0} bis {1}'
          },
          series: {
            airsourceheatpumps: 'Luftwärmepumpen',
            batterycharge: 'Batterieladung',
            batterydischarge: 'Batterieentladung',
            buffertanktemperature: 'Pufferspeichertemperatur',
            co2historical: 'Historische CO2',
            co2savings: 'CO2-Einsparungen',
            co2withsectorcoupling: 'CO2 mit Sektorkopplung',
            cooling: 'Kühlung',
            coolingpumps: 'Kühlpumpen',
            dailypeakpower: 'Tägliche Spitzenleistung',
            dhw: 'Warmwasser',
            dhwairheatpump: 'Warmwasser-Luftwärmepumpe',
            dhweboiler: 'Warmwasser-E-Boiler',
            dhwwellsystem: 'Warmwasser Brunnensystem',
            eboilerheating: 'E-Boiler-Heizung',
            electricityimportcost: 'Stromimportkosten',
            energycosthistorical: 'Historische Energiekosten',
            energycostwithsectorcoupling: 'Energiekosten mit Sektorkopplung',
            feedin: 'Einspeisung',
            feedinenergy: 'Einspeiseenergie',
            feedinrevenues: 'Einspeiseerlöse',
            generalbuilding: 'Allgemeines Gebäude',
            heating: 'Heizung',
            heatingdemand: 'Heizungsbedarf',
            heatingwellnominalpower: 'Nennleistung des Heizbrunnens',
            heatingwellsystemgeneration: 'Erzeugung des Heizbrunnensystems',
            maxweeklypowerabsorptionashp:
              'Max. wöchentliche Leistungsaufnahme Luftwärmepumpen',
            opexsavings: 'OPEX-Einsparungen',
            outdoortemperature: 'Außentemperatur',
            powerlimit: 'Leistungsgrenze',
            pvcontribution: 'PV-Beitrag',
            pvgeneration: 'PV-Erzeugung',
            pvnominalpeakpower: 'Nennleistung der PV-Spitzenleistung',
            totalheatpumpgeneration: 'Gesamterzeugung der Wärmepumpe',
            watersourceheatpumps: 'Wasserwärmepumpen',
            weeklyoutdooravgtemp:
              'Wöchentliche Durchschnittstemperatur im Freien'
          },
          titles: {
            atesconservation: 'ATES-Einsparung',
            batterychargeanddischarge: 'Batterieladung und -entladung',
            energyusage: 'Energieverbrauch',
            energyusagebyasset: 'Energieverbrauch nach Anlage',
            feedinenergyandrevenue: 'Einspeisung von Energie und Einnahmen',
            heatingoff: 'Heizung aus – T runter',
            heatpumpoperation: 'Wärmepumpenbetrieb',
            impactofsectorcouplingonco2:
              'Auswirkungen der Sektorkopplung auf CO2',
            impactofsectorcouplingoncost:
              'Auswirkungen der Sektorkopplung auf die Kosten',
            opexandco2savings: 'OPEX- und CO2-Einsparungen',
            outdoortemperaturevsenergyprices:
              'Außentemperatur vs. Energiepreise',
            powerpeakfromthegrid: 'Leistungsspitze aus dem Netz',
            pvcontributiontotheenergyneeds: 'PV-Beitrag zum Energiebedarf',
            pvgeneration: 'PV-Erzeugung',
            savingspersector: 'Einsparungen pro Sektor',
            thermaldemandsplit: 'Aufteilung des Wärmebedarfs'
          }
        },
        peak_power_absorption_grid: 'Spitzenleistungsaufnahme aus dem Netz',
        pv_generated_going_to_grid_export: 'Erzeugte PV geht ins Netz Export',
        pv_self_consumption: 'PV-Eigenverbrauch',
        pv_to_heating_and_cooling_demand: 'PV für Heiz- und Kühlbedarf',
        savings_building_demand:
          'Einsparungen beim Gebäudebedarf durch Eigenverbrauch',
        savings_co2_vs_historical:
          'CO2-Einsparungen vs. historische Daten – 2022',
        savings_cooling_demand:
          'Einsparungen beim Kühlbedarf durch Eigenverbrauch',
        savings_dhw_demand:
          'Einsparungen beim Warmwasserbedarf durch Eigenverbrauch',
        savings_heating_demand:
          'Einsparungen beim Heizbedarf durch Eigenverbrauch',
        savings_opex: 'OPEX-Einsparungen vs. historische Daten – 2022'
      },
      models: {
        air_hp: 'Heizung aus Umgebungsluft',
        ates: {
          title: 'ATES-Bilanzierung'
        },
        battery: 'Batterie',
        battery_capacity: {
          title: 'Batteriekapazität'
        },
        battery_efficiency: {
          title: 'Round-Trip-Effizienz'
        },
        battery_power: {
          title: 'Nennleistung'
        },
        co2_data: {
          title: 'CO2-Daten'
        },
        cooling_capacity: {
          title: 'Kühlleistung'
        },
        cooling_direct: 'Kühlung',
        demand_cooling: {
          title: 'Gebäude Kühlbedarf'
        },
        demand_dhw: {
          title: 'Gebäude Warmwasserbedarf'
        },
        demand_heating: {
          title: 'Gebäude Heizbedarf'
        },
        demand_temperature: {
          title: 'Gebäude min. Heiztemperatur'
        },
        dhw_air_hp: 'Warmwasser aus Umgebungsluft',
        dhw_buffer: 'Warmwasserpuffer',
        dhw_buffering: 'Warmwasserpufferung aktivieren',
        dhw_capacity: {
          title: 'Warmwasserleistung'
        },
        dhw_hp: 'Warmwasser von ATES',
        e_boiler: 'Elektrokessel',
        el_demand: {
          title: 'Gebäude eLoad historisch'
        },
        el_export_price: {
          title: 'Stromexportkosten'
        },
        el_import_price: {
          title: 'Stromimportkosten'
        },
        feed_in_capacity: {
          title: 'Einspeiseleistung'
        },
        gas_boiler: 'Gaskessel',
        gas_prices: {
          title: 'Gaspreise'
        },
        goal: {
          co2: 'CO2',
          cost: 'Kosten',
          reference: 'Referenz',
          title: 'Ziel'
        },
        grid_capacity: {
          title: 'Netzanschlusskapazität'
        },
        grid_connection: 'Netzanschluss',
        heat_buffer: 'Wärmepuffer',
        heat_buffering: 'Wärmepufferung aktivieren',
        heat_hp: 'Heizung von ATES',
        heat_to_ates_capacity: {
          title: 'Wärme an ATES-Kapazität'
        },
        heating_buffer: {
          title: 'Heizungspuffer aktivieren'
        },
        heating_capacity: {
          title: 'Heizleistung'
        },
        hist_gas_h_consumption_m3: {
          title: 'Historischer Gasverbrauch'
        },
        hp_capacity: {
          title: 'Kapazität'
        },
        hp_eta: {
          title: 'Nennheizleistung eta'
        },
        hp_max_temp: {
          title: 'Maximale Temperatur'
        },
        hp_pump_cop: {
          title: 'COP'
        },
        indoors_cooling_buffer: {
          title: 'Kühlungspuffer'
        },
        indoors_dhw_buffer: {
          title: 'Warmwasserpuffer'
        },
        loss_buffer: {
          title: 'Verlust'
        },
        max_temp_buffer: {
          title: 'Maximale Temperatur'
        },
        min_temp_buffer: {
          title: 'Mindesttemperatur'
        },
        pv_capacity: {
          title: 'PV-Kapazität'
        },
        pv_gen: {
          title: 'PV-Leistungsabgabe'
        },
        scenario_definition: 'Szenariodefinition',
        timeseries: 'Zeitreihen',
        volume_buffer: {
          title: 'Volumen'
        }
      },
      releasenote:
        'Dies ist eine Vorschauversion mit eingeschränkter Funktionalität. Es sind umfangreiche Änderungen an der Benutzeroberfläche und Funktionalität zu erwarten.',
      sections: {
        co2: 'CO2',
        feedin: 'Einspeisung',
        opex: 'OPEX',
        peakpower: 'Spitzenleistung',
        pv: 'PV',
        savings: 'Einsparungen'
      },
      tabs: {
        graphs: 'Diagramme',
        input: 'Eingabe',
        kpigraphs: 'KPI-Diagramme',
        result: 'Ergebnis',
        table: 'Tabelle'
      },
      title: 'Secosim'
    },
    sections: {
      balancingunit: 'Aktive Bilanz',
      bms: 'Energie',
      calculations: 'Berechnungen',
      energystorages: 'Speicher',
      grid: 'Raster',
      modelparams: 'Geschäftsmodell',
      othersources: 'Andere'
    },
    share: {
      adduser: 'Benutzer hinzufügen',
      displayname: 'Benutzer',
      failedtoaddusers:
        'Benutzer konnte nicht hinzugefügt werden. Ist die E-Mail gültig?',
      failedtodeleteproject:
        'Projekt kann nicht gelöscht werden. Gibt es mehr als einen Benutzer für dieses Projekt?',
      failedtoremoveuser:
        'Benutzer kann nicht gelöscht werden. Ist dies der Eigentümer des Projekts?',
      mail: 'E-Mail',
      placeholder: 'Benutzer-E-Mail',
      title: 'Teilen {0}'
    },
    technologies: 'Technologien',
    title: 'Ectoplanner',
    unauthorized:
      'Nicht autorisiert. Sie haben keinen Zugriff auf diese Seite.',
    unitdescriptions: {
      maxflowvel: 'Max. Strömungsgeschwindigkeit (m/s)',
      maxpam: 'Max. Druckgefälle (Pa/m)',
      minflowvel: 'Min. Fließgeschwindigkeit (m/s)',
      pam: 'Druckgefälle (Pa/m)',
      specannpumpwork: 'Spez. jährliche Pumpenarbeit (kWh/m/a)'
    },
    units: {
      btuh: 'Btuh',
      degc: '°C',
      eur: 'EUR',
      eura: 'EUR/a',
      eurkw: '€/kW',
      eurkwel: (
        <span>
          €/kW<sub>el</sub>
        </span>
      ),
      eurkwh: '€/kWh',
      eurkwp: '€/kWp',
      eurkwth: (
        <span>
          €/kW<sub>th</sub>
        </span>
      ),
      eurm: '€/m',
      eurm3: '€/m³',
      eurmwh: '€/MWh',
      gco2kwh: 'gCO2/kWh',
      gkwh: 'g/kWh',
      ha: 'h/a',
      hyear: 'h/Jahr',
      k: 'K',
      kgCO2eq: 'kg CO₂eq',
      kgkwh: 'kg/kWh',
      kgs: 'kg/s',
      km: 'km',
      kmday: 'km/Tag',
      kw: 'kW',
      kwel: (
        <span>
          kW<sub>el</sub>
        </span>
      ),
      kwh: 'kWh',
      kwh100km: 'kWh/100 km',
      kwha: 'kWh/a',
      kwhel: 'kWh_el',
      kwhkwh: 'kWh/kWh',
      kwhm2: 'kWh/m²',
      kwhm2year: 'kWh/m²/Jahr',
      kwp: 'kWp',
      kwth: (
        <span>
          kW<sub>th</sub>
        </span>
      ),
      ls: 'l/s',
      m: 'm',
      m2: 'm²',
      m3: 'm³',
      m3h: 'm³/h',
      mm: 'mm',
      mw: 'MW',
      mwel: (
        <span>
          MW<sub>el</sub>
        </span>
      ),
      mwh: 'MWh',
      mwha: 'MWh/a',
      mwhel: (
        <span>
          MWh<sub>el</sub>
        </span>
      ),
      mwhth: (
        <span>
          MWh<sub>th</sub>
        </span>
      ),
      mwhyear: 'MWh/Jahr',
      mwth: (
        <span>
          MW<sub>th</sub>
        </span>
      ),
      pam: 'Pa/m',
      percent: '%',
      percenta: '%/a',
      percentdoc: '% (DOC)',
      percentofheatdemand: '% des Wärmebedarfs',
      percentofinvest: '% der Investition',
      percentperhour: '% pro Stunde',
      th: 't/h',
      tonnco2: 'tonnCO2',
      w: 'W',
      wm2: 'W/m²',
      wmk: 'W/(m K)',
      years: 'Jahre'
    },
    validationerror: {
      maxformat: 'Wert muss kleiner oder gleich {0} sein',
      minformat: 'Wert muss größer als oder gleich {0} sein',
      notset: 'Wert muss festgelegt werden'
    }
  },
  editsignalvalue: {
    dialogtitle: 'Geben Sie den Grund für die Wertänderung an'
  },
  equipment: {
    addalarm: {
      addedalarm: 'Alarm hinzugefügt',
      nodeformat: 'Alarm wird zu {0} hinzugefügt.',
      nodeformatwarning:
        'Alarm wird zu {0} hinzugefügt. Sie haben mehrere Signale ausgewählt, aber der Alarm wird dem Standort hinzugefügt. Um Alarme für einzelne Signale hinzuzufügen, wählen Sie diese einzeln aus.',
      placeholder: 'Alarmkommentar',
      title: 'Alarm hinzufügen'
    },
    addedsignalformat: 'Signal {0} hinzugefügt.',
    analyzeandexport: 'Analysieren und exportieren...',
    category: 'Kategorie',
    currentvalue: 'Aktueller Wert',
    deletesignalvalues: 'Signalwerte löschen...',
    description: 'Beschreibung',
    displayname: 'Signalname',
    equipmenttype: 'Ausrüstungstyp',
    errorfetchingprocessmap:
      'Beim Abrufen der Prozesskarte ist ein Fehler aufgetreten.',
    lastupdate: 'Letztes Update',
    mappedsignals: 'Abgebildete Signale',
    newvalue: 'Neuer Wert',
    nodescription: 'Keine Beschreibung',
    nosignalselected: 'Kein Signal ausgewählt',
    novalue: '-',
    novalueset: 'Kein Signalwert eingestellt',
    removedsignalformat: 'Signal {0} entfernt.',
    searchbar: {
      clear: 'Alle ausgewählten Signale löschen',
      datefrom: 'Zeit:',
      dateplaceholder: 'Jetzt',
      export: 'Exportieren...',
      searchplaceholder: 'Signale suchen...',
      selectedsignalsformat: 'Ausgewählte Signale ({0})',
      showingraph: 'Im Diagramm anzeigen...',
      showlogs: 'Protokolle anzeigen...'
    },
    setvalue: 'Update',
    setvaluefailure: 'Signalwert konnte nicht aktualisiert werden',
    setvaluefailurenoconnection:
      'Signalwert konnte nicht aktualisiert werden. Energy Manager konnte nicht erreicht werden.',
    setvaluefailurenodevice:
      'Signalwert konnte nicht aktualisiert werden. Das Signal ist nicht mit einem Energy Manager verbunden.',
    setvalueformat: 'Wert für {0} einstellen',
    setvaluesuccess: 'Signalwert wurde aktualisiert',
    signaltype: 'Signaltyp',
    source: 'Quelle',
    systemname: 'Funktion',
    tooltipformat: 'Zeit: {0} | Schritt: {1}',
    unit: 'Einheit',
    unmappedsignals: 'Andere Signale',
    updatethreshold: 'Schwellenwert aktualisieren',
    value: 'Wert'
  },
  equipments: {
    equipments: 'Ausrüstungen',
    locationswithdata: 'Hier sind einige Standorte mit Ausrüstung:',
    nodata: 'Es gibt keine Ausrüstungen in diesem {0}.'
  },
  exportdownload: {
    error: 'Der Export von Signalen konnte nicht heruntergeladen werden.'
  },
  exportoptions: {
    daterange: 'Datum'
  },
  exportpage: {
    confirmexport:
      'Möchten Sie diese Daten exportieren? Sie erhalten eine E-Mail, sobald die Daten verarbeitet worden sind.',
    exportdata: 'Daten exportieren',
    exportdatafailed: 'Datenexportanforderung fehlgeschlagen.',
    exportdatasuccess: 'Datenexportanfrage erfolgreich.',
    loadfromcollection: 'Signale aus Diagramm laden...',
    title: 'Daten exportieren'
  },
  featureflag: {
    ectotableadmin: 'Ectotable admin',
    integrations: 'Integrationen',
    newalarms: 'Neue Alarmansicht'
  },
  geocodinginput: {
    error: {
      resolve: 'Koordinaten konnten nicht geladen werden.'
    },
    placeholder: 'Suche nach Standort...'
  },
  graphs: {
    aggregationformat: 'Anhäufung: {0}',
    analytics: {
      help: 'Beachten Sie, dass einige Datenpunkte möglicherweise wegoptimiert werden, wenn das Diagramm eine große Datenmenge enthält.',
      title: 'Datenanalyse',
      tooltip:
        'Datenanalyse. Klicken Sie zum Aktivieren und wählen Sie dann einen Bereich des Diagramms aus, um Statistiken anzuzeigen.'
    },
    autoupdate: 'Automatische Updates',
    autoupdateformat: '{0} (auto)',
    confirmdelete: {
      message: 'Sind Sie sicher, dass Sie {0} löschen möchten?',
      title: 'Löschen bestätigen'
    },
    confirmsave: {
      message:
        'Es gibt mehrere ungespeicherte Diagramme. Möchten Sie alle speichern oder nur diese eine?',
      saveall: 'Alle speichern',
      savecurrent: 'Aktuelles speichern',
      title: 'Alle Diagramme speichern?'
    },
    custominterval: 'Benutzerdefiniertes Intervall',
    deletebutton: 'Diagramm löschen',
    deletegraph: 'Diagramm löschen',
    duplicatebutton: 'Diagramm duplizieren',
    duplicatesuffix: 'kopieren',
    editmodal: {
      addtitle: 'Neues Diagramm hinzufügen',
      errornoscatterxaxis:
        'Wenn Sie ein Punktediagramm verwenden, müssen Sie ein Signal für die x-Achse angeben.',
      settings: {
        numpointslabel: 'Anzahl der Punkte',
        numpointsplaceholder: 'Basierend auf der Bildschirmauflösung',
        numpointsplaceholderwithsampling:
          'Basierend auf dem Stichprobenintervall',
        seriesinterval: 'Zoomstufe',
        typelabel: 'Diagrammtyp',
        xaxislabel: 'Streuung des Signals auf der x-Achse',
        xaxisplaceholder: 'X-Achsen-Signal auswählen'
      },
      title: 'Diagramm bearbeiten'
    },
    exportdialog: {
      aggregation: {
        count: 'Anzahl',
        first: 'Erste',
        last: 'Letzte',
        max: 'Max',
        mean: 'Mitte',
        median: 'Median',
        min: 'Min',
        mode: 'Modus',
        none: 'Keine',
        sum: 'Summe'
      },
      aggregationtitle: 'Anhäufung',
      confirm: 'Exportieren',
      export: {
        success: 'Daten wurden erfolgreich exportiert.'
      },
      exportcvs: {
        failure:
          'Daten können nicht exportiert werden. Bitte versuchen Sie es später erneut.'
      },
      exportformat: 'Format',
      failedtosavefile: 'Datei konnte nicht gespeichert werden',
      filename: 'Dateiname',
      imageheight: 'Bildhöhe',
      imagewidth: 'Bildbreite',
      sampling: {
        day: 'Tag',
        fifteenminutes: '15 Minuten',
        fiveminutes: '5 Minuten',
        hour: 'Stunde',
        minute: 'Minute',
        month: 'Monat',
        raw: 'Rohdaten',
        week: 'Woche'
      },
      samplinginterval: 'Stichprobenintervall',
      title: 'Diagramm exportieren'
    },
    failedtoload: 'Diagramme konnten nicht geladen werden.',
    failedtoloadsignals: 'Signale konnten nicht geladen werden',
    failedtosave: 'Diagramme konnten nicht gespeichert werden.',
    minmax: {
      table: {
        max: 'Maximaler Wert',
        min: 'Minimaler Wert',
        placeholder: 'Automatisch',
        unit: 'Einheit'
      },
      title: 'Minimale und maximale Werte für die Achse'
    },
    newgraphname: 'Neues Diagramm',
    nosignalsfound:
      'Es wurden keine Signale gefunden, die den aktuellen Einstellungen entsprechen',
    options: 'Diagrammoptionen',
    panel: {
      unsavedchanges:
        'Sie haben das Diagramm bearbeitet, das Sie in einem Dashboard ausgewählt haben. Möchten Sie es speichern?'
    },
    pointsoverflow:
      'Der Datensatz für das Diagramm ist zu groß, um interaktiv zu zeichnen. Bitte überprüfen Sie die Einstellungen, um sicherzustellen, dass Sie die richtigen Aggregationseinstellungen haben.',
    select: {
      graph: {
        type: 'Diagrammtyp auswählen'
      },
      xaxis: {
        signal: 'X-Achsen-Signal auswählen'
      }
    },
    settingplaceholder: 'Aus Diagrammeinstellungen',
    signalcollection: 'Diagramm:',
    signalselector: {
      name: {
        placeholder: 'Name'
      }
    },
    signalselectorbutton: 'Signale ändern...',
    signalselectormodal: {
      title: 'Signalauswahl'
    },
    tempsuffix: '(vorübergehend)',
    timerange: {
      option: {
        all: 'Alle',
        custom: 'Optional',
        day: 'Tag',
        fiveyears: 'Fünf Jahre',
        hour: 'Stunde',
        month: 'Monat',
        week: 'Woche',
        year: 'Jahr'
      },
      title: 'Zeitbereich',
      titlewithfilterdescription:
        'Zeitbereich (Das Signal ist nur in diesem Bereich sichtbar)'
    },
    type: {
      line: 'Liniendiagramm',
      scatter: 'Punktediagramm'
    },
    unsavedsuffix: '(nicht gespeichert)',
    zoom: 'Zoom:'
  },
  grids: {
    cooling: 'Kühlen',
    ectogrid: 'ectogrid™',
    electricity: 'Elektrizität',
    generic: 'Generisch',
    heating: 'Heizung'
  },
  help: {
    missingfilemessage: 'Die richtige Hilfedatei kann nicht gefunden werden.'
  },
  helpbutton: {
    error:
      'Hilfetext kann nicht abgerufen werden, bitte versuchen Sie es später erneut.'
  },
  helptooltip: {
    gotohelp: 'Zur Hilfeseite gehen'
  },
  highcharts: {
    contextbuttontitle: 'Kontextmenü des Diagramms',
    decimalpoint: ',',
    downloadcsv: 'CSV herunterladen',
    downloadjpeg: 'Laden Sie JPEG herunter',
    downloadmidi: 'Laden Sie MIDI herunter',
    downloadpdf: 'PDF herunterladen',
    downloadpng: 'PNG herunterladen',
    downloadsvg: 'SVG herunterladen',
    downloadxls: 'Laden Sie XLS herunter',
    error:
      'Beim Abrufen der Daten ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.',
    exitfullscreen: 'Verlassen Sie den Vollbildmodus',
    exporting: 'Exportieren...',
    loading: 'Daten werden geladen...',
    months: {
      april: 'April',
      august: 'August',
      december: 'Dezember',
      february: 'Februar',
      january: 'Januar',
      july: 'Juli',
      june: 'Juni',
      march: 'März',
      may: 'Mai',
      november: 'November',
      october: 'Oktober',
      september: 'September'
    },
    nodata: 'Keine Daten verfügbar.',
    printchart: 'Diagramm drucken',
    shortmonths: {
      april: 'Apr.',
      august: 'Aug.',
      december: 'Dez.',
      february: 'Feb.',
      january: 'Jan.',
      july: 'Jul.',
      june: 'Jun.',
      march: 'März',
      may: 'Mai',
      november: 'Nov.',
      october: 'Okt.',
      september: 'Sept.'
    },
    shortweekdays: {
      friday: 'Fr.',
      monday: 'Mo.',
      saturday: 'Sa.',
      sunday: 'So.',
      thursday: 'Do.',
      tuesday: 'Di.',
      wednesday: 'Mi.'
    },
    viewfullscreen: 'Im Vollbildmodus ansehen',
    weekdays: {
      friday: 'Freitag',
      monday: 'Montag',
      saturday: 'Samstag',
      sunday: 'Sonntag',
      thursday: 'Donnerstag',
      tuesday: 'Dienstag',
      wednesday: 'Mittwoch'
    }
  },
  jobs: {
    columns: {
      dataend: 'Ende der Daten',
      datastart: 'Start der Daten',
      date: 'Datum',
      expires: 'Läuft ab',
      importance: 'Wichtigkeit',
      lasttrained: 'Zuletzt geschult',
      model: 'Modell',
      name: 'Name',
      r2: 'R2',
      rmse: 'RMSE',
      scoring: 'Bewertung'
    },
    compare: 'Vergleichen',
    details: 'Details',
    earlierversions: 'Frühere Versionen',
    featureimportance: 'Wichtigkeit der Funktion',
    outputsignals: 'Ausgangssignale',
    predictedformat: '{0} Vorhersage',
    scoringhistory: 'Historie der Bewertung',
    titleformat: '{0} - {1} - Ranking bei {2}'
  },
  language: {
    de: 'Deutsch',
    en: 'English',
    sv: 'Svenska'
  },
  loadingcontainer: {
    longerthanexpected: 'Das dauert länger als normal, bitte warten...'
  },
  location: {
    button: {
      titleformat: 'Standort: {0}'
    },
    error: {
      chooseotherlocation: 'Standort auswählen',
      find: 'Standort kann nicht gefunden werden',
      nolocations:
        'Sie haben keinen Zugriff auf die Standorte. Wenden Sie sich an Ihren Administrator.'
    },
    page: {
      buildings: 'Gebäude',
      controlpanel: 'Bedienfeld',
      copybuttonitem: 'Standortname',
      help: 'Hilfe',
      helptitle: 'Benutzerhilfe',
      locations: 'Standorte',
      provisioning: 'Energiemanager'
    },
    tabs: {
      alarms: 'Alarme',
      dashboard: 'Dashboard',
      equipment: 'Ausrüstung',
      exportpage: 'Daten exportieren',
      graphs: 'Diagramme',
      jobs: 'Datenaufträge',
      logs: 'Protokolle',
      powercontrol: 'Leistungsregelung',
      processmaps: 'Prozesskarten',
      remoteopt: 'Fernoptimierung',
      signalproviders: 'Signale',
      tools: 'Werkzeuge',
      userdashboards: 'Persönliche Dashboards'
    }
  },
  locationheader: {
    facilityid: 'Einrichtungs-ID',
    status: 'Status:',
    street: 'Adresse:',
    tags: 'Schlagwörter:'
  },
  locationtree: {
    advanced: 'Erweitert...'
  },
  login: {
    action: {
      login: 'Anmelden',
      signup: 'Registrieren'
    },
    env: {
      development: 'Entwicklung',
      mock: 'Mock'
    },
    environment: 'Umwelt',
    password: {
      label: 'passwort',
      placeholder: 'Passwort'
    },
    toast: {
      error: {
        login: 'Anmeldung nicht möglich'
      },
      loggedout: 'Sie wurden abgemeldet',
      sessionexpired:
        'Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an.'
    },
    username: {
      label: 'benutzername',
      placeholder: 'Benutzername'
    }
  },
  logtype: {
    alltypes: 'Alle Protokolltypen',
    type: {
      signal: 'Sollwertänderung',
      system: 'System',
      user: 'Handbuch'
    }
  },
  logview: {
    addbutton: {
      title: 'Log-Eintrag hinzufügen'
    },
    columns: {
      author: 'Verfasser',
      location: 'Standort',
      logtype: 'Protokolltyp',
      message: 'Mitteilung',
      valuechange: 'Einzelheiten'
    },
    helpbutton: {
      title: 'Protokolle'
    },
    modal: {
      add: {
        action: {
          failure:
            'Der Log-Eintrag konnte nicht gespeichert werden, bitte versuchen Sie es später noch einmal.',
          title: 'Speichern'
        },
        info1:
          'Wählen Sie einen Ort für Ihren Log-Eintrag und verwenden Sie das Freitextfeld, um einen Kommentar hinzuzufügen',
        info2:
          'Beachten Sie, dass dieser Text für alle Personen verfügbar ist, die Zugang zu dem ausgewählten Standort haben',
        text: {
          placeholder: 'Freitext Log-Eintrag'
        },
        title: 'Log-Eintrag hinzufügen'
      },
      select: {
        location: 'Standort auswählen'
      }
    },
    nodatatext:
      'Für diesen Punkt wurden noch keine Log-Einträge hinzugefügt. Tippen Sie auf die Schaltfläche Hinzufügen, um den ersten Log-Eintrag hinzuzufügen',
    search: {
      placeholder: 'In Protokollen suchen'
    },
    title: 'Protokolle',
    user: {
      unknown: 'Unbekannt'
    }
  },
  modeleditor: {
    failedtoloadsignaldata: 'Signalname konnte nicht geladen werden.',
    locationformat: '{0} Standorte ausgewählt',
    selectcolor: 'Farbe auswählen...'
  },
  navbar: {
    adduser: 'Benutzer hinzufügen',
    applanguage: 'Sprache',
    displayname: 'Name',
    edituser: 'Benutzer bearbeiten',
    featureflags: 'Feature flags',
    logout: 'Abmelden',
    title: 'ectocloud™',
    username: 'Benutzername',
    usersettings: 'Einstellungen'
  },
  nodetypes: {
    building: 'Gebäude',
    equipment: 'Ausrüstung',
    site: 'Standort'
  },
  operatorchart: {
    interval: {
      i1d: '1 Tag',
      i1h: '1 Stunde',
      i1m: '1 Monat',
      i1w: '1 Woche',
      i1y: '1 Jahr',
      iall: 'Alle'
    },
    signalpicker: {
      addsignal: {
        error: 'Kein Signal ausgewählt oder es wurde bereits hinzugefügt.'
      },
      button: {
        deleteallsignals: 'Alle ausgewählten Signale löschen',
        loadprofile: 'Profil laden',
        saveprofile: 'Profil speichern'
      },
      filter: {
        header: 'Filter:'
      },
      load: {
        error: 'Laden von Informationen fehlgeschlagen.'
      },
      selectedsignals: {
        header: 'Ausgewählte Signale:'
      }
    }
  },
  pagingfooter: {
    pageformat: 'Seite {0}',
    pageformatwithtotal: 'Seite {0} / {1}'
  },
  powercontrol: {
    dispatch: {
      automatic: {
        schedule: {
          failure: {
            disable: 'Die automatische Planung konnte nicht deaktiviert werden',
            enable: 'Die automatische Planung konnte nicht aktiviert werden'
          },
          label: 'Automatische Planung',
          success: {
            disable: 'Automatische Planung deaktiviert',
            enable: 'Automatische Planung aktiviert'
          },
          verify: {
            disable:
              'Sind Sie sicher, dass Sie die automatische Planung deaktivieren möchten?',
            enable:
              'Sind Sie sicher, dass Sie die automatische Planung aktivieren wollen?'
          }
        }
      },
      chart: {
        action: {
          clearsignals: 'Alle ausgewählten Signale löschen',
          exportcsv: {
            toast:
              'Ausgewählte Daten werden in CSV-Format exportiert. Bitte warten...'
          },
          exportcvs: {
            success:
              'Daten wurden erfolgreich exportiert. Der Download sollte bald beginnen.'
          },
          signalpicker: 'Signale bearbeiten'
        },
        button: {
          downloadcsv: 'Exportieren',
          help: 'Telemetrie'
        },
        equipments: {
          columnerror: 'Unbekannte Spalte'
        },
        hour: 'h',
        loadingdata: {
          failure:
            'Beim Abrufen der Daten ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut',
          inprogress: 'Daten werden geladen...'
        },
        nodata: 'Keine Daten verfügbar',
        nosignals: 'Keine Signale ausgewählt',
        range: {
          all: 'Alle'
        },
        series: {
          nodata: 'Keine Daten'
        },
        signalselectormodal: {
          equipment: {
            heading: 'Signalauswahl'
          },
          heading: 'Ausgewählte Signale',
          title: 'Signalauswahl'
        },
        title: 'Telemetrie',
        unknownunit: 'n/a'
      },
      download: {
        download: 'Herunterladen',
        item: {
          aggregate: 'Aggregation',
          period: 'Periode'
        },
        nosignals: 'Keine Signale ausgewählt.',
        options: {
          aggregate: 'Aggregat',
          average: 'Durchschnitt',
          count: 'Anzahl',
          day: '1 Tag',
          first: 'Erste',
          hour: '1 Stunde',
          last: 'Letzte',
          max: 'Max',
          mean: 'Mitte',
          median: 'Median',
          min: 'Min',
          minute: '1 Minute',
          mode: 'Modus',
          month: '1 Monat',
          none: 'Keine',
          raw: 'Rohdaten',
          sum: 'Summe',
          week: '1 Woche'
        },
        range: "Ausgewählter Zeitbereich von '{0}' bis '{1}'",
        subtitle: 'Signale konfigurieren',
        title: 'Exportsignale konfigurieren'
      },
      recommendedsettings: {
        outsidetemp: 'Außentemperatur',
        title: 'Empfohlene Einstellungen',
        usebutton: 'Verwenden Sie die empfohlenen Einstellungen'
      },
      schedule: {
        acutestop: 'Akuter Stopp',
        affectedbuildings: '{0} von {1} betroffenen Gebäuden aktualisiert.',
        dateerror:
          'Der Plan muss an einem Datum in der Zukunft beginnen, um gültig zu sein. Bitte wählen Sie ein gültiges Datum, um fortzufahren.',
        doaction: {
          question: 'Sind Sie sicher, dass Sie diesen Plan {0} wollen?',
          title: 'Bitte bestätigen Sie die Aktion'
        },
        forecasting: {
          decrease: 'Zu Beginn des Plans wird die Leistung um etwa {0} sinken.',
          increase: 'Zu Beginn des Plans wird die Leistung um etwa {0} steigen.'
        },
        header: {
          duration: 'Dauer des Plans',
          effect: 'Wirkung während des Plans',
          impact: 'Temperaturauswirkung',
          start: 'Beginn des Plans',
          type: 'Art des Plans'
        },
        immediately: 'Direkt',
        invalidamplitude:
          'Bitte geben Sie einen gültigen Wert für den Kontrollparameter ein.',
        noforecasting:
          'Wir verfügen nicht über genügend Prognosedaten, um eine Leistungsschätzung für dieses Datum abzugeben.',
        option: {
          hours: 'Stunden',
          startoffset: 'Plan starten in',
          starttime: 'Plan starten bei',
          stop: 'Plan stoppen nach'
        },
        power: '{0} MW von {1} MW verfügbarer Leistung aktiviert.',
        schedule: 'Plan',
        showmostaffectedbuilding: 'Das am meisten betroffene Gebäude anzeigen',
        showmostaffectedbuildings:
          'Anzeigen der {0} am meisten betroffenen Gebäude',
        startinterval: 'Der Plan beginnt bei {0} {1} und endet bei {2} {3}.',
        state: {
          abort: 'abbrechen',
          confirm: 'Bestätigen',
          delete: 'löschen'
        },
        stopschedulesettings: 'Planeinstellungen stoppen',
        temperatureaffectedbuildings: '{0} betroffene Gebäude.',
        temperatureimpact: {
          affectedbuildings: {
            empty: 'Keine betroffenen Gebäude gefunden.',
            title: 'Am meisten betroffene Gebäude'
          }
        },
        temperaturerangewarning:
          'Die Einstellung beeinträchtigt den Komfort des Gebäudes und darf nur in Notsituationen verwendet werden.',
        testinfo: 'Der Leistungsregelungstest beginnt bei {0}.',
        tooltip: {
          abortschedule: 'Plan abbrechen',
          deleteschedule: 'Plan löschen',
          editschedule: 'Plan bearbeiten',
          gotoschedule: 'Zum Plan gehen'
        }
      },
      scheduled: {
        abort: {
          failure: {
            toast: 'Plan kann nicht abgebrochen werden'
          },
          success: {
            toast: 'Plan erfolgreich abgebrochen'
          }
        },
        action: {
          addschedule: 'Plan hinzufügen',
          confirmremoveall:
            'Sind Sie sicher, dass Sie alle ausstehenden Pläne löschen wollen?',
          removeall: 'Alle ausstehenden Pläne löschen'
        },
        add: {
          failure: {
            create: 'Plan kann nicht hinzugefügt werden',
            edit: 'Plan kann nicht bearbeitet werden'
          },
          failuremulti: {
            create: 'Pläne können nicht hinzugefügt werden',
            edit: 'Pläne können nicht bearbeitet werden'
          },
          success: {
            create: 'Plan erfolgreich hinzugefügt',
            edit: 'Plan erfolgreich bearbeitet'
          },
          successmulti: {
            create: 'Pläne erfolgreich hinzugefügt',
            edit: 'Pläne erfolgreich bearbeitet'
          }
        },
        dateerror:
          'Das Startdatum muss in der Zukunft und vor dem Enddatum liegen. Die "Nachfrageprognose" muss kleiner als der Minimaleffekt und größer als Null sein.',
        delete: {
          failure: {
            toast: 'Plan kann nicht gelöscht werden'
          },
          success: {
            toast: 'Plan erfolgreich gelöscht'
          }
        },
        deletemulti: {
          failure: {
            toast: 'Pläne können nicht gelöscht werden'
          },
          success: {
            toast: 'Pläne erfolgreich gelöscht'
          }
        },
        events: 'Termine planen',
        fetch: {
          failure:
            'Daten können nicht abgerufen werden, bitte versuchen Sie es später noch einmal.'
        },
        noschedules: 'Keine Pläne verfügbar.',
        refresh: {
          failure: {
            toast: 'Aktualisierung der Pläne fehlgeschlagen'
          }
        }
      },
      schedulestatus: {
        column: {
          comment: 'Kommentar',
          date: 'Datum',
          status: 'Status',
          user: 'Benutzer'
        }
      },
      scheduletype: {
        manual: 'Manueller Plan',
        stop: 'Plan stoppen'
      }
    },
    manualdispatch: {
      nodata: 'keine Daten verfügbar',
      savebutton: {
        title: 'Speichern'
      }
    },
    nodata:
      'Die Leistungsregelung ist für diesen Standort nicht verfügbar. Wählen Sie einen anderen Standort, um dieses Tool zu verwenden.',
    schedule: {
      action: {
        title: 'Plan Einstellungen'
      },
      confirm: {
        above:
          'Der Plan überschreitet den Schwellenwert. Möchten Sie fortfahren?',
        below:
          'Der Plan liegt unterhalb des Schwellenwertes. Möchten Sie fortfahren?'
      },
      confirmtitle: 'Sind Sie sicher, dass Sie den Plan hinzufügen möchten?',
      createschedule: {
        action: 'Hinzufügen',
        title: 'Plan hinzufügen'
      },
      editschedule: {
        action: 'Bearbeiten',
        title: 'Plan bearbeiten'
      },
      header: 'Plan',
      now: 'Jetzt',
      preset: {
        amplitude: 'Steuerung',
        enddate: 'Endzeit',
        endoffset: 'Endet nach',
        header: 'Voreinstellungen',
        startdate: 'Startzeit',
        startoffset: 'Beginnt in'
      },
      preset1: 'Voreinstellung 1',
      preset2: 'Voreinstellung 2',
      preset3: 'Voreinstellung 3'
    },
    status: {
      aborted: 'Abgebrochen',
      finished: 'Fertiggestellt',
      pending: 'Ausstehend',
      running: 'Laufen'
    },
    tabs: {
      control: 'Kontrolle',
      manual: 'Manuell',
      schedule: 'Plan'
    },
    title: 'Leistungsregelung',
    unauthorized: {
      text: 'Sie haben keine Zugriffsrechte, um die Leistungsregelung anzuzeigen.'
    }
  },
  relativetime: {
    hoursagoformat: 'vor {0} Stunden',
    lessthanoneminutago: 'vor weniger als 1 Minute',
    minutesagoformat: 'vor {0} Minuten',
    onehourago: 'vor 1 Stunde',
    oneminuteago: 'vor 1 Minute'
  },
  remoteopt: {
    action: {
      enable: {
        failure:
          "Die Remoteoptimierung für '{0}' kann nicht aktiviert werden. Bitte versuchen Sie es später erneut."
      },
      save: {
        failure: {
          toast:
            "Die Einstellungen zur Fernoptimierung für '{0}' können nicht gespeichert werden. Bitte versuchen Sie es später erneut."
        },
        success: {
          toast: 'Neue Optimierungskurve erfolgreich gespeichert'
        }
      }
    },
    button: {
      save: {
        title: 'Speichern'
      }
    },
    fetch: {
      error:
        'Daten können nicht abgerufen werden, bitte versuchen Sie es später noch einmal.'
    },
    info: {
      title: 'Fernoptimierung der linearen Interpolation aktivieren'
    },
    nodata: 'Keine Daten verfügbar',
    notreedata: 'In der Fernoptimierungsansicht werden keine Daten angezeigt.',
    outsidetemplabel: 'Außentemperatur:',
    tempoffsetlabel: 'Temperaturabweichung:',
    title: 'Fernoptimierung'
  },
  select: {
    creatableempty: 'Schreiben Sie, um einen neuen Wert hinzuzufügen'
  },
  selectsignalcollectiondialog: {
    tableheader: 'Diagramm',
    title: 'Diagramm auswählen'
  },
  selectusersdialog: {
    title: 'Benutzer auswählen'
  },
  sidebar: {
    location: {
      grid: {
        header: 'Netz:'
      },
      search: {
        placeholder: 'Suche nach Standort'
      },
      tags: {
        placeholder: 'Filtern nach Tags...'
      }
    },
    sections: {
      home: 'Startseite',
      manage: 'Verwalten',
      user: 'Benutzer',
      visualize: 'Visualisieren'
    }
  },
  signalproviders: {
    emptylist: 'Am aktuellen Standort sind keine Signale verfügbar.',
    type: {
      AccumulatorTankEnergyModel: 'Pufferspeicher Energiemodell',
      Alarm: 'Alarm',
      COPCoolingForecast: 'COP Kühlungsprognose',
      COPHeatingForecast: 'COP-Heizungsprognose',
      CoolingForecast: 'Prognose Kühlen',
      EctogridSetpointCooling: 'Ectogrid-Sollwert Kühlen',
      EctogridSetpointHeating: 'Ectogrid-Sollwert Heizung',
      EctogridSetpointModel: 'Ectogrid-Sollwert Modell',
      Equipment: 'Ausrüstung',
      EroIntegration: 'EroIntegration',
      HeatingForecast: 'Vorhersage Heizung',
      LinearOptimisation: 'Lineare Optimierung',
      Meteorology: 'Meteorologie',
      PowerControl: 'Leistungsregelung',
      ReversibleHeatPumpCOP: 'Reversible Wärmepumpe COP',
      ReversibleHeatPumpScheduler: 'Reversible Wärmepumpe Scheduler',
      SteerablePower: 'Lenkbare Leistung'
    }
  },
  signals: {
    category: 'Kategorie',
    categoryplaceholder: 'Signale in Kategorien gruppieren',
    customcolor: 'Farbe anpassen',
    displayname: 'Name',
    displaynameplaceholder: 'Signalname wird ersetzt',
    equipmenttype: 'Ausrüstungstyp',
    filter: {
      matchall: 'Alle anpassen'
    },
    missingtype: 'Fehlender Signaltyp',
    nocompatiblesignalsfound: 'Keine kompatiblen Signale gefunden',
    nosignalsfound: 'Keine Signale gefunden',
    provider: 'Anbieter',
    selectsignal: 'Signal auswählen...',
    signalid: {
      description:
        'Verwenden Sie stattdessen den Signaltyp, wenn Sie können. Sollte nur verwendet werden, wenn der Signaltyp nicht spezifisch genug ist.',
      label: 'Hartcodiertes Signal'
    },
    signalprovidertype: 'Signalanbietertyp',
    signaltype: 'Signaltyp',
    systemname: 'Systemname'
  },
  signalsseriestable: {
    addsignals: 'Signale hinzufügen',
    xsignal: 'X-Signal',
    ysignal: 'Y-Signal'
  },
  signaltypes: {
    selectfolder: 'Ordner auswählen...',
    selecttype: 'Signaltyp auswählen...',
    selectunit: 'Einheit auswählen...'
  },
  tenants: {
    error: {
      failedtoload: 'Organisationen konnten nicht geladen werden'
    },
    noaccess: 'Sie haben keinen Zugriff auf Organisationen.',
    noadminrole: 'Keine Administratorrolle',
    noadminroletext:
      'Sie haben keine Administratorrechte für diesen Teil der Applikation.',
    noresources: 'Keine Ressourcen der Organisation zugewiesen',
    noresourcestext: 'Ihrer Organisation sind keine Ressourcen zugewiesen.',
    title: 'Organisation'
  },
  toast: {
    addaction: {
      failformat: 'Hinzufügen von {0} fehlgeschlagen',
      successformat: '{0} hinzugefügt'
    },
    deleteaction: {
      failformat: 'Löschen von {0} fehlgeschlagen',
      successformat: '{0} gelöscht'
    },
    editaction: {
      failformat: 'Aktualisieren von {0} fehlgeschlagen',
      successformat: '{0} aktualisiert'
    },
    getaction: {
      failformat: 'Abruf von {0} fehlgeschlagen'
    }
  },
  tools: {
    powercontrols: 'Leistungsregelung',
    title: 'Werkzeuge'
  },
  treeview: {
    moreresults: 'Mehr laden...'
  },
  format: formatLangString
};

export const langDict: Record<string, typeof langEn> = {
  en: langEn,
  sv: langSv,
  de: langDe
};

const T = new Proxy<typeof langEn>(langEn, {
  get(target: any, key: keyof typeof langEn): string | React.ReactNode {
    return langDict[selectedLanguage][key] as unknown as React.ReactNode;
  }
});

export const ValidLanguages = ['en', 'sv', 'de'];

export default T;

if (!ValidLanguages.includes(selectedLanguage)) {
  selectedLanguage = ValidLanguages[0];
  setSelectedLanguage(selectedLanguage);
}

const langOptions = {
  lang: {
    months: [
      T.highcharts.months.january,
      T.highcharts.months.february,
      T.highcharts.months.march,
      T.highcharts.months.april,
      T.highcharts.months.may,
      T.highcharts.months.june,
      T.highcharts.months.july,
      T.highcharts.months.august,
      T.highcharts.months.september,
      T.highcharts.months.october,
      T.highcharts.months.november,
      T.highcharts.months.december
    ],
    weekdays: [
      T.highcharts.weekdays.sunday,
      T.highcharts.weekdays.monday,
      T.highcharts.weekdays.tuesday,
      T.highcharts.weekdays.wednesday,
      T.highcharts.weekdays.thursday,
      T.highcharts.weekdays.friday,
      T.highcharts.weekdays.saturday
    ],
    shortWeekdays: [
      T.highcharts.shortweekdays.sunday,
      T.highcharts.shortweekdays.monday,
      T.highcharts.shortweekdays.tuesday,
      T.highcharts.shortweekdays.wednesday,
      T.highcharts.shortweekdays.thursday,
      T.highcharts.shortweekdays.friday,
      T.highcharts.shortweekdays.saturday
    ],
    shortMonths: [
      T.highcharts.shortmonths.january,
      T.highcharts.shortmonths.february,
      T.highcharts.shortmonths.march,
      T.highcharts.shortmonths.april,
      T.highcharts.shortmonths.may,
      T.highcharts.shortmonths.june,
      T.highcharts.shortmonths.july,
      T.highcharts.shortmonths.august,
      T.highcharts.shortmonths.september,
      T.highcharts.shortmonths.october,
      T.highcharts.shortmonths.november,
      T.highcharts.shortmonths.december
    ],
    decimalPoint: T.highcharts.decimalpoint,
    loading: T.highcharts.loading,
    noData: T.highcharts.nodata,
    contextButtonTitle: T.highcharts.contextbuttontitle,
    downloadCSV: T.highcharts.downloadcsv,
    downloadJPEG: T.highcharts.downloadjpeg,
    downloadMIDI: T.highcharts.downloadmidi,
    downloadPDF: T.highcharts.downloadpdf,
    downloadPNG: T.highcharts.downloadpng,
    downloadSVG: T.highcharts.downloadsvg,
    downloadXLS: T.highcharts.downloadxls,
    exitFullscreen: T.highcharts.exitfullscreen,
    viewFullscreen: T.highcharts.viewfullscreen,
    exportInProgress: T.highcharts.exporting,
    printChart: T.highcharts.printchart
  }
};

export function setLanguage(language: string) {
  selectedLanguage = language;
  setSelectedLanguage(language);

  if (language === 'en') {
    moment.locale('en-GB');
  } else {
    moment.locale(language);
  }

  Highcharts.setOptions(langOptions);
}

Highcharts.setOptions(langOptions);

export const UnlocalizedString = '-TRANSLATE-ME-';
moment.locale(selectedLanguage);
