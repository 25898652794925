import React, { useCallback, useMemo, useState } from 'react';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import ModelFormDialog from '../ModelForm/ModelFormDialog';
import ToolbarContentPage from '../ToolbarContentPage/ToolbarContentPage';
import { useSimpleDialogState } from '../hooks/useDialogState';
import T from '../lang/Language';
import { ModelDefinition } from '../ModelForm/ModelPropType';
import DataTable, { DataTableColumnProps } from '../DataTable/DataTable';
import _ from 'lodash';
import { standardColumns } from '../utils/dataTableUtils';
import { toastStore } from '../Toast/ToastContainer';
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseMutationResult
} from '@tanstack/react-query';
import DataTableLoadMoreFooter from '../DataTable/DataTableLoadMoreFooter';
import { ASC, SortDirectionType } from '../DataTable/SortDirection';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

export type ODataListQuery = {
  $filter?: string;
  $orderby?: string;
  $top?: number;
};

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

const getItemName = <T extends object>(
  item: T,
  itemName: StringKeys<T>
): string => {
  return _.get(item, itemName as keyof object);
};

export type CRUDListResponseType<ValueType extends object> = {
  items?: ValueType[];
  continuationToken?: string;
};

const CRUDView = <
  ValueType extends object,
  UpdateErrorType extends object,
  CreateErrorType extends object
>({
  models,
  columns,
  createNewItem,
  itemName,
  searchItems,
  sortDirection,
  sortBy,
  title,
  editTitle,
  addTitle,
  listQueryHook,
  deleteItemMutation,
  updateItemMutation,
  createItemMutation
}: {
  models: ModelDefinition<ValueType>[];
  columns: DataTableColumnProps<ValueType>[];
  createNewItem: () => ValueType;
  itemName: StringKeys<ValueType>;
  searchItems?: (keyof ValueType)[];
  sortBy?: keyof ValueType;
  sortDirection?: SortDirectionType;
  title: string | React.ReactElement;
  editTitle: string | string[];
  addTitle: string;
  listQueryHook: (
    query: ODataListQuery
  ) => UseInfiniteQueryResult<
    InfiniteData<CRUDListResponseType<ValueType>, unknown>,
    unknown
  >;
  deleteItemMutation: UseMutationResult;
  updateItemMutation: UseMutationResult<unknown, UpdateErrorType, ValueType>;
  createItemMutation: UseMutationResult<unknown, CreateErrorType, ValueType>;
}) => {
  const [editItem, setEditItem] = useState<ValueType>(null);
  const [isNewItem, setIsNewItem] = useState(false);
  const [sort, setSort] = useState<{
    sortBy: keyof ValueType;
    sortDirection: SortDirectionType;
  }>(sortBy ? { sortBy, sortDirection: sortDirection || ASC } : null);

  const resetEditItem = useCallback(() => {
    setEditItem(null);
    setIsNewItem(false);
  }, []);
  const createItem = useCallback(() => {
    setEditItem(createNewItem());
    setIsNewItem(true);
  }, [createNewItem]);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useSimpleDialogState();
  const [confirmDeleteItem, _setConfirmDeleteItem] = useState<ValueType>(null);
  const setConfirmDeleteItem = useCallback(
    (item: ValueType) => {
      _setConfirmDeleteItem(item);
      if (item) {
        openDeleteDialog();
      } else {
        closeDeleteDialog();
      }
    },
    [closeDeleteDialog, openDeleteDialog]
  );
  const queryParams = queryString.parse(location.search);
  const search = queryParams.search as string;
  const navigate = useNavigate();

  const setSearch = useCallback(
    (newSearch: string) => {
      if (newSearch !== '') {
        navigate(
          {
            search: '?' + queryString.stringify({ search: newSearch })
          },
          { replace: true }
        );
      } else {
        navigate({ search: null }, { replace: true });
      }
    },
    [navigate]
  );

  const searchFilter = !_.isEmpty(search)
    ? _.join(
        _.map(
          searchItems,
          (item: string) => `contains(tolower(${item}), tolower('${search}'))`
        ),
        ' or '
      )
    : '';

  const listQuery = listQueryHook({
    ...(_.isEmpty(searchFilter) ? {} : { $filter: searchFilter }),
    ...(_.isEmpty(sort)
      ? {}
      : { $orderby: `${String(sort.sortBy)} ${sort.sortDirection}` }),
    $top: 20
  });

  const onSuccessUpdateItem = useCallback(
    (_unused: unknown, item: ValueType) => {
      resetEditItem();
      toastStore.addSuccessToastForUpdatedItem(
        getItemName(item, itemName),
        isNewItem
      );
      listQuery.refetch();
    },
    [isNewItem, itemName, listQuery, resetEditItem]
  );

  const onErrorUpdateItem = useCallback(
    (_unused: unknown, item: ValueType) => {
      toastStore.addErrorToastForUpdatedItem(
        getItemName(item, itemName),
        isNewItem
      );
    },
    [isNewItem, itemName]
  );
  const onSuccessCreateItem = useCallback(
    (_unused: unknown, item: ValueType) => {
      toastStore.addSuccessToastForUpdatedItem(
        getItemName(item, itemName),
        true
      );
      resetEditItem();
      listQuery.refetch();
    },
    [itemName, resetEditItem, listQuery]
  );

  const onErrorCreateItem = useCallback(
    (_unused: unknown, item: ValueType) => {
      toastStore.addErrorToastForUpdatedItem(
        getItemName(item, itemName),
        isNewItem
      );
    },
    [isNewItem, itemName]
  );
  const onSuccessDeleteItem = useCallback(() => {
    toastStore.addSuccessToastForDeletedItem(
      getItemName(confirmDeleteItem, itemName)
    );
    resetEditItem();
    setConfirmDeleteItem(null);

    listQuery.refetch();
  }, [
    confirmDeleteItem,
    itemName,
    listQuery,
    resetEditItem,
    setConfirmDeleteItem
  ]);

  const onErrorDeleteItem = useCallback(() => {
    toastStore.addErrorToastForDeletedItem(
      getItemName(confirmDeleteItem, itemName)
    );
  }, [confirmDeleteItem, itemName]);

  const isLoading =
    updateItemMutation.isPending ||
    deleteItemMutation.isPending ||
    createItemMutation.isPending;

  const _columns = useMemo(() => {
    return [
      ...columns,
      ...standardColumns<ValueType>({
        onDelete: (item: ValueType) => setConfirmDeleteItem(item),
        onEdit: (item: ValueType) => setEditItem(_.cloneDeep(item))
      })
    ];
  }, [columns, setConfirmDeleteItem]);

  const allItems = useMemo(() => {
    return _.compact(_.flatMap(listQuery.data?.pages, 'items'));
  }, [listQuery.data?.pages]);

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      title={title}
      addAction={() => createItem()}
      addActionTitle={addTitle}
      onSearchInput={!_.isEmpty(searchItems) ? setSearch : undefined}
    >
      <ConfirmDeleteDialog
        isOpen={isDeleteDialogOpen}
        onModalClose={() => setConfirmDeleteItem(null)}
        isLoading={deleteItemMutation.isPending}
        itemName={getItemName(confirmDeleteItem, itemName)}
        onDelete={() =>
          deleteItemMutation.mutate(confirmDeleteItem, {
            onSuccess: onSuccessDeleteItem,
            onError: onErrorDeleteItem
          })
        }
      />
      <ModelFormDialog<ValueType, false>
        onModalClose={resetEditItem}
        input={editItem}
        editTitle={editTitle}
        addTitle={addTitle}
        actionText={isNewItem ? T.common.add : T.common.save}
        isSavingInput={isLoading}
        saveInput={
          isNewItem
            ? (item) =>
                createItemMutation.mutate(item, {
                  onSuccess: onSuccessCreateItem,
                  onError: onErrorCreateItem
                })
            : (item) =>
                updateItemMutation.mutate(item, {
                  onSuccess: onSuccessUpdateItem,
                  onError: onErrorUpdateItem
                })
        }
        models={models}
        isObjectNew={() => isNewItem}
        saveAsArray={false}
      />
      <DataTable<ValueType>
        hasError={listQuery.error != null}
        isLoading={listQuery.isLoading}
        data={allItems}
        columns={_columns}
        sortBy={sort?.sortBy as string}
        sortDirection={sort?.sortDirection}
        onSortChange={(newSortBy, newSortDirection) => {
          setSort({
            sortBy: newSortBy as keyof ValueType,
            sortDirection: newSortDirection as SortDirectionType
          });
        }}
      />
      <DataTableLoadMoreFooter
        isFetchingNextPage={listQuery.isFetchingNextPage}
        fetchNextPage={listQuery.fetchNextPage}
        hasNextPage={listQuery.hasNextPage}
      />
    </ToolbarContentPage>
  );
};

export default CRUDView;
