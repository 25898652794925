import React, { useCallback, useContext, useMemo } from 'react';
import SelectDashboardCollectionRelationModal, {
  SelectDashboardCollectionRelationModalProps,
  onUpdateRelationError,
  onUpdateRelationSuccess
} from './SelectDashboardCollectionRelationModal';
import APIGen, {
  DashboardCollectionResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

type SelectDashboardCollectionForNodeModalProps =
  SelectDashboardCollectionRelationModalProps & {
    nodeId?: string;
  };

const SelectDashboardCollectionForNodeModal = ({
  nodeId,
  onModalClose,
  ...otherProps
}: SelectDashboardCollectionForNodeModalProps) => {
  const getRelationsArgs = useMemo(() => ({ nodeIds: [nodeId] }), [nodeId]);
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const updateRelationMutation =
    APIGen.AdminDashboard.addOrUpdateDashboardCollectionNodeRelations.useMutation(
      {
        onSuccess: () => {
          onUpdateRelationSuccess(contextSettings, queryClient, onModalClose);
        },
        onError: () => {
          onUpdateRelationError();
        }
      }
    );

  const onSelectDone = useCallback(
    (selectedCollection: DashboardCollectionResponseModel) => {
      updateRelationMutation.mutate([
        {
          dashboardCollectionId: selectedCollection?.dashboardCollectionId,
          nodeId
        }
      ]);
    },
    [nodeId, updateRelationMutation]
  );

  return (
    <SelectDashboardCollectionRelationModal
      getRelationsArgs={getRelationsArgs}
      onModalClose={onModalClose}
      onSelectDone={onSelectDone}
      isLoading={updateRelationMutation.isPending}
      {...otherProps}
    />
  );
};

export default SelectDashboardCollectionForNodeModal;
