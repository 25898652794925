import React, { useCallback, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import DashboardsCollectionsPageList from 'js/components/DashboardCollections/DashboardCollectionsPageList';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import {
  calculateDataTableMinHeight,
  standardColumns,
  truncateColumns
} from 'ecto-common/lib/utils/dataTableUtils';
import { DashboardCollectionFormColumns } from 'js/components/DashboardCollections/DashboardCollectionForm';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import APIGen, {
  DashboardCollectionResponseModel,
  GetDashboardCollectionsRequestModel
} from 'ecto-common/lib/API/APIGen';
import { QueryClient } from '@tanstack/react-query';
import { DashboardCollectionIdResponseModel } from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

export const onUpdateRelationSuccess = (
  contextSettings: ApiContextSettings,
  queryClient: QueryClient,
  onModalClose: () => void
) => {
  queryClient.invalidateQueries({
    queryKey:
      APIGen.AdminDashboard.getDashboardCollections.path(contextSettings)
  });
  queryClient.invalidateQueries({
    queryKey:
      APIGen.AdminDashboard.getDefaultDashboardCollectionId.path(
        contextSettings
      )
  });
  toastStore.addSuccessToast(T.admin.dashboardcollection.editsuccess);
  onModalClose();
};

export const onUpdateRelationError = () => {
  toastStore.addErrorToast(T.admin.dashboardcollection.editfailure);
};

export interface SelectDashboardCollectionRelationModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  forceSet?: boolean;
  getRelationsArgs?: GetDashboardCollectionsRequestModel;
  isLoading?: boolean;
  onSelectDone?(collection: DashboardCollectionResponseModel): void;
  actionText?: string;
  title?: string;
  // Set this if you want to preselect a collection and skip fetching the relation.
  collection?: DashboardCollectionIdResponseModel;
}

const SelectDashboardCollectionRelationModal = ({
  isOpen,
  onModalClose,
  getRelationsArgs,
  isLoading,
  onSelectDone,
  actionText = T.common.save,
  collection,
  forceSet = false,
  title = T.admin.dashboardcollection.select
}: SelectDashboardCollectionRelationModalProps) => {
  const [initialCollection, setInitialCollection] =
    useState<DashboardCollectionResponseModel>(collection);
  const [selectedCollection, setSelectedCollection] =
    useState<DashboardCollectionResponseModel>(null);
  const [hasError, setHasError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const enableFetchingRelation =
    collection === undefined && getRelationsArgs != null;

  if (collection != null && initialCollection !== collection) {
    setInitialCollection(collection);
    setSelectedCollection(collection);
  }

  const getRelationQuery =
    APIGen.AdminDashboard.getDashboardCollections.useQuery(getRelationsArgs, {
      enabled: enableFetchingRelation
    });

  if (
    getRelationQuery.data != null &&
    initialCollection?.dashboardCollectionId !==
      getRelationQuery.data?.[0]?.dashboardCollectionId
  ) {
    const fetchedCollection = _.head(getRelationQuery.data);
    setInitialCollection(fetchedCollection);
    setSelectedCollection(fetchedCollection);
  }

  const onConfirm = useCallback(() => {
    onSelectDone(selectedCollection);
  }, [selectedCollection, onSelectDone]);

  const onClickRow = useCallback(
    (item: DashboardCollectionResponseModel) => {
      setSelectedCollection((oldCollection) => {
        if (
          oldCollection?.dashboardCollectionId === item.dashboardCollectionId
        ) {
          setHasChanges(initialCollection != null);
          return null;
        }

        setHasChanges(
          initialCollection?.dashboardCollectionId !==
            item.dashboardCollectionId
        );
        return item;
      });
    },
    [initialCollection]
  );

  const selectedData = useMemo(
    () => _.compact([selectedCollection]),
    [selectedCollection]
  );

  const resetSelected = useCallback(() => {
    setSelectedCollection(null);
    setHasChanges(true);
  }, []);

  const topColumns: DataTableColumnProps<DashboardCollectionResponseModel>[] =
    useMemo(
      () => [
        ...truncateColumns(DashboardCollectionFormColumns),
        ...standardColumns({ onDelete: resetSelected })
      ],
      [resetSelected]
    );

  const _onModalClose = useCallback(() => {
    onModalClose();
    setInitialCollection(null);
    setSelectedCollection(null);
    setHasError(false);
    setHasChanges(false);
  }, [onModalClose]);

  const isLoadingRelation =
    enableFetchingRelation && getRelationQuery.isLoading;

  return (
    <ActionModal
      isOpen={isOpen}
      headerIcon={Icons.Folder}
      title={title}
      actionText={actionText}
      onConfirmClick={onConfirm}
      isLoading={isLoading}
      onModalClose={_onModalClose}
      disableActionButton={
        hasError ||
        isLoadingRelation ||
        (forceSet && selectedCollection == null) ||
        !hasChanges
      }
    >
      <KeyValueLine>
        <KeyValueGeneric keyText={T.admin.dashboardcollection.current}>
          <DataTable<DashboardCollectionResponseModel>
            isLoading={isLoadingRelation}
            columns={topColumns}
            data={selectedData}
            noDataText={T.admin.dashboardcollection.noselectedcollection}
            minHeight={calculateDataTableMinHeight({
              pageSize: 1,
              withButtons: true,
              showNoticeHeaders: false
            })}
            showNoticeHeaders={false}
            hasError={hasError}
          />
        </KeyValueGeneric>
      </KeyValueLine>
      <KeyValueLabel>
        {T.admin.dashboardcollection.selectincontext}
      </KeyValueLabel>
      <DashboardsCollectionsPageList
        pageSize={10}
        onClickRow={onClickRow}
        useAsSelector
        selectedCollectionId={selectedCollection?.dashboardCollectionId}
      />
    </ActionModal>
  );
};

export default React.memo(SelectDashboardCollectionRelationModal);
