import React, { useCallback } from 'react';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import DeleteModal from 'ecto-common/lib/ConfirmDeleteDialog/DeleteModal';
import APIGen, {
  DashboardCollectionResponseModel
} from 'ecto-common/lib/API/APIGen';

interface DashboardCollectionsDeleteModalProps {
  deleteItem?: DashboardCollectionResponseModel;
  onModalClose: () => void;
  onSuccess?(): void;
}

const DashboardCollectionsDeleteModal = ({
  deleteItem,
  onModalClose,
  onSuccess
}: DashboardCollectionsDeleteModalProps) => {
  const removeDashboardMutation =
    APIGen.AdminDashboard.deleteDashboardsCollections.useMutation({
      onSuccess: () => {
        toastStore.addSuccessToast(T.admin.dashboardcollection.remove.success);
        onModalClose();
        onSuccess?.();
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.dashboardcollection.remove.failure);
      }
    });

  const _removeDashboard = useCallback(
    () =>
      removeDashboardMutation.mutate({
        dashboardCollectionIds: [deleteItem.dashboardCollectionId]
      }),
    [removeDashboardMutation, deleteItem]
  );

  return (
    <DeleteModal
      isLoading={removeDashboardMutation.isPending}
      deleteItem={deleteItem}
      onConfirmDelete={_removeDashboard}
      onModalClose={onModalClose}
      itemName={deleteItem?.name}
    />
  );
};

export default React.memo(DashboardCollectionsDeleteModal);
