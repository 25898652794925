import React, { useState, useCallback, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import { NodeType } from 'ecto-common/lib/API/APIGen';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { searchByCaseInsensitive } from 'ecto-common/lib/utils/stringUtils';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import { beautifyEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import _ from 'lodash';

export type NodeTypesItem = {
  type: string;
  name: string;
};

export const columns: DataTableColumnProps<NodeTypesItem>[] = [
  {
    label: null,
    dataKey: 'name',
    flexGrow: 1,
    width: 100,
    linkColumn: true
  }
];

const NodeTypeProcessMapsRelations = ({
  searchString
}: {
  searchString: string;
}) => {
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );

  const nodeTypes = useMemo(
    () => [
      { type: NodeType.Building, name: T.nodetypes.building },
      { type: NodeType.Site, name: T.nodetypes.site },
      { type: NodeType.Equipment, name: T.nodetypes.equipment },

      ..._.map(equipmentTypes, (equipmentType) => ({
        type: equipmentType.equipmentTypeId,
        name: beautifyEquipmentName(equipmentType.name)
      }))
    ],
    [equipmentTypes]
  );

  const [selectedEquipmentTypeId, setSelectedEquipmentTypeId] =
    useState<string>(null);
  const onClickRow = useCallback((equipment: NodeTypesItem) => {
    setSelectedEquipmentTypeId(equipment.type);
  }, []);

  const filteredList = useMemo(() => {
    return searchByCaseInsensitive(nodeTypes, searchString, 'name');
  }, [nodeTypes, searchString]);

  return (
    <>
      <DataTable<NodeTypesItem>
        isLoading={false}
        disableHeader
        onClickRow={onClickRow}
        data={filteredList}
        columns={columns}
      />
      <SelectProcessMapDialog
        isOpen={selectedEquipmentTypeId != null}
        onModalClose={() => setSelectedEquipmentTypeId(null)}
        nodeId={null}
        equipmentTypeId={selectedEquipmentTypeId}
      />
    </>
  );
};
export default NodeTypeProcessMapsRelations;
