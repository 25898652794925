import {
  MutationOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import ProcessMapDataHandling, {
  ProcessMapDataType
} from 'ecto-common/lib/ProcessMaps/ProcessMapDataHandling';
import PresentationAPIGen, {
  ListAssignedPicturesResponse,
  ListPicturesResponse,
  PictureModel,
  PicturesDeletePicturePath,
  ProblemDetails
} from 'ecto-common/lib/API/PresentationAPIGen';
import { useContext, useMemo } from 'react';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { Base64 } from 'js-base64';
import { emptyProcessMapDocument } from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import _ from 'lodash';
import T from '../lang/Language';
import { ApiContextSettings } from '../API/APIUtils';
import { useSelector } from 'react-redux';
import { CommonRootState } from '../reducers/storeCommon';
import { getNodeFromMap } from '../utils/locationUtils';
import { NodeType } from '../API/APIGen';

const useItemQuery = (itemId: string) => {
  return PresentationAPIGen.Pictures.getPicture.useQuery({ id: itemId });
};

const triggerReload = (
  queryClient: QueryClient,
  contextSettings: ApiContextSettings
) => {
  queryClient.invalidateQueries({
    queryKey: PresentationAPIGen.Pictures.listPictures.path(contextSettings)
  });
  queryClient.invalidateQueries({
    queryKey: PresentationAPIGen.Nodes.getPictures.path(contextSettings)
  });
};

const useListQuery = (
  nodeTypes?: string[],
  options?: Partial<UseQueryOptions<ListAssignedPicturesResponse>>
): UseQueryResult<ProcessMapDataType[]> => {
  const nodeTypesFilter = useMemo(() => {
    if (nodeTypes?.length > 0) {
      const nodeTypesString = _.map(
        nodeTypes,
        (value) => "'" + value + "'"
      ).join(',');
      return `allowedNodeTypes/any(nodeType: nodeType in (${nodeTypesString}))`;
    }
    return undefined;
  }, [nodeTypes]);

  return PresentationAPIGen.Pictures.listPictures.useQuery(
    {
      $top: 1000, // "Disable" pagination for now until we add proper UI support for it.,
      $orderby: 'name asc',
      $filter: nodeTypesFilter
    },
    {
      ...(options ?? {}),
      meta: _.get(options, 'meta') ?? {
        errorString: T.admin.processmaps.maps.error
      },
      select: (data: ListPicturesResponse) => {
        return data.items as ListPicturesResponse;
      }
    }
  ) as UseQueryResult<ProcessMapDataType[]>;
};

export const useNodeRelation = ({
  nodeId,
  equipmentTypeId,
  options
}: {
  nodeId: string;
  equipmentTypeId?: string;
  options?: Partial<UseQueryOptions<ListAssignedPicturesResponse>>;
}) => {
  const equipmentOrNodeId = nodeId ?? equipmentTypeId;

  const nodeMap = useSelector(
    (state: CommonRootState) => state.general.nodeMap
  );
  const equipmentMap = useSelector(
    (state: CommonRootState) => state.general.equipmentMap
  );

  const node = getNodeFromMap(nodeMap, nodeId);
  const equipment = getNodeFromMap(equipmentMap, nodeId);

  const nodeTypes = useMemo(() => {
    const result = _.compact([
      node?.nodeType,
      equipment?.equipmentTypeId,
      equipmentTypeId,
      // also add generic equipment for equipment types
      equipmentTypeId ? NodeType.Equipment : null
    ]);
    return result.length === 0 ? undefined : result;
  }, [node?.nodeType, equipment?.equipmentTypeId, equipmentTypeId]);

  return {
    query: useNodeListQuery(nodeTypes, equipmentOrNodeId, options),
    nodeTypes
  };
};

const useNodeListQuery = (
  nodeTypes: string[],
  nodeId: string,
  options?: Partial<UseQueryOptions<ListAssignedPicturesResponse>>
): UseQueryResult<ListAssignedPicturesResponse> => {
  return PresentationAPIGen.Nodes.getPictures.useQuery(
    { nodeIds: [nodeId, ...(nodeTypes ?? [])] },
    {
      ...(options ?? {}),
      placeholderData: { items: [] },
      // Map the response from node pictures to a list of pictures with name
      select: (data: ListAssignedPicturesResponse) => ({
        ...data,
        items: _.reverse(
          _.sortBy(data.items, (processMap) => processMap.priority)
        )
      })
    }
  ) as UseQueryResult<ListAssignedPicturesResponse>;
};

const useUpdateNodeRelationMutation = (
  id: string,
  options?: UseMutationOptions<
    void,
    ProblemDetails,
    {
      originalData: ListAssignedPicturesResponse;
      assignedPictures: ProcessMapDataType[];
    }
  >
) => {
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      originalData,
      assignedPictures
    }: {
      originalData: ListAssignedPicturesResponse;
      assignedPictures: ProcessMapDataType[];
    }) => {
      const newOrChangedProcessMaps = _.reduce(
        assignedPictures,
        (result, processMap) => {
          const oldProcessMap = _.find(originalData.items, {
            id: processMap.id
          });
          if (
            !oldProcessMap ||
            oldProcessMap.priority !== processMap.priority
          ) {
            return [...result, processMap];
          }

          return result;
        },
        []
      );

      const deletedProcessMaps = _.differenceBy(
        originalData.items,
        assignedPictures,
        'id'
      );

      const deletePromises = _.map(deletedProcessMaps, (processMap) =>
        PresentationAPIGen.Nodes.unassignPicture.promise(
          contextSettings,
          { id: processMap.nodeId, pictureId: processMap.id },
          null
        )
      );
      return Promise.all([
        ...deletePromises,
        newOrChangedProcessMaps.length === 0
          ? Promise.resolve()
          : PresentationAPIGen.Nodes.assignPictures.promise(
              contextSettings,
              { id },
              newOrChangedProcessMaps,
              null
            )
      ]).then(() => {
        queryClient.invalidateQueries({
          queryKey: [
            ...PresentationAPIGen.Nodes.getPictures.path(contextSettings),
            { nodeIds: id }
          ]
        });
      });
    },
    ...(options ?? {})
  });
};

const useSaveMutation = (
  id: string,
  options?: UseMutationOptions<ProcessMapDataType, Error, ProcessMapDataType>
) => {
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  return PresentationAPIGen.Pictures.updatePicture.useMutation(
    { id },
    {
      ...((options as object) ?? {}),
      onSuccess: (result) => {
        queryClient.invalidateQueries({
          queryKey: PresentationAPIGen.Pictures.getPicture.path(
            contextSettings,
            {
              id: result.id
            }
          )
        });

        triggerReload(queryClient, contextSettings);
        options?.onSuccess?.(null, null, null);
      },
      meta: {
        errorString: T.admin.processmaps.error.update
      }
    }
  );
};

const useCreateMutation = (
  options?: MutationOptions<PictureModel, ProblemDetails, PictureModel>
) => {
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (picture: ProcessMapDataType) =>
      PresentationAPIGen.Pictures.createPicture.promise(
        contextSettings,
        {
          ...picture,
          data:
            picture.data ??
            Base64.encode(JSON.stringify(emptyProcessMapDocument))
        },
        null
      ),
    onSuccess: (...args) => {
      triggerReload(queryClient, contextSettings);
      options?.onSuccess?.(...args);
    },
    meta: {
      errorString: T.admin.processmaps.error.create
    }
  });
};

const useDeleteMutation = (
  options?: MutationOptions<void, ProblemDetails, PicturesDeletePicturePath>
) => {
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  return PresentationAPIGen.Pictures.deletePicture.useMutation({
    ...(options ?? ({} as object)),
    onSuccess: (...args) => {
      triggerReload(queryClient, contextSettings);
      options?.onSuccess?.(...args);
    },
    meta: {
      errorString: T.admin.processmaps.error.delete
    }
  });
};

const usePictureLibrary = (): ProcessMapDataHandling => {
  return {
    useUpdateNodeRelationMutation,
    useListQuery,
    useNodeListQuery,
    useItemQuery,
    useSaveMutation,
    useCreateMutation,
    useDeleteMutation,
    canEditPriorites: true,
    canEditAllowedNodes: true,
    canEditProcessMapSymbols: false,
    canChangeTenant: false
  };
};

export default usePictureLibrary;
