import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import styles from './CreateLocationDialog.module.css';
import T from 'ecto-common/lib/lang/Language';
import { bindActionCreators } from '@reduxjs/toolkit';

import Map, { MapLocation } from 'ecto-common/lib/Map/Map';
import MapMarker from 'ecto-common/lib/Map/MapMarker';
import { CreateNodeActions } from 'js/modules/createNodeForm/createNodeForm';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import ConfigureTemplateEquipmentDialog from 'js/components/InitEquipmentTemplate/ConfigureTemplateEquipmentDialog';
import { EquipmentTemplateFormActions } from 'js/modules/equipmentTemplateForm/equipmentTemplateForm';
import EditMeteorologyPointDialog from 'js/components/EditLocation/EditMeteorologyPointDialog';
import SelectMapPointDialog from 'js/components/EditLocation/SelectMapPointDialog';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import CreatableSelect from 'ecto-common/lib/Select/CreatableSelect';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';

import _ from 'lodash';
import SelectDashboardCollectionForCreateNodeDialog from 'js/components/DashboardCollections/RelationModals/SelectDashboardCollectionForCreateNodeDialog';
import GeocodingInputField from 'ecto-common/lib/GeocodingInputField/GeocodingInputField';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import APIGen, {
  BuildingTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { KeyValueColumn } from 'ecto-common/lib/KeyValueInput/KeyValueColumn';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { onGoogleApiLoadedProps } from 'google-maps-react-markers';

const mapOptions = {
  fullscreenControl: false
};

interface ConfigureNodeFieldsProps {
  parentLocation: SingleGridNode;
  nodeType?: string;
  isLoading?: boolean;
}

const ConfigureNodeFields = ({
  nodeType,
  parentLocation,
  isLoading
}: ConfigureNodeFieldsProps) => {
  const _getTemplateLabel = (
    equipmentGroupTemplates: BuildingTemplateResponseModel[],
    equipmentTemplateGroupId: string
  ) => _.find(equipmentGroupTemplates, { equipmentTemplateGroupId })?.name;
  const [
    showingSelectMapPointModal,
    showSelectMapPointModal,
    hideSelectMapPointModal
  ] = useSimpleDialogState();
  const language = useAdminSelector((state) => state.general.language);

  const renderMap = (
    latitude: number,
    longitude: number,
    onMapsLoaded: (maps: onGoogleApiLoadedProps) => void
  ) => {
    const center = [latitude, longitude];

    /**
     * We don't want to use DraggableMarkerMap.js here because we just want to trigger that the popups opens.
     */
    return (
      <div className={styles.map}>
        <div className={styles.mapOverlay} onClick={showSelectMapPointModal} />

        <Map
          zoom={10}
          center={center}
          options={mapOptions}
          onMapsLoaded={onMapsLoaded}
          language={language}
        >
          <MapMarker lat={center[0]} lng={center[1]} />
        </Map>
      </div>
    );
  };

  const name = useAdminSelector((state) => state.createNodeForm.name);
  const street = useAdminSelector((state) => state.createNodeForm.street);
  const tags = useAdminSelector((state) => state.createNodeForm.tags);
  const latitude = useAdminSelector((state) => state.createNodeForm.latitude);
  const longitude = useAdminSelector((state) => state.createNodeForm.longitude);
  const nodeTags = useAdminSelector((state) => state.general.nodeTags);
  const dashboardCollection = useAdminSelector(
    (state) => state.createNodeForm.dashboardCollection
  );
  const showWeatherPointDialog = useAdminSelector(
    (state) => state.createNodeForm.showWeatherPointDialog
  );
  const showDashboardCollectionDialog: boolean = useAdminSelector(
    (state) => state.createNodeForm.showDashboardCollectionDialog
  );
  const equipmentGroupTemplates = useAdminSelector(
    (state) => state.admin.equipmentGroupTemplates
  );
  const equipmentTemplateGroupId = useAdminSelector(
    (state) => state.equipmentTemplateForm.equipmentTemplateGroupId
  );
  const weatherPoint = useAdminSelector(
    (state) => state.createNodeForm.weatherPoint
  );

  const dispatch = useAdminDispatch();
  const createNodeActions = useMemo(
    () => bindActionCreators(CreateNodeActions, dispatch),
    [dispatch]
  );
  const equipmentTemplateActions = useMemo(
    () => bindActionCreators(EquipmentTemplateFormActions, dispatch),
    [dispatch]
  );

  const getRelationsQueryEnabled = equipmentTemplateGroupId != null;
  const getRelationsQuery =
    APIGen.AdminDashboard.getDashboardCollections.useQuery(
      {
        buildingTemplateIds: [equipmentTemplateGroupId]
      },
      {
        enabled: getRelationsQueryEnabled,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false
      }
    );

  useEffect(() => {
    if (getRelationsQuery.data != null) {
      createNodeActions.setDashboardCollection(getRelationsQuery.data[0]);
    }
  }, [createNodeActions, getRelationsQuery.data]);

  const createOption = (tag: string) => ({
    value: tag,
    label: tag
  });
  const tagOptions = nodeTags ? nodeTags.map(createOption) : [];
  const tagValue = tags && tags.map(createOption);
  const tagsChanged = (newTags: GenericSelectOption<string>[]) =>
    createNodeActions.setTags(newTags.map((tag) => tag.value));

  const weatherControl = (
    <KeyValueFixedSelectableInput
      keyText={T.admin.createlocation.configureweather.sectionheader}
      onClick={() => {
        createNodeActions.showWeatherPointDialog(true);
      }}
      placeholder={T.admin.createlocation.configureweather.title}
      value={weatherPoint && weatherPoint.name}
    />
  );

  const isBuilding = NodeTypes.isEqual(nodeType, NodeTypes.BUILDING);
  const [maps, setMaps] = useState<typeof google.maps>(null);

  const onMapsLoaded = useCallback(
    ({ maps: newMaps }: onGoogleApiLoadedProps) => {
      setMaps(newMaps);
    },
    []
  );

  const onSelectedLocation = useCallback(
    (location: MapLocation) => {
      createNodeActions.setStreet(location.street);
      createNodeActions.setCoordinates(location.lat, location.lng);
    },
    [createNodeActions]
  );

  return (
    <Fragment>
      <div className={styles.upperSection}>
        <div className={styles.inputSection}>
          <KeyValueInput
            keyText={T.admin.createlocation.parentlabel}
            disabled
            value={parentLocation.name}
          />
          <KeyValueInput
            keyText={T.admin.createlocation.nameplaceholder}
            value={name}
            autoFocus
            placeholder={T.admin.createlocation.nameplaceholder}
            onChange={(e) => createNodeActions.setName(e.target.value)}
          />
          <KeyValueGeneric keyText={T.admin.createlocation.addressplaceholder}>
            <GeocodingInputField
              maps={maps}
              value={street}
              error={isNullOrWhitespace(street) && isBuilding}
              placeholder={T.admin.createlocation.addressfieldplaceholder}
              onChange={(e) => createNodeActions.setStreet(e.target.value)}
              onSelectedLocation={onSelectedLocation}
              latitude={latitude}
              longitude={longitude}
            />
          </KeyValueGeneric>
        </div>
        <div className={styles.mapSection}>
          <KeyValueGeneric keyText={T.admin.createlocation.coordinate}>
            {renderMap(latitude, longitude, onMapsLoaded)}
          </KeyValueGeneric>
        </div>
      </div>
      <KeyValueColumn>
        <KeyValueGeneric keyText={T.admin.editlocation.tags}>
          <CreatableSelect<true>
            isMulti
            value={tagValue}
            options={tagOptions}
            onChange={tagsChanged}
            showOptionWhenEmpty={false}
          />
        </KeyValueGeneric>

        {isBuilding && (
          <KeyValueFixedSelectableInput
            onClick={() => equipmentTemplateActions.showDialog(true)}
            keyText={T.admin.createlocation.equipmentgrouptemplate}
            placeholder={T.admin.createlocation.choosetemplate}
            value={_getTemplateLabel(
              equipmentGroupTemplates,
              equipmentTemplateGroupId
            )}
          />
        )}

        <KeyValueFixedSelectableInput
          onClick={() => createNodeActions.showDashboardCollectionDialog(true)}
          isLoading={getRelationsQuery.isLoading}
          keyText={T.admin.createlocation.dashboardcollection}
          placeholder={T.admin.createlocation.selectdashboardcollection}
          value={dashboardCollection?.name}
        />

        {weatherControl}
      </KeyValueColumn>
      <SelectDashboardCollectionForCreateNodeDialog
        isOpen={showDashboardCollectionDialog}
        onModalClose={() =>
          createNodeActions.showDashboardCollectionDialog(false)
        }
        dashboardCollection={dashboardCollection}
        onSelectedCollection={(collection) => {
          createNodeActions.setDashboardCollection(collection);
          createNodeActions.showDashboardCollectionDialog(false);
        }}
      />

      <SelectMapPointDialog
        isOpen={showingSelectMapPointModal}
        onModalClose={hideSelectMapPointModal}
        onSelectedLocation={onSelectedLocation}
      />

      <EditMeteorologyPointDialog
        selectedLocation={parentLocation}
        onModalClose={() => createNodeActions.showWeatherPointDialog(false)}
        isOpen={showWeatherPointDialog}
      />

      <ConfigureTemplateEquipmentDialog
        isLoading={isLoading}
        confirmText={T.common.done}
        onConfirmClick={() => equipmentTemplateActions.showDialog(false)}
        onModalClose={() => equipmentTemplateActions.showDialog(false)}
        parentLocation={parentLocation}
      />
    </Fragment>
  );
};

export default ConfigureNodeFields;
