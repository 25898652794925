import React, { useState } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import _ from 'lodash';
import NodeTypeProcessMapsRelations from './NodeTypeProcessMapsRelations';
import T from 'ecto-common/lib/lang/Language';

const NodeTypeProcessMapsRelationsModal = ({
  isOpen,
  onModalClose
}: {
  isOpen: boolean;
  onModalClose: () => void;
}) => {
  const [searchString, setSearchString] = useState<string>('');

  return (
    <>
      <ActionModal
        withSearchField
        disableCancel
        onSearchTextChanged={setSearchString}
        isOpen={isOpen}
        onModalClose={onModalClose}
        onConfirmClick={onModalClose}
        headerIcon={Icons.Edit}
        title={T.admin.processmaps.editnodetyperelation}
      >
        <NodeTypeProcessMapsRelations searchString={searchString} />
      </ActionModal>
    </>
  );
};

export default NodeTypeProcessMapsRelationsModal;
