import React, { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ResourceType } from 'ecto-common/lib/constants';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import { keyValueFixedSelectableMenuComponents } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import SelectNodeDialog from 'ecto-common/lib/SelectNodeDialog/SelectNodeDialog';
import T from 'ecto-common/lib/lang/Language';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import APIGen from 'ecto-common/lib/API/APIGen';
import {
  createFlatNodeTree,
  createNodeMap,
  createEquipmentMap
} from 'ecto-common/lib/utils/locationUtils';
import styles from './PreviewNodeToolbarItems.module.css';
import { isNullOrWhitespace } from '../utils/stringUtils';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { useQuery } from '@tanstack/react-query';

type PreviewNodeToolbarItemsProps = {
  previewNodeId: string;
  setPreviewNodeId: Dispatch<SetStateAction<string>>;
  previewTenantId: string;
  setPreviewTenantId: Dispatch<SetStateAction<string>>;
  isLoadingNodeTreeSet?: boolean;
  nodeTreeSet: CustomNodeTreeSet;
};

export const useNodeTreeSet = (tenantId: string) => {
  const contextSettings = useMemo(() => {
    return { tenantId };
  }, [tenantId]);

  const enabled = tenantId != null;

  const nodesQuery = useQuery({
    queryKey: APIGen.Nodes.getNodes.path(contextSettings),

    queryFn: ({ signal }) => {
      return APIGen.Nodes.getNodes.promise(contextSettings, {}, signal);
    },
    staleTime: Infinity,
    enabled
  });

  const tagsQuery = useQuery({
    queryKey: APIGen.Nodes.getNodeTags.path(contextSettings),

    queryFn: ({ signal }) => {
      return APIGen.Nodes.getNodeTags.promise(contextSettings, signal);
    },
    staleTime: Infinity,
    enabled
  });

  const result: CustomNodeTreeSet = useMemo(() => {
    if (nodesQuery.data && tagsQuery.data) {
      const gridTree = createFlatNodeTree([], nodesQuery.data);
      return {
        nodeTree: gridTree.nodeTree,
        nodeMap: createNodeMap(gridTree.nodeTree),
        equipmentMap: createEquipmentMap(gridTree.nodeTree),
        nodeTags: tagsQuery.data
      };
    }

    return {
      nodeTree: [],
      nodeMap: {},
      equipmentMap: {},
      nodeTags: []
    };
  }, [nodesQuery.data, tagsQuery.data]);

  return [result, nodesQuery.isLoading || tagsQuery.isLoading] as const;
};

const PreviewNodeSelector = ({
  nodeTreeSet,
  previewNodeId,
  setPreviewNodeId,
  disabled,
  isLoading
}: {
  nodeTreeSet: CustomNodeTreeSet;
  previewNodeId: string;
  setPreviewNodeId: (nodeId: string) => void;
  disabled: boolean;
  isLoading: boolean;
}) => {
  const [showingDialog, showDialog, hideDialog] = useSimpleDialogState();

  const label =
    previewNodeId == null
      ? null
      : nodeTreeSet.nodeMap[previewNodeId]?.name ??
        nodeTreeSet.equipmentMap[previewNodeId]?.name;
  const nodeIds = useMemo(() => {
    return previewNodeId ? [previewNodeId] : [];
  }, [previewNodeId]);

  const options = useMemo(() => {
    return [
      {
        label,
        value: null
      }
    ];
  }, [label]);
  return (
    <>
      <Tooltip
        disableTooltip={showingDialog}
        text={T.admin.previewtenant.previewnodetooltip}
      >
        <Select
          isDisabled={isLoading || disabled}
          value={previewNodeId == null ? null : options[0]}
          options={options}
          onMenuOpen={isLoading ? null : showDialog}
          disabled={disabled}
          placeholder={T.admin.previewtenant.previewnode}
          isSearchable={false}
          components={keyValueFixedSelectableMenuComponents}
          isClearable
          isLoading={isLoading}
          onChange={(e) => {
            setPreviewNodeId(e?.value);
          }}
        />
      </Tooltip>
      <SelectNodeDialog
        selectEquipment
        isOpen={showingDialog}
        onModalClose={hideDialog}
        nodeIds={nodeIds}
        onNodesSelected={(newNodeIds) => setPreviewNodeId(newNodeIds[0])}
        multiSelect={false}
        customNodeTreeSet={nodeTreeSet}
      />
    </>
  );
};

const PreviewNodeToolbarItems = ({
  previewTenantId,
  setPreviewTenantId,
  previewNodeId,
  setPreviewNodeId,
  nodeTreeSet,
  isLoadingNodeTreeSet
}: PreviewNodeToolbarItemsProps) => {
  const { tenants } = useContext(TenantContext);

  const tenantOptions: GenericSelectOption<string>[] = useMemo(() => {
    return tenants
      .filter((x) =>
        x.resources.some((resource) => resource.name === ResourceType.CORE)
      )
      .map((tenant) => ({
        label: tenant.name,
        value: tenant.id
      }));
  }, [tenants]);
  const selectedOption = tenantOptions.find(
    (option) => option.value === previewTenantId
  );

  return (
    <>
      <ToolbarItem>
        <Tooltip text={T.admin.previewtenant.previewtenanttooltip}>
          {setPreviewTenantId && (
            <Select
              options={tenantOptions}
              value={selectedOption}
              onChange={(option) => setPreviewTenantId(option.value)}
              placeholder={T.admin.previewtenant.previewtenant}
              className={styles.select}
            />
          )}
        </Tooltip>
      </ToolbarItem>
      <ToolbarItem>
        <PreviewNodeSelector
          nodeTreeSet={nodeTreeSet}
          previewNodeId={previewNodeId}
          setPreviewNodeId={setPreviewNodeId}
          disabled={isNullOrWhitespace(previewTenantId)}
          isLoading={isLoadingNodeTreeSet}
        />
      </ToolbarItem>
    </>
  );
};

export default React.memo(PreviewNodeToolbarItems);
