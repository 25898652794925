import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useEffect
} from 'react';
import _ from 'lodash';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import HelpPaths from 'ecto-common/help/tocKeys';

import ProcessMapsEditModal from 'ecto-common/lib/ProcessMaps/ProcessMapsEditModal';
import ProcessMapTable from 'ecto-common/lib/ProcessMaps/ProcessMapTable';
import { ProcessMapResponseModel } from 'ecto-common/lib/API/APIGen';
import ProcessMapEditor from 'ecto-common/lib/ProcessMaps/ProcessMapEditor';
import {
  TemplateManagementParams,
  TemplateManagementRoute,
  getProcessMapsManagementRoute
} from 'js/utils/routeConstants';
import {
  useNavigate,
  useParams,
  matchPath,
  useSearchParams
} from 'react-router-dom';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { NavLink } from 'react-router-dom';

import { NestedTenantContainer } from 'ecto-common/lib/Application/TenantContainer';
import { EventHubServiceContainer } from 'ecto-common/lib/EventHubConnection/EventHubService';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { useImmer } from 'use-immer';
import {
  ProcessMapState,
  initProcessMapState
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import { ProcessMapEditorActions } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActions';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import EditButton from 'ecto-common/lib/Button/EditButton';
import ProcessMapEditLibraryModal from 'ecto-common/lib/ProcessMaps/ProcessMapEditLibraryModal';
import { useProcessMapLibrary } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorHooks';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { Draft } from 'immer';
import ProcessMapDataHandling, {
  ProcessMapDataType
} from 'ecto-common/lib/ProcessMaps/ProcessMapDataHandling';
import usePictureLibrary from 'ecto-common/lib/ProcessMaps/usePictureLibrary';
import NodeTypeProcessMapsRelationsModal from './NodeTypeProcessMapsRelationsModal';
import { usePromptFunction } from 'ecto-common/lib/hooks/useBlockerListener';

const ProcessMapEditorPage = ({
  itemId,
  processMapData,
  parentName,
  parentLink
}: {
  itemId: string;
  processMapData: ProcessMapDataHandling;
  parentName: string;
  parentLink: string;
}) => {
  const hasDataForIdRef = useRef({
    [itemId]: false
  });

  const itemQuery = processMapData.useItemQuery(itemId);
  const processMap = itemQuery.data;

  const [processMapState, setProcessMapState] = useImmer<ProcessMapState>(null);

  // Workaround to make sure the process map is only fetched once.
  // When going to process map list, component will be unmounted, and
  // when going back to process map, the request will be re-triggered.
  if (itemQuery.data != null && !hasDataForIdRef.current[itemId]) {
    hasDataForIdRef.current[itemId] = true;
    const initialData = itemQuery.data?.data;
    setProcessMapState(initProcessMapState(initialData));
  }

  const saveRequest = processMapData.useSaveMutation(itemId, {
    onSuccess: () => {
      setProcessMapState((draft: Draft<ProcessMapState>) =>
        ProcessMapEditorActions.clearHasChanges(draft)
      );
    }
  });

  const { libraryRequest, libraryItems } = useProcessMapLibrary();

  const { tenantId } = useContext(TenantContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const previewTenantId = processMapData.canChangeTenant
    ? searchParams.get('tenantId') ?? tenantId
    : tenantId;
  const previewNodeId = searchParams.get('nodeId');

  const setPreviewTenantId = useCallback(
    (newTenantId: string) => {
      setSearchParams(
        (oldParams) => {
          oldParams.set('tenantId', newTenantId);
          return oldParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const setPreviewNodeId = useCallback(
    (newNodeId: string) => {
      setSearchParams(
        (oldParams) => {
          oldParams.set('nodeId', newNodeId);
          return oldParams;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const titleElement = itemQuery.isPending ? (
    <Spinner size={SpinnerSize.SMALL} />
  ) : (
    <>{processMap?.name ?? T.common.unknownerror}</>
  );
  useEffect(() => {
    if (itemQuery.isPending) {
      document.title = `${T.admin.processmaps.title} > ${T.common.loading}`;
    } else {
      document.title = `${T.admin.processmaps.title} > ${itemQuery?.data?.name ?? T.common.unknownerror}`;
    }
  }, [itemQuery?.data?.name, itemQuery.isPending]);

  const title = (
    <>
      <NavLink to={parentLink}>{parentName}</NavLink>
      &nbsp;&gt;&nbsp;{titleElement}
    </>
  );

  const onSave = useCallback(
    (data: string) => {
      saveRequest.mutate({
        ...processMap,
        data
      });
    },
    [processMap, saveRequest]
  );

  usePromptFunction(
    useCallback(
      ({ nextLocation }) => {
        const newParams = matchPath<
          TemplateManagementParams,
          typeof TemplateManagementRoute.path
        >(TemplateManagementRoute.path, nextLocation.pathname)?.params;
        return newParams?.itemId !== itemId ? T.admin.form.unsavedstate : null;
      },
      [itemId]
    ),
    processMapState?.hasChanges
  );

  return (
    <>
      <ToolbarContentPage
        title={title}
        showLocationPicker={false}
        wrapContent={false}
        removeTopMargin
        removeSideMargin
        removeBottomMargin
      >
        <LoadingContainer
          isLoading={
            itemQuery.isPending ||
            saveRequest.isPending ||
            libraryRequest.isPending
          }
        >
          {(itemQuery.error != null || libraryRequest.error != null) && (
            <div>{T.admin.processmaps.failedtoload}</div>
          )}
          {processMapState != null && libraryRequest.data != null && (
            <NestedTenantContainer tenantId={previewTenantId}>
              <EventHubServiceContainer>
                <ProcessMapEditor
                  onSave={onSave}
                  processMapState={processMapState}
                  setProcessMapState={setProcessMapState}
                  library={libraryItems}
                  previewTenantId={previewTenantId}
                  setPreviewTenantId={
                    processMapData.canChangeTenant ? setPreviewTenantId : null
                  }
                  previewNodeId={previewNodeId}
                  setPreviewNodeId={setPreviewNodeId}
                />
              </EventHubServiceContainer>
            </NestedTenantContainer>
          )}
        </LoadingContainer>
      </ToolbarContentPage>
    </>
  );
};

const ProcessMapsList = ({
  processMapData,
  onEditProcessMap,
  onProcessMapCreated
}: {
  processMapData: ProcessMapDataHandling;
  onEditProcessMap: (id: string) => void;
  onProcessMapCreated?: (id: string) => void;
}) => {
  const listQuery = processMapData.useListQuery();
  const [searchString, setSearchString] = useState('');
  const [isEditProcessMapOpen, setIsEditProcessMapOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProcessMapId, setSelectedProcessMapId] =
    useState<string>(null);

  const [isShowingDialog, showDialog, hideDialog] = useSimpleDialogState();

  const items: Record<string, ProcessMapDataType> = useMemo(() => {
    return _.keyBy(listQuery.data, 'id');
  }, [listQuery.data]);

  const onEditRow = useCallback((processMap: ProcessMapDataType) => {
    setSelectedProcessMapId(processMap.id);
    setIsEditing(true);
    setIsEditProcessMapOpen(true);
  }, []);

  const onClickRow = useCallback(
    ({ id }: ProcessMapResponseModel) => {
      onEditProcessMap(id);
    },
    [onEditProcessMap]
  );

  const onAddClickHandler = useCallback(() => {
    setIsEditProcessMapOpen(true);
    setIsEditing(false);
  }, []);

  const [isEditRelationsOpen, showEditRelations, hideEditRelations] =
    useSimpleDialogState();
  const toolbarItems = (
    <>
      <ToolbarItem>
        <EditButton onClick={showEditRelations}>
          {T.admin.processmaps.editnodetyperelation}...
        </EditButton>
      </ToolbarItem>

      {processMapData.canEditProcessMapSymbols && (
        <ToolbarItem>
          <EditButton onClick={showDialog}>
            {T.admin.processmaps.editsymbollibrary}...
          </EditButton>
        </ToolbarItem>
      )}
    </>
  );

  useEffect(() => {
    document.title = T.admin.processmaps.title;
  }, []);

  return (
    <ToolbarContentPage
      title={T.admin.processmaps.title}
      addAction={onAddClickHandler}
      addActionTitle={T.admin.processmaps.addmap}
      showLocationPicker={false}
      searchString={searchString}
      onSearchInput={setSearchString}
      wrapContent={false}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.admin.manage.process_maps.process_maps}
    >
      {listQuery.error && (
        <ErrorNotice> {T.admin.processmaps.maps.error} </ErrorNotice>
      )}
      <ProcessMapTable
        onClickRow={onClickRow}
        searchString={searchString}
        isLoading={listQuery.isPending}
        items={items}
        hasError={listQuery.error != null}
        onEdit={onEditRow}
      />

      <NodeTypeProcessMapsRelationsModal
        isOpen={isEditRelationsOpen}
        onModalClose={hideEditRelations}
      />
      <ProcessMapsEditModal
        isOpen={isEditProcessMapOpen}
        onModalClose={() => setIsEditProcessMapOpen(false)}
        processMapData={processMapData}
        isEditing={isEditing}
        selectedProcessMapId={selectedProcessMapId}
        items={items}
        isLoading={listQuery.isLoading}
        setSelectedProcessMapId={setSelectedProcessMapId}
        onProcessMapCreated={onProcessMapCreated}
      />
      <ProcessMapEditLibraryModal
        isOpen={isShowingDialog}
        onModalClose={hideDialog}
      />
    </ToolbarContentPage>
  );
};

const ProcessMapsPage = () => {
  const params = useParams<TemplateManagementParams>();
  const processMapData = usePictureLibrary();
  const { tenantId } = useContext(TenantContext);
  const navigate = useNavigate();

  const openEditItemPage = useCallback(
    (id: string) => {
      navigate(getProcessMapsManagementRoute(tenantId, id));
    },
    [navigate, tenantId]
  );

  if (params.itemId != null) {
    const parentLink = getProcessMapsManagementRoute(tenantId);
    return (
      <ProcessMapEditorPage
        processMapData={processMapData}
        itemId={params.itemId}
        key={params.itemId}
        parentName={T.admin.processmaps.title}
        parentLink={parentLink}
      />
    );
  }

  return (
    <ProcessMapsList
      processMapData={processMapData}
      onEditProcessMap={openEditItemPage}
      onProcessMapCreated={openEditItemPage}
    />
  );
};

export default ProcessMapsPage;
