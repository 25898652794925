import React, { useContext } from 'react';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import DeleteModal from 'ecto-common/lib/ConfirmDeleteDialog/DeleteModal';
import APIGen, {
  DashboardResponseModel,
  DeleteDashboardsRequestModel
} from 'ecto-common/lib/API/APIGen';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const removePersonalDashboardPromise = (
  contextSettings: ApiContextSettings,
  request: DeleteDashboardsRequestModel
) => {
  return IdentityServiceAPIGenV2.User.deleteUserDashboard.promise(
    contextSettings,
    { dashboardId: request.dashboardIds[0] },
    null
  );
};

interface DashboardDeleteModalProps {
  deleteItem?: DashboardResponseModel;
  onModalClose(): void;
  onSuccess?(deleteItem: DashboardResponseModel): void;
  handlePersonalDashboards?: boolean;
}

const DashboardDeleteModal = ({
  deleteItem,
  onModalClose,
  onSuccess,
  handlePersonalDashboards
}: DashboardDeleteModalProps) => {
  const { contextSettings } = useContext(TenantContext);

  const deleteDashboardMutation = useMutation({
    mutationFn: () => {
      if (handlePersonalDashboards) {
        return removePersonalDashboardPromise(contextSettings, {
          dashboardIds: [deleteItem.dashboardId]
        });
      }
      return APIGen.AdminDashboard.deleteDashboards.promise(
        contextSettings,
        {
          dashboardIds: [deleteItem.dashboardId]
        },
        null
      );
    },

    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.dashboards.remove.success);
      onModalClose();
      onSuccess?.(deleteItem);
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.dashboards.remove.failure);
    }
  });

  return (
    <DeleteModal
      isLoading={deleteDashboardMutation.isPending}
      deleteItem={deleteItem}
      onConfirmDelete={() => deleteDashboardMutation.mutate()}
      onModalClose={onModalClose}
      itemName={deleteItem?.name}
    />
  );
};

export default React.memo(DashboardDeleteModal);
