/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ListAssignedPicturesResponse {
  items?: AssignedPictureModel[] | null;
}

export interface AssignedPictureModel {
  id?: string | null;
  /** @format int32 */
  priority?: number | null;
  nodeId?: string | null;
  name?: string | null;
  data?: string | null;
}

export interface ListAssignedTenantDashboardsResponse {
  items?: AssignedTenantDashboardModel[] | null;
}

export interface AssignedTenantDashboardModel {
  id?: string | null;
  /** @format int32 */
  priority?: number | null;
  nodeId?: string | null;
  name?: string | null;
  data?: string | null;
}

export interface AssignPictureToNodeRequest {
  id?: string | null;
  /** @format int32 */
  priority?: number | null;
}

export interface AssignTenantDashboardToNodeRequest {
  id?: string | null;
  /** @format int32 */
  priority?: number | null;
}

export interface ListPicturesResponse {
  items?: PictureModel[] | null;
  continuationToken?: string | null;
}

export interface PictureModel {
  id?: string | null;
  tenant?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  /** @format int32 */
  defaultPriority?: number;
  allowedNodeTypes?: string[] | null;
}

export interface ListSymbolResponse {
  items?: SymbolModel[] | null;
  continuationToken?: string | null;
}

export interface SymbolModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  states?: string[] | null;
  connections?: ConnectionModel[] | null;
}

export interface ConnectionModel {
  id?: string | null;
  /** @format float */
  x?: number;
  /** @format float */
  y?: number;
}

export interface ListTenantDashboardResponse {
  items?: TenantDashboardModel[] | null;
  continuationToken?: string | null;
}

export interface TenantDashboardModel {
  id?: string | null;
  tenant?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  /** @format int32 */
  defaultPriority?: number;
  allowedNodeTypes?: string[] | null;
}

export interface DatapointDeleteDatapointSamplesParams {
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
  id: string;
}

export interface NodesGetPicturesParams {
  nodeIds?: string[] | null;
}

export interface NodesGetDashboardsParams {
  nodeIds?: string[] | null;
}

export interface PicturesListPicturesParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface SymbolsListSymbolsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface TenantDashboardsListTenantDashboardsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface VersionVersionParams {
  'api-version'?: string | null;
}

/**
 * @title Presentation API
 * @version v1
 * @baseUrl https://app-ec-presentation-dev-weu-001-7xkz.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.PresentationAPIGen;

export interface DatapointDeleteDatapointSamplesPath {
  id: string;
}

export interface DatapointDeleteDatapointWithSamplesPath {
  id: string;
}

export interface NodesAssignPicturesPath {
  id: string;
}

export interface NodesAssignTenantDashboardsPath {
  id: string;
}

export interface NodesUnassignPicturePath {
  id: string;
  pictureId: string;
}

export interface NodesUnassignDashboardPath {
  id: string;
  dashboardId: string;
}

export interface PicturesGetPicturePath {
  id: string;
}

export interface PicturesUpdatePicturePath {
  id: string;
}

export interface PicturesDeletePicturePath {
  id: string;
}

export interface SymbolsGetSymbolPath {
  id: string;
}

export interface SymbolsUpdateSymbolPath {
  id: string;
}

export interface SymbolsDeleteSymbolPath {
  id: string;
}

export interface TenantDashboardsGetTenantDashboardPath {
  id: string;
}

export interface TenantDashboardsUpdateTenantDashboardPath {
  id: string;
}

export interface TenantDashboardsDeleteTenantDashboardPath {
  id: string;
}

const PresentationAPIGen = {
  Datapoint: {
    deleteDatapointSamples: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        DatapointDeleteDatapointSamplesParams,
        keyof DatapointDeleteDatapointSamplesPath
      >,
      void,
      ProblemDetails,
      DatapointDeleteDatapointSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointSamplesPath) =>
        `/api/v1/datapoints/${id}/samples`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    deleteDatapointWithSamples: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      DatapointDeleteDatapointWithSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointWithSamplesPath) =>
        `/api/v1/datapoints/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Nodes: {
    getPictures: apiEndpointEmptyUsingQueryParams<
      NodesGetPicturesParams,
      ListAssignedPicturesResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/nodes/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getDashboards: apiEndpointEmptyUsingQueryParams<
      NodesGetDashboardsParams,
      ListAssignedTenantDashboardsResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/nodes/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    assignPictures: apiEndpointWithPath<
      AssignPictureToNodeRequest[],
      AssignedPictureModel[],
      ProblemDetails,
      NodesAssignPicturesPath
    >(
      Method.PUT,
      ({ id }: NodesAssignPicturesPath) => `/api/v1/nodes/${id}/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    assignTenantDashboards: apiEndpointWithPath<
      AssignTenantDashboardToNodeRequest[],
      AssignedTenantDashboardModel[],
      ProblemDetails,
      NodesAssignTenantDashboardsPath
    >(
      Method.PUT,
      ({ id }: NodesAssignTenantDashboardsPath) =>
        `/api/v1/nodes/${id}/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    unassignPicture: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesUnassignPicturePath
    >(
      Method.DELETE,
      ({ id, pictureId }: NodesUnassignPicturePath) =>
        `/api/v1/nodes/${id}/pictures/${pictureId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    unassignDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      NodesUnassignDashboardPath
    >(
      Method.DELETE,
      ({ id, dashboardId }: NodesUnassignDashboardPath) =>
        `/api/v1/nodes/${id}/dashboards/${dashboardId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Pictures: {
    listPictures: apiEndpointEmptyUsingQueryParams<
      PicturesListPicturesParams,
      ListPicturesResponse,
      any
    >(
      Method.GET,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createPicture: apiEndpoint<PictureModel, PictureModel, ProblemDetails>(
      Method.POST,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getPicture: apiEndpointEmptyWithPath<
      PictureModel,
      ProblemDetails,
      PicturesGetPicturePath
    >(
      Method.GET,
      ({ id }: PicturesGetPicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updatePicture: apiEndpointWithPath<
      PictureModel,
      PictureModel,
      ProblemDetails,
      PicturesUpdatePicturePath
    >(
      Method.PUT,
      ({ id }: PicturesUpdatePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deletePicture: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      PicturesDeletePicturePath
    >(
      Method.DELETE,
      ({ id }: PicturesDeletePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Symbols: {
    listSymbols: apiEndpointEmptyUsingQueryParams<
      SymbolsListSymbolsParams,
      ListSymbolResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createSymbol: apiEndpoint<SymbolModel, SymbolModel, ProblemDetails>(
      Method.POST,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSymbol: apiEndpointEmptyWithPath<
      SymbolModel,
      ProblemDetails,
      SymbolsGetSymbolPath
    >(
      Method.GET,
      ({ id }: SymbolsGetSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateSymbol: apiEndpointWithPath<
      SymbolModel,
      SymbolModel,
      ProblemDetails,
      SymbolsUpdateSymbolPath
    >(
      Method.PUT,
      ({ id }: SymbolsUpdateSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteSymbol: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      SymbolsDeleteSymbolPath
    >(
      Method.DELETE,
      ({ id }: SymbolsDeleteSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  TenantDashboards: {
    listTenantDashboards: apiEndpointEmptyUsingQueryParams<
      TenantDashboardsListTenantDashboardsParams,
      ListTenantDashboardResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createTenantDashboard: apiEndpoint<
      TenantDashboardModel,
      TenantDashboardModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getTenantDashboard: apiEndpointEmptyWithPath<
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsGetTenantDashboardPath
    >(
      Method.GET,
      ({ id }: TenantDashboardsGetTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateTenantDashboard: apiEndpointWithPath<
      TenantDashboardModel,
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsUpdateTenantDashboardPath
    >(
      Method.PUT,
      ({ id }: TenantDashboardsUpdateTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteTenantDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      TenantDashboardsDeleteTenantDashboardPath
    >(
      Method.DELETE,
      ({ id }: TenantDashboardsDeleteTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmptyUsingQueryParams<VersionVersionParams, File, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default PresentationAPIGen;
