import React, {
  ChangeEventHandler,
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import T from 'ecto-common/lib/lang/Language';

import ProcessMapDataHandling, {
  ProcessMapDataType
} from './ProcessMapDataHandling';
import { KeyValueSelectableInput } from '../KeyValueInput/KeyValueSelectableInput';
import { GenericSelectOption } from '../Select/Select';
import { getEquipmentName } from '../utils/equipmentTypeUtils';
import { useCommonSelector } from '../reducers/storeCommon';
import { NodeType } from '../API/APIGen';

interface ProcessMapsEditModalContentProps {
  onModalClose: () => void;
  isEditing: boolean;
  selectedProcessMapId?: string;
  items?: Record<string, ProcessMapDataType>;
  onProcessMapCreated: (id: string) => void;
  processMapData: ProcessMapDataHandling;
}

const ProcessMapsEditModalContent = ({
  onModalClose,
  isEditing,
  selectedProcessMapId,
  items,
  onProcessMapCreated,
  processMapData
}: ProcessMapsEditModalContentProps) => {
  const processMap = _.get(items, selectedProcessMapId);
  const [name, setName] = useState(processMap?.name ?? '');
  const [description, setDescription] = useState(processMap?.description ?? '');
  const [allowedNodeTypes, setAllowedNodeTypes] = useState(
    processMap?.allowedNodeTypes ?? []
  );

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const successToastAndClose = useCallback(
    (message: string) => {
      toastStore.addSuccessToast(message);
      setDeleteModalIsOpen(false);
      onModalClose();
    },
    [onModalClose]
  );

  const updateMutation = processMapData.useSaveMutation(selectedProcessMapId, {
    onSuccess: () => {
      successToastAndClose(T.admin.processmaps.updated);
    }
  });
  const updateIsLoading = updateMutation.isPending;

  const createMutation = processMapData.useCreateMutation({
    onSuccess: (newItem) => {
      successToastAndClose(T.admin.processmaps.created);
      onProcessMapCreated(newItem.id);
    }
  });

  const deleteMutation = processMapData.useDeleteMutation({
    onSuccess: () => {
      successToastAndClose(T.admin.processmaps.removed);
    }
  });

  const isLoading =
    updateIsLoading || createMutation.isPending || deleteMutation.isPending;

  const onSaveHandler = useCallback(() => {
    if (name.length <= 0) {
      toastStore.addErrorToast(T.admin.processmaps.error.missingname);
      return;
    }

    if (isEditing) {
      updateMutation.mutate({
        ...processMap,
        name,
        description,
        allowedNodeTypes,
        defaultPriority: 1
      });
    } else {
      createMutation.mutate({
        name,
        description,
        defaultPriority: 1,
        allowedNodeTypes
      });
    }
  }, [
    name,
    isEditing,
    updateMutation,
    processMap,
    description,
    allowedNodeTypes,
    createMutation
  ]);

  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );

  const allowedNodeTypesOptions = useMemo(() => {
    return [
      { value: NodeType.Building, label: T.nodetypes.building },
      { value: NodeType.Site, label: T.nodetypes.site },
      { value: NodeType.Equipment, label: T.nodetypes.equipment },

      ...equipmentTypes.map((equipmentType) => ({
        value: equipmentType.equipmentTypeId,
        label: getEquipmentName(equipmentType.equipmentTypeId, equipmentTypes)
      }))
    ];
  }, [equipmentTypes]);

  const onDeleteHandler = useCallback(() => {
    deleteMutation.mutate({ id: selectedProcessMapId });
  }, [deleteMutation, selectedProcessMapId]);

  const onChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setName(event.target.value),
    []
  );

  const onChangeDescription: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setDescription(event.target.value),
    []
  );

  const onChangeAllowedNodeTypes = useCallback(
    (value: GenericSelectOption<string>[]) =>
      setAllowedNodeTypes(value.map((option) => option.value)),
    []
  );
  const allowedNodeTypesValue = useMemo(() => {
    return _.map(allowedNodeTypes, (value) =>
      _.find(allowedNodeTypesOptions, { value })
    );
  }, [allowedNodeTypes, allowedNodeTypesOptions]);

  const onDeleteButtonClick = useCallback(() => {
    setDeleteModalIsOpen(true);
  }, []);

  const nameError = _.trim(name).length === 0;
  const allowedNodeTypesError =
    processMapData.canEditAllowedNodes && allowedNodeTypes.length === 0;
  const hasErrors = name.length === 0 || allowedNodeTypesError || nameError;

  return (
    <>
      <ModalBody loading={isLoading}>
        <KeyValueLine>
          <KeyValueInput
            disabled={isLoading}
            keyText={T.admin.processmaps.name}
            value={name}
            onChange={onChangeName}
            hasError={nameError}
          />
        </KeyValueLine>

        <KeyValueLine>
          <KeyValueInput
            disabled={isLoading}
            keyText={T.admin.processmaps.description}
            value={description}
            onChange={onChangeDescription}
          />
        </KeyValueLine>
        {processMapData.canEditAllowedNodes && (
          <KeyValueLine>
            <KeyValueSelectableInput<GenericSelectOption<string>, true>
              options={allowedNodeTypesOptions}
              value={allowedNodeTypesValue}
              keyText={T.common.allowednodetypes}
              onChange={onChangeAllowedNodeTypes}
              hasError={allowedNodeTypesError}
              isMulti
            />
          </KeyValueLine>
        )}
      </ModalBody>

      <ModalFooter>
        {isEditing && (
          <DeleteButton disabled={isLoading} onClick={onDeleteButtonClick}>
            {T.admin.processmaps.deletemap}
          </DeleteButton>
        )}

        <ModalSpace />

        <SaveButton disabled={isLoading || hasErrors} onClick={onSaveHandler}>
          {isEditing ? T.common.save : T.admin.processmaps.add}
        </SaveButton>

        <LocalizedButtons.Cancel disabled={isLoading} onClick={onModalClose} />
      </ModalFooter>

      <ConfirmDeleteDialog
        isOpen={deleteModalIsOpen}
        isLoading={deleteMutation.isPending}
        onDelete={onDeleteHandler}
        onModalClose={() => setDeleteModalIsOpen(false)}
        itemName={name}
      />
    </>
  );
};

export default memo(ProcessMapsEditModalContent);
