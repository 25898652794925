import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import Button from 'ecto-common/lib/Button/Button';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import T from 'ecto-common/lib/lang/Language';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { getEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';

import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import styles from './EditEquipmentFields.module.css';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import { getEquipmentPageUrl } from 'js/utils/linkUtil';
import _ from 'lodash';
import { organizeEquipments } from 'js/components/Equipments/Equipments';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { patchNodes } from 'ecto-common/lib/actions/getNodes';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import APIGen, {
  EquipmentResponseModel,
  UpdateEquipmentRequestModel
} from 'ecto-common/lib/API/APIGen';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation } from '@tanstack/react-query';
import { usePromptMessage } from 'ecto-common/lib/hooks/useBlockerListener';

const deletePromise = (
  contextSettings: ApiContextSettings,
  equipmentId: string,
  nodeId: string
) => {
  return APIGen.AdminNodes.deleteNode
    .promise(contextSettings, { nodeId: equipmentId }, null)
    .then(() => {
      return APIGen.AdminNodes.getNodes.promise(
        contextSettings,
        {
          NodeId: nodeId
        },
        null
      );
    });
};

const savePromise = (
  contextSettings: ApiContextSettings,
  args: UpdateEquipmentRequestModel,
  nodeId: string
) => {
  return APIGen.AdminEquipments.updateEquipments
    .promise(contextSettings, [args], null)
    .then(() => {
      return APIGen.AdminNodes.getNodes.promise(
        contextSettings,
        {
          NodeId: nodeId
        },
        null
      );
    });
};

interface EditEquipmentFieldsProps {
  equipment?: EquipmentResponseModel;
}

const EditEquipmentFields = ({ equipment }: EditEquipmentFieldsProps) => {
  const { equipmentId, nodeId } = equipment;
  const dispatch = useAdminDispatch();
  const [name, setName] = useState(equipment?.name ?? '');
  const [equipmentTypeId, setEquipmentTypeId] = useState(
    equipment?.equipmentTypeId ?? ''
  );
  const [description, setDescription] = useState(equipment?.description ?? '');

  const hasUnsavedChanges =
    name !== (equipment?.name ?? '') ||
    description !== (equipment?.description ?? '') ||
    equipmentTypeId !== (equipment?.equipmentTypeId ?? '');

  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const [showModal, onShowModal, onHideModal] = useSimpleDialogState();

  const [showProcessMapDialog, onShowProcessMapDialog, onHideProcessMapDialog] =
    useDialogState('select-process-map-dialog');
  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setName(event.target.value),
    []
  );
  const onDescriptionChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => setDescription(event.target.value), []);
  const navigate = useNavigate();

  const { tenantId } = useContext(TenantContext);

  const deleteMutation = useMutation({
    mutationFn: () => deletePromise(contextSettings, equipmentId, nodeId),

    onSuccess: (newNodes) => {
      dispatch(patchNodes(newNodes));
      toastStore.addSuccessToast(T.admin.equipment.delete.success);
      const node = _.head(newNodes);
      const { sortedEquipments } = organizeEquipments(
        node?.equipments ?? [],
        equipmentTypes
      );
      navigate(
        getEquipmentPageUrl(
          tenantId,
          nodeId,
          sortedEquipments[0]?.equipmentId,
          'details'
        )
      );
      onHideModal();
    },

    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.delete.error);
    }
  });

  const { contextSettings } = useContext(TenantContext);

  const saveMutation = useMutation({
    mutationFn: (args: UpdateEquipmentRequestModel) =>
      savePromise(contextSettings, args, nodeId),

    onSuccess: (newNodes) => {
      dispatch(patchNodes(newNodes));
    },

    onError: () => {
      toastStore.addErrorToast(T.common.unknownerror);
    }
  });

  const onSave = useCallback(() => {
    saveMutation.mutate({
      equipmentId,
      name,
      description,
      equipmentTypeId
    });
  }, [saveMutation, equipmentId, name, description, equipmentTypeId]);

  const hasValidName = !_.isEmpty(name);

  const options = useMemo(() => {
    return equipmentTypes.map((equipmentType) => ({
      value: equipmentType.equipmentTypeId,
      label: getEquipmentName(equipmentType.equipmentTypeId, equipmentTypes)
    }));
  }, [equipmentTypes]);

  const equipmentTypeValue = options.find(
    (option) => option.value === equipmentTypeId
  );

  usePromptMessage(T.admin.form.unsavedstate, hasUnsavedChanges);

  return (
    <div>
      <KeyValueLine>
        <KeyValueSelectableInput<GenericSelectOption<string>, false>
          options={options}
          value={equipmentTypeValue}
          keyText={T.admin.equipment.type}
          onChange={(val) => setEquipmentTypeId(val.value)}
        />

        <KeyValueInput
          onChange={onNameChange}
          keyText={T.admin.equipment.namelabel}
          value={name}
          hasError={!hasValidName}
        />

        <KeyValueInput
          onChange={onDescriptionChange}
          keyText={T.admin.equipment.descriptionlabel}
          value={description}
        />
      </KeyValueLine>

      <div className={styles.saveSection}>
        <Button onClick={onShowProcessMapDialog}>
          <Icons.Settings />
          {T.admin.editlocation.editprocessmap}
        </Button>

        <DeleteButton onClick={onShowModal}>
          {T.admin.equipment.deleteequipment}
        </DeleteButton>

        <LocalizedButtons.Save
          disabled={
            !hasUnsavedChanges || saveMutation.isPending || !hasValidName
          }
          onClick={onSave}
        />
      </div>

      <ActionModal
        compact
        onModalClose={onHideModal}
        isOpen={showModal}
        isLoading={deleteMutation.isPending}
        headerIcon={Icons.Delete}
        title={T.admin.equipment.confirmdelete.title}
        actionText={T.common.delete}
        onConfirmClick={deleteMutation.mutate}
      >
        {T.admin.equipment.confirmdelete.message}
      </ActionModal>

      <SelectProcessMapDialog
        isOpen={showProcessMapDialog}
        onModalClose={onHideProcessMapDialog}
        nodeId={equipmentId}
        equipmentTypeId={equipmentTypeId}
      />
    </div>
  );
};

export default EditEquipmentFields;
