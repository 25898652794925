import React, { useCallback, useContext, useMemo } from 'react';
import SelectDashboardCollectionRelationModal, {
  SelectDashboardCollectionRelationModalProps,
  onUpdateRelationError,
  onUpdateRelationSuccess
} from './SelectDashboardCollectionRelationModal';
import T from 'ecto-common/lib/lang/Language';
import APIGen, {
  DashboardCollectionResponseModel
} from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

type SelectDefaultDashboardCollectionDialogProps =
  SelectDashboardCollectionRelationModalProps & {
    dashboardCollectionId?: string;
    onSelectedCollection?(
      collection: DashboardCollectionResponseModel
    ): unknown;
  };

const SelectDefaultDashboardCollectionDialog = ({
  onModalClose,
  ...otherProps
}: SelectDefaultDashboardCollectionDialogProps) => {
  const defaultCollectionIdQuery =
    APIGen.AdminDashboard.getDefaultDashboardCollectionId.useQuery({});
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const updateRelationMutation =
    APIGen.AdminDashboard.setDefaultDashboardCollectionId.useMutation({
      onSuccess: () => {
        onUpdateRelationSuccess(contextSettings, queryClient, onModalClose);
      },
      onError: () => {
        onUpdateRelationError();
      }
    });

  const onSelectDone = useCallback(
    (selectedCollection: DashboardCollectionResponseModel) => {
      updateRelationMutation.mutate({
        dashboardCollectionId: selectedCollection?.dashboardCollectionId
      });
    },
    [updateRelationMutation]
  );

  const relationsArgs = useMemo(() => {
    return {
      dashboardCollectionIds: _.compact([
        defaultCollectionIdQuery.data?.dashboardCollectionId
      ])
    };
  }, [defaultCollectionIdQuery.data?.dashboardCollectionId]);

  return (
    <SelectDashboardCollectionRelationModal
      getRelationsArgs={relationsArgs}
      onSelectDone={onSelectDone}
      onModalClose={onModalClose}
      isLoading={updateRelationMutation.isPending}
      forceSet
      title={T.admin.dashboardcollection.setdefault}
      {...otherProps}
    />
  );
};

export default React.memo(SelectDefaultDashboardCollectionDialog);
