import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import EctotableAPIGen, {
  ScreenContentsResponse,
  ScreenContentsResponseScreenContent,
  ScreenContentType
} from 'ecto-common/lib/API/EctotableAPIGen';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import MultilineTextInput from 'ecto-common/lib/TextInput/MultilineTextInput';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import styles from './EditEctotableScreen.module.css';
import { KeyValueColumn } from 'ecto-common/lib/KeyValueInput/KeyValueColumn';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

type EditEctotableScreenProps = {
  screen: ScreenContentsResponseScreenContent;
  index: string;
  screenContentId: string;
  onScreenContentsUpdated: (screenContents: ScreenContentsResponse) => void;
};

const typeOptions = enumValues(ScreenContentType).map((value) => ({
  label: value,
  value
}));

type EctotableFileContentEditorProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  screen: ScreenContentsResponseScreenContent;
  type: ScreenContentType;
};

const EctotableFileContentEditor = ({
  onChange,
  screen,
  type
}: EctotableFileContentEditorProps) => {
  return (
    <KeyValueGeneric
      keyText={
        type === ScreenContentType.Video
          ? T.admin.ectotable.video
          : T.admin.ectotable.background
      }
      className={styles.fileInput}
    >
      {screen?.filename && (
        <div>
          {T.admin.ectotable.existingfile}{' '}
          <a href={screen.contentUri} target="_blank">
            {screen.filename}
          </a>
        </div>
      )}
      <input type={'file'} onChange={onChange} />
    </KeyValueGeneric>
  );
};

const EditEctotableScreen = ({
  index,
  screen,
  screenContentId,
  onScreenContentsUpdated
}: EditEctotableScreenProps) => {
  const [selectedType, setSelectedType] = useState<ScreenContentType>(
    screen?.screenContentType
  );
  const [text, setText] = useState(screen?.text);
  const [file, setFile] = useState<File | null>(null);
  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const clearCache = (response: ScreenContentsResponse) => {
    onScreenContentsUpdated(response);
    setFile(null);
    queryClient.invalidateQueries({
      queryKey:
        EctotableAPIGen.ScreenContentAdmin.listScreenContent.path(
          contextSettings
        )
    });
    queryClient.invalidateQueries({
      queryKey:
        EctotableAPIGen.ScreenContentAdmin.getScreenContentConfiguration.path(
          contextSettings,
          { id: screenContentId }
        )
    });
  };

  const { mutate: saveText, isPending: isSavingText } =
    EctotableAPIGen.ScreenContentAdmin.postScreenContentText.useMutation(
      { id: screenContentId, index },
      {
        onSuccess: clearCache
      }
    );

  const { mutate: saveFile, isPending: isSavingFile } =
    EctotableAPIGen.ScreenContentAdmin.postScreenContentFile.useMutation(
      { id: screenContentId, index },
      {
        onSuccess: clearCache
      }
    );

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files[0]);
  }, []);

  const selectedOption = _.find(typeOptions, ['value', selectedType]);

  const save = () => {
    if (selectedType === ScreenContentType.Text) {
      saveText({
        text: text
      });
    } else {
      saveFile({
        file: file
      });
    }
  };

  const isLoading = isSavingFile || isSavingText;

  return (
    <div>
      <KeyValueColumn>
        <KeyValueSelectableInput<GenericSelectOption<ScreenContentType>>
          keyText={
            index === 'fullwall'
              ? T.admin.ectotable.fullscreentype
              : T.format(T.admin.ectotable.typeformat, index)
          }
          options={typeOptions}
          value={selectedOption}
          isLoading={isLoading}
          onChange={(option) => {
            setFile(null);
            setText('');
            setSelectedType(option.value);
          }}
        />
        {selectedType === ScreenContentType.Text && (
          <KeyValueGeneric keyText={T.admin.ectotable.text}>
            <MultilineTextInput
              rows={5}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </KeyValueGeneric>
        )}
        {selectedType === ScreenContentType.Image && (
          <EctotableFileContentEditor
            type={selectedType}
            onChange={onChange}
            screen={screen}
          />
        )}
        {selectedType === ScreenContentType.Video && (
          <EctotableFileContentEditor
            type={selectedType}
            onChange={onChange}
            screen={screen}
          />
        )}
        <SaveButton
          loading={isLoading}
          onClick={save}
          disabled={
            selectedType == null ||
            isLoading ||
            (selectedType !== ScreenContentType.Text && file == null) ||
            (selectedType === ScreenContentType.Text &&
              (text == null || text === screen?.text))
          }
        >
          {T.common.save}
        </SaveButton>
      </KeyValueColumn>
    </div>
  );
};

export default React.memo(EditEctotableScreen);
