import React, { useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import Icons from 'ecto-common/lib/Icons/Icons';
import {
  checkboxColumn,
  standardColumns
} from 'ecto-common/lib/utils/dataTableUtils';

import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { ProcessMapDataType } from './ProcessMapDataHandling';

interface ProcessMapTableProps {
  onClickRow?(
    processMap: ProcessMapDataType,
    row: number,
    column: number
  ): void;
  searchString?: string;
  showCheckboxes?: boolean;
  selectedIds?: string[];
  isLoading?: boolean;
  items: Record<string, ProcessMapDataType>;
  hasError?: boolean;
  onEdit?: (processMap: ProcessMapDataType) => void;
  nameColumnTitle?: string;
}

const ProcessMapTable = ({
  items,
  onClickRow,
  searchString,
  showCheckboxes = false,
  selectedIds,
  isLoading = false,
  hasError,
  nameColumnTitle = T.admin.processmaps.name,
  onEdit = null
}: ProcessMapTableProps) => {
  const filteredProcessMaps = useMemo(() => {
    const searchStringLowerCase = _.toLower(searchString);
    return _.filter(
      items,
      (item) => item && _.includes(_.toLower(item?.name), searchStringLowerCase)
    );
  }, [items, searchString]);

  const allColumns: DataTableColumnProps<ProcessMapDataType>[] = useMemo(() => {
    return _.compact([
      showCheckboxes &&
        checkboxColumn({
          rowIsChecked: (item) => _.includes(selectedIds, item.id)
        }),
      {
        label: nameColumnTitle,

        dataKey: 'name',
        idKey: 'id',
        linkColumn: true,
        dataFormatter: (value: string) => {
          return (
            <>
              <Icons.File /> {value}{' '}
            </>
          );
        }
      },
      {
        label: T.admin.processmaps.description,
        dataKey: 'description'
      },
      ...(onEdit ? standardColumns({ onEdit }) : [])
    ]);
  }, [showCheckboxes, nameColumnTitle, onEdit, selectedIds]);

  return (
    <DataTable<ProcessMapDataType>
      columns={allColumns}
      data={filteredProcessMaps}
      hasError={hasError}
      noDataText={T.admin.processmaps.nofound}
      isLoading={isLoading}
      onClickRow={onClickRow}
    />
  );
};

export default ProcessMapTable;
