import React, { useCallback, useContext, useEffect, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import { text, updateInputItem } from 'ecto-common/lib/ModelForm/formUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import APIGen, {
  AddOrUpdateDashboardRequestModel,
  DashboardResponseModel
} from 'ecto-common/lib/API/APIGen';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

export const DashboardModels = [
  text<DashboardResponseModel>(
    (input) => input.name,
    T.admin.dashboards.column.name,
    isNullOrWhitespace
  ),
  text<DashboardResponseModel>(
    (input) => input.description,
    T.admin.dashboards.column.description,
    null
  )
];

const saveUserDashboardPromise = (
  contextSettings: ApiContextSettings,
  { dashboardId, name, description }: AddOrUpdateDashboardRequestModel
) => {
  return IdentityServiceAPIGenV2.User.createOrUpdateUserDashboards.promise(
    contextSettings,
    { dashboards: [{ id: dashboardId, name, description }] },
    null
  );
};

interface EditDashboardPropertiesModalProps {
  isOpen?: boolean;
  onCloseModal(): void;
  dashboard?: DashboardResponseModel;
  onDashboardUpdated(
    editedDashboard: DashboardResponseModel,
    isNew: boolean
  ): void;
  isNew?: boolean;
}

const EditDashboardPropertiesModal = ({
  isOpen,
  onCloseModal,
  dashboard,
  onDashboardUpdated,
  isNew
}: EditDashboardPropertiesModalProps) => {
  const isAdmin = useCommonSelector((state) => state.general.isAdmin);

  const [hasChanges, setHasChanges] = useState(false);
  const [editDashboard, setEditDashboard] =
    useState<DashboardResponseModel>(null);

  const { contextSettings } = useContext(TenantContext);

  const saveDashboardMutation = useMutation({
    mutationFn: () => {
      if (isAdmin) {
        return APIGen.AdminDashboard.addOrUpdateDashboards.promise(
          contextSettings,
          [editDashboard as AddOrUpdateDashboardRequestModel],
          null
        );
      }

      return saveUserDashboardPromise(
        contextSettings,
        editDashboard as AddOrUpdateDashboardRequestModel
      );
    },

    onSuccess: () => {
      setHasChanges(false);
      onCloseModal();
      onDashboardUpdated(editDashboard, isNew);
    },

    onError: () => {
      toastStore.addErrorToast(
        isNew
          ? T.admin.dashboards.save.failure
          : T.admin.dashboards.change.failure
      );
    }
  });

  useEffect(() => {
    setEditDashboard(_.cloneDeep(dashboard));
  }, [isOpen, dashboard]);

  const isValid = modelFormIsValid(DashboardModels, editDashboard);
  const isLoading = false;

  const onUpdateDashboard = useCallback((key: string[], value: unknown) => {
    setEditDashboard((oldDashboard) => {
      setHasChanges(true);
      return updateInputItem(oldDashboard, key, value);
    });
  }, []);

  const onSaveDashboard = useCallback(() => {
    if (isNew) {
      saveDashboardMutation.mutate();
    } else {
      onCloseModal();
      onDashboardUpdated(editDashboard, isNew);
    }
  }, [
    editDashboard,
    isNew,
    onCloseModal,
    onDashboardUpdated,
    saveDashboardMutation
  ]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onCloseModal}
      disableActionButton={!isValid || (!isNew && !hasChanges)}
      isLoading={saveDashboardMutation.isPending}
      onConfirmClick={onSaveDashboard}
      headerIcon={isNew ? Icons.Add : Icons.Edit}
      title={isNew ? T.admin.dashboards.add : T.admin.dashboards.edit}
    >
      <ModelForm
        input={editDashboard}
        models={DashboardModels}
        onUpdateInput={onUpdateDashboard}
        isLoading={isLoading}
      />
    </ActionModal>
  );
};

export default EditDashboardPropertiesModal;
